/* eslint-disable no-useless-escape */
const data =  {
    "errors": [],
    "status": "200",
    "data": {
        "etf": { 
            "current_page": 1,
            "data": [
                {
                    "id": 356,
                    "market_id": 3,
                    "tradingview": "ACWI",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "ACWI",
                    "market_name": "stock",
                    "price_aud": "164.59",
                    "price_brl": "548.97",
                    "price_cad": "146.42",
                    "price_eur": "100.11",
                    "price_gbp": "86.02",
                    "price_usd": "107",
                    "price_php": "6162.34",
                    "price_zar": "2050.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 294,
                    "market_id": 3,
                    "tradingview": "AGG",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "AGG",
                    "market_name": "stock",
                    "price_aud": "147.12",
                    "price_brl": "490.69",
                    "price_cad": "130.87",
                    "price_eur": "89.48",
                    "price_gbp": "76.89",
                    "price_usd": "95.64",
                    "price_php": "5508.10",
                    "price_zar": "1833.05",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 424,
                    "market_id": 3,
                    "tradingview": "BBJP",
                    "name": "JPmorgan",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "BBJP",
                    "market_name": "stock",
                    "price_aud": "85.15",
                    "price_brl": "284.00",
                    "price_cad": "75.75",
                    "price_eur": "51.79",
                    "price_gbp": "44.50",
                    "price_usd": "55.355",
                    "price_php": "3188.01",
                    "price_zar": "1060.94",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/jpmorgan--big.svg"
                },
                {
                    "id": 371,
                    "market_id": 3,
                    "tradingview": "BIV",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "BIV",
                    "market_name": "stock",
                    "price_aud": "113.18",
                    "price_brl": "377.51",
                    "price_cad": "100.69",
                    "price_eur": "68.84",
                    "price_gbp": "59.16",
                    "price_usd": "73.58",
                    "price_php": "4237.62",
                    "price_zar": "1410.24",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 293,
                    "market_id": 3,
                    "tradingview": "BND",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "BND",
                    "market_name": "stock",
                    "price_aud": "109.08",
                    "price_brl": "363.81",
                    "price_cad": "97.03",
                    "price_eur": "66.34",
                    "price_gbp": "57.01",
                    "price_usd": "70.9101",
                    "price_php": "4083.85",
                    "price_zar": "1359.07",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 309,
                    "market_id": 3,
                    "tradingview": "BNDX",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "BNDX",
                    "market_name": "stock",
                    "price_aud": "74.72",
                    "price_brl": "249.22",
                    "price_cad": "66.47",
                    "price_eur": "45.45",
                    "price_gbp": "39.05",
                    "price_usd": "48.575",
                    "price_php": "2797.53",
                    "price_zar": "930.99",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 317,
                    "market_id": 3,
                    "tradingview": "BSV",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "BSV",
                    "market_name": "stock",
                    "price_aud": "116.97",
                    "price_brl": "390.13",
                    "price_cad": "104.05",
                    "price_eur": "71.14",
                    "price_gbp": "61.13",
                    "price_usd": "76.04",
                    "price_php": "4379.30",
                    "price_zar": "1457.39",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 383,
                    "market_id": 3,
                    "tradingview": "COWZ",
                    "name": "Pacer",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "COWZ",
                    "market_name": "stock",
                    "price_aud": "85.59",
                    "price_brl": "285.47",
                    "price_cad": "76.14",
                    "price_eur": "52.06",
                    "price_gbp": "44.73",
                    "price_usd": "55.64",
                    "price_php": "3204.42",
                    "price_zar": "1066.40",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/pacer-american-energy--big.svg"
                },
                {
                    "id": 408,
                    "market_id": 3,
                    "tradingview": "CSB",
                    "name": "VictoryShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "CSB",
                    "market_name": "stock",
                    "price_aud": "83.68",
                    "price_brl": "279.10",
                    "price_cad": "74.44",
                    "price_eur": "50.90",
                    "price_gbp": "43.74",
                    "price_usd": "54.4",
                    "price_php": "3133.00",
                    "price_zar": "1042.64",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/victoryshares--big.svg"
                },
                {
                    "id": 342,
                    "market_id": 3,
                    "tradingview": "DGRO",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "DGRO",
                    "market_name": "stock",
                    "price_aud": "86.99",
                    "price_brl": "290.16",
                    "price_cad": "77.39",
                    "price_eur": "52.91",
                    "price_gbp": "45.47",
                    "price_usd": "56.5546",
                    "price_php": "3257.09",
                    "price_zar": "1083.93",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 416,
                    "market_id": 3,
                    "tradingview": "DGRW",
                    "name": "WisdomTree",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "DGRW",
                    "market_name": "stock",
                    "price_aud": "113.58",
                    "price_brl": "378.84",
                    "price_cad": "101.04",
                    "price_eur": "69.08",
                    "price_gbp": "59.37",
                    "price_usd": "73.84",
                    "price_php": "4252.59",
                    "price_zar": "1415.22",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/wisdomtree--big.svg"
                },
                {
                    "id": 351,
                    "market_id": 3,
                    "tradingview": "DVY",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "DVY",
                    "market_name": "stock",
                    "price_aud": "184.91",
                    "price_brl": "616.75",
                    "price_cad": "164.50",
                    "price_eur": "112.47",
                    "price_gbp": "96.65",
                    "price_usd": "120.21",
                    "price_php": "6923.13",
                    "price_zar": "2303.96",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 343,
                    "market_id": 3,
                    "tradingview": "EEM",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "EEM",
                    "market_name": "stock",
                    "price_aud": "62.45",
                    "price_brl": "208.30",
                    "price_cad": "55.56",
                    "price_eur": "37.98",
                    "price_gbp": "32.64",
                    "price_usd": "40.6",
                    "price_php": "2338.24",
                    "price_zar": "778.14",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 307,
                    "market_id": 3,
                    "tradingview": "EFA",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "EFA",
                    "market_name": "stock",
                    "price_aud": "119.70",
                    "price_brl": "399.26",
                    "price_cad": "106.49",
                    "price_eur": "72.81",
                    "price_gbp": "62.56",
                    "price_usd": "77.82",
                    "price_php": "4481.81",
                    "price_zar": "1491.51",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 381,
                    "market_id": 3,
                    "tradingview": "EFG",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "EFG",
                    "market_name": "stock",
                    "price_aud": "153.44",
                    "price_brl": "511.78",
                    "price_cad": "136.50",
                    "price_eur": "93.32",
                    "price_gbp": "80.20",
                    "price_usd": "99.75",
                    "price_php": "5744.80",
                    "price_zar": "1911.82",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 372,
                    "market_id": 3,
                    "tradingview": "EFV",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "EFV",
                    "market_name": "stock",
                    "price_aud": "82.85",
                    "price_brl": "276.33",
                    "price_cad": "73.70",
                    "price_eur": "50.39",
                    "price_gbp": "43.30",
                    "price_usd": "53.86",
                    "price_php": "3101.91",
                    "price_zar": "1032.29",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 370,
                    "market_id": 3,
                    "tradingview": "EMB",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "EMB",
                    "market_name": "stock",
                    "price_aud": "135.13",
                    "price_brl": "450.72",
                    "price_cad": "120.21",
                    "price_eur": "82.19",
                    "price_gbp": "70.63",
                    "price_usd": "87.85",
                    "price_php": "5059.46",
                    "price_zar": "1683.74",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 375,
                    "market_id": 3,
                    "tradingview": "ESGU",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "ESGU",
                    "market_name": "stock",
                    "price_aud": "170.60",
                    "price_brl": "569.01",
                    "price_cad": "151.76",
                    "price_eur": "103.76",
                    "price_gbp": "89.16",
                    "price_usd": "110.9059",
                    "price_php": "6387.29",
                    "price_zar": "2125.63",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 409,
                    "market_id": 3,
                    "tradingview": "FNDF",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "FNDF",
                    "market_name": "stock",
                    "price_aud": "53.78",
                    "price_brl": "179.37",
                    "price_cad": "47.84",
                    "price_eur": "32.71",
                    "price_gbp": "28.11",
                    "price_usd": "34.96",
                    "price_php": "2013.42",
                    "price_zar": "670.05",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                {
                    "id": 398,
                    "market_id": 3,
                    "tradingview": "FNDX",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "FNDX",
                    "market_name": "stock",
                    "price_aud": "100.38",
                    "price_brl": "334.80",
                    "price_cad": "89.29",
                    "price_eur": "61.05",
                    "price_gbp": "52.46",
                    "price_usd": "65.255",
                    "price_php": "3758.17",
                    "price_zar": "1250.68",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                {
                    "id": 423,
                    "market_id": 3,
                    "tradingview": "FTCS",
                    "name": "First Trust",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "FTCS",
                    "market_name": "stock",
                    "price_aud": "126.84",
                    "price_brl": "423.07",
                    "price_cad": "112.84",
                    "price_eur": "77.15",
                    "price_gbp": "66.30",
                    "price_usd": "82.46",
                    "price_php": "4749.04",
                    "price_zar": "1580.44",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/first-trust--big.svg"
                },
                {
                    "id": 393,
                    "market_id": 3,
                    "tradingview": "FVD",
                    "name": "First Trust",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "FVD",
                    "market_name": "stock",
                    "price_aud": "63.07",
                    "price_brl": "210.38",
                    "price_cad": "56.11",
                    "price_eur": "38.36",
                    "price_gbp": "32.97",
                    "price_usd": "41.005",
                    "price_php": "2361.56",
                    "price_zar": "785.91",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/first-trust--big.svg"
                },
                {
                    "id": 392,
                    "market_id": 3,
                    "tradingview": "GDX",
                    "name": "VanEck",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "GDX",
                    "market_name": "stock",
                    "price_aud": "50.65",
                    "price_brl": "168.95",
                    "price_cad": "45.06",
                    "price_eur": "30.81",
                    "price_gbp": "26.47",
                    "price_usd": "32.93",
                    "price_php": "1896.50",
                    "price_zar": "631.14",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vaneck--big.svg"
                },
                {
                    "id": 301,
                    "market_id": 3,
                    "tradingview": "GLD",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "GLD",
                    "market_name": "stock",
                    "price_aud": "329.60",
                    "price_brl": "1099.33",
                    "price_cad": "293.21",
                    "price_eur": "200.47",
                    "price_gbp": "172.27",
                    "price_usd": "214.27",
                    "price_php": "12340.24",
                    "price_zar": "4106.72",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                {
                    "id": 337,
                    "market_id": 3,
                    "tradingview": "GOVT",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "GOVT",
                    "market_name": "stock",
                    "price_aud": "34.19",
                    "price_brl": "114.05",
                    "price_cad": "30.42",
                    "price_eur": "20.80",
                    "price_gbp": "17.87",
                    "price_usd": "22.23",
                    "price_php": "1280.27",
                    "price_zar": "426.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 399,
                    "market_id": 3,
                    "tradingview": "GSLC",
                    "name": "Goldman Sachs",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "GSLC",
                    "market_name": "stock",
                    "price_aud": "153.76",
                    "price_brl": "512.85",
                    "price_cad": "136.79",
                    "price_eur": "93.52",
                    "price_gbp": "80.36",
                    "price_usd": "99.96",
                    "price_php": "5756.90",
                    "price_zar": "1915.84",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/golden-sachs-etf-trust-goldman--big.svg"
                },
                {
                    "id": 407,
                    "market_id": 3,
                    "tradingview": "HDV",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "HDV",
                    "market_name": "stock",
                    "price_aud": "168.08",
                    "price_brl": "560.62",
                    "price_cad": "149.53",
                    "price_eur": "102.23",
                    "price_gbp": "87.85",
                    "price_usd": "109.27",
                    "price_php": "6293.08",
                    "price_zar": "2094.28",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 368,
                    "market_id": 3,
                    "tradingview": "HYG",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "HYG",
                    "market_name": "stock",
                    "price_aud": "118.14",
                    "price_brl": "394.03",
                    "price_cad": "105.09",
                    "price_eur": "71.85",
                    "price_gbp": "61.74",
                    "price_usd": "76.8",
                    "price_php": "4423.07",
                    "price_zar": "1471.96",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 334,
                    "market_id": 3,
                    "tradingview": "IAU",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IAU",
                    "market_name": "stock",
                    "price_aud": "67.34",
                    "price_brl": "224.62",
                    "price_cad": "59.91",
                    "price_eur": "40.96",
                    "price_gbp": "35.20",
                    "price_usd": "43.78",
                    "price_php": "2521.38",
                    "price_zar": "839.09",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 422,
                    "market_id": 3,
                    "tradingview": "IBB",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IBB",
                    "market_name": "stock",
                    "price_aud": "196.92",
                    "price_brl": "656.82",
                    "price_cad": "175.18",
                    "price_eur": "119.77",
                    "price_gbp": "102.92",
                    "price_usd": "128.02",
                    "price_php": "7372.93",
                    "price_zar": "2453.64",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 402,
                    "market_id": 3,
                    "tradingview": "IDEV",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IDEV",
                    "market_name": "stock",
                    "price_aud": "100.75",
                    "price_brl": "336.05",
                    "price_cad": "89.63",
                    "price_eur": "61.28",
                    "price_gbp": "52.66",
                    "price_usd": "65.5",
                    "price_php": "3772.28",
                    "price_zar": "1255.38",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 379,
                    "market_id": 3,
                    "tradingview": "IEF",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IEF",
                    "market_name": "stock",
                    "price_aud": "141.27",
                    "price_brl": "471.19",
                    "price_cad": "125.67",
                    "price_eur": "85.92",
                    "price_gbp": "73.84",
                    "price_usd": "91.84",
                    "price_php": "5289.25",
                    "price_zar": "1760.21",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 291,
                    "market_id": 3,
                    "tradingview": "IEFA",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IEFA",
                    "market_name": "stock",
                    "price_aud": "111.26",
                    "price_brl": "371.10",
                    "price_cad": "98.98",
                    "price_eur": "67.67",
                    "price_gbp": "58.15",
                    "price_usd": "72.33",
                    "price_php": "4165.63",
                    "price_zar": "1386.28",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 390,
                    "market_id": 3,
                    "tradingview": "IEI",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IEI",
                    "market_name": "stock",
                    "price_aud": "175.13",
                    "price_brl": "584.12",
                    "price_cad": "155.79",
                    "price_eur": "106.52",
                    "price_gbp": "91.53",
                    "price_usd": "113.85",
                    "price_php": "6556.85",
                    "price_zar": "2182.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 297,
                    "market_id": 3,
                    "tradingview": "IEMG",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IEMG",
                    "market_name": "stock",
                    "price_aud": "78.36",
                    "price_brl": "261.35",
                    "price_cad": "69.71",
                    "price_eur": "47.66",
                    "price_gbp": "40.95",
                    "price_usd": "50.94",
                    "price_php": "2933.74",
                    "price_zar": "976.32",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 394,
                    "market_id": 3,
                    "tradingview": "IGIB",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IGIB",
                    "market_name": "stock",
                    "price_aud": "77.53",
                    "price_brl": "258.58",
                    "price_cad": "68.97",
                    "price_eur": "47.15",
                    "price_gbp": "40.52",
                    "price_usd": "50.4",
                    "price_php": "2902.64",
                    "price_zar": "965.97",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 344,
                    "market_id": 3,
                    "tradingview": "IGSB",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IGSB",
                    "market_name": "stock",
                    "price_aud": "78.19",
                    "price_brl": "260.79",
                    "price_cad": "69.56",
                    "price_eur": "47.56",
                    "price_gbp": "40.87",
                    "price_usd": "50.83",
                    "price_php": "2927.40",
                    "price_zar": "974.21",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 298,
                    "market_id": 3,
                    "tradingview": "IJH",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IJH",
                    "market_name": "stock",
                    "price_aud": "88.94",
                    "price_brl": "296.65",
                    "price_cad": "79.12",
                    "price_eur": "54.10",
                    "price_gbp": "46.49",
                    "price_usd": "57.82",
                    "price_php": "3329.97",
                    "price_zar": "1108.18",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 300,
                    "market_id": 3,
                    "tradingview": "IJR",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IJR",
                    "market_name": "stock",
                    "price_aud": "162.99",
                    "price_brl": "543.64",
                    "price_cad": "145.00",
                    "price_eur": "99.13",
                    "price_gbp": "85.19",
                    "price_usd": "105.96",
                    "price_php": "6102.45",
                    "price_zar": "2030.84",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 312,
                    "market_id": 3,
                    "tradingview": "ITOT",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "ITOT",
                    "market_name": "stock",
                    "price_aud": "171.90",
                    "price_brl": "573.34",
                    "price_cad": "152.92",
                    "price_eur": "104.55",
                    "price_gbp": "89.84",
                    "price_usd": "111.75",
                    "price_php": "6435.91",
                    "price_zar": "2141.81",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 346,
                    "market_id": 3,
                    "tradingview": "IUSB",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IUSB",
                    "market_name": "stock",
                    "price_aud": "68.57",
                    "price_brl": "228.70",
                    "price_cad": "61.00",
                    "price_eur": "41.70",
                    "price_gbp": "35.84",
                    "price_usd": "44.575",
                    "price_php": "2567.16",
                    "price_zar": "854.33",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 410,
                    "market_id": 3,
                    "tradingview": "IUSG",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IUSG",
                    "market_name": "stock",
                    "price_aud": "173.60",
                    "price_brl": "579.04",
                    "price_cad": "154.44",
                    "price_eur": "105.59",
                    "price_gbp": "90.74",
                    "price_usd": "112.86",
                    "price_php": "6499.83",
                    "price_zar": "2163.09",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 339,
                    "market_id": 3,
                    "tradingview": "IVE",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IVE",
                    "market_name": "stock",
                    "price_aud": "278.33",
                    "price_brl": "928.33",
                    "price_cad": "247.60",
                    "price_eur": "169.28",
                    "price_gbp": "145.47",
                    "price_usd": "180.94",
                    "price_php": "10420.70",
                    "price_zar": "3467.91",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 285,
                    "market_id": 3,
                    "tradingview": "IVV",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IVV",
                    "market_name": "stock",
                    "price_aud": "782.40",
                    "price_brl": "2609.63",
                    "price_cad": "696.02",
                    "price_eur": "475.87",
                    "price_gbp": "408.93",
                    "price_usd": "508.64",
                    "price_php": "29293.59",
                    "price_zar": "9748.65",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 323,
                    "market_id": 3,
                    "tradingview": "IVW",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IVW",
                    "market_name": "stock",
                    "price_aud": "125.21",
                    "price_brl": "417.63",
                    "price_cad": "111.39",
                    "price_eur": "76.16",
                    "price_gbp": "65.44",
                    "price_usd": "81.4",
                    "price_php": "4687.99",
                    "price_zar": "1560.12",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 330,
                    "market_id": 3,
                    "tradingview": "IWB",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWB",
                    "market_name": "stock",
                    "price_aud": "427.27",
                    "price_brl": "1425.13",
                    "price_cad": "380.10",
                    "price_eur": "259.88",
                    "price_gbp": "223.32",
                    "price_usd": "277.77",
                    "price_php": "15997.33",
                    "price_zar": "5323.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 306,
                    "market_id": 3,
                    "tradingview": "IWD",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWD",
                    "market_name": "stock",
                    "price_aud": "267.11",
                    "price_brl": "890.93",
                    "price_cad": "237.62",
                    "price_eur": "162.46",
                    "price_gbp": "139.61",
                    "price_usd": "173.65",
                    "price_php": "10000.85",
                    "price_zar": "3328.19",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 296,
                    "market_id": 3,
                    "tradingview": "IWF",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWF",
                    "market_name": "stock",
                    "price_aud": "501.46",
                    "price_brl": "1672.58",
                    "price_cad": "446.10",
                    "price_eur": "305.00",
                    "price_gbp": "262.09",
                    "price_usd": "326",
                    "price_php": "18774.99",
                    "price_zar": "6248.15",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 303,
                    "market_id": 3,
                    "tradingview": "IWM",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWM",
                    "market_name": "stock",
                    "price_aud": "304.29",
                    "price_brl": "1014.94",
                    "price_cad": "270.70",
                    "price_eur": "185.08",
                    "price_gbp": "159.04",
                    "price_usd": "197.82",
                    "price_php": "11392.85",
                    "price_zar": "3791.44",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 400,
                    "market_id": 3,
                    "tradingview": "IWN",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWN",
                    "market_name": "stock",
                    "price_aud": "233.44",
                    "price_brl": "778.62",
                    "price_cad": "207.67",
                    "price_eur": "141.98",
                    "price_gbp": "122.01",
                    "price_usd": "151.76",
                    "price_php": "8740.16",
                    "price_zar": "2908.65",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 411,
                    "market_id": 3,
                    "tradingview": "IWO",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWO",
                    "market_name": "stock",
                    "price_aud": "388.12",
                    "price_brl": "1294.55",
                    "price_cad": "345.27",
                    "price_eur": "236.06",
                    "price_gbp": "202.86",
                    "price_usd": "252.32",
                    "price_php": "14531.61",
                    "price_zar": "4835.99",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 384,
                    "market_id": 3,
                    "tradingview": "IWP",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWP",
                    "market_name": "stock",
                    "price_aud": "167.00",
                    "price_brl": "557.01",
                    "price_cad": "148.56",
                    "price_eur": "101.57",
                    "price_gbp": "87.28",
                    "price_usd": "108.5668",
                    "price_php": "6252.58",
                    "price_zar": "2080.80",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 332,
                    "market_id": 3,
                    "tradingview": "IWR",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWR",
                    "market_name": "stock",
                    "price_aud": "123.59",
                    "price_brl": "412.22",
                    "price_cad": "109.94",
                    "price_eur": "75.17",
                    "price_gbp": "64.59",
                    "price_usd": "80.345",
                    "price_php": "4627.23",
                    "price_zar": "1539.90",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 388,
                    "market_id": 3,
                    "tradingview": "IWS",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWS",
                    "market_name": "stock",
                    "price_aud": "184.45",
                    "price_brl": "615.21",
                    "price_cad": "164.08",
                    "price_eur": "112.18",
                    "price_gbp": "96.40",
                    "price_usd": "119.9099",
                    "price_php": "6905.85",
                    "price_zar": "2298.21",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 396,
                    "market_id": 3,
                    "tradingview": "IWV",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWV",
                    "market_name": "stock",
                    "price_aud": "444.87",
                    "price_brl": "1483.82",
                    "price_cad": "395.76",
                    "price_eur": "270.58",
                    "price_gbp": "232.52",
                    "price_usd": "289.21",
                    "price_php": "16656.18",
                    "price_zar": "5543.03",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 327,
                    "market_id": 3,
                    "tradingview": "IXUS",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IXUS",
                    "market_name": "stock",
                    "price_aud": "102.22",
                    "price_brl": "340.95",
                    "price_cad": "90.94",
                    "price_eur": "62.17",
                    "price_gbp": "53.43",
                    "price_usd": "66.455",
                    "price_php": "3827.28",
                    "price_zar": "1273.68",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 389,
                    "market_id": 3,
                    "tradingview": "IYW",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IYW",
                    "market_name": "stock",
                    "price_aud": "198.31",
                    "price_brl": "661.44",
                    "price_cad": "176.41",
                    "price_eur": "120.61",
                    "price_gbp": "103.65",
                    "price_usd": "128.92",
                    "price_php": "7424.76",
                    "price_zar": "2470.89",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 335,
                    "market_id": 3,
                    "tradingview": "JEPI",
                    "name": "JPmorgan",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "JEPI",
                    "market_name": "stock",
                    "price_aud": "86.77",
                    "price_brl": "289.42",
                    "price_cad": "77.19",
                    "price_eur": "52.78",
                    "price_gbp": "45.35",
                    "price_usd": "56.41",
                    "price_php": "3248.76",
                    "price_zar": "1081.16",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/jpmorgan--big.svg"
                },
                {
                    "id": 341,
                    "market_id": 3,
                    "tradingview": "JPST",
                    "name": "JPmorgan",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "JPST",
                    "market_name": "stock",
                    "price_aud": "77.48",
                    "price_brl": "258.43",
                    "price_cad": "68.93",
                    "price_eur": "47.12",
                    "price_gbp": "40.50",
                    "price_usd": "50.37",
                    "price_php": "2900.91",
                    "price_zar": "965.40",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/jpmorgan--big.svg"
                },
                {
                    "id": 428,
                    "market_id": 3,
                    "tradingview": "LABU",
                    "name": "Direxion Daily S&P Biotech Bull 3X Shares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "LABU",
                    "market_name": "stock",
                    "price_aud": "140.56",
                    "price_brl": "468.83",
                    "price_cad": "125.04",
                    "price_eur": "85.49",
                    "price_gbp": "73.47",
                    "price_usd": "91.38",
                    "price_php": "5262.76",
                    "price_zar": "1751.40",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/direxion--big.svg"
                },
                {
                    "id": 318,
                    "market_id": 3,
                    "tradingview": "LQD",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "LQD",
                    "market_name": "stock",
                    "price_aud": "162.21",
                    "price_brl": "541.02",
                    "price_cad": "144.30",
                    "price_eur": "98.66",
                    "price_gbp": "84.78",
                    "price_usd": "105.45",
                    "price_php": "6073.08",
                    "price_zar": "2021.07",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 336,
                    "market_id": 3,
                    "tradingview": "MBB",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "MBB",
                    "market_name": "stock",
                    "price_aud": "138.59",
                    "price_brl": "462.27",
                    "price_cad": "123.29",
                    "price_eur": "84.30",
                    "price_gbp": "72.44",
                    "price_usd": "90.1",
                    "price_php": "5189.04",
                    "price_zar": "1726.87",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 352,
                    "market_id": 3,
                    "tradingview": "MDY",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "MDY",
                    "market_name": "stock",
                    "price_aud": "814.87",
                    "price_brl": "2717.94",
                    "price_cad": "724.91",
                    "price_eur": "495.62",
                    "price_gbp": "425.90",
                    "price_usd": "529.75",
                    "price_php": "30509.36",
                    "price_zar": "10153.24",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                {
                    "id": 382,
                    "market_id": 3,
                    "tradingview": "MGK",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "MGK",
                    "market_name": "stock",
                    "price_aud": "424.24",
                    "price_brl": "1415.02",
                    "price_cad": "377.40",
                    "price_eur": "258.03",
                    "price_gbp": "221.73",
                    "price_usd": "275.8",
                    "price_php": "15883.87",
                    "price_zar": "5286.01",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 417,
                    "market_id": 3,
                    "tradingview": "MINT",
                    "name": "Pimco",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "MINT",
                    "market_name": "stock",
                    "price_aud": "154.56",
                    "price_brl": "515.52",
                    "price_cad": "137.50",
                    "price_eur": "94.01",
                    "price_gbp": "80.78",
                    "price_usd": "100.48",
                    "price_php": "5786.84",
                    "price_zar": "1925.81",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/pimco--big.svg"
                },
                {
                    "id": 414,
                    "market_id": 3,
                    "tradingview": "MOAT",
                    "name": "VanEck",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "MOAT",
                    "market_name": "stock",
                    "price_aud": "131.87",
                    "price_brl": "439.85",
                    "price_cad": "117.31",
                    "price_eur": "80.21",
                    "price_gbp": "68.92",
                    "price_usd": "85.73",
                    "price_php": "4937.36",
                    "price_zar": "1643.11",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vaneck--big.svg"
                },
                {
                    "id": 419,
                    "market_id": 3,
                    "tradingview": "MTUM",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "MTUM",
                    "market_name": "stock",
                    "price_aud": "275.47",
                    "price_brl": "918.79",
                    "price_cad": "245.05",
                    "price_eur": "167.54",
                    "price_gbp": "143.97",
                    "price_usd": "179.08",
                    "price_php": "10313.58",
                    "price_zar": "3432.27",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 325,
                    "market_id": 3,
                    "tradingview": "MUB",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "MUB",
                    "market_name": "stock",
                    "price_aud": "163.87",
                    "price_brl": "546.56",
                    "price_cad": "145.78",
                    "price_eur": "99.67",
                    "price_gbp": "85.65",
                    "price_usd": "106.53",
                    "price_php": "6135.28",
                    "price_zar": "2041.76",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 395,
                    "market_id": 3,
                    "tradingview": "NOBL",
                    "name": "Proshares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "NOBL",
                    "market_name": "stock",
                    "price_aud": "150.48",
                    "price_brl": "501.93",
                    "price_cad": "133.87",
                    "price_eur": "91.53",
                    "price_gbp": "78.65",
                    "price_usd": "97.83",
                    "price_php": "5634.23",
                    "price_zar": "1875.02",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/proshares--big.svg"
                },
                {
                    "id": 387,
                    "market_id": 3,
                    "tradingview": "PFF",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "PFF",
                    "market_name": "stock",
                    "price_aud": "48.22",
                    "price_brl": "160.84",
                    "price_cad": "42.90",
                    "price_eur": "29.33",
                    "price_gbp": "25.20",
                    "price_usd": "31.35",
                    "price_php": "1805.51",
                    "price_zar": "600.86",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 284,
                    "market_id": 3,
                    "tradingview": "QQQ",
                    "name": "Invesco",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "QQQ",
                    "market_name": "stock",
                    "price_aud": "657.64",
                    "price_brl": "2193.49",
                    "price_cad": "585.03",
                    "price_eur": "399.99",
                    "price_gbp": "343.72",
                    "price_usd": "427.53",
                    "price_php": "24622.31",
                    "price_zar": "8194.08",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/invesco--big.svg"
                },
                {
                    "id": 287,
                    "market_id": 3,
                    "tradingview": "QQQM",
                    "name": "Invesco",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "QQQM",
                    "market_name": "stock",
                    "price_aud": "270.31",
                    "price_brl": "901.60",
                    "price_cad": "240.47",
                    "price_eur": "164.41",
                    "price_gbp": "141.28",
                    "price_usd": "175.73",
                    "price_php": "10120.64",
                    "price_zar": "3368.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/invesco--big.svg"
                },
                {
                    "id": 328,
                    "market_id": 3,
                    "tradingview": "QUAL",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "QUAL",
                    "market_name": "stock",
                    "price_aud": "242.56",
                    "price_brl": "809.04",
                    "price_cad": "215.78",
                    "price_eur": "147.53",
                    "price_gbp": "126.78",
                    "price_usd": "157.69",
                    "price_php": "9081.68",
                    "price_zar": "3022.30",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 316,
                    "market_id": 3,
                    "tradingview": "RSP",
                    "name": "Invesco",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "RSP",
                    "market_name": "stock",
                    "price_aud": "250.19",
                    "price_brl": "834.49",
                    "price_cad": "222.57",
                    "price_eur": "152.17",
                    "price_gbp": "130.77",
                    "price_usd": "162.65",
                    "price_php": "9367.34",
                    "price_zar": "3117.37",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/invesco--big.svg"
                },
                {
                    "id": 377,
                    "market_id": 3,
                    "tradingview": "SCHA",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHA",
                    "market_name": "stock",
                    "price_aud": "71.84",
                    "price_brl": "239.60",
                    "price_cad": "63.90",
                    "price_eur": "43.69",
                    "price_gbp": "37.55",
                    "price_usd": "46.7",
                    "price_php": "2689.55",
                    "price_zar": "895.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                {
                    "id": 345,
                    "market_id": 3,
                    "tradingview": "SCHB",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHB",
                    "market_name": "stock",
                    "price_aud": "90.76",
                    "price_brl": "302.71",
                    "price_cad": "80.74",
                    "price_eur": "55.20",
                    "price_gbp": "47.43",
                    "price_usd": "59",
                    "price_php": "3397.93",
                    "price_zar": "1130.80",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                {
                    "id": 311,
                    "market_id": 3,
                    "tradingview": "SCHD",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHD",
                    "market_name": "stock",
                    "price_aud": "119.21",
                    "price_brl": "397.62",
                    "price_cad": "106.05",
                    "price_eur": "72.51",
                    "price_gbp": "62.31",
                    "price_usd": "77.5",
                    "price_php": "4463.38",
                    "price_zar": "1485.37",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                {
                    "id": 425,
                    "market_id": 3,
                    "tradingview": "SCHE",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHE",
                    "market_name": "stock",
                    "price_aud": "38.56",
                    "price_brl": "128.62",
                    "price_cad": "34.31",
                    "price_eur": "23.45",
                    "price_gbp": "20.16",
                    "price_usd": "25.07",
                    "price_php": "1443.83",
                    "price_zar": "480.49",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                {
                    "id": 329,
                    "market_id": 3,
                    "tradingview": "SCHF",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHF",
                    "market_name": "stock",
                    "price_aud": "60.14",
                    "price_brl": "200.61",
                    "price_cad": "53.50",
                    "price_eur": "36.58",
                    "price_gbp": "31.44",
                    "price_usd": "39.1",
                    "price_php": "2251.85",
                    "price_zar": "749.39",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                {
                    "id": 353,
                    "market_id": 3,
                    "tradingview": "SCHG",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHG",
                    "market_name": "stock",
                    "price_aud": "138.10",
                    "price_brl": "460.63",
                    "price_cad": "122.86",
                    "price_eur": "84.00",
                    "price_gbp": "72.18",
                    "price_usd": "89.78",
                    "price_php": "5170.61",
                    "price_zar": "1720.73",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                {
                    "id": 412,
                    "market_id": 3,
                    "tradingview": "SCHM",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHM",
                    "market_name": "stock",
                    "price_aud": "118.92",
                    "price_brl": "396.65",
                    "price_cad": "105.79",
                    "price_eur": "72.33",
                    "price_gbp": "62.15",
                    "price_usd": "77.31",
                    "price_php": "4452.44",
                    "price_zar": "1481.73",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                {
                    "id": 376,
                    "market_id": 3,
                    "tradingview": "SCHO",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHO",
                    "market_name": "stock",
                    "price_aud": "73.70",
                    "price_brl": "245.81",
                    "price_cad": "65.56",
                    "price_eur": "44.82",
                    "price_gbp": "38.52",
                    "price_usd": "47.9101",
                    "price_php": "2759.24",
                    "price_zar": "918.25",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                {
                    "id": 397,
                    "market_id": 3,
                    "tradingview": "SCHP",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHP",
                    "market_name": "stock",
                    "price_aud": "79.03",
                    "price_brl": "263.61",
                    "price_cad": "70.31",
                    "price_eur": "48.07",
                    "price_gbp": "41.31",
                    "price_usd": "51.38",
                    "price_php": "2959.08",
                    "price_zar": "984.75",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                {
                    "id": 413,
                    "market_id": 3,
                    "tradingview": "SCHV",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHV",
                    "market_name": "stock",
                    "price_aud": "112.94",
                    "price_brl": "376.69",
                    "price_cad": "100.47",
                    "price_eur": "68.69",
                    "price_gbp": "59.03",
                    "price_usd": "73.42",
                    "price_php": "4228.40",
                    "price_zar": "1407.18",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                {
                    "id": 322,
                    "market_id": 3,
                    "tradingview": "SCHX",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHX",
                    "market_name": "stock",
                    "price_aud": "92.71",
                    "price_brl": "309.22",
                    "price_cad": "82.47",
                    "price_eur": "56.39",
                    "price_gbp": "48.46",
                    "price_usd": "60.27",
                    "price_php": "3471.07",
                    "price_zar": "1155.14",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                {
                    "id": 405,
                    "market_id": 3,
                    "tradingview": "SCZ",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCZ",
                    "market_name": "stock",
                    "price_aud": "94.65",
                    "price_brl": "315.69",
                    "price_cad": "84.20",
                    "price_eur": "57.57",
                    "price_gbp": "49.47",
                    "price_usd": "61.53",
                    "price_php": "3543.64",
                    "price_zar": "1179.29",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 348,
                    "market_id": 3,
                    "tradingview": "SDY",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SDY",
                    "market_name": "stock",
                    "price_aud": "197.09",
                    "price_brl": "657.38",
                    "price_cad": "175.33",
                    "price_eur": "119.88",
                    "price_gbp": "103.01",
                    "price_usd": "128.13",
                    "price_php": "7379.26",
                    "price_zar": "2455.75",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                {
                    "id": 403,
                    "market_id": 3,
                    "tradingview": "SGOV",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SGOV",
                    "market_name": "stock",
                    "price_aud": "154.76",
                    "price_brl": "516.19",
                    "price_cad": "137.67",
                    "price_eur": "94.13",
                    "price_gbp": "80.89",
                    "price_usd": "100.61",
                    "price_php": "5794.33",
                    "price_zar": "1928.30",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 350,
                    "market_id": 3,
                    "tradingview": "SHV",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SHV",
                    "market_name": "stock",
                    "price_aud": "169.81",
                    "price_brl": "566.39",
                    "price_cad": "151.06",
                    "price_eur": "103.28",
                    "price_gbp": "88.75",
                    "price_usd": "110.395",
                    "price_php": "6357.87",
                    "price_zar": "2115.84",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 361,
                    "market_id": 3,
                    "tradingview": "SHY",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SHY",
                    "market_name": "stock",
                    "price_aud": "125.00",
                    "price_brl": "416.91",
                    "price_cad": "111.20",
                    "price_eur": "76.02",
                    "price_gbp": "65.33",
                    "price_usd": "81.26",
                    "price_php": "4679.93",
                    "price_zar": "1557.44",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 406,
                    "market_id": 3,
                    "tradingview": "SLV",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SLV",
                    "market_name": "stock",
                    "price_aud": "38.13",
                    "price_brl": "127.19",
                    "price_cad": "33.92",
                    "price_eur": "23.19",
                    "price_gbp": "19.93",
                    "price_usd": "24.79",
                    "price_php": "1427.71",
                    "price_zar": "475.13",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 364,
                    "market_id": 3,
                    "tradingview": "SPDW",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SPDW",
                    "market_name": "stock",
                    "price_aud": "53.76",
                    "price_brl": "179.31",
                    "price_cad": "47.83",
                    "price_eur": "32.70",
                    "price_gbp": "28.10",
                    "price_usd": "34.95",
                    "price_php": "2012.84",
                    "price_zar": "669.86",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                {
                    "id": 355,
                    "market_id": 3,
                    "tradingview": "SPLG",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SPLG",
                    "market_name": "stock",
                    "price_aud": "91.56",
                    "price_brl": "305.37",
                    "price_cad": "81.45",
                    "price_eur": "55.69",
                    "price_gbp": "47.85",
                    "price_usd": "59.52",
                    "price_php": "3427.88",
                    "price_zar": "1140.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                {
                    "id": 418,
                    "market_id": 3,
                    "tradingview": "SPLV",
                    "name": "Invesco",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SPLV",
                    "market_name": "stock",
                    "price_aud": "98.98",
                    "price_brl": "330.15",
                    "price_cad": "88.06",
                    "price_eur": "60.20",
                    "price_gbp": "51.74",
                    "price_usd": "64.35",
                    "price_php": "3706.05",
                    "price_zar": "1233.34",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/invesco--big.svg"
                },
                {
                    "id": 282,
                    "market_id": 3,
                    "tradingview": "SPY",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SPY",
                    "market_name": "stock",
                    "price_aud": "778.33",
                    "price_brl": "2596.03",
                    "price_cad": "692.40",
                    "price_eur": "473.39",
                    "price_gbp": "406.80",
                    "price_usd": "505.99",
                    "price_php": "29140.98",
                    "price_zar": "9697.85",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                {
                    "id": 357,
                    "market_id": 3,
                    "tradingview": "SPYG",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SPYG",
                    "market_name": "stock",
                    "price_aud": "109.06",
                    "price_brl": "363.76",
                    "price_cad": "97.02",
                    "price_eur": "66.33",
                    "price_gbp": "57.00",
                    "price_usd": "70.9",
                    "price_php": "4083.27",
                    "price_zar": "1358.88",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                {
                    "id": 365,
                    "market_id": 3,
                    "tradingview": "SPYV",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SPYV",
                    "market_name": "stock",
                    "price_aud": "74.57",
                    "price_brl": "248.73",
                    "price_cad": "66.34",
                    "price_eur": "45.36",
                    "price_gbp": "38.98",
                    "price_usd": "48.48",
                    "price_php": "2792.06",
                    "price_zar": "929.17",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                {
                    "id": 391,
                    "market_id": 3,
                    "tradingview": "STIP",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "STIP",
                    "market_name": "stock",
                    "price_aud": "152.82",
                    "price_brl": "509.73",
                    "price_cad": "135.95",
                    "price_eur": "92.95",
                    "price_gbp": "79.87",
                    "price_usd": "99.35",
                    "price_php": "5721.77",
                    "price_zar": "1904.15",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 415,
                    "market_id": 3,
                    "tradingview": "SUB",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SUB",
                    "market_name": "stock",
                    "price_aud": "160.93",
                    "price_brl": "536.76",
                    "price_cad": "143.16",
                    "price_eur": "97.88",
                    "price_gbp": "84.11",
                    "price_usd": "104.62",
                    "price_php": "6025.28",
                    "price_zar": "2005.16",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 349,
                    "market_id": 3,
                    "tradingview": "TIP",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "TIP",
                    "market_name": "stock",
                    "price_aud": "162.85",
                    "price_brl": "543.18",
                    "price_cad": "144.87",
                    "price_eur": "99.05",
                    "price_gbp": "85.12",
                    "price_usd": "105.87",
                    "price_php": "6097.27",
                    "price_zar": "2029.12",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 427,
                    "market_id": 3,
                    "tradingview": "TLH",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "TLH",
                    "market_name": "stock",
                    "price_aud": "153.47",
                    "price_brl": "511.88",
                    "price_cad": "136.53",
                    "price_eur": "93.34",
                    "price_gbp": "80.21",
                    "price_usd": "99.77",
                    "price_php": "5745.95",
                    "price_zar": "1912.20",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 401,
                    "market_id": 3,
                    "tradingview": "TLT",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "TLT",
                    "market_name": "stock",
                    "price_aud": "136.32",
                    "price_brl": "454.67",
                    "price_cad": "121.27",
                    "price_eur": "82.91",
                    "price_gbp": "71.25",
                    "price_usd": "88.62",
                    "price_php": "5103.80",
                    "price_zar": "1698.50",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 286,
                    "market_id": 3,
                    "tradingview": "TQQQ",
                    "name": "Proshares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "TQQQ",
                    "market_name": "stock",
                    "price_aud": "83.20",
                    "price_brl": "277.51",
                    "price_cad": "74.02",
                    "price_eur": "50.61",
                    "price_gbp": "43.49",
                    "price_usd": "54.09",
                    "price_php": "3115.15",
                    "price_zar": "1036.69",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/proshares--big.svg"
                },
                {
                    "id": 362,
                    "market_id": 3,
                    "tradingview": "USFR",
                    "name": "WisdomTree",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "USFR",
                    "market_name": "stock",
                    "price_aud": "77.33",
                    "price_brl": "257.92",
                    "price_cad": "68.79",
                    "price_eur": "47.03",
                    "price_gbp": "40.42",
                    "price_usd": "50.27",
                    "price_php": "2895.15",
                    "price_zar": "963.48",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/wisdomtree--big.svg"
                },
                {
                    "id": 420,
                    "market_id": 3,
                    "tradingview": "USHY",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "USHY",
                    "market_name": "stock",
                    "price_aud": "55.50",
                    "price_brl": "185.11",
                    "price_cad": "49.37",
                    "price_eur": "33.76",
                    "price_gbp": "29.01",
                    "price_usd": "36.08",
                    "price_php": "2077.92",
                    "price_zar": "691.51",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 421,
                    "market_id": 3,
                    "tradingview": "USIG",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "USIG",
                    "market_name": "stock",
                    "price_aud": "76.21",
                    "price_brl": "254.19",
                    "price_cad": "67.80",
                    "price_eur": "46.35",
                    "price_gbp": "39.83",
                    "price_usd": "49.5435",
                    "price_php": "2853.31",
                    "price_zar": "949.56",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 331,
                    "market_id": 3,
                    "tradingview": "USMV",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "USMV",
                    "market_name": "stock",
                    "price_aud": "124.92",
                    "price_brl": "416.66",
                    "price_cad": "111.13",
                    "price_eur": "75.98",
                    "price_gbp": "65.29",
                    "price_usd": "81.21",
                    "price_php": "4677.05",
                    "price_zar": "1556.48",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                {
                    "id": 313,
                    "market_id": 3,
                    "tradingview": "VB",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VB",
                    "market_name": "stock",
                    "price_aud": "333.79",
                    "price_brl": "1113.34",
                    "price_cad": "296.94",
                    "price_eur": "203.02",
                    "price_gbp": "174.46",
                    "price_usd": "217",
                    "price_php": "12497.46",
                    "price_zar": "4159.04",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 378,
                    "market_id": 3,
                    "tradingview": "VBK",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VBK",
                    "market_name": "stock",
                    "price_aud": "384.56",
                    "price_brl": "1282.65",
                    "price_cad": "342.10",
                    "price_eur": "233.89",
                    "price_gbp": "200.99",
                    "price_usd": "250",
                    "price_php": "14398.00",
                    "price_zar": "4791.53",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 340,
                    "market_id": 3,
                    "tradingview": "VBR",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VBR",
                    "market_name": "stock",
                    "price_aud": "281.51",
                    "price_brl": "938.95",
                    "price_cad": "250.43",
                    "price_eur": "171.22",
                    "price_gbp": "147.13",
                    "price_usd": "183.01",
                    "price_php": "10539.91",
                    "price_zar": "3507.59",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 315,
                    "market_id": 3,
                    "tradingview": "VCIT",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VCIT",
                    "market_name": "stock",
                    "price_aud": "120.90",
                    "price_brl": "403.24",
                    "price_cad": "107.55",
                    "price_eur": "73.53",
                    "price_gbp": "63.19",
                    "price_usd": "78.595",
                    "price_php": "4526.44",
                    "price_zar": "1506.36",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 319,
                    "market_id": 3,
                    "tradingview": "VCSH",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VCSH",
                    "market_name": "stock",
                    "price_aud": "117.93",
                    "price_brl": "393.34",
                    "price_cad": "104.91",
                    "price_eur": "71.73",
                    "price_gbp": "61.64",
                    "price_usd": "76.665",
                    "price_php": "4415.29",
                    "price_zar": "1469.37",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 289,
                    "market_id": 3,
                    "tradingview": "VEA",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VEA",
                    "market_name": "stock",
                    "price_aud": "75.37",
                    "price_brl": "251.40",
                    "price_cad": "67.05",
                    "price_eur": "45.84",
                    "price_gbp": "39.39",
                    "price_usd": "49",
                    "price_php": "2822.01",
                    "price_zar": "939.14",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 320,
                    "market_id": 3,
                    "tradingview": "VEU",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VEU",
                    "market_name": "stock",
                    "price_aud": "88.26",
                    "price_brl": "294.39",
                    "price_cad": "78.52",
                    "price_eur": "53.68",
                    "price_gbp": "46.13",
                    "price_usd": "57.38",
                    "price_php": "3304.63",
                    "price_zar": "1099.75",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 380,
                    "market_id": 3,
                    "tradingview": "VGIT",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VGIT",
                    "market_name": "stock",
                    "price_aud": "88.27",
                    "price_brl": "294.42",
                    "price_cad": "78.53",
                    "price_eur": "53.69",
                    "price_gbp": "46.14",
                    "price_usd": "57.385",
                    "price_php": "3304.92",
                    "price_zar": "1099.85",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 354,
                    "market_id": 3,
                    "tradingview": "VGK",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VGK",
                    "market_name": "stock",
                    "price_aud": "101.97",
                    "price_brl": "340.11",
                    "price_cad": "90.71",
                    "price_eur": "62.02",
                    "price_gbp": "53.30",
                    "price_usd": "66.29",
                    "price_php": "3817.77",
                    "price_zar": "1270.52",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 347,
                    "market_id": 3,
                    "tradingview": "VGSH",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VGSH",
                    "market_name": "stock",
                    "price_aud": "88.80",
                    "price_brl": "296.19",
                    "price_cad": "79.00",
                    "price_eur": "54.01",
                    "price_gbp": "46.41",
                    "price_usd": "57.73",
                    "price_php": "3324.79",
                    "price_zar": "1106.46",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 305,
                    "market_id": 3,
                    "tradingview": "VGT",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VGT",
                    "market_name": "stock",
                    "price_aud": "760.58",
                    "price_brl": "2536.83",
                    "price_cad": "676.61",
                    "price_eur": "462.60",
                    "price_gbp": "397.52",
                    "price_usd": "494.45",
                    "price_php": "28476.36",
                    "price_zar": "9476.68",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 360,
                    "market_id": 3,
                    "tradingview": "VHT",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VHT",
                    "market_name": "stock",
                    "price_aud": "396.62",
                    "price_brl": "1322.87",
                    "price_cad": "352.83",
                    "price_eur": "241.23",
                    "price_gbp": "207.30",
                    "price_usd": "257.84",
                    "price_php": "14849.52",
                    "price_zar": "4941.79",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 299,
                    "market_id": 3,
                    "tradingview": "VIG",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VIG",
                    "market_name": "stock",
                    "price_aud": "271.57",
                    "price_brl": "905.81",
                    "price_cad": "241.59",
                    "price_eur": "165.18",
                    "price_gbp": "141.94",
                    "price_usd": "176.55",
                    "price_php": "10167.87",
                    "price_zar": "3383.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 366,
                    "market_id": 3,
                    "tradingview": "VMBS",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VMBS",
                    "market_name": "stock",
                    "price_aud": "68.47",
                    "price_brl": "228.39",
                    "price_cad": "60.91",
                    "price_eur": "41.65",
                    "price_gbp": "35.79",
                    "price_usd": "44.515",
                    "price_php": "2563.71",
                    "price_zar": "853.18",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 326,
                    "market_id": 3,
                    "tradingview": "VNQ",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VNQ",
                    "market_name": "stock",
                    "price_aud": "123.72",
                    "price_brl": "412.65",
                    "price_cad": "110.06",
                    "price_eur": "75.25",
                    "price_gbp": "64.66",
                    "price_usd": "80.43",
                    "price_php": "4632.12",
                    "price_zar": "1541.53",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 304,
                    "market_id": 3,
                    "tradingview": "VO",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VO",
                    "market_name": "stock",
                    "price_aud": "367.47",
                    "price_brl": "1225.65",
                    "price_cad": "326.90",
                    "price_eur": "223.50",
                    "price_gbp": "192.06",
                    "price_usd": "238.89",
                    "price_php": "13758.15",
                    "price_zar": "4578.59",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 363,
                    "market_id": 3,
                    "tradingview": "VOE",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VOE",
                    "market_name": "stock",
                    "price_aud": "231.01",
                    "price_brl": "770.51",
                    "price_cad": "205.51",
                    "price_eur": "140.50",
                    "price_gbp": "120.74",
                    "price_usd": "150.18",
                    "price_php": "8649.17",
                    "price_zar": "2878.36",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 386,
                    "market_id": 3,
                    "tradingview": "VONG",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VONG",
                    "market_name": "stock",
                    "price_aud": "128.27",
                    "price_brl": "427.84",
                    "price_cad": "114.11",
                    "price_eur": "78.02",
                    "price_gbp": "67.04",
                    "price_usd": "83.39",
                    "price_php": "4802.60",
                    "price_zar": "1598.26",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 283,
                    "market_id": 3,
                    "tradingview": "VOO",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VOO",
                    "market_name": "stock",
                    "price_aud": "715.34",
                    "price_brl": "2385.93",
                    "price_cad": "636.36",
                    "price_eur": "435.08",
                    "price_gbp": "373.88",
                    "price_usd": "465.04",
                    "price_php": "26782.58",
                    "price_zar": "8913.00",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 404,
                    "market_id": 3,
                    "tradingview": "VOT",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VOT",
                    "market_name": "stock",
                    "price_aud": "344.79",
                    "price_brl": "1150.02",
                    "price_cad": "306.73",
                    "price_eur": "209.71",
                    "price_gbp": "180.21",
                    "price_usd": "224.15",
                    "price_php": "12909.25",
                    "price_zar": "4296.08",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 426,
                    "market_id": 3,
                    "tradingview": "VSS",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VSS",
                    "market_name": "stock",
                    "price_aud": "175.81",
                    "price_brl": "586.40",
                    "price_cad": "156.40",
                    "price_eur": "106.93",
                    "price_gbp": "91.89",
                    "price_usd": "114.295",
                    "price_php": "6582.48",
                    "price_zar": "2190.59",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 288,
                    "market_id": 3,
                    "tradingview": "VT",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VT",
                    "market_name": "stock",
                    "price_aud": "165.28",
                    "price_brl": "551.28",
                    "price_cad": "147.03",
                    "price_eur": "100.53",
                    "price_gbp": "86.39",
                    "price_usd": "107.45",
                    "price_php": "6188.26",
                    "price_zar": "2059.40",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 338,
                    "market_id": 3,
                    "tradingview": "VTEB",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VTEB",
                    "market_name": "stock",
                    "price_aud": "76.83",
                    "price_brl": "256.25",
                    "price_cad": "68.34",
                    "price_eur": "46.73",
                    "price_gbp": "40.15",
                    "price_usd": "49.945",
                    "price_php": "2876.43",
                    "price_zar": "957.25",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 374,
                    "market_id": 3,
                    "tradingview": "VTIP",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VTIP",
                    "market_name": "stock",
                    "price_aud": "73.63",
                    "price_brl": "245.60",
                    "price_cad": "65.51",
                    "price_eur": "44.79",
                    "price_gbp": "38.49",
                    "price_usd": "47.87",
                    "price_php": "2756.93",
                    "price_zar": "917.48",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 290,
                    "market_id": 3,
                    "tradingview": "VTWO",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VTWO",
                    "market_name": "stock",
                    "price_aud": "123.13",
                    "price_brl": "410.70",
                    "price_cad": "109.54",
                    "price_eur": "74.89",
                    "price_gbp": "64.36",
                    "price_usd": "80.05",
                    "price_php": "4610.24",
                    "price_zar": "1534.25",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 292,
                    "market_id": 3,
                    "tradingview": "VUG",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VUG",
                    "market_name": "stock",
                    "price_aud": "511.46",
                    "price_brl": "1705.92",
                    "price_cad": "454.99",
                    "price_eur": "311.08",
                    "price_gbp": "267.32",
                    "price_usd": "332.5",
                    "price_php": "19149.34",
                    "price_zar": "6372.73",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 333,
                    "market_id": 3,
                    "tradingview": "VV",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VV",
                    "market_name": "stock",
                    "price_aud": "357.21",
                    "price_brl": "1191.43",
                    "price_cad": "317.77",
                    "price_eur": "217.26",
                    "price_gbp": "186.70",
                    "price_usd": "232.22",
                    "price_php": "13374.01",
                    "price_zar": "4450.75",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 295,
                    "market_id": 3,
                    "tradingview": "VWO",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VWO",
                    "market_name": "stock",
                    "price_aud": "64.28",
                    "price_brl": "214.41",
                    "price_cad": "57.19",
                    "price_eur": "39.10",
                    "price_gbp": "33.60",
                    "price_usd": "41.79",
                    "price_php": "2406.77",
                    "price_zar": "800.95",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 369,
                    "market_id": 3,
                    "tradingview": "VXF",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VXF",
                    "market_name": "stock",
                    "price_aud": "255.78",
                    "price_brl": "853.12",
                    "price_cad": "227.54",
                    "price_eur": "155.57",
                    "price_gbp": "133.68",
                    "price_usd": "166.28",
                    "price_php": "9576.40",
                    "price_zar": "3186.94",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 302,
                    "market_id": 3,
                    "tradingview": "VXUS",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VXUS",
                    "market_name": "stock",
                    "price_aud": "90.76",
                    "price_brl": "302.71",
                    "price_cad": "80.74",
                    "price_eur": "55.20",
                    "price_gbp": "47.43",
                    "price_usd": "59",
                    "price_php": "3397.93",
                    "price_zar": "1130.80",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 310,
                    "market_id": 3,
                    "tradingview": "VYM",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VYM",
                    "market_name": "stock",
                    "price_aud": "181.51",
                    "price_brl": "605.41",
                    "price_cad": "161.47",
                    "price_eur": "110.40",
                    "price_gbp": "94.87",
                    "price_usd": "118",
                    "price_php": "6795.86",
                    "price_zar": "2261.60",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                {
                    "id": 385,
                    "market_id": 3,
                    "tradingview": "XLC",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "XLC",
                    "market_name": "stock",
                    "price_aud": "124.58",
                    "price_brl": "415.53",
                    "price_cad": "110.83",
                    "price_eur": "75.77",
                    "price_gbp": "65.11",
                    "price_usd": "80.99",
                    "price_php": "4664.38",
                    "price_zar": "1552.26",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                {
                    "id": 321,
                    "market_id": 3,
                    "tradingview": "XLE",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "XLE",
                    "market_name": "stock",
                    "price_aud": "147.02",
                    "price_brl": "490.38",
                    "price_cad": "130.79",
                    "price_eur": "89.42",
                    "price_gbp": "76.84",
                    "price_usd": "95.58",
                    "price_php": "5504.64",
                    "price_zar": "1831.90",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                {
                    "id": 324,
                    "market_id": 3,
                    "tradingview": "XLF",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "XLF",
                    "market_name": "stock",
                    "price_aud": "63.31",
                    "price_brl": "211.18",
                    "price_cad": "56.32",
                    "price_eur": "38.51",
                    "price_gbp": "33.09",
                    "price_usd": "41.16",
                    "price_php": "2370.49",
                    "price_zar": "788.88",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                {
                    "id": 373,
                    "market_id": 3,
                    "tradingview": "XLI",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "XLI",
                    "market_name": "stock",
                    "price_aud": "188.68",
                    "price_brl": "629.32",
                    "price_cad": "167.85",
                    "price_eur": "114.76",
                    "price_gbp": "98.61",
                    "price_usd": "122.66",
                    "price_php": "7064.23",
                    "price_zar": "2350.91",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                {
                    "id": 308,
                    "market_id": 3,
                    "tradingview": "XLK",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "XLK",
                    "market_name": "stock",
                    "price_aud": "305.08",
                    "price_brl": "1017.55",
                    "price_cad": "271.39",
                    "price_eur": "185.55",
                    "price_gbp": "159.45",
                    "price_usd": "198.33",
                    "price_php": "11422.22",
                    "price_zar": "3801.21",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                {
                    "id": 358,
                    "market_id": 3,
                    "tradingview": "XLP",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "XLP",
                    "market_name": "stock",
                    "price_aud": "115.61",
                    "price_brl": "385.62",
                    "price_cad": "102.85",
                    "price_eur": "70.32",
                    "price_gbp": "60.43",
                    "price_usd": "75.16",
                    "price_php": "4328.61",
                    "price_zar": "1440.52",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                {
                    "id": 367,
                    "market_id": 3,
                    "tradingview": "XLU",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "XLU",
                    "market_name": "stock",
                    "price_aud": "102.00",
                    "price_brl": "340.21",
                    "price_cad": "90.74",
                    "price_eur": "62.04",
                    "price_gbp": "53.31",
                    "price_usd": "66.31",
                    "price_php": "3818.93",
                    "price_zar": "1270.90",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                {
                    "id": 314,
                    "market_id": 3,
                    "tradingview": "XLV",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "XLV",
                    "market_name": "stock",
                    "price_aud": "216.91",
                    "price_brl": "723.47",
                    "price_cad": "192.96",
                    "price_eur": "131.93",
                    "price_gbp": "113.37",
                    "price_usd": "141.01",
                    "price_php": "8121.05",
                    "price_zar": "2702.61",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                {
                    "id": 359,
                    "market_id": 3,
                    "tradingview": "XLY",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "XLY",
                    "market_name": "stock",
                    "price_aud": "268.59",
                    "price_brl": "895.85",
                    "price_cad": "238.94",
                    "price_eur": "163.36",
                    "price_gbp": "140.38",
                    "price_usd": "174.61",
                    "price_php": "10056.14",
                    "price_zar": "3346.59",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                }
            ],
            "total": 147,
            "object": {
                "ACWI": {
                    "id": 356,
                    "market_id": 3,
                    "tradingview": "ACWI",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "ACWI",
                    "market_name": "stock",
                    "price_aud": "164.59",
                    "price_brl": "548.97",
                    "price_cad": "146.42",
                    "price_eur": "100.11",
                    "price_gbp": "86.02",
                    "price_usd": "107",
                    "price_php": "6162.34",
                    "price_zar": "2050.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "AGG": {
                    "id": 294,
                    "market_id": 3,
                    "tradingview": "AGG",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "AGG",
                    "market_name": "stock",
                    "price_aud": "147.12",
                    "price_brl": "490.69",
                    "price_cad": "130.87",
                    "price_eur": "89.48",
                    "price_gbp": "76.89",
                    "price_usd": "95.64",
                    "price_php": "5508.10",
                    "price_zar": "1833.05",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "BBJP": {
                    "id": 424,
                    "market_id": 3,
                    "tradingview": "BBJP",
                    "name": "JPmorgan",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "BBJP",
                    "market_name": "stock",
                    "price_aud": "85.15",
                    "price_brl": "284.00",
                    "price_cad": "75.75",
                    "price_eur": "51.79",
                    "price_gbp": "44.50",
                    "price_usd": "55.355",
                    "price_php": "3188.01",
                    "price_zar": "1060.94",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/jpmorgan--big.svg"
                },
                "BIV": {
                    "id": 371,
                    "market_id": 3,
                    "tradingview": "BIV",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "BIV",
                    "market_name": "stock",
                    "price_aud": "113.18",
                    "price_brl": "377.51",
                    "price_cad": "100.69",
                    "price_eur": "68.84",
                    "price_gbp": "59.16",
                    "price_usd": "73.58",
                    "price_php": "4237.62",
                    "price_zar": "1410.24",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "BND": {
                    "id": 293,
                    "market_id": 3,
                    "tradingview": "BND",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "BND",
                    "market_name": "stock",
                    "price_aud": "109.08",
                    "price_brl": "363.81",
                    "price_cad": "97.03",
                    "price_eur": "66.34",
                    "price_gbp": "57.01",
                    "price_usd": "70.9101",
                    "price_php": "4083.85",
                    "price_zar": "1359.07",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "BNDX": {
                    "id": 309,
                    "market_id": 3,
                    "tradingview": "BNDX",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "BNDX",
                    "market_name": "stock",
                    "price_aud": "74.72",
                    "price_brl": "249.22",
                    "price_cad": "66.47",
                    "price_eur": "45.45",
                    "price_gbp": "39.05",
                    "price_usd": "48.575",
                    "price_php": "2797.53",
                    "price_zar": "930.99",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "BSV": {
                    "id": 317,
                    "market_id": 3,
                    "tradingview": "BSV",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "BSV",
                    "market_name": "stock",
                    "price_aud": "116.97",
                    "price_brl": "390.13",
                    "price_cad": "104.05",
                    "price_eur": "71.14",
                    "price_gbp": "61.13",
                    "price_usd": "76.04",
                    "price_php": "4379.30",
                    "price_zar": "1457.39",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "COWZ": {
                    "id": 383,
                    "market_id": 3,
                    "tradingview": "COWZ",
                    "name": "Pacer",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "COWZ",
                    "market_name": "stock",
                    "price_aud": "85.59",
                    "price_brl": "285.47",
                    "price_cad": "76.14",
                    "price_eur": "52.06",
                    "price_gbp": "44.73",
                    "price_usd": "55.64",
                    "price_php": "3204.42",
                    "price_zar": "1066.40",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/pacer-american-energy--big.svg"
                },
                "CSB": {
                    "id": 408,
                    "market_id": 3,
                    "tradingview": "CSB",
                    "name": "VictoryShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "CSB",
                    "market_name": "stock",
                    "price_aud": "83.68",
                    "price_brl": "279.10",
                    "price_cad": "74.44",
                    "price_eur": "50.90",
                    "price_gbp": "43.74",
                    "price_usd": "54.4",
                    "price_php": "3133.00",
                    "price_zar": "1042.64",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/victoryshares--big.svg"
                },
                "DGRO": {
                    "id": 342,
                    "market_id": 3,
                    "tradingview": "DGRO",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "DGRO",
                    "market_name": "stock",
                    "price_aud": "86.99",
                    "price_brl": "290.16",
                    "price_cad": "77.39",
                    "price_eur": "52.91",
                    "price_gbp": "45.47",
                    "price_usd": "56.5546",
                    "price_php": "3257.09",
                    "price_zar": "1083.93",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "DGRW": {
                    "id": 416,
                    "market_id": 3,
                    "tradingview": "DGRW",
                    "name": "WisdomTree",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "DGRW",
                    "market_name": "stock",
                    "price_aud": "113.58",
                    "price_brl": "378.84",
                    "price_cad": "101.04",
                    "price_eur": "69.08",
                    "price_gbp": "59.37",
                    "price_usd": "73.84",
                    "price_php": "4252.59",
                    "price_zar": "1415.22",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/wisdomtree--big.svg"
                },
                "DVY": {
                    "id": 351,
                    "market_id": 3,
                    "tradingview": "DVY",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "DVY",
                    "market_name": "stock",
                    "price_aud": "184.91",
                    "price_brl": "616.75",
                    "price_cad": "164.50",
                    "price_eur": "112.47",
                    "price_gbp": "96.65",
                    "price_usd": "120.21",
                    "price_php": "6923.13",
                    "price_zar": "2303.96",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "EEM": {
                    "id": 343,
                    "market_id": 3,
                    "tradingview": "EEM",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "EEM",
                    "market_name": "stock",
                    "price_aud": "62.45",
                    "price_brl": "208.30",
                    "price_cad": "55.56",
                    "price_eur": "37.98",
                    "price_gbp": "32.64",
                    "price_usd": "40.6",
                    "price_php": "2338.24",
                    "price_zar": "778.14",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "EFA": {
                    "id": 307,
                    "market_id": 3,
                    "tradingview": "EFA",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "EFA",
                    "market_name": "stock",
                    "price_aud": "119.70",
                    "price_brl": "399.26",
                    "price_cad": "106.49",
                    "price_eur": "72.81",
                    "price_gbp": "62.56",
                    "price_usd": "77.82",
                    "price_php": "4481.81",
                    "price_zar": "1491.51",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "EFG": {
                    "id": 381,
                    "market_id": 3,
                    "tradingview": "EFG",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "EFG",
                    "market_name": "stock",
                    "price_aud": "153.44",
                    "price_brl": "511.78",
                    "price_cad": "136.50",
                    "price_eur": "93.32",
                    "price_gbp": "80.20",
                    "price_usd": "99.75",
                    "price_php": "5744.80",
                    "price_zar": "1911.82",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "EFV": {
                    "id": 372,
                    "market_id": 3,
                    "tradingview": "EFV",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "EFV",
                    "market_name": "stock",
                    "price_aud": "82.85",
                    "price_brl": "276.33",
                    "price_cad": "73.70",
                    "price_eur": "50.39",
                    "price_gbp": "43.30",
                    "price_usd": "53.86",
                    "price_php": "3101.91",
                    "price_zar": "1032.29",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "EMB": {
                    "id": 370,
                    "market_id": 3,
                    "tradingview": "EMB",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "EMB",
                    "market_name": "stock",
                    "price_aud": "135.13",
                    "price_brl": "450.72",
                    "price_cad": "120.21",
                    "price_eur": "82.19",
                    "price_gbp": "70.63",
                    "price_usd": "87.85",
                    "price_php": "5059.46",
                    "price_zar": "1683.74",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "ESGU": {
                    "id": 375,
                    "market_id": 3,
                    "tradingview": "ESGU",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "ESGU",
                    "market_name": "stock",
                    "price_aud": "170.60",
                    "price_brl": "569.01",
                    "price_cad": "151.76",
                    "price_eur": "103.76",
                    "price_gbp": "89.16",
                    "price_usd": "110.9059",
                    "price_php": "6387.29",
                    "price_zar": "2125.63",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "FNDF": {
                    "id": 409,
                    "market_id": 3,
                    "tradingview": "FNDF",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "FNDF",
                    "market_name": "stock",
                    "price_aud": "53.78",
                    "price_brl": "179.37",
                    "price_cad": "47.84",
                    "price_eur": "32.71",
                    "price_gbp": "28.11",
                    "price_usd": "34.96",
                    "price_php": "2013.42",
                    "price_zar": "670.05",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                "FNDX": {
                    "id": 398,
                    "market_id": 3,
                    "tradingview": "FNDX",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "FNDX",
                    "market_name": "stock",
                    "price_aud": "100.38",
                    "price_brl": "334.80",
                    "price_cad": "89.29",
                    "price_eur": "61.05",
                    "price_gbp": "52.46",
                    "price_usd": "65.255",
                    "price_php": "3758.17",
                    "price_zar": "1250.68",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                "FTCS": {
                    "id": 423,
                    "market_id": 3,
                    "tradingview": "FTCS",
                    "name": "First Trust",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "FTCS",
                    "market_name": "stock",
                    "price_aud": "126.84",
                    "price_brl": "423.07",
                    "price_cad": "112.84",
                    "price_eur": "77.15",
                    "price_gbp": "66.30",
                    "price_usd": "82.46",
                    "price_php": "4749.04",
                    "price_zar": "1580.44",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/first-trust--big.svg"
                },
                "FVD": {
                    "id": 393,
                    "market_id": 3,
                    "tradingview": "FVD",
                    "name": "First Trust",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "FVD",
                    "market_name": "stock",
                    "price_aud": "63.07",
                    "price_brl": "210.38",
                    "price_cad": "56.11",
                    "price_eur": "38.36",
                    "price_gbp": "32.97",
                    "price_usd": "41.005",
                    "price_php": "2361.56",
                    "price_zar": "785.91",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/first-trust--big.svg"
                },
                "GDX": {
                    "id": 392,
                    "market_id": 3,
                    "tradingview": "GDX",
                    "name": "VanEck",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "GDX",
                    "market_name": "stock",
                    "price_aud": "50.65",
                    "price_brl": "168.95",
                    "price_cad": "45.06",
                    "price_eur": "30.81",
                    "price_gbp": "26.47",
                    "price_usd": "32.93",
                    "price_php": "1896.50",
                    "price_zar": "631.14",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vaneck--big.svg"
                },
                "GLD": {
                    "id": 301,
                    "market_id": 3,
                    "tradingview": "GLD",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "GLD",
                    "market_name": "stock",
                    "price_aud": "329.60",
                    "price_brl": "1099.33",
                    "price_cad": "293.21",
                    "price_eur": "200.47",
                    "price_gbp": "172.27",
                    "price_usd": "214.27",
                    "price_php": "12340.24",
                    "price_zar": "4106.72",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                "GOVT": {
                    "id": 337,
                    "market_id": 3,
                    "tradingview": "GOVT",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "GOVT",
                    "market_name": "stock",
                    "price_aud": "34.19",
                    "price_brl": "114.05",
                    "price_cad": "30.42",
                    "price_eur": "20.80",
                    "price_gbp": "17.87",
                    "price_usd": "22.23",
                    "price_php": "1280.27",
                    "price_zar": "426.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "GSLC": {
                    "id": 399,
                    "market_id": 3,
                    "tradingview": "GSLC",
                    "name": "Goldman Sachs",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "GSLC",
                    "market_name": "stock",
                    "price_aud": "153.76",
                    "price_brl": "512.85",
                    "price_cad": "136.79",
                    "price_eur": "93.52",
                    "price_gbp": "80.36",
                    "price_usd": "99.96",
                    "price_php": "5756.90",
                    "price_zar": "1915.84",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/golden-sachs-etf-trust-goldman--big.svg"
                },
                "HDV": {
                    "id": 407,
                    "market_id": 3,
                    "tradingview": "HDV",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "HDV",
                    "market_name": "stock",
                    "price_aud": "168.08",
                    "price_brl": "560.62",
                    "price_cad": "149.53",
                    "price_eur": "102.23",
                    "price_gbp": "87.85",
                    "price_usd": "109.27",
                    "price_php": "6293.08",
                    "price_zar": "2094.28",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "HYG": {
                    "id": 368,
                    "market_id": 3,
                    "tradingview": "HYG",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "HYG",
                    "market_name": "stock",
                    "price_aud": "118.14",
                    "price_brl": "394.03",
                    "price_cad": "105.09",
                    "price_eur": "71.85",
                    "price_gbp": "61.74",
                    "price_usd": "76.8",
                    "price_php": "4423.07",
                    "price_zar": "1471.96",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IAU": {
                    "id": 334,
                    "market_id": 3,
                    "tradingview": "IAU",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IAU",
                    "market_name": "stock",
                    "price_aud": "67.34",
                    "price_brl": "224.62",
                    "price_cad": "59.91",
                    "price_eur": "40.96",
                    "price_gbp": "35.20",
                    "price_usd": "43.78",
                    "price_php": "2521.38",
                    "price_zar": "839.09",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IBB": {
                    "id": 422,
                    "market_id": 3,
                    "tradingview": "IBB",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IBB",
                    "market_name": "stock",
                    "price_aud": "196.92",
                    "price_brl": "656.82",
                    "price_cad": "175.18",
                    "price_eur": "119.77",
                    "price_gbp": "102.92",
                    "price_usd": "128.02",
                    "price_php": "7372.93",
                    "price_zar": "2453.64",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IDEV": {
                    "id": 402,
                    "market_id": 3,
                    "tradingview": "IDEV",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IDEV",
                    "market_name": "stock",
                    "price_aud": "100.75",
                    "price_brl": "336.05",
                    "price_cad": "89.63",
                    "price_eur": "61.28",
                    "price_gbp": "52.66",
                    "price_usd": "65.5",
                    "price_php": "3772.28",
                    "price_zar": "1255.38",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IEF": {
                    "id": 379,
                    "market_id": 3,
                    "tradingview": "IEF",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IEF",
                    "market_name": "stock",
                    "price_aud": "141.27",
                    "price_brl": "471.19",
                    "price_cad": "125.67",
                    "price_eur": "85.92",
                    "price_gbp": "73.84",
                    "price_usd": "91.84",
                    "price_php": "5289.25",
                    "price_zar": "1760.21",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IEFA": {
                    "id": 291,
                    "market_id": 3,
                    "tradingview": "IEFA",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IEFA",
                    "market_name": "stock",
                    "price_aud": "111.26",
                    "price_brl": "371.10",
                    "price_cad": "98.98",
                    "price_eur": "67.67",
                    "price_gbp": "58.15",
                    "price_usd": "72.33",
                    "price_php": "4165.63",
                    "price_zar": "1386.28",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IEI": {
                    "id": 390,
                    "market_id": 3,
                    "tradingview": "IEI",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IEI",
                    "market_name": "stock",
                    "price_aud": "175.13",
                    "price_brl": "584.12",
                    "price_cad": "155.79",
                    "price_eur": "106.52",
                    "price_gbp": "91.53",
                    "price_usd": "113.85",
                    "price_php": "6556.85",
                    "price_zar": "2182.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IEMG": {
                    "id": 297,
                    "market_id": 3,
                    "tradingview": "IEMG",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IEMG",
                    "market_name": "stock",
                    "price_aud": "78.36",
                    "price_brl": "261.35",
                    "price_cad": "69.71",
                    "price_eur": "47.66",
                    "price_gbp": "40.95",
                    "price_usd": "50.94",
                    "price_php": "2933.74",
                    "price_zar": "976.32",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IGIB": {
                    "id": 394,
                    "market_id": 3,
                    "tradingview": "IGIB",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IGIB",
                    "market_name": "stock",
                    "price_aud": "77.53",
                    "price_brl": "258.58",
                    "price_cad": "68.97",
                    "price_eur": "47.15",
                    "price_gbp": "40.52",
                    "price_usd": "50.4",
                    "price_php": "2902.64",
                    "price_zar": "965.97",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IGSB": {
                    "id": 344,
                    "market_id": 3,
                    "tradingview": "IGSB",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IGSB",
                    "market_name": "stock",
                    "price_aud": "78.19",
                    "price_brl": "260.79",
                    "price_cad": "69.56",
                    "price_eur": "47.56",
                    "price_gbp": "40.87",
                    "price_usd": "50.83",
                    "price_php": "2927.40",
                    "price_zar": "974.21",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IJH": {
                    "id": 298,
                    "market_id": 3,
                    "tradingview": "IJH",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IJH",
                    "market_name": "stock",
                    "price_aud": "88.94",
                    "price_brl": "296.65",
                    "price_cad": "79.12",
                    "price_eur": "54.10",
                    "price_gbp": "46.49",
                    "price_usd": "57.82",
                    "price_php": "3329.97",
                    "price_zar": "1108.18",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IJR": {
                    "id": 300,
                    "market_id": 3,
                    "tradingview": "IJR",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IJR",
                    "market_name": "stock",
                    "price_aud": "162.99",
                    "price_brl": "543.64",
                    "price_cad": "145.00",
                    "price_eur": "99.13",
                    "price_gbp": "85.19",
                    "price_usd": "105.96",
                    "price_php": "6102.45",
                    "price_zar": "2030.84",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "ITOT": {
                    "id": 312,
                    "market_id": 3,
                    "tradingview": "ITOT",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "ITOT",
                    "market_name": "stock",
                    "price_aud": "171.90",
                    "price_brl": "573.34",
                    "price_cad": "152.92",
                    "price_eur": "104.55",
                    "price_gbp": "89.84",
                    "price_usd": "111.75",
                    "price_php": "6435.91",
                    "price_zar": "2141.81",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IUSB": {
                    "id": 346,
                    "market_id": 3,
                    "tradingview": "IUSB",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IUSB",
                    "market_name": "stock",
                    "price_aud": "68.57",
                    "price_brl": "228.70",
                    "price_cad": "61.00",
                    "price_eur": "41.70",
                    "price_gbp": "35.84",
                    "price_usd": "44.575",
                    "price_php": "2567.16",
                    "price_zar": "854.33",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IUSG": {
                    "id": 410,
                    "market_id": 3,
                    "tradingview": "IUSG",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IUSG",
                    "market_name": "stock",
                    "price_aud": "173.60",
                    "price_brl": "579.04",
                    "price_cad": "154.44",
                    "price_eur": "105.59",
                    "price_gbp": "90.74",
                    "price_usd": "112.86",
                    "price_php": "6499.83",
                    "price_zar": "2163.09",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IVE": {
                    "id": 339,
                    "market_id": 3,
                    "tradingview": "IVE",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IVE",
                    "market_name": "stock",
                    "price_aud": "278.33",
                    "price_brl": "928.33",
                    "price_cad": "247.60",
                    "price_eur": "169.28",
                    "price_gbp": "145.47",
                    "price_usd": "180.94",
                    "price_php": "10420.70",
                    "price_zar": "3467.91",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "IVV": {
                    "id": 285,
                    "market_id": 3,
                    "tradingview": "IVV",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IVV",
                    "market_name": "stock",
                    "price_aud": "782.40",
                    "price_brl": "2609.63",
                    "price_cad": "696.02",
                    "price_eur": "475.87",
                    "price_gbp": "408.93",
                    "price_usd": "508.64",
                    "price_php": "29293.59",
                    "price_zar": "9748.65",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IVW": {
                    "id": 323,
                    "market_id": 3,
                    "tradingview": "IVW",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IVW",
                    "market_name": "stock",
                    "price_aud": "125.21",
                    "price_brl": "417.63",
                    "price_cad": "111.39",
                    "price_eur": "76.16",
                    "price_gbp": "65.44",
                    "price_usd": "81.4",
                    "price_php": "4687.99",
                    "price_zar": "1560.12",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IWB": {
                    "id": 330,
                    "market_id": 3,
                    "tradingview": "IWB",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWB",
                    "market_name": "stock",
                    "price_aud": "427.27",
                    "price_brl": "1425.13",
                    "price_cad": "380.10",
                    "price_eur": "259.88",
                    "price_gbp": "223.32",
                    "price_usd": "277.77",
                    "price_php": "15997.33",
                    "price_zar": "5323.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IWD": {
                    "id": 306,
                    "market_id": 3,
                    "tradingview": "IWD",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWD",
                    "market_name": "stock",
                    "price_aud": "267.11",
                    "price_brl": "890.93",
                    "price_cad": "237.62",
                    "price_eur": "162.46",
                    "price_gbp": "139.61",
                    "price_usd": "173.65",
                    "price_php": "10000.85",
                    "price_zar": "3328.19",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IWF": {
                    "id": 296,
                    "market_id": 3,
                    "tradingview": "IWF",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWF",
                    "market_name": "stock",
                    "price_aud": "501.46",
                    "price_brl": "1672.58",
                    "price_cad": "446.10",
                    "price_eur": "305.00",
                    "price_gbp": "262.09",
                    "price_usd": "326",
                    "price_php": "18774.99",
                    "price_zar": "6248.15",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IWM": {
                    "id": 303,
                    "market_id": 3,
                    "tradingview": "IWM",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWM",
                    "market_name": "stock",
                    "price_aud": "304.29",
                    "price_brl": "1014.94",
                    "price_cad": "270.70",
                    "price_eur": "185.08",
                    "price_gbp": "159.04",
                    "price_usd": "197.82",
                    "price_php": "11392.85",
                    "price_zar": "3791.44",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IWN": {
                    "id": 400,
                    "market_id": 3,
                    "tradingview": "IWN",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWN",
                    "market_name": "stock",
                    "price_aud": "233.44",
                    "price_brl": "778.62",
                    "price_cad": "207.67",
                    "price_eur": "141.98",
                    "price_gbp": "122.01",
                    "price_usd": "151.76",
                    "price_php": "8740.16",
                    "price_zar": "2908.65",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IWO": {
                    "id": 411,
                    "market_id": 3,
                    "tradingview": "IWO",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWO",
                    "market_name": "stock",
                    "price_aud": "388.12",
                    "price_brl": "1294.55",
                    "price_cad": "345.27",
                    "price_eur": "236.06",
                    "price_gbp": "202.86",
                    "price_usd": "252.32",
                    "price_php": "14531.61",
                    "price_zar": "4835.99",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IWP": {
                    "id": 384,
                    "market_id": 3,
                    "tradingview": "IWP",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWP",
                    "market_name": "stock",
                    "price_aud": "167.00",
                    "price_brl": "557.01",
                    "price_cad": "148.56",
                    "price_eur": "101.57",
                    "price_gbp": "87.28",
                    "price_usd": "108.5668",
                    "price_php": "6252.58",
                    "price_zar": "2080.80",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IWR": {
                    "id": 332,
                    "market_id": 3,
                    "tradingview": "IWR",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWR",
                    "market_name": "stock",
                    "price_aud": "123.59",
                    "price_brl": "412.22",
                    "price_cad": "109.94",
                    "price_eur": "75.17",
                    "price_gbp": "64.59",
                    "price_usd": "80.345",
                    "price_php": "4627.23",
                    "price_zar": "1539.90",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IWS": {
                    "id": 388,
                    "market_id": 3,
                    "tradingview": "IWS",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWS",
                    "market_name": "stock",
                    "price_aud": "184.45",
                    "price_brl": "615.21",
                    "price_cad": "164.08",
                    "price_eur": "112.18",
                    "price_gbp": "96.40",
                    "price_usd": "119.9099",
                    "price_php": "6905.85",
                    "price_zar": "2298.21",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IWV": {
                    "id": 396,
                    "market_id": 3,
                    "tradingview": "IWV",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IWV",
                    "market_name": "stock",
                    "price_aud": "444.87",
                    "price_brl": "1483.82",
                    "price_cad": "395.76",
                    "price_eur": "270.58",
                    "price_gbp": "232.52",
                    "price_usd": "289.21",
                    "price_php": "16656.18",
                    "price_zar": "5543.03",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IXUS": {
                    "id": 327,
                    "market_id": 3,
                    "tradingview": "IXUS",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IXUS",
                    "market_name": "stock",
                    "price_aud": "102.22",
                    "price_brl": "340.95",
                    "price_cad": "90.94",
                    "price_eur": "62.17",
                    "price_gbp": "53.43",
                    "price_usd": "66.455",
                    "price_php": "3827.28",
                    "price_zar": "1273.68",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "IYW": {
                    "id": 389,
                    "market_id": 3,
                    "tradingview": "IYW",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "IYW",
                    "market_name": "stock",
                    "price_aud": "198.31",
                    "price_brl": "661.44",
                    "price_cad": "176.41",
                    "price_eur": "120.61",
                    "price_gbp": "103.65",
                    "price_usd": "128.92",
                    "price_php": "7424.76",
                    "price_zar": "2470.89",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "JEPI": {
                    "id": 335,
                    "market_id": 3,
                    "tradingview": "JEPI",
                    "name": "JPmorgan",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "JEPI",
                    "market_name": "stock",
                    "price_aud": "86.77",
                    "price_brl": "289.42",
                    "price_cad": "77.19",
                    "price_eur": "52.78",
                    "price_gbp": "45.35",
                    "price_usd": "56.41",
                    "price_php": "3248.76",
                    "price_zar": "1081.16",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/jpmorgan--big.svg"
                },
                "JPST": {
                    "id": 341,
                    "market_id": 3,
                    "tradingview": "JPST",
                    "name": "JPmorgan",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "JPST",
                    "market_name": "stock",
                    "price_aud": "77.48",
                    "price_brl": "258.43",
                    "price_cad": "68.93",
                    "price_eur": "47.12",
                    "price_gbp": "40.50",
                    "price_usd": "50.37",
                    "price_php": "2900.91",
                    "price_zar": "965.40",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/jpmorgan--big.svg"
                },
                "LABU": {
                    "id": 428,
                    "market_id": 3,
                    "tradingview": "LABU",
                    "name": "Direxion Daily S&P Biotech Bull 3X Shares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "LABU",
                    "market_name": "stock",
                    "price_aud": "140.56",
                    "price_brl": "468.83",
                    "price_cad": "125.04",
                    "price_eur": "85.49",
                    "price_gbp": "73.47",
                    "price_usd": "91.38",
                    "price_php": "5262.76",
                    "price_zar": "1751.40",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/direxion--big.svg"
                },
                "LQD": {
                    "id": 318,
                    "market_id": 3,
                    "tradingview": "LQD",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "LQD",
                    "market_name": "stock",
                    "price_aud": "162.21",
                    "price_brl": "541.02",
                    "price_cad": "144.30",
                    "price_eur": "98.66",
                    "price_gbp": "84.78",
                    "price_usd": "105.45",
                    "price_php": "6073.08",
                    "price_zar": "2021.07",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "MBB": {
                    "id": 336,
                    "market_id": 3,
                    "tradingview": "MBB",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "MBB",
                    "market_name": "stock",
                    "price_aud": "138.59",
                    "price_brl": "462.27",
                    "price_cad": "123.29",
                    "price_eur": "84.30",
                    "price_gbp": "72.44",
                    "price_usd": "90.1",
                    "price_php": "5189.04",
                    "price_zar": "1726.87",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "MDY": {
                    "id": 352,
                    "market_id": 3,
                    "tradingview": "MDY",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "MDY",
                    "market_name": "stock",
                    "price_aud": "814.87",
                    "price_brl": "2717.94",
                    "price_cad": "724.91",
                    "price_eur": "495.62",
                    "price_gbp": "425.90",
                    "price_usd": "529.75",
                    "price_php": "30509.36",
                    "price_zar": "10153.24",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                "MGK": {
                    "id": 382,
                    "market_id": 3,
                    "tradingview": "MGK",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "MGK",
                    "market_name": "stock",
                    "price_aud": "424.24",
                    "price_brl": "1415.02",
                    "price_cad": "377.40",
                    "price_eur": "258.03",
                    "price_gbp": "221.73",
                    "price_usd": "275.8",
                    "price_php": "15883.87",
                    "price_zar": "5286.01",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "MINT": {
                    "id": 417,
                    "market_id": 3,
                    "tradingview": "MINT",
                    "name": "Pimco",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "MINT",
                    "market_name": "stock",
                    "price_aud": "154.56",
                    "price_brl": "515.52",
                    "price_cad": "137.50",
                    "price_eur": "94.01",
                    "price_gbp": "80.78",
                    "price_usd": "100.48",
                    "price_php": "5786.84",
                    "price_zar": "1925.81",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/pimco--big.svg"
                },
                "MOAT": {
                    "id": 414,
                    "market_id": 3,
                    "tradingview": "MOAT",
                    "name": "VanEck",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "MOAT",
                    "market_name": "stock",
                    "price_aud": "131.87",
                    "price_brl": "439.85",
                    "price_cad": "117.31",
                    "price_eur": "80.21",
                    "price_gbp": "68.92",
                    "price_usd": "85.73",
                    "price_php": "4937.36",
                    "price_zar": "1643.11",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vaneck--big.svg"
                },
                "MTUM": {
                    "id": 419,
                    "market_id": 3,
                    "tradingview": "MTUM",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "MTUM",
                    "market_name": "stock",
                    "price_aud": "275.47",
                    "price_brl": "918.79",
                    "price_cad": "245.05",
                    "price_eur": "167.54",
                    "price_gbp": "143.97",
                    "price_usd": "179.08",
                    "price_php": "10313.58",
                    "price_zar": "3432.27",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "MUB": {
                    "id": 325,
                    "market_id": 3,
                    "tradingview": "MUB",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "MUB",
                    "market_name": "stock",
                    "price_aud": "163.87",
                    "price_brl": "546.56",
                    "price_cad": "145.78",
                    "price_eur": "99.67",
                    "price_gbp": "85.65",
                    "price_usd": "106.53",
                    "price_php": "6135.28",
                    "price_zar": "2041.76",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "NOBL": {
                    "id": 395,
                    "market_id": 3,
                    "tradingview": "NOBL",
                    "name": "Proshares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "NOBL",
                    "market_name": "stock",
                    "price_aud": "150.48",
                    "price_brl": "501.93",
                    "price_cad": "133.87",
                    "price_eur": "91.53",
                    "price_gbp": "78.65",
                    "price_usd": "97.83",
                    "price_php": "5634.23",
                    "price_zar": "1875.02",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/proshares--big.svg"
                },
                "PFF": {
                    "id": 387,
                    "market_id": 3,
                    "tradingview": "PFF",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "PFF",
                    "market_name": "stock",
                    "price_aud": "48.22",
                    "price_brl": "160.84",
                    "price_cad": "42.90",
                    "price_eur": "29.33",
                    "price_gbp": "25.20",
                    "price_usd": "31.35",
                    "price_php": "1805.51",
                    "price_zar": "600.86",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "QQQ": {
                    "id": 284,
                    "market_id": 3,
                    "tradingview": "QQQ",
                    "name": "Invesco",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "QQQ",
                    "market_name": "stock",
                    "price_aud": "657.64",
                    "price_brl": "2193.49",
                    "price_cad": "585.03",
                    "price_eur": "399.99",
                    "price_gbp": "343.72",
                    "price_usd": "427.53",
                    "price_php": "24622.31",
                    "price_zar": "8194.08",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/invesco--big.svg"
                },
                "QQQM": {
                    "id": 287,
                    "market_id": 3,
                    "tradingview": "QQQM",
                    "name": "Invesco",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "QQQM",
                    "market_name": "stock",
                    "price_aud": "270.31",
                    "price_brl": "901.60",
                    "price_cad": "240.47",
                    "price_eur": "164.41",
                    "price_gbp": "141.28",
                    "price_usd": "175.73",
                    "price_php": "10120.64",
                    "price_zar": "3368.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/invesco--big.svg"
                },
                "QUAL": {
                    "id": 328,
                    "market_id": 3,
                    "tradingview": "QUAL",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "QUAL",
                    "market_name": "stock",
                    "price_aud": "242.56",
                    "price_brl": "809.04",
                    "price_cad": "215.78",
                    "price_eur": "147.53",
                    "price_gbp": "126.78",
                    "price_usd": "157.69",
                    "price_php": "9081.68",
                    "price_zar": "3022.30",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "RSP": {
                    "id": 316,
                    "market_id": 3,
                    "tradingview": "RSP",
                    "name": "Invesco",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "RSP",
                    "market_name": "stock",
                    "price_aud": "250.19",
                    "price_brl": "834.49",
                    "price_cad": "222.57",
                    "price_eur": "152.17",
                    "price_gbp": "130.77",
                    "price_usd": "162.65",
                    "price_php": "9367.34",
                    "price_zar": "3117.37",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/invesco--big.svg"
                },
                "SCHA": {
                    "id": 377,
                    "market_id": 3,
                    "tradingview": "SCHA",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHA",
                    "market_name": "stock",
                    "price_aud": "71.84",
                    "price_brl": "239.60",
                    "price_cad": "63.90",
                    "price_eur": "43.69",
                    "price_gbp": "37.55",
                    "price_usd": "46.7",
                    "price_php": "2689.55",
                    "price_zar": "895.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                "SCHB": {
                    "id": 345,
                    "market_id": 3,
                    "tradingview": "SCHB",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHB",
                    "market_name": "stock",
                    "price_aud": "90.76",
                    "price_brl": "302.71",
                    "price_cad": "80.74",
                    "price_eur": "55.20",
                    "price_gbp": "47.43",
                    "price_usd": "59",
                    "price_php": "3397.93",
                    "price_zar": "1130.80",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                "SCHD": {
                    "id": 311,
                    "market_id": 3,
                    "tradingview": "SCHD",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHD",
                    "market_name": "stock",
                    "price_aud": "119.21",
                    "price_brl": "397.62",
                    "price_cad": "106.05",
                    "price_eur": "72.51",
                    "price_gbp": "62.31",
                    "price_usd": "77.5",
                    "price_php": "4463.38",
                    "price_zar": "1485.37",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                "SCHE": {
                    "id": 425,
                    "market_id": 3,
                    "tradingview": "SCHE",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHE",
                    "market_name": "stock",
                    "price_aud": "38.56",
                    "price_brl": "128.62",
                    "price_cad": "34.31",
                    "price_eur": "23.45",
                    "price_gbp": "20.16",
                    "price_usd": "25.07",
                    "price_php": "1443.83",
                    "price_zar": "480.49",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                "SCHF": {
                    "id": 329,
                    "market_id": 3,
                    "tradingview": "SCHF",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHF",
                    "market_name": "stock",
                    "price_aud": "60.14",
                    "price_brl": "200.61",
                    "price_cad": "53.50",
                    "price_eur": "36.58",
                    "price_gbp": "31.44",
                    "price_usd": "39.1",
                    "price_php": "2251.85",
                    "price_zar": "749.39",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                "SCHG": {
                    "id": 353,
                    "market_id": 3,
                    "tradingview": "SCHG",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHG",
                    "market_name": "stock",
                    "price_aud": "138.10",
                    "price_brl": "460.63",
                    "price_cad": "122.86",
                    "price_eur": "84.00",
                    "price_gbp": "72.18",
                    "price_usd": "89.78",
                    "price_php": "5170.61",
                    "price_zar": "1720.73",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                "SCHM": {
                    "id": 412,
                    "market_id": 3,
                    "tradingview": "SCHM",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHM",
                    "market_name": "stock",
                    "price_aud": "118.92",
                    "price_brl": "396.65",
                    "price_cad": "105.79",
                    "price_eur": "72.33",
                    "price_gbp": "62.15",
                    "price_usd": "77.31",
                    "price_php": "4452.44",
                    "price_zar": "1481.73",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                "SCHO": {
                    "id": 376,
                    "market_id": 3,
                    "tradingview": "SCHO",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHO",
                    "market_name": "stock",
                    "price_aud": "73.70",
                    "price_brl": "245.81",
                    "price_cad": "65.56",
                    "price_eur": "44.82",
                    "price_gbp": "38.52",
                    "price_usd": "47.9101",
                    "price_php": "2759.24",
                    "price_zar": "918.25",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                "SCHP": {
                    "id": 397,
                    "market_id": 3,
                    "tradingview": "SCHP",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHP",
                    "market_name": "stock",
                    "price_aud": "79.03",
                    "price_brl": "263.61",
                    "price_cad": "70.31",
                    "price_eur": "48.07",
                    "price_gbp": "41.31",
                    "price_usd": "51.38",
                    "price_php": "2959.08",
                    "price_zar": "984.75",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                "SCHV": {
                    "id": 413,
                    "market_id": 3,
                    "tradingview": "SCHV",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHV",
                    "market_name": "stock",
                    "price_aud": "112.94",
                    "price_brl": "376.69",
                    "price_cad": "100.47",
                    "price_eur": "68.69",
                    "price_gbp": "59.03",
                    "price_usd": "73.42",
                    "price_php": "4228.40",
                    "price_zar": "1407.18",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                "SCHX": {
                    "id": 322,
                    "market_id": 3,
                    "tradingview": "SCHX",
                    "name": "Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCHX",
                    "market_name": "stock",
                    "price_aud": "92.71",
                    "price_brl": "309.22",
                    "price_cad": "82.47",
                    "price_eur": "56.39",
                    "price_gbp": "48.46",
                    "price_usd": "60.27",
                    "price_php": "3471.07",
                    "price_zar": "1155.14",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                "SCZ": {
                    "id": 405,
                    "market_id": 3,
                    "tradingview": "SCZ",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SCZ",
                    "market_name": "stock",
                    "price_aud": "94.65",
                    "price_brl": "315.69",
                    "price_cad": "84.20",
                    "price_eur": "57.57",
                    "price_gbp": "49.47",
                    "price_usd": "61.53",
                    "price_php": "3543.64",
                    "price_zar": "1179.29",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "SDY": {
                    "id": 348,
                    "market_id": 3,
                    "tradingview": "SDY",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SDY",
                    "market_name": "stock",
                    "price_aud": "197.09",
                    "price_brl": "657.38",
                    "price_cad": "175.33",
                    "price_eur": "119.88",
                    "price_gbp": "103.01",
                    "price_usd": "128.13",
                    "price_php": "7379.26",
                    "price_zar": "2455.75",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                "SGOV": {
                    "id": 403,
                    "market_id": 3,
                    "tradingview": "SGOV",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SGOV",
                    "market_name": "stock",
                    "price_aud": "154.76",
                    "price_brl": "516.19",
                    "price_cad": "137.67",
                    "price_eur": "94.13",
                    "price_gbp": "80.89",
                    "price_usd": "100.61",
                    "price_php": "5794.33",
                    "price_zar": "1928.30",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "SHV": {
                    "id": 350,
                    "market_id": 3,
                    "tradingview": "SHV",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SHV",
                    "market_name": "stock",
                    "price_aud": "169.81",
                    "price_brl": "566.39",
                    "price_cad": "151.06",
                    "price_eur": "103.28",
                    "price_gbp": "88.75",
                    "price_usd": "110.395",
                    "price_php": "6357.87",
                    "price_zar": "2115.84",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "SHY": {
                    "id": 361,
                    "market_id": 3,
                    "tradingview": "SHY",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SHY",
                    "market_name": "stock",
                    "price_aud": "125.00",
                    "price_brl": "416.91",
                    "price_cad": "111.20",
                    "price_eur": "76.02",
                    "price_gbp": "65.33",
                    "price_usd": "81.26",
                    "price_php": "4679.93",
                    "price_zar": "1557.44",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "SLV": {
                    "id": 406,
                    "market_id": 3,
                    "tradingview": "SLV",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SLV",
                    "market_name": "stock",
                    "price_aud": "38.13",
                    "price_brl": "127.19",
                    "price_cad": "33.92",
                    "price_eur": "23.19",
                    "price_gbp": "19.93",
                    "price_usd": "24.79",
                    "price_php": "1427.71",
                    "price_zar": "475.13",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "SPDW": {
                    "id": 364,
                    "market_id": 3,
                    "tradingview": "SPDW",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SPDW",
                    "market_name": "stock",
                    "price_aud": "53.76",
                    "price_brl": "179.31",
                    "price_cad": "47.83",
                    "price_eur": "32.70",
                    "price_gbp": "28.10",
                    "price_usd": "34.95",
                    "price_php": "2012.84",
                    "price_zar": "669.86",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                "SPLG": {
                    "id": 355,
                    "market_id": 3,
                    "tradingview": "SPLG",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SPLG",
                    "market_name": "stock",
                    "price_aud": "91.56",
                    "price_brl": "305.37",
                    "price_cad": "81.45",
                    "price_eur": "55.69",
                    "price_gbp": "47.85",
                    "price_usd": "59.52",
                    "price_php": "3427.88",
                    "price_zar": "1140.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                "SPLV": {
                    "id": 418,
                    "market_id": 3,
                    "tradingview": "SPLV",
                    "name": "Invesco",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SPLV",
                    "market_name": "stock",
                    "price_aud": "98.98",
                    "price_brl": "330.15",
                    "price_cad": "88.06",
                    "price_eur": "60.20",
                    "price_gbp": "51.74",
                    "price_usd": "64.35",
                    "price_php": "3706.05",
                    "price_zar": "1233.34",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/invesco--big.svg"
                },
                "SPY": {
                    "id": 282,
                    "market_id": 3,
                    "tradingview": "SPY",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SPY",
                    "market_name": "stock",
                    "price_aud": "778.33",
                    "price_brl": "2596.03",
                    "price_cad": "692.40",
                    "price_eur": "473.39",
                    "price_gbp": "406.80",
                    "price_usd": "505.99",
                    "price_php": "29140.98",
                    "price_zar": "9697.85",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                "SPYG": {
                    "id": 357,
                    "market_id": 3,
                    "tradingview": "SPYG",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SPYG",
                    "market_name": "stock",
                    "price_aud": "109.06",
                    "price_brl": "363.76",
                    "price_cad": "97.02",
                    "price_eur": "66.33",
                    "price_gbp": "57.00",
                    "price_usd": "70.9",
                    "price_php": "4083.27",
                    "price_zar": "1358.88",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                "SPYV": {
                    "id": 365,
                    "market_id": 3,
                    "tradingview": "SPYV",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SPYV",
                    "market_name": "stock",
                    "price_aud": "74.57",
                    "price_brl": "248.73",
                    "price_cad": "66.34",
                    "price_eur": "45.36",
                    "price_gbp": "38.98",
                    "price_usd": "48.48",
                    "price_php": "2792.06",
                    "price_zar": "929.17",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                "STIP": {
                    "id": 391,
                    "market_id": 3,
                    "tradingview": "STIP",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "STIP",
                    "market_name": "stock",
                    "price_aud": "152.82",
                    "price_brl": "509.73",
                    "price_cad": "135.95",
                    "price_eur": "92.95",
                    "price_gbp": "79.87",
                    "price_usd": "99.35",
                    "price_php": "5721.77",
                    "price_zar": "1904.15",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "SUB": {
                    "id": 415,
                    "market_id": 3,
                    "tradingview": "SUB",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "SUB",
                    "market_name": "stock",
                    "price_aud": "160.93",
                    "price_brl": "536.76",
                    "price_cad": "143.16",
                    "price_eur": "97.88",
                    "price_gbp": "84.11",
                    "price_usd": "104.62",
                    "price_php": "6025.28",
                    "price_zar": "2005.16",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "TIP": {
                    "id": 349,
                    "market_id": 3,
                    "tradingview": "TIP",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "TIP",
                    "market_name": "stock",
                    "price_aud": "162.85",
                    "price_brl": "543.18",
                    "price_cad": "144.87",
                    "price_eur": "99.05",
                    "price_gbp": "85.12",
                    "price_usd": "105.87",
                    "price_php": "6097.27",
                    "price_zar": "2029.12",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "TLH": {
                    "id": 427,
                    "market_id": 3,
                    "tradingview": "TLH",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "TLH",
                    "market_name": "stock",
                    "price_aud": "153.47",
                    "price_brl": "511.88",
                    "price_cad": "136.53",
                    "price_eur": "93.34",
                    "price_gbp": "80.21",
                    "price_usd": "99.77",
                    "price_php": "5745.95",
                    "price_zar": "1912.20",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "TLT": {
                    "id": 401,
                    "market_id": 3,
                    "tradingview": "TLT",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "TLT",
                    "market_name": "stock",
                    "price_aud": "136.32",
                    "price_brl": "454.67",
                    "price_cad": "121.27",
                    "price_eur": "82.91",
                    "price_gbp": "71.25",
                    "price_usd": "88.62",
                    "price_php": "5103.80",
                    "price_zar": "1698.50",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "TQQQ": {
                    "id": 286,
                    "market_id": 3,
                    "tradingview": "TQQQ",
                    "name": "Proshares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "TQQQ",
                    "market_name": "stock",
                    "price_aud": "83.20",
                    "price_brl": "277.51",
                    "price_cad": "74.02",
                    "price_eur": "50.61",
                    "price_gbp": "43.49",
                    "price_usd": "54.09",
                    "price_php": "3115.15",
                    "price_zar": "1036.69",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/proshares--big.svg"
                },
                "USFR": {
                    "id": 362,
                    "market_id": 3,
                    "tradingview": "USFR",
                    "name": "WisdomTree",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "USFR",
                    "market_name": "stock",
                    "price_aud": "77.33",
                    "price_brl": "257.92",
                    "price_cad": "68.79",
                    "price_eur": "47.03",
                    "price_gbp": "40.42",
                    "price_usd": "50.27",
                    "price_php": "2895.15",
                    "price_zar": "963.48",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/wisdomtree--big.svg"
                },
                "USHY": {
                    "id": 420,
                    "market_id": 3,
                    "tradingview": "USHY",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "USHY",
                    "market_name": "stock",
                    "price_aud": "55.50",
                    "price_brl": "185.11",
                    "price_cad": "49.37",
                    "price_eur": "33.76",
                    "price_gbp": "29.01",
                    "price_usd": "36.08",
                    "price_php": "2077.92",
                    "price_zar": "691.51",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "USIG": {
                    "id": 421,
                    "market_id": 3,
                    "tradingview": "USIG",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "USIG",
                    "market_name": "stock",
                    "price_aud": "76.21",
                    "price_brl": "254.19",
                    "price_cad": "67.80",
                    "price_eur": "46.35",
                    "price_gbp": "39.83",
                    "price_usd": "49.5435",
                    "price_php": "2853.31",
                    "price_zar": "949.56",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "USMV": {
                    "id": 331,
                    "market_id": 3,
                    "tradingview": "USMV",
                    "name": "iShares",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "USMV",
                    "market_name": "stock",
                    "price_aud": "124.92",
                    "price_brl": "416.66",
                    "price_cad": "111.13",
                    "price_eur": "75.98",
                    "price_gbp": "65.29",
                    "price_usd": "81.21",
                    "price_php": "4677.05",
                    "price_zar": "1556.48",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ishares--big.svg"
                },
                "VB": {
                    "id": 313,
                    "market_id": 3,
                    "tradingview": "VB",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VB",
                    "market_name": "stock",
                    "price_aud": "333.79",
                    "price_brl": "1113.34",
                    "price_cad": "296.94",
                    "price_eur": "203.02",
                    "price_gbp": "174.46",
                    "price_usd": "217",
                    "price_php": "12497.46",
                    "price_zar": "4159.04",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VBK": {
                    "id": 378,
                    "market_id": 3,
                    "tradingview": "VBK",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VBK",
                    "market_name": "stock",
                    "price_aud": "384.56",
                    "price_brl": "1282.65",
                    "price_cad": "342.10",
                    "price_eur": "233.89",
                    "price_gbp": "200.99",
                    "price_usd": "250",
                    "price_php": "14398.00",
                    "price_zar": "4791.53",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VBR": {
                    "id": 340,
                    "market_id": 3,
                    "tradingview": "VBR",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VBR",
                    "market_name": "stock",
                    "price_aud": "281.51",
                    "price_brl": "938.95",
                    "price_cad": "250.43",
                    "price_eur": "171.22",
                    "price_gbp": "147.13",
                    "price_usd": "183.01",
                    "price_php": "10539.91",
                    "price_zar": "3507.59",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VCIT": {
                    "id": 315,
                    "market_id": 3,
                    "tradingview": "VCIT",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VCIT",
                    "market_name": "stock",
                    "price_aud": "120.90",
                    "price_brl": "403.24",
                    "price_cad": "107.55",
                    "price_eur": "73.53",
                    "price_gbp": "63.19",
                    "price_usd": "78.595",
                    "price_php": "4526.44",
                    "price_zar": "1506.36",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VCSH": {
                    "id": 319,
                    "market_id": 3,
                    "tradingview": "VCSH",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VCSH",
                    "market_name": "stock",
                    "price_aud": "117.93",
                    "price_brl": "393.34",
                    "price_cad": "104.91",
                    "price_eur": "71.73",
                    "price_gbp": "61.64",
                    "price_usd": "76.665",
                    "price_php": "4415.29",
                    "price_zar": "1469.37",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VEA": {
                    "id": 289,
                    "market_id": 3,
                    "tradingview": "VEA",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VEA",
                    "market_name": "stock",
                    "price_aud": "75.37",
                    "price_brl": "251.40",
                    "price_cad": "67.05",
                    "price_eur": "45.84",
                    "price_gbp": "39.39",
                    "price_usd": "49",
                    "price_php": "2822.01",
                    "price_zar": "939.14",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VEU": {
                    "id": 320,
                    "market_id": 3,
                    "tradingview": "VEU",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VEU",
                    "market_name": "stock",
                    "price_aud": "88.26",
                    "price_brl": "294.39",
                    "price_cad": "78.52",
                    "price_eur": "53.68",
                    "price_gbp": "46.13",
                    "price_usd": "57.38",
                    "price_php": "3304.63",
                    "price_zar": "1099.75",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VGIT": {
                    "id": 380,
                    "market_id": 3,
                    "tradingview": "VGIT",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VGIT",
                    "market_name": "stock",
                    "price_aud": "88.27",
                    "price_brl": "294.42",
                    "price_cad": "78.53",
                    "price_eur": "53.69",
                    "price_gbp": "46.14",
                    "price_usd": "57.385",
                    "price_php": "3304.92",
                    "price_zar": "1099.85",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VGK": {
                    "id": 354,
                    "market_id": 3,
                    "tradingview": "VGK",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VGK",
                    "market_name": "stock",
                    "price_aud": "101.97",
                    "price_brl": "340.11",
                    "price_cad": "90.71",
                    "price_eur": "62.02",
                    "price_gbp": "53.30",
                    "price_usd": "66.29",
                    "price_php": "3817.77",
                    "price_zar": "1270.52",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VGSH": {
                    "id": 347,
                    "market_id": 3,
                    "tradingview": "VGSH",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VGSH",
                    "market_name": "stock",
                    "price_aud": "88.80",
                    "price_brl": "296.19",
                    "price_cad": "79.00",
                    "price_eur": "54.01",
                    "price_gbp": "46.41",
                    "price_usd": "57.73",
                    "price_php": "3324.79",
                    "price_zar": "1106.46",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VGT": {
                    "id": 305,
                    "market_id": 3,
                    "tradingview": "VGT",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VGT",
                    "market_name": "stock",
                    "price_aud": "760.58",
                    "price_brl": "2536.83",
                    "price_cad": "676.61",
                    "price_eur": "462.60",
                    "price_gbp": "397.52",
                    "price_usd": "494.45",
                    "price_php": "28476.36",
                    "price_zar": "9476.68",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VHT": {
                    "id": 360,
                    "market_id": 3,
                    "tradingview": "VHT",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VHT",
                    "market_name": "stock",
                    "price_aud": "396.62",
                    "price_brl": "1322.87",
                    "price_cad": "352.83",
                    "price_eur": "241.23",
                    "price_gbp": "207.30",
                    "price_usd": "257.84",
                    "price_php": "14849.52",
                    "price_zar": "4941.79",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VIG": {
                    "id": 299,
                    "market_id": 3,
                    "tradingview": "VIG",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VIG",
                    "market_name": "stock",
                    "price_aud": "271.57",
                    "price_brl": "905.81",
                    "price_cad": "241.59",
                    "price_eur": "165.18",
                    "price_gbp": "141.94",
                    "price_usd": "176.55",
                    "price_php": "10167.87",
                    "price_zar": "3383.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VMBS": {
                    "id": 366,
                    "market_id": 3,
                    "tradingview": "VMBS",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VMBS",
                    "market_name": "stock",
                    "price_aud": "68.47",
                    "price_brl": "228.39",
                    "price_cad": "60.91",
                    "price_eur": "41.65",
                    "price_gbp": "35.79",
                    "price_usd": "44.515",
                    "price_php": "2563.71",
                    "price_zar": "853.18",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VNQ": {
                    "id": 326,
                    "market_id": 3,
                    "tradingview": "VNQ",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VNQ",
                    "market_name": "stock",
                    "price_aud": "123.72",
                    "price_brl": "412.65",
                    "price_cad": "110.06",
                    "price_eur": "75.25",
                    "price_gbp": "64.66",
                    "price_usd": "80.43",
                    "price_php": "4632.12",
                    "price_zar": "1541.53",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VO": {
                    "id": 304,
                    "market_id": 3,
                    "tradingview": "VO",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VO",
                    "market_name": "stock",
                    "price_aud": "367.47",
                    "price_brl": "1225.65",
                    "price_cad": "326.90",
                    "price_eur": "223.50",
                    "price_gbp": "192.06",
                    "price_usd": "238.89",
                    "price_php": "13758.15",
                    "price_zar": "4578.59",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VOE": {
                    "id": 363,
                    "market_id": 3,
                    "tradingview": "VOE",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VOE",
                    "market_name": "stock",
                    "price_aud": "231.01",
                    "price_brl": "770.51",
                    "price_cad": "205.51",
                    "price_eur": "140.50",
                    "price_gbp": "120.74",
                    "price_usd": "150.18",
                    "price_php": "8649.17",
                    "price_zar": "2878.36",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VONG": {
                    "id": 386,
                    "market_id": 3,
                    "tradingview": "VONG",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VONG",
                    "market_name": "stock",
                    "price_aud": "128.27",
                    "price_brl": "427.84",
                    "price_cad": "114.11",
                    "price_eur": "78.02",
                    "price_gbp": "67.04",
                    "price_usd": "83.39",
                    "price_php": "4802.60",
                    "price_zar": "1598.26",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VOO": {
                    "id": 283,
                    "market_id": 3,
                    "tradingview": "VOO",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VOO",
                    "market_name": "stock",
                    "price_aud": "715.34",
                    "price_brl": "2385.93",
                    "price_cad": "636.36",
                    "price_eur": "435.08",
                    "price_gbp": "373.88",
                    "price_usd": "465.04",
                    "price_php": "26782.58",
                    "price_zar": "8913.00",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VOT": {
                    "id": 404,
                    "market_id": 3,
                    "tradingview": "VOT",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VOT",
                    "market_name": "stock",
                    "price_aud": "344.79",
                    "price_brl": "1150.02",
                    "price_cad": "306.73",
                    "price_eur": "209.71",
                    "price_gbp": "180.21",
                    "price_usd": "224.15",
                    "price_php": "12909.25",
                    "price_zar": "4296.08",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VSS": {
                    "id": 426,
                    "market_id": 3,
                    "tradingview": "VSS",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VSS",
                    "market_name": "stock",
                    "price_aud": "175.81",
                    "price_brl": "586.40",
                    "price_cad": "156.40",
                    "price_eur": "106.93",
                    "price_gbp": "91.89",
                    "price_usd": "114.295",
                    "price_php": "6582.48",
                    "price_zar": "2190.59",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VT": {
                    "id": 288,
                    "market_id": 3,
                    "tradingview": "VT",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VT",
                    "market_name": "stock",
                    "price_aud": "165.28",
                    "price_brl": "551.28",
                    "price_cad": "147.03",
                    "price_eur": "100.53",
                    "price_gbp": "86.39",
                    "price_usd": "107.45",
                    "price_php": "6188.26",
                    "price_zar": "2059.40",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VTEB": {
                    "id": 338,
                    "market_id": 3,
                    "tradingview": "VTEB",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VTEB",
                    "market_name": "stock",
                    "price_aud": "76.83",
                    "price_brl": "256.25",
                    "price_cad": "68.34",
                    "price_eur": "46.73",
                    "price_gbp": "40.15",
                    "price_usd": "49.945",
                    "price_php": "2876.43",
                    "price_zar": "957.25",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VTIP": {
                    "id": 374,
                    "market_id": 3,
                    "tradingview": "VTIP",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VTIP",
                    "market_name": "stock",
                    "price_aud": "73.63",
                    "price_brl": "245.60",
                    "price_cad": "65.51",
                    "price_eur": "44.79",
                    "price_gbp": "38.49",
                    "price_usd": "47.87",
                    "price_php": "2756.93",
                    "price_zar": "917.48",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VTWO": {
                    "id": 290,
                    "market_id": 3,
                    "tradingview": "VTWO",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VTWO",
                    "market_name": "stock",
                    "price_aud": "123.13",
                    "price_brl": "410.70",
                    "price_cad": "109.54",
                    "price_eur": "74.89",
                    "price_gbp": "64.36",
                    "price_usd": "80.05",
                    "price_php": "4610.24",
                    "price_zar": "1534.25",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VUG": {
                    "id": 292,
                    "market_id": 3,
                    "tradingview": "VUG",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VUG",
                    "market_name": "stock",
                    "price_aud": "511.46",
                    "price_brl": "1705.92",
                    "price_cad": "454.99",
                    "price_eur": "311.08",
                    "price_gbp": "267.32",
                    "price_usd": "332.5",
                    "price_php": "19149.34",
                    "price_zar": "6372.73",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VV": {
                    "id": 333,
                    "market_id": 3,
                    "tradingview": "VV",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VV",
                    "market_name": "stock",
                    "price_aud": "357.21",
                    "price_brl": "1191.43",
                    "price_cad": "317.77",
                    "price_eur": "217.26",
                    "price_gbp": "186.70",
                    "price_usd": "232.22",
                    "price_php": "13374.01",
                    "price_zar": "4450.75",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VWO": {
                    "id": 295,
                    "market_id": 3,
                    "tradingview": "VWO",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VWO",
                    "market_name": "stock",
                    "price_aud": "64.28",
                    "price_brl": "214.41",
                    "price_cad": "57.19",
                    "price_eur": "39.10",
                    "price_gbp": "33.60",
                    "price_usd": "41.79",
                    "price_php": "2406.77",
                    "price_zar": "800.95",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VXF": {
                    "id": 369,
                    "market_id": 3,
                    "tradingview": "VXF",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VXF",
                    "market_name": "stock",
                    "price_aud": "255.78",
                    "price_brl": "853.12",
                    "price_cad": "227.54",
                    "price_eur": "155.57",
                    "price_gbp": "133.68",
                    "price_usd": "166.28",
                    "price_php": "9576.40",
                    "price_zar": "3186.94",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VXUS": {
                    "id": 302,
                    "market_id": 3,
                    "tradingview": "VXUS",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VXUS",
                    "market_name": "stock",
                    "price_aud": "90.76",
                    "price_brl": "302.71",
                    "price_cad": "80.74",
                    "price_eur": "55.20",
                    "price_gbp": "47.43",
                    "price_usd": "59",
                    "price_php": "3397.93",
                    "price_zar": "1130.80",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "VYM": {
                    "id": 310,
                    "market_id": 3,
                    "tradingview": "VYM",
                    "name": "Vanguard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "VYM",
                    "market_name": "stock",
                    "price_aud": "181.51",
                    "price_brl": "605.41",
                    "price_cad": "161.47",
                    "price_eur": "110.40",
                    "price_gbp": "94.87",
                    "price_usd": "118",
                    "price_php": "6795.86",
                    "price_zar": "2261.60",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vanguard--big.svg"
                },
                "XLC": {
                    "id": 385,
                    "market_id": 3,
                    "tradingview": "XLC",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "XLC",
                    "market_name": "stock",
                    "price_aud": "124.58",
                    "price_brl": "415.53",
                    "price_cad": "110.83",
                    "price_eur": "75.77",
                    "price_gbp": "65.11",
                    "price_usd": "80.99",
                    "price_php": "4664.38",
                    "price_zar": "1552.26",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                "XLE": {
                    "id": 321,
                    "market_id": 3,
                    "tradingview": "XLE",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "XLE",
                    "market_name": "stock",
                    "price_aud": "147.02",
                    "price_brl": "490.38",
                    "price_cad": "130.79",
                    "price_eur": "89.42",
                    "price_gbp": "76.84",
                    "price_usd": "95.58",
                    "price_php": "5504.64",
                    "price_zar": "1831.90",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                "XLF": {
                    "id": 324,
                    "market_id": 3,
                    "tradingview": "XLF",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "XLF",
                    "market_name": "stock",
                    "price_aud": "63.31",
                    "price_brl": "211.18",
                    "price_cad": "56.32",
                    "price_eur": "38.51",
                    "price_gbp": "33.09",
                    "price_usd": "41.16",
                    "price_php": "2370.49",
                    "price_zar": "788.88",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                "XLI": {
                    "id": 373,
                    "market_id": 3,
                    "tradingview": "XLI",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "XLI",
                    "market_name": "stock",
                    "price_aud": "188.68",
                    "price_brl": "629.32",
                    "price_cad": "167.85",
                    "price_eur": "114.76",
                    "price_gbp": "98.61",
                    "price_usd": "122.66",
                    "price_php": "7064.23",
                    "price_zar": "2350.91",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                "XLK": {
                    "id": 308,
                    "market_id": 3,
                    "tradingview": "XLK",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "XLK",
                    "market_name": "stock",
                    "price_aud": "305.08",
                    "price_brl": "1017.55",
                    "price_cad": "271.39",
                    "price_eur": "185.55",
                    "price_gbp": "159.45",
                    "price_usd": "198.33",
                    "price_php": "11422.22",
                    "price_zar": "3801.21",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                "XLP": {
                    "id": 358,
                    "market_id": 3,
                    "tradingview": "XLP",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "XLP",
                    "market_name": "stock",
                    "price_aud": "115.61",
                    "price_brl": "385.62",
                    "price_cad": "102.85",
                    "price_eur": "70.32",
                    "price_gbp": "60.43",
                    "price_usd": "75.16",
                    "price_php": "4328.61",
                    "price_zar": "1440.52",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                "XLU": {
                    "id": 367,
                    "market_id": 3,
                    "tradingview": "XLU",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "XLU",
                    "market_name": "stock",
                    "price_aud": "102.00",
                    "price_brl": "340.21",
                    "price_cad": "90.74",
                    "price_eur": "62.04",
                    "price_gbp": "53.31",
                    "price_usd": "66.31",
                    "price_php": "3818.93",
                    "price_zar": "1270.90",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                "XLV": {
                    "id": 314,
                    "market_id": 3,
                    "tradingview": "XLV",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "XLV",
                    "market_name": "stock",
                    "price_aud": "216.91",
                    "price_brl": "723.47",
                    "price_cad": "192.96",
                    "price_eur": "131.93",
                    "price_gbp": "113.37",
                    "price_usd": "141.01",
                    "price_php": "8121.05",
                    "price_zar": "2702.61",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                },
                "XLY": {
                    "id": 359,
                    "market_id": 3,
                    "tradingview": "XLY",
                    "name": "SPDR",
                    "polygon": "",
                    "listed": "yes",
                    "type": "etf",
                    "firebase": "XLY",
                    "market_name": "stock",
                    "price_aud": "268.59",
                    "price_brl": "895.85",
                    "price_cad": "238.94",
                    "price_eur": "163.36",
                    "price_gbp": "140.38",
                    "price_usd": "174.61",
                    "price_php": "10056.14",
                    "price_zar": "3346.59",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/spdr-sandp500-etf-tr--big.svg"
                }
            }
        }, 
        "index": {
            "current_page": 1,
            "data": [
                {
                    "id": 431,
                    "market_id": 1,
                    "tradingview": "NAS100USD",
                    "name": "US Nas 100",
                    "polygon": "",
                    "listed": "yes",
                    "type": "index",
                    "firebase": "NAS100USD",
                    "market_name": "index",
                    "price_aud": "27046.22",
                    "price_brl": "90210.06",
                    "price_cad": "24060.25",
                    "price_eur": "16450.00",
                    "price_gbp": "14135.98",
                    "price_usd": "17582.75",
                    "price_php": "1012625.74",
                    "price_zar": "336992.74",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/indices\/nasdaq-100--big.svg"
                },
                {
                    "id": 429,
                    "market_id": 1,
                    "tradingview": "SPX500USD",
                    "name": "S&P 500 Index",
                    "polygon": "",
                    "listed": "yes",
                    "type": "index",
                    "firebase": "SPX500USD",
                    "market_name": "index",
                    "price_aud": "7816.72",
                    "price_brl": "26071.91",
                    "price_cad": "6953.73",
                    "price_eur": "4754.27",
                    "price_gbp": "4085.49",
                    "price_usd": "5081.65",
                    "price_php": "292662.39",
                    "price_zar": "97395.41",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/indices\/s-and-p-500--big.svg"
                },
                {
                    "id": 432,
                    "market_id": 1,
                    "tradingview": "US2000USD",
                    "name": "US ROSS 2000",
                    "polygon": "",
                    "listed": "yes",
                    "type": "index",
                    "firebase": "US2000USD",
                    "market_name": "index",
                    "price_aud": "3068.11",
                    "price_brl": "10233.38",
                    "price_cad": "2729.38",
                    "price_eur": "1866.08",
                    "price_gbp": "1603.58",
                    "price_usd": "1994.578",
                    "price_php": "114871.74",
                    "price_zar": "38228.28",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/indices\/russell-2000--big.svg"
                },
                {
                    "id": 430,
                    "market_id": 1,
                    "tradingview": "US30USD",
                    "name": "US Wall St 30",
                    "polygon": "",
                    "listed": "yes",
                    "type": "index",
                    "firebase": "US30USD",
                    "market_name": "index",
                    "price_aud": "59220.89",
                    "price_brl": "197525.53",
                    "price_cad": "52682.75",
                    "price_eur": "36019.22",
                    "price_gbp": "30952.38",
                    "price_usd": "38499.5",
                    "price_php": "2217263.20",
                    "price_zar": "737885.27",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/indices\/dow-30--big.svg"
                }
            ],
            "total": 4,
            "object": {
                "NAS100USD": {
                    "id": 431,
                    "market_id": 1,
                    "tradingview": "NAS100USD",
                    "name": "US Nas 100",
                    "polygon": "",
                    "listed": "yes",
                    "type": "index",
                    "firebase": "NAS100USD",
                    "market_name": "index",
                    "price_aud": "27046.22",
                    "price_brl": "90210.06",
                    "price_cad": "24060.25",
                    "price_eur": "16450.00",
                    "price_gbp": "14135.98",
                    "price_usd": "17582.75",
                    "price_php": "1012625.74",
                    "price_zar": "336992.74",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/indices\/nasdaq-100--big.svg"
                },
                "SPX500USD": {
                    "id": 429,
                    "market_id": 1,
                    "tradingview": "SPX500USD",
                    "name": "S&P 500 Index",
                    "polygon": "",
                    "listed": "yes",
                    "type": "index",
                    "firebase": "SPX500USD",
                    "market_name": "index",
                    "price_aud": "7816.72",
                    "price_brl": "26071.91",
                    "price_cad": "6953.73",
                    "price_eur": "4754.27",
                    "price_gbp": "4085.49",
                    "price_usd": "5081.65",
                    "price_php": "292662.39",
                    "price_zar": "97395.41",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/indices\/s-and-p-500--big.svg"
                },
                "US2000USD": {
                    "id": 432,
                    "market_id": 1,
                    "tradingview": "US2000USD",
                    "name": "US ROSS 2000",
                    "polygon": "",
                    "listed": "yes",
                    "type": "index",
                    "firebase": "US2000USD",
                    "market_name": "index",
                    "price_aud": "3068.11",
                    "price_brl": "10233.38",
                    "price_cad": "2729.38",
                    "price_eur": "1866.08",
                    "price_gbp": "1603.58",
                    "price_usd": "1994.578",
                    "price_php": "114871.74",
                    "price_zar": "38228.28",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/indices\/russell-2000--big.svg"
                },
                "US30USD": {
                    "id": 430,
                    "market_id": 1,
                    "tradingview": "US30USD",
                    "name": "US Wall St 30",
                    "polygon": "",
                    "listed": "yes",
                    "type": "index",
                    "firebase": "US30USD",
                    "market_name": "index",
                    "price_aud": "59220.89",
                    "price_brl": "197525.53",
                    "price_cad": "52682.75",
                    "price_eur": "36019.22",
                    "price_gbp": "30952.38",
                    "price_usd": "38499.5",
                    "price_php": "2217263.20",
                    "price_zar": "737885.27",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/indices\/dow-30--big.svg"
                }
            }
        },
        "forex": {
            "current_page": 1,
            "data": [
                {
                    "id": 1,
                    "market_id": 2,
                    "tradingview": "AUDCAD",
                    "name": "AUDCAD",
                    "polygon": "AUD-CAD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "AUDCAD",
                    "market_name": "forex",
                    "price_aud": "1.37",
                    "price_brl": "4.56",
                    "price_cad": "1.22",
                    "price_eur": "0.83",
                    "price_gbp": "0.72",
                    "price_usd": "0.8896",
                    "price_php": "51.23",
                    "price_zar": "17.05",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-audcad.img.svg"
                },
                {
                    "id": 2,
                    "market_id": 2,
                    "tradingview": "AUDCHF",
                    "name": "AUDCHF",
                    "polygon": "AUD-CHF",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "AUDCHF",
                    "market_name": "forex",
                    "price_aud": "0.91",
                    "price_brl": "3.05",
                    "price_cad": "0.81",
                    "price_eur": "0.56",
                    "price_gbp": "0.48",
                    "price_usd": "0.59437",
                    "price_php": "34.23",
                    "price_zar": "11.39",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-audchf.img.svg"
                },
                {
                    "id": 4,
                    "market_id": 2,
                    "tradingview": "AUDJPY",
                    "name": "AUDJPY",
                    "polygon": "AUD-JPY",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "AUDJPY",
                    "market_name": "forex",
                    "price_aud": "154.88",
                    "price_brl": "516.59",
                    "price_cad": "137.78",
                    "price_eur": "94.20",
                    "price_gbp": "80.95",
                    "price_usd": "100.688",
                    "price_php": "5798.82",
                    "price_zar": "1929.80",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-audjpy.img.svg"
                },
                {
                    "id": 3,
                    "market_id": 2,
                    "tradingview": "AUDNZD",
                    "name": "AUDNZD",
                    "polygon": "AUD-NZD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "AUDNZD",
                    "market_name": "forex",
                    "price_aud": "1.69",
                    "price_brl": "5.62",
                    "price_cad": "1.50",
                    "price_eur": "1.03",
                    "price_gbp": "0.88",
                    "price_usd": "1.0959",
                    "price_php": "63.12",
                    "price_zar": "21.00",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-audnzd.img.svg"
                },
                {
                    "id": 5,
                    "market_id": 2,
                    "tradingview": "AUDUSD",
                    "name": "AUDUSD",
                    "polygon": "AUD-USD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "AUDUSD",
                    "market_name": "forex",
                    "price_aud": "1.00",
                    "price_brl": "3.34",
                    "price_cad": "0.89",
                    "price_eur": "0.61",
                    "price_gbp": "0.52",
                    "price_usd": "0.6501",
                    "price_php": "37.44",
                    "price_zar": "12.46",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-audusd.img.svg"
                },
                {
                    "id": 27,
                    "market_id": 2,
                    "tradingview": "BRLUSD",
                    "name": "BRLUSD",
                    "polygon": "BRL-USD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "BRLUSD",
                    "market_name": "forex",
                    "price_aud": "0.30",
                    "price_brl": "1.00",
                    "price_cad": "0.27",
                    "price_eur": "0.18",
                    "price_gbp": "0.16",
                    "price_usd": "0.1949089775075",
                    "price_php": "11.23",
                    "price_zar": "3.74",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-brlusd.img.svg"
                },
                {
                    "id": 24,
                    "market_id": 2,
                    "tradingview": "CADCHF",
                    "name": "CADCHF",
                    "polygon": "CAD-CHF",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "CADCHF",
                    "market_name": "forex",
                    "price_aud": "1.03",
                    "price_brl": "3.43",
                    "price_cad": "0.92",
                    "price_eur": "0.63",
                    "price_gbp": "0.54",
                    "price_usd": "0.6687",
                    "price_php": "38.51",
                    "price_zar": "12.82",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-cadchf.img.svg"
                },
                {
                    "id": 25,
                    "market_id": 2,
                    "tradingview": "CADJPY",
                    "name": "CADJPY",
                    "polygon": "CAD-JPY",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "CADJPY",
                    "market_name": "forex",
                    "price_aud": "174.16",
                    "price_brl": "580.89",
                    "price_cad": "154.93",
                    "price_eur": "105.93",
                    "price_gbp": "91.03",
                    "price_usd": "113.22",
                    "price_php": "6520.57",
                    "price_zar": "2169.99",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-cadjpy.img.svg"
                },
                {
                    "id": 26,
                    "market_id": 2,
                    "tradingview": "CADUSD",
                    "name": "CADUSD",
                    "polygon": "CAD-USD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "CADUSD",
                    "market_name": "forex",
                    "price_aud": "1.12",
                    "price_brl": "3.75",
                    "price_cad": "1.00",
                    "price_eur": "0.68",
                    "price_gbp": "0.59",
                    "price_usd": "0.73078",
                    "price_php": "42.09",
                    "price_zar": "14.01",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-cadusd.img.svg"
                },
                {
                    "id": 23,
                    "market_id": 2,
                    "tradingview": "CHFJPY",
                    "name": "CHFJPY",
                    "polygon": "CHF-JPY",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "CHFJPY",
                    "market_name": "forex",
                    "price_aud": "260.60",
                    "price_brl": "869.21",
                    "price_cad": "231.83",
                    "price_eur": "158.50",
                    "price_gbp": "136.21",
                    "price_usd": "169.416",
                    "price_php": "9757.01",
                    "price_zar": "3247.04",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-chfjpy.img.svg"
                },
                {
                    "id": 6,
                    "market_id": 2,
                    "tradingview": "EURCAD",
                    "name": "EURCAD",
                    "polygon": "EUR-CAD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "EURCAD",
                    "market_name": "forex",
                    "price_aud": "2.25",
                    "price_brl": "7.50",
                    "price_cad": "2.00",
                    "price_eur": "1.37",
                    "price_gbp": "1.18",
                    "price_usd": "1.46268",
                    "price_php": "84.24",
                    "price_zar": "28.03",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-eurcad.img.svg"
                },
                {
                    "id": 7,
                    "market_id": 2,
                    "tradingview": "EURCHF",
                    "name": "EURCHF",
                    "polygon": "EUR-CHF",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "EURCHF",
                    "market_name": "forex",
                    "price_aud": "1.50",
                    "price_brl": "5.02",
                    "price_cad": "1.34",
                    "price_eur": "0.91",
                    "price_gbp": "0.79",
                    "price_usd": "0.9776",
                    "price_php": "56.30",
                    "price_zar": "18.74",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-eurchf.img.svg"
                },
                {
                    "id": 9,
                    "market_id": 2,
                    "tradingview": "EURJPY",
                    "name": "EURJPY",
                    "polygon": "EUR-JPY",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "EURJPY",
                    "market_name": "forex",
                    "price_aud": "254.73",
                    "price_brl": "849.63",
                    "price_cad": "226.61",
                    "price_eur": "154.93",
                    "price_gbp": "133.14",
                    "price_usd": "165.6",
                    "price_php": "9537.24",
                    "price_zar": "3173.91",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-eurjpy.img.svg"
                },
                {
                    "id": 8,
                    "market_id": 2,
                    "tradingview": "EURNZD",
                    "name": "EURNZD",
                    "polygon": "EUR-NZD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "EURNZD",
                    "market_name": "forex",
                    "price_aud": "2.77",
                    "price_brl": "9.25",
                    "price_cad": "2.47",
                    "price_eur": "1.69",
                    "price_gbp": "1.45",
                    "price_usd": "1.80199",
                    "price_php": "103.78",
                    "price_zar": "34.54",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-eurnzd.img.svg"
                },
                {
                    "id": 10,
                    "market_id": 2,
                    "tradingview": "EURUSD",
                    "name": "EURUSD",
                    "polygon": "EUR-USD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "EURUSD",
                    "market_name": "forex",
                    "price_aud": "1.64",
                    "price_brl": "5.48",
                    "price_cad": "1.46",
                    "price_eur": "1.00",
                    "price_gbp": "0.86",
                    "price_usd": "1.06886",
                    "price_php": "61.56",
                    "price_zar": "20.49",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-eurusd.img.svg"
                },
                {
                    "id": 11,
                    "market_id": 2,
                    "tradingview": "GBPCAD",
                    "name": "GBPCAD",
                    "polygon": "GBP-CAD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "GBPCAD",
                    "market_name": "forex",
                    "price_aud": "2.62",
                    "price_brl": "8.73",
                    "price_cad": "2.33",
                    "price_eur": "1.59",
                    "price_gbp": "1.37",
                    "price_usd": "1.7025",
                    "price_php": "98.05",
                    "price_zar": "32.63",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-gbpcad.img.svg"
                },
                {
                    "id": 12,
                    "market_id": 2,
                    "tradingview": "GBPCHF",
                    "name": "GBPCHF",
                    "polygon": "GBP-CHF",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "GBPCHF",
                    "market_name": "forex",
                    "price_aud": "1.75",
                    "price_brl": "5.84",
                    "price_cad": "1.56",
                    "price_eur": "1.06",
                    "price_gbp": "0.91",
                    "price_usd": "1.1377",
                    "price_php": "65.52",
                    "price_zar": "21.81",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-gbpchf.img.svg"
                },
                {
                    "id": 14,
                    "market_id": 2,
                    "tradingview": "GBPJPY",
                    "name": "GBPJPY",
                    "polygon": "GBP-JPY",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "GBPJPY",
                    "market_name": "forex",
                    "price_aud": "296.43",
                    "price_brl": "988.70",
                    "price_cad": "263.70",
                    "price_eur": "180.29",
                    "price_gbp": "154.93",
                    "price_usd": "192.707",
                    "price_php": "11098.38",
                    "price_zar": "3693.44",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-gbpjpy.img.svg"
                },
                {
                    "id": 13,
                    "market_id": 2,
                    "tradingview": "GBPNZD",
                    "name": "GBPNZD",
                    "polygon": "GBP-NZD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "GBPNZD",
                    "market_name": "forex",
                    "price_aud": "3.23",
                    "price_brl": "10.76",
                    "price_cad": "2.87",
                    "price_eur": "1.96",
                    "price_gbp": "1.69",
                    "price_usd": "2.09677",
                    "price_php": "120.76",
                    "price_zar": "40.19",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-gbpnzd.img.svg"
                },
                {
                    "id": 15,
                    "market_id": 2,
                    "tradingview": "GBPUSD",
                    "name": "GBPUSD",
                    "polygon": "GBP-USD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "GBPUSD",
                    "market_name": "forex",
                    "price_aud": "1.91",
                    "price_brl": "6.38",
                    "price_cad": "1.70",
                    "price_eur": "1.16",
                    "price_gbp": "1.00",
                    "price_usd": "1.24383",
                    "price_php": "71.63",
                    "price_zar": "23.84",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-gbpusd.img.svg"
                },
                {
                    "id": 16,
                    "market_id": 2,
                    "tradingview": "NZDCAD",
                    "name": "NZDCAD",
                    "polygon": "NZD-CAD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "NZDCAD",
                    "market_name": "forex",
                    "price_aud": "1.25",
                    "price_brl": "4.17",
                    "price_cad": "1.11",
                    "price_eur": "0.76",
                    "price_gbp": "0.65",
                    "price_usd": "0.81195",
                    "price_php": "46.76",
                    "price_zar": "15.56",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-nzdcad.img.svg"
                },
                {
                    "id": 17,
                    "market_id": 2,
                    "tradingview": "NZDCHF",
                    "name": "NZDCHF",
                    "polygon": "NZD-CHF",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "NZDCHF",
                    "market_name": "forex",
                    "price_aud": "0.83",
                    "price_brl": "2.78",
                    "price_cad": "0.74",
                    "price_eur": "0.51",
                    "price_gbp": "0.44",
                    "price_usd": "0.54239",
                    "price_php": "31.24",
                    "price_zar": "10.40",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-nzdchf.img.svg"
                },
                {
                    "id": 18,
                    "market_id": 2,
                    "tradingview": "NZDJPY",
                    "name": "NZDJPY",
                    "polygon": "NZD-JPY",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "NZDJPY",
                    "market_name": "forex",
                    "price_aud": "141.36",
                    "price_brl": "471.50",
                    "price_cad": "125.76",
                    "price_eur": "85.98",
                    "price_gbp": "73.88",
                    "price_usd": "91.9",
                    "price_php": "5292.70",
                    "price_zar": "1761.36",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-nzdjpy.img.svg"
                },
                {
                    "id": 19,
                    "market_id": 2,
                    "tradingview": "NZDUSD",
                    "name": "NZDUSD",
                    "polygon": "NZD-USD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "NZDUSD",
                    "market_name": "forex",
                    "price_aud": "0.91",
                    "price_brl": "3.05",
                    "price_cad": "0.81",
                    "price_eur": "0.56",
                    "price_gbp": "0.48",
                    "price_usd": "0.5935",
                    "price_php": "34.18",
                    "price_zar": "11.38",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-nzdusd.img.svg"
                },
                {
                    "id": 29,
                    "market_id": 2,
                    "tradingview": "PHPUSD",
                    "name": "PHPUSD",
                    "polygon": "PHP-USD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "PHPUSD",
                    "market_name": "forex",
                    "price_aud": "0.03",
                    "price_brl": "0.09",
                    "price_cad": "0.02",
                    "price_eur": "0.02",
                    "price_gbp": "0.01",
                    "price_usd": "0.017363522711488",
                    "price_php": "1.00",
                    "price_zar": "0.33",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-phpusd.img.svg"
                },
                {
                    "id": 20,
                    "market_id": 2,
                    "tradingview": "USDCAD",
                    "name": "USDCAD",
                    "polygon": "USD-CAD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "USDCAD",
                    "market_name": "forex",
                    "price_aud": "2.11",
                    "price_brl": "7.02",
                    "price_cad": "1.87",
                    "price_eur": "1.28",
                    "price_gbp": "1.10",
                    "price_usd": "1.36848",
                    "price_php": "78.81",
                    "price_zar": "26.23",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-usdcad.img.svg"
                },
                {
                    "id": 21,
                    "market_id": 2,
                    "tradingview": "USDCHF",
                    "name": "USDCHF",
                    "polygon": "USD-CHF",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "USDCHF",
                    "market_name": "forex",
                    "price_aud": "1.41",
                    "price_brl": "4.69",
                    "price_cad": "1.25",
                    "price_eur": "0.86",
                    "price_gbp": "0.74",
                    "price_usd": "0.9148",
                    "price_php": "52.69",
                    "price_zar": "17.53",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-usdchf.img.svg"
                },
                {
                    "id": 22,
                    "market_id": 2,
                    "tradingview": "USDJPY",
                    "name": "USDJPY",
                    "polygon": "USD-JPY",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "USDJPY",
                    "market_name": "forex",
                    "price_aud": "238.33",
                    "price_brl": "794.91",
                    "price_cad": "212.01",
                    "price_eur": "144.95",
                    "price_gbp": "124.56",
                    "price_usd": "154.936",
                    "price_php": "8923.07",
                    "price_zar": "2969.52",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-usdjpy.img.svg"
                },
                {
                    "id": 28,
                    "market_id": 2,
                    "tradingview": "ZARUSD",
                    "name": "ZARUSD",
                    "polygon": "ZAR-USD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "ZARUSD",
                    "market_name": "forex",
                    "price_aud": "0.08",
                    "price_brl": "0.27",
                    "price_cad": "0.07",
                    "price_eur": "0.05",
                    "price_gbp": "0.04",
                    "price_usd": "0.052175455622166",
                    "price_php": "3.00",
                    "price_zar": "1.00",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-zarusd.img.svg"
                }
            ],
            "total": 29,
            "object": {
                "AUDCAD": {
                    "id": 1,
                    "market_id": 2,
                    "tradingview": "AUDCAD",
                    "name": "AUDCAD",
                    "polygon": "AUD-CAD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "AUDCAD",
                    "market_name": "forex",
                    "price_aud": "1.37",
                    "price_brl": "4.56",
                    "price_cad": "1.22",
                    "price_eur": "0.83",
                    "price_gbp": "0.72",
                    "price_usd": "0.8896",
                    "price_php": "51.23",
                    "price_zar": "17.05",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-audcad.img.svg"
                },
                "AUDCHF": {
                    "id": 2,
                    "market_id": 2,
                    "tradingview": "AUDCHF",
                    "name": "AUDCHF",
                    "polygon": "AUD-CHF",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "AUDCHF",
                    "market_name": "forex",
                    "price_aud": "0.91",
                    "price_brl": "3.05",
                    "price_cad": "0.81",
                    "price_eur": "0.56",
                    "price_gbp": "0.48",
                    "price_usd": "0.59437",
                    "price_php": "34.23",
                    "price_zar": "11.39",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-audchf.img.svg"
                },
                "AUDJPY": {
                    "id": 4,
                    "market_id": 2,
                    "tradingview": "AUDJPY",
                    "name": "AUDJPY",
                    "polygon": "AUD-JPY",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "AUDJPY",
                    "market_name": "forex",
                    "price_aud": "154.88",
                    "price_brl": "516.59",
                    "price_cad": "137.78",
                    "price_eur": "94.20",
                    "price_gbp": "80.95",
                    "price_usd": "100.688",
                    "price_php": "5798.82",
                    "price_zar": "1929.80",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-audjpy.img.svg"
                },
                "AUDNZD": {
                    "id": 3,
                    "market_id": 2,
                    "tradingview": "AUDNZD",
                    "name": "AUDNZD",
                    "polygon": "AUD-NZD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "AUDNZD",
                    "market_name": "forex",
                    "price_aud": "1.69",
                    "price_brl": "5.62",
                    "price_cad": "1.50",
                    "price_eur": "1.03",
                    "price_gbp": "0.88",
                    "price_usd": "1.0959",
                    "price_php": "63.12",
                    "price_zar": "21.00",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-audnzd.img.svg"
                },
                "AUDUSD": {
                    "id": 5,
                    "market_id": 2,
                    "tradingview": "AUDUSD",
                    "name": "AUDUSD",
                    "polygon": "AUD-USD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "AUDUSD",
                    "market_name": "forex",
                    "price_aud": "1.00",
                    "price_brl": "3.34",
                    "price_cad": "0.89",
                    "price_eur": "0.61",
                    "price_gbp": "0.52",
                    "price_usd": "0.6501",
                    "price_php": "37.44",
                    "price_zar": "12.46",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-audusd.img.svg"
                },
                "BRLUSD": {
                    "id": 27,
                    "market_id": 2,
                    "tradingview": "BRLUSD",
                    "name": "BRLUSD",
                    "polygon": "BRL-USD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "BRLUSD",
                    "market_name": "forex",
                    "price_aud": "0.30",
                    "price_brl": "1.00",
                    "price_cad": "0.27",
                    "price_eur": "0.18",
                    "price_gbp": "0.16",
                    "price_usd": "0.1949089775075",
                    "price_php": "11.23",
                    "price_zar": "3.74",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-brlusd.img.svg"
                },
                "CADCHF": {
                    "id": 24,
                    "market_id": 2,
                    "tradingview": "CADCHF",
                    "name": "CADCHF",
                    "polygon": "CAD-CHF",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "CADCHF",
                    "market_name": "forex",
                    "price_aud": "1.03",
                    "price_brl": "3.43",
                    "price_cad": "0.92",
                    "price_eur": "0.63",
                    "price_gbp": "0.54",
                    "price_usd": "0.6687",
                    "price_php": "38.51",
                    "price_zar": "12.82",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-cadchf.img.svg"
                },
                "CADJPY": {
                    "id": 25,
                    "market_id": 2,
                    "tradingview": "CADJPY",
                    "name": "CADJPY",
                    "polygon": "CAD-JPY",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "CADJPY",
                    "market_name": "forex",
                    "price_aud": "174.16",
                    "price_brl": "580.89",
                    "price_cad": "154.93",
                    "price_eur": "105.93",
                    "price_gbp": "91.03",
                    "price_usd": "113.22",
                    "price_php": "6520.57",
                    "price_zar": "2169.99",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-cadjpy.img.svg"
                },
                "CADUSD": {
                    "id": 26,
                    "market_id": 2,
                    "tradingview": "CADUSD",
                    "name": "CADUSD",
                    "polygon": "CAD-USD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "CADUSD",
                    "market_name": "forex",
                    "price_aud": "1.12",
                    "price_brl": "3.75",
                    "price_cad": "1.00",
                    "price_eur": "0.68",
                    "price_gbp": "0.59",
                    "price_usd": "0.73078",
                    "price_php": "42.09",
                    "price_zar": "14.01",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-cadusd.img.svg"
                },
                "CHFJPY": {
                    "id": 23,
                    "market_id": 2,
                    "tradingview": "CHFJPY",
                    "name": "CHFJPY",
                    "polygon": "CHF-JPY",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "CHFJPY",
                    "market_name": "forex",
                    "price_aud": "260.60",
                    "price_brl": "869.21",
                    "price_cad": "231.83",
                    "price_eur": "158.50",
                    "price_gbp": "136.21",
                    "price_usd": "169.416",
                    "price_php": "9757.01",
                    "price_zar": "3247.04",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-chfjpy.img.svg"
                },
                "EURCAD": {
                    "id": 6,
                    "market_id": 2,
                    "tradingview": "EURCAD",
                    "name": "EURCAD",
                    "polygon": "EUR-CAD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "EURCAD",
                    "market_name": "forex",
                    "price_aud": "2.25",
                    "price_brl": "7.50",
                    "price_cad": "2.00",
                    "price_eur": "1.37",
                    "price_gbp": "1.18",
                    "price_usd": "1.46268",
                    "price_php": "84.24",
                    "price_zar": "28.03",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-eurcad.img.svg"
                },
                "EURCHF": {
                    "id": 7,
                    "market_id": 2,
                    "tradingview": "EURCHF",
                    "name": "EURCHF",
                    "polygon": "EUR-CHF",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "EURCHF",
                    "market_name": "forex",
                    "price_aud": "1.50",
                    "price_brl": "5.02",
                    "price_cad": "1.34",
                    "price_eur": "0.91",
                    "price_gbp": "0.79",
                    "price_usd": "0.9776",
                    "price_php": "56.30",
                    "price_zar": "18.74",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-eurchf.img.svg"
                },
                "EURJPY": {
                    "id": 9,
                    "market_id": 2,
                    "tradingview": "EURJPY",
                    "name": "EURJPY",
                    "polygon": "EUR-JPY",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "EURJPY",
                    "market_name": "forex",
                    "price_aud": "254.73",
                    "price_brl": "849.63",
                    "price_cad": "226.61",
                    "price_eur": "154.93",
                    "price_gbp": "133.14",
                    "price_usd": "165.6",
                    "price_php": "9537.24",
                    "price_zar": "3173.91",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-eurjpy.img.svg"
                },
                "EURNZD": {
                    "id": 8,
                    "market_id": 2,
                    "tradingview": "EURNZD",
                    "name": "EURNZD",
                    "polygon": "EUR-NZD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "EURNZD",
                    "market_name": "forex",
                    "price_aud": "2.77",
                    "price_brl": "9.25",
                    "price_cad": "2.47",
                    "price_eur": "1.69",
                    "price_gbp": "1.45",
                    "price_usd": "1.80199",
                    "price_php": "103.78",
                    "price_zar": "34.54",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-eurnzd.img.svg"
                },
                "EURUSD": {
                    "id": 10,
                    "market_id": 2,
                    "tradingview": "EURUSD",
                    "name": "EURUSD",
                    "polygon": "EUR-USD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "EURUSD",
                    "market_name": "forex",
                    "price_aud": "1.64",
                    "price_brl": "5.48",
                    "price_cad": "1.46",
                    "price_eur": "1.00",
                    "price_gbp": "0.86",
                    "price_usd": "1.06886",
                    "price_php": "61.56",
                    "price_zar": "20.49",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-eurusd.img.svg"
                },
                "GBPCAD": {
                    "id": 11,
                    "market_id": 2,
                    "tradingview": "GBPCAD",
                    "name": "GBPCAD",
                    "polygon": "GBP-CAD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "GBPCAD",
                    "market_name": "forex",
                    "price_aud": "2.62",
                    "price_brl": "8.73",
                    "price_cad": "2.33",
                    "price_eur": "1.59",
                    "price_gbp": "1.37",
                    "price_usd": "1.7025",
                    "price_php": "98.05",
                    "price_zar": "32.63",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-gbpcad.img.svg"
                },
                "GBPCHF": {
                    "id": 12,
                    "market_id": 2,
                    "tradingview": "GBPCHF",
                    "name": "GBPCHF",
                    "polygon": "GBP-CHF",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "GBPCHF",
                    "market_name": "forex",
                    "price_aud": "1.75",
                    "price_brl": "5.84",
                    "price_cad": "1.56",
                    "price_eur": "1.06",
                    "price_gbp": "0.91",
                    "price_usd": "1.1377",
                    "price_php": "65.52",
                    "price_zar": "21.81",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-gbpchf.img.svg"
                },
                "GBPJPY": {
                    "id": 14,
                    "market_id": 2,
                    "tradingview": "GBPJPY",
                    "name": "GBPJPY",
                    "polygon": "GBP-JPY",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "GBPJPY",
                    "market_name": "forex",
                    "price_aud": "296.43",
                    "price_brl": "988.70",
                    "price_cad": "263.70",
                    "price_eur": "180.29",
                    "price_gbp": "154.93",
                    "price_usd": "192.707",
                    "price_php": "11098.38",
                    "price_zar": "3693.44",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-gbpjpy.img.svg"
                },
                "GBPNZD": {
                    "id": 13,
                    "market_id": 2,
                    "tradingview": "GBPNZD",
                    "name": "GBPNZD",
                    "polygon": "GBP-NZD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "GBPNZD",
                    "market_name": "forex",
                    "price_aud": "3.23",
                    "price_brl": "10.76",
                    "price_cad": "2.87",
                    "price_eur": "1.96",
                    "price_gbp": "1.69",
                    "price_usd": "2.09677",
                    "price_php": "120.76",
                    "price_zar": "40.19",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-gbpnzd.img.svg"
                },
                "GBPUSD": {
                    "id": 15,
                    "market_id": 2,
                    "tradingview": "GBPUSD",
                    "name": "GBPUSD",
                    "polygon": "GBP-USD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "GBPUSD",
                    "market_name": "forex",
                    "price_aud": "1.91",
                    "price_brl": "6.38",
                    "price_cad": "1.70",
                    "price_eur": "1.16",
                    "price_gbp": "1.00",
                    "price_usd": "1.24383",
                    "price_php": "71.63",
                    "price_zar": "23.84",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-gbpusd.img.svg"
                },
                "NZDCAD": {
                    "id": 16,
                    "market_id": 2,
                    "tradingview": "NZDCAD",
                    "name": "NZDCAD",
                    "polygon": "NZD-CAD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "NZDCAD",
                    "market_name": "forex",
                    "price_aud": "1.25",
                    "price_brl": "4.17",
                    "price_cad": "1.11",
                    "price_eur": "0.76",
                    "price_gbp": "0.65",
                    "price_usd": "0.81195",
                    "price_php": "46.76",
                    "price_zar": "15.56",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-nzdcad.img.svg"
                },
                "NZDCHF": {
                    "id": 17,
                    "market_id": 2,
                    "tradingview": "NZDCHF",
                    "name": "NZDCHF",
                    "polygon": "NZD-CHF",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "NZDCHF",
                    "market_name": "forex",
                    "price_aud": "0.83",
                    "price_brl": "2.78",
                    "price_cad": "0.74",
                    "price_eur": "0.51",
                    "price_gbp": "0.44",
                    "price_usd": "0.54239",
                    "price_php": "31.24",
                    "price_zar": "10.40",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-nzdchf.img.svg"
                },
                "NZDJPY": {
                    "id": 18,
                    "market_id": 2,
                    "tradingview": "NZDJPY",
                    "name": "NZDJPY",
                    "polygon": "NZD-JPY",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "NZDJPY",
                    "market_name": "forex",
                    "price_aud": "141.36",
                    "price_brl": "471.50",
                    "price_cad": "125.76",
                    "price_eur": "85.98",
                    "price_gbp": "73.88",
                    "price_usd": "91.9",
                    "price_php": "5292.70",
                    "price_zar": "1761.36",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-nzdjpy.img.svg"
                },
                "NZDUSD": {
                    "id": 19,
                    "market_id": 2,
                    "tradingview": "NZDUSD",
                    "name": "NZDUSD",
                    "polygon": "NZD-USD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "NZDUSD",
                    "market_name": "forex",
                    "price_aud": "0.91",
                    "price_brl": "3.05",
                    "price_cad": "0.81",
                    "price_eur": "0.56",
                    "price_gbp": "0.48",
                    "price_usd": "0.5935",
                    "price_php": "34.18",
                    "price_zar": "11.38",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-nzdusd.img.svg"
                },
                "PHPUSD": {
                    "id": 29,
                    "market_id": 2,
                    "tradingview": "PHPUSD",
                    "name": "PHPUSD",
                    "polygon": "PHP-USD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "PHPUSD",
                    "market_name": "forex",
                    "price_aud": "0.03",
                    "price_brl": "0.09",
                    "price_cad": "0.02",
                    "price_eur": "0.02",
                    "price_gbp": "0.01",
                    "price_usd": "0.017363522711488",
                    "price_php": "1.00",
                    "price_zar": "0.33",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-phpusd.img.svg"
                },
                "USDCAD": {
                    "id": 20,
                    "market_id": 2,
                    "tradingview": "USDCAD",
                    "name": "USDCAD",
                    "polygon": "USD-CAD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "USDCAD",
                    "market_name": "forex",
                    "price_aud": "2.11",
                    "price_brl": "7.02",
                    "price_cad": "1.87",
                    "price_eur": "1.28",
                    "price_gbp": "1.10",
                    "price_usd": "1.36848",
                    "price_php": "78.81",
                    "price_zar": "26.23",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-usdcad.img.svg"
                },
                "USDCHF": {
                    "id": 21,
                    "market_id": 2,
                    "tradingview": "USDCHF",
                    "name": "USDCHF",
                    "polygon": "USD-CHF",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "USDCHF",
                    "market_name": "forex",
                    "price_aud": "1.41",
                    "price_brl": "4.69",
                    "price_cad": "1.25",
                    "price_eur": "0.86",
                    "price_gbp": "0.74",
                    "price_usd": "0.9148",
                    "price_php": "52.69",
                    "price_zar": "17.53",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-usdchf.img.svg"
                },
                "USDJPY": {
                    "id": 22,
                    "market_id": 2,
                    "tradingview": "USDJPY",
                    "name": "USDJPY",
                    "polygon": "USD-JPY",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "USDJPY",
                    "market_name": "forex",
                    "price_aud": "238.33",
                    "price_brl": "794.91",
                    "price_cad": "212.01",
                    "price_eur": "144.95",
                    "price_gbp": "124.56",
                    "price_usd": "154.936",
                    "price_php": "8923.07",
                    "price_zar": "2969.52",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-usdjpy.img.svg"
                },
                "ZARUSD": {
                    "id": 28,
                    "market_id": 2,
                    "tradingview": "ZARUSD",
                    "name": "ZARUSD",
                    "polygon": "ZAR-USD",
                    "listed": "yes",
                    "type": "forex",
                    "firebase": "ZARUSD",
                    "market_name": "forex",
                    "price_aud": "0.08",
                    "price_brl": "0.27",
                    "price_cad": "0.07",
                    "price_eur": "0.05",
                    "price_gbp": "0.04",
                    "price_usd": "0.052175455622166",
                    "price_php": "3.00",
                    "price_zar": "1.00",
                    "icon": "https:\/\/cfcdn-plus.olymptrade.com\/fe\/319_240222135719\/bundle\/images\/pair-icon-zarusd.img.svg"
                }
            }
        },
        "stock": {
            "current_page": 1,
            "data": [
                {
                    "id": 137,
                    "market_id": 3,
                    "tradingview": "AAPL",
                    "name": "Apple",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "AAPL",
                    "market_name": "stock",
                    "price_aud": "256.82",
                    "price_brl": "856.60",
                    "price_cad": "228.47",
                    "price_eur": "156.20",
                    "price_gbp": "134.23",
                    "price_usd": "166.96",
                    "price_php": "9615.56",
                    "price_zar": "3199.97",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/apple--big.svg"
                },
                {
                    "id": 160,
                    "market_id": 3,
                    "tradingview": "ABBV",
                    "name": "AbbVie",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ABBV",
                    "market_name": "stock",
                    "price_aud": "260.81",
                    "price_brl": "869.89",
                    "price_cad": "232.01",
                    "price_eur": "158.63",
                    "price_gbp": "136.31",
                    "price_usd": "169.55",
                    "price_php": "9764.72",
                    "price_zar": "3249.61",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/abbvie--big.svg"
                },
                {
                    "id": 230,
                    "market_id": 3,
                    "tradingview": "ABNB",
                    "name": "Airbnb",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ABNB",
                    "market_name": "stock",
                    "price_aud": "251.92",
                    "price_brl": "840.24",
                    "price_cad": "224.10",
                    "price_eur": "153.22",
                    "price_gbp": "131.67",
                    "price_usd": "163.77",
                    "price_php": "9431.84",
                    "price_zar": "3138.83",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/airbnb--big.svg"
                },
                {
                    "id": 170,
                    "market_id": 3,
                    "tradingview": "ABT",
                    "name": "Abbott Labs",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ABT",
                    "market_name": "stock",
                    "price_aud": "165.81",
                    "price_brl": "553.03",
                    "price_cad": "147.50",
                    "price_eur": "100.85",
                    "price_gbp": "86.66",
                    "price_usd": "107.79",
                    "price_php": "6207.84",
                    "price_zar": "2065.91",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/abbott--big.svg"
                },
                {
                    "id": 163,
                    "market_id": 3,
                    "tradingview": "ADBE",
                    "name": "Adobe",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ADBE",
                    "market_name": "stock",
                    "price_aud": "729.89",
                    "price_brl": "2434.47",
                    "price_cad": "649.31",
                    "price_eur": "443.93",
                    "price_gbp": "381.48",
                    "price_usd": "474.5",
                    "price_php": "27327.40",
                    "price_zar": "9094.31",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/adobe--big.svg"
                },
                {
                    "id": 217,
                    "market_id": 3,
                    "tradingview": "ADI",
                    "name": "Analog Devices",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ADI",
                    "market_name": "stock",
                    "price_aud": "301.63",
                    "price_brl": "1006.06",
                    "price_cad": "268.33",
                    "price_eur": "183.46",
                    "price_gbp": "157.65",
                    "price_usd": "196.09",
                    "price_php": "11293.22",
                    "price_zar": "3758.28",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/analog-devices--big.svg"
                },
                {
                    "id": 221,
                    "market_id": 3,
                    "tradingview": "ADP",
                    "name": "Automatic Data Processing",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ADP",
                    "market_name": "stock",
                    "price_aud": "379.70",
                    "price_brl": "1266.44",
                    "price_cad": "337.78",
                    "price_eur": "230.94",
                    "price_gbp": "198.45",
                    "price_usd": "246.84",
                    "price_php": "14216.01",
                    "price_zar": "4730.96",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/automatic-data-processing--big.svg"
                },
                {
                    "id": 200,
                    "market_id": 3,
                    "tradingview": "AMAT",
                    "name": "Applied Material",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "AMAT",
                    "market_name": "stock",
                    "price_aud": "301.34",
                    "price_brl": "1005.08",
                    "price_cad": "268.07",
                    "price_eur": "183.28",
                    "price_gbp": "157.50",
                    "price_usd": "195.9",
                    "price_php": "11282.27",
                    "price_zar": "3754.64",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/applied-materials--big.svg"
                },
                {
                    "id": 171,
                    "market_id": 3,
                    "tradingview": "AMD",
                    "name": "Advanced Mirco Devices",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "AMD",
                    "market_name": "stock",
                    "price_aud": "238.19",
                    "price_brl": "794.47",
                    "price_cad": "211.90",
                    "price_eur": "144.87",
                    "price_gbp": "124.49",
                    "price_usd": "154.85",
                    "price_php": "8918.12",
                    "price_zar": "2967.87",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/advanced-micro-devices--big.svg"
                },
                {
                    "id": 197,
                    "market_id": 3,
                    "tradingview": "AMGN",
                    "name": "Amgen",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "AMGN",
                    "market_name": "stock",
                    "price_aud": "420.77",
                    "price_brl": "1403.42",
                    "price_cad": "374.31",
                    "price_eur": "255.92",
                    "price_gbp": "219.92",
                    "price_usd": "273.54",
                    "price_php": "15753.72",
                    "price_zar": "5242.69",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/amgen--big.svg"
                },
                {
                    "id": 223,
                    "market_id": 3,
                    "tradingview": "AMT",
                    "name": "American Tower",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "AMT",
                    "market_name": "stock",
                    "price_aud": "269.19",
                    "price_brl": "897.86",
                    "price_cad": "239.47",
                    "price_eur": "163.73",
                    "price_gbp": "140.69",
                    "price_usd": "175",
                    "price_php": "10078.60",
                    "price_zar": "3354.07",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/american-tower--big.svg"
                },
                {
                    "id": 140,
                    "market_id": 3,
                    "tradingview": "AMZN",
                    "name": "Amazon",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "AMZN",
                    "market_name": "stock",
                    "price_aud": "277.54",
                    "price_brl": "925.71",
                    "price_cad": "246.90",
                    "price_eur": "168.81",
                    "price_gbp": "145.06",
                    "price_usd": "180.43",
                    "price_php": "10391.32",
                    "price_zar": "3458.14",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/amazon--big.svg"
                },
                {
                    "id": 250,
                    "market_id": 3,
                    "tradingview": "APD",
                    "name": "Air Products and Chemicals",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "APD",
                    "market_name": "stock",
                    "price_aud": "359.50",
                    "price_brl": "1199.07",
                    "price_cad": "319.81",
                    "price_eur": "218.65",
                    "price_gbp": "187.90",
                    "price_usd": "233.71",
                    "price_php": "13459.83",
                    "price_zar": "4479.31",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/air-products-and-chemicals--big.svg"
                },
                {
                    "id": 153,
                    "market_id": 3,
                    "tradingview": "AVGO",
                    "name": "Broadcom",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "AVGO",
                    "market_name": "stock",
                    "price_aud": "1935.30",
                    "price_brl": "6455.01",
                    "price_cad": "1721.64",
                    "price_eur": "1177.09",
                    "price_gbp": "1011.50",
                    "price_usd": "1258.14",
                    "price_php": "72458.80",
                    "price_zar": "24113.64",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/broadcom--big.svg"
                },
                {
                    "id": 191,
                    "market_id": 3,
                    "tradingview": "AXP",
                    "name": "American Express",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "AXP",
                    "market_name": "stock",
                    "price_aud": "367.01",
                    "price_brl": "1224.11",
                    "price_cad": "326.49",
                    "price_eur": "223.22",
                    "price_gbp": "191.82",
                    "price_usd": "238.59",
                    "price_php": "13740.88",
                    "price_zar": "4572.84",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/american-express--big.svg"
                },
                {
                    "id": 193,
                    "market_id": 3,
                    "tradingview": "BA",
                    "name": "Boeing",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "BA",
                    "market_name": "stock",
                    "price_aud": "263.81",
                    "price_brl": "879.90",
                    "price_cad": "234.68",
                    "price_eur": "160.45",
                    "price_gbp": "137.88",
                    "price_usd": "171.5",
                    "price_php": "9877.03",
                    "price_zar": "3286.99",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/boeing--big.svg"
                },
                {
                    "id": 162,
                    "market_id": 3,
                    "tradingview": "BAC",
                    "name": "Bank of America",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "BAC",
                    "market_name": "stock",
                    "price_aud": "58.91",
                    "price_brl": "196.50",
                    "price_cad": "52.41",
                    "price_eur": "35.83",
                    "price_gbp": "30.79",
                    "price_usd": "38.3",
                    "price_php": "2205.77",
                    "price_zar": "734.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/bank-of-america--big.svg"
                },
                {
                    "id": 239,
                    "market_id": 3,
                    "tradingview": "BDX",
                    "name": "Becton Dickinson",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "BDX",
                    "market_name": "stock",
                    "price_aud": "360.50",
                    "price_brl": "1202.41",
                    "price_cad": "320.70",
                    "price_eur": "219.26",
                    "price_gbp": "188.42",
                    "price_usd": "234.36",
                    "price_php": "13497.26",
                    "price_zar": "4491.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/becton-dickinson--big.svg"
                },
                {
                    "id": 213,
                    "market_id": 3,
                    "tradingview": "BKNG",
                    "name": "Booking Holdings",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "BKNG",
                    "market_name": "stock",
                    "price_aud": "5436.07",
                    "price_brl": "18131.49",
                    "price_cad": "4835.92",
                    "price_eur": "3306.32",
                    "price_gbp": "2841.22",
                    "price_usd": "3533.99",
                    "price_php": "203529.55",
                    "price_zar": "67732.81",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/booking--big.svg"
                },
                {
                    "id": 211,
                    "market_id": 3,
                    "tradingview": "BLK",
                    "name": "BlackRock",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "BLK",
                    "market_name": "stock",
                    "price_aud": "1179.93",
                    "price_brl": "3935.53",
                    "price_cad": "1049.66",
                    "price_eur": "717.65",
                    "price_gbp": "616.70",
                    "price_usd": "767.07",
                    "price_php": "44177.10",
                    "price_zar": "14701.74",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/blackrock--big.svg"
                },
                {
                    "id": 186,
                    "market_id": 3,
                    "tradingview": "BMY",
                    "name": "Bristol-Myers Squibb",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "BMY",
                    "market_name": "stock",
                    "price_aud": "75.67",
                    "price_brl": "252.37",
                    "price_cad": "67.31",
                    "price_eur": "46.02",
                    "price_gbp": "39.55",
                    "price_usd": "49.19",
                    "price_php": "2832.95",
                    "price_zar": "942.78",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/bristol-myers-squibb--big.svg"
                },
                {
                    "id": 234,
                    "market_id": 3,
                    "tradingview": "BSX",
                    "name": "Boston Scientific",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "BSX",
                    "market_name": "stock",
                    "price_aud": "110.94",
                    "price_brl": "370.02",
                    "price_cad": "98.69",
                    "price_eur": "67.47",
                    "price_gbp": "57.98",
                    "price_usd": "72.12",
                    "price_php": "4153.54",
                    "price_zar": "1382.26",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/boston-scientific--big.svg"
                },
                {
                    "id": 249,
                    "market_id": 3,
                    "tradingview": "BX",
                    "name": "Blackstone",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "BX",
                    "market_name": "stock",
                    "price_aud": "191.69",
                    "price_brl": "639.38",
                    "price_cad": "170.53",
                    "price_eur": "116.59",
                    "price_gbp": "100.19",
                    "price_usd": "124.62",
                    "price_php": "7177.12",
                    "price_zar": "2388.48",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/blackstone--big.svg"
                },
                {
                    "id": 219,
                    "market_id": 3,
                    "tradingview": "C",
                    "name": "Citigroup",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "C",
                    "market_name": "stock",
                    "price_aud": "96.52",
                    "price_brl": "321.95",
                    "price_cad": "85.87",
                    "price_eur": "58.71",
                    "price_gbp": "50.45",
                    "price_usd": "62.75",
                    "price_php": "3613.90",
                    "price_zar": "1202.67",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/citigroup--big.svg"
                },
                {
                    "id": 195,
                    "market_id": 3,
                    "tradingview": "CAT",
                    "name": "Caterpillar",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CAT",
                    "market_name": "stock",
                    "price_aud": "558.79",
                    "price_brl": "1863.79",
                    "price_cad": "497.10",
                    "price_eur": "339.87",
                    "price_gbp": "292.06",
                    "price_usd": "363.27",
                    "price_php": "20921.45",
                    "price_zar": "6962.47",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/caterpillar--big.svg"
                },
                {
                    "id": 276,
                    "market_id": 3,
                    "tradingview": "CHTR",
                    "name": "Charter Communications",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CHTR",
                    "market_name": "stock",
                    "price_aud": "408.11",
                    "price_brl": "1361.20",
                    "price_cad": "363.05",
                    "price_eur": "248.22",
                    "price_gbp": "213.30",
                    "price_usd": "265.31",
                    "price_php": "15279.73",
                    "price_zar": "5084.96",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/charter--big.svg"
                },
                {
                    "id": 228,
                    "market_id": 3,
                    "tradingview": "CI",
                    "name": "Cigna",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CI",
                    "market_name": "stock",
                    "price_aud": "542.46",
                    "price_brl": "1809.31",
                    "price_cad": "482.57",
                    "price_eur": "329.93",
                    "price_gbp": "283.52",
                    "price_usd": "352.65",
                    "price_php": "20309.82",
                    "price_zar": "6758.93",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/cigna--big.svg"
                },
                {
                    "id": 255,
                    "market_id": 3,
                    "tradingview": "CL",
                    "name": "Colgate-Palmolive",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CL",
                    "market_name": "stock",
                    "price_aud": "136.26",
                    "price_brl": "454.47",
                    "price_cad": "121.21",
                    "price_eur": "82.87",
                    "price_gbp": "71.22",
                    "price_usd": "88.58",
                    "price_php": "5101.50",
                    "price_zar": "1697.73",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/colgate-palmolive--big.svg"
                },
                {
                    "id": 174,
                    "market_id": 3,
                    "tradingview": "CMCSA",
                    "name": "Comcast",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CMCSA",
                    "market_name": "stock",
                    "price_aud": "61.98",
                    "price_brl": "206.71",
                    "price_cad": "55.13",
                    "price_eur": "37.69",
                    "price_gbp": "32.39",
                    "price_usd": "40.29",
                    "price_php": "2320.38",
                    "price_zar": "772.20",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/comcast--big.svg"
                },
                {
                    "id": 251,
                    "market_id": 3,
                    "tradingview": "CME",
                    "name": "CME Group",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CME",
                    "market_name": "stock",
                    "price_aud": "333.32",
                    "price_brl": "1111.75",
                    "price_cad": "296.52",
                    "price_eur": "202.73",
                    "price_gbp": "174.21",
                    "price_usd": "216.69",
                    "price_php": "12479.61",
                    "price_zar": "4153.10",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/cme--big.svg"
                },
                {
                    "id": 271,
                    "market_id": 3,
                    "tradingview": "CMG",
                    "name": "Chipotle Mexican Grill",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CMG",
                    "market_name": "stock",
                    "price_aud": "4518.54",
                    "price_brl": "15071.14",
                    "price_cad": "4019.68",
                    "price_eur": "2748.26",
                    "price_gbp": "2361.66",
                    "price_usd": "2937.5",
                    "price_php": "169176.50",
                    "price_zar": "56300.42",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/chipotle-mexican-grill--big.svg"
                },
                {
                    "id": 196,
                    "market_id": 3,
                    "tradingview": "COP",
                    "name": "ConocoPhillips",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "COP",
                    "market_name": "stock",
                    "price_aud": "199.72",
                    "price_brl": "666.16",
                    "price_cad": "177.67",
                    "price_eur": "121.48",
                    "price_gbp": "104.39",
                    "price_usd": "129.84",
                    "price_php": "7477.75",
                    "price_zar": "2488.53",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/conocophillips--big.svg"
                },
                {
                    "id": 161,
                    "market_id": 3,
                    "tradingview": "COST",
                    "name": "COSTCO",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "COST",
                    "market_name": "stock",
                    "price_aud": "1111.17",
                    "price_brl": "3706.19",
                    "price_cad": "988.49",
                    "price_eur": "675.83",
                    "price_gbp": "580.76",
                    "price_usd": "722.37",
                    "price_php": "41602.73",
                    "price_zar": "13845.02",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/costco-wholesale--big.svg"
                },
                {
                    "id": 167,
                    "market_id": 3,
                    "tradingview": "CRM",
                    "name": "Salesforce Inc",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CRM",
                    "market_name": "stock",
                    "price_aud": "426.80",
                    "price_brl": "1423.54",
                    "price_cad": "379.68",
                    "price_eur": "259.58",
                    "price_gbp": "223.07",
                    "price_usd": "277.46",
                    "price_php": "15979.48",
                    "price_zar": "5317.83",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/salesforce--big.svg"
                },
                {
                    "id": 166,
                    "market_id": 3,
                    "tradingview": "CSCO",
                    "name": "Cisco",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CSCO",
                    "market_name": "stock",
                    "price_aud": "74.10",
                    "price_brl": "247.14",
                    "price_cad": "65.92",
                    "price_eur": "45.07",
                    "price_gbp": "38.73",
                    "price_usd": "48.17",
                    "price_php": "2774.21",
                    "price_zar": "923.23",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/cisco--big.svg"
                },
                {
                    "id": 246,
                    "market_id": 3,
                    "tradingview": "CSX",
                    "name": "CSX Corporation",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CSX",
                    "market_name": "stock",
                    "price_aud": "53.38",
                    "price_brl": "178.03",
                    "price_cad": "47.48",
                    "price_eur": "32.46",
                    "price_gbp": "27.90",
                    "price_usd": "34.7",
                    "price_php": "1998.44",
                    "price_zar": "665.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/csx--big.svg"
                },
                {
                    "id": 224,
                    "market_id": 3,
                    "tradingview": "CVS",
                    "name": "CVS Health",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CVS",
                    "market_name": "stock",
                    "price_aud": "105.85",
                    "price_brl": "353.04",
                    "price_cad": "94.16",
                    "price_eur": "64.38",
                    "price_gbp": "55.32",
                    "price_usd": "68.81",
                    "price_php": "3962.91",
                    "price_zar": "1318.82",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/cvs-health--big.svg"
                },
                {
                    "id": 156,
                    "market_id": 3,
                    "tradingview": "CVX",
                    "name": "Chevron",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CVX",
                    "market_name": "stock",
                    "price_aud": "250.39",
                    "price_brl": "835.16",
                    "price_cad": "222.75",
                    "price_eur": "152.29",
                    "price_gbp": "130.87",
                    "price_usd": "162.78",
                    "price_php": "9374.83",
                    "price_zar": "3119.86",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/chevron--big.svg"
                },
                {
                    "id": 199,
                    "market_id": 3,
                    "tradingview": "DE",
                    "name": "Deere & Company",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "DE",
                    "market_name": "stock",
                    "price_aud": "611.00",
                    "price_brl": "2037.93",
                    "price_cad": "543.54",
                    "price_eur": "371.62",
                    "price_gbp": "319.34",
                    "price_usd": "397.21",
                    "price_php": "22876.12",
                    "price_zar": "7612.97",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/deere--big.svg"
                },
                {
                    "id": 173,
                    "market_id": 3,
                    "tradingview": "DHR",
                    "name": "Danaher",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "DHR",
                    "market_name": "stock",
                    "price_aud": "390.00",
                    "price_brl": "1300.81",
                    "price_cad": "346.94",
                    "price_eur": "237.21",
                    "price_gbp": "203.84",
                    "price_usd": "253.54",
                    "price_php": "14601.88",
                    "price_zar": "4859.37",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/danaher--big.svg"
                },
                {
                    "id": 176,
                    "market_id": 3,
                    "tradingview": "DIS",
                    "name": "Walt Disney",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "DIS",
                    "market_name": "stock",
                    "price_aud": "175.34",
                    "price_brl": "584.84",
                    "price_cad": "155.98",
                    "price_eur": "106.65",
                    "price_gbp": "91.64",
                    "price_usd": "113.99",
                    "price_php": "6564.91",
                    "price_zar": "2184.74",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/walt-disney--big.svg"
                },
                {
                    "id": 242,
                    "market_id": 3,
                    "tradingview": "DUK",
                    "name": "Duke Energy",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "DUK",
                    "market_name": "stock",
                    "price_aud": "151.05",
                    "price_brl": "503.82",
                    "price_cad": "134.38",
                    "price_eur": "91.87",
                    "price_gbp": "78.95",
                    "price_usd": "98.2",
                    "price_php": "5655.53",
                    "price_zar": "1882.11",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/duke-energy--big.svg"
                },
                {
                    "id": 243,
                    "market_id": 3,
                    "tradingview": "EL",
                    "name": "Estee Lauder",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "EL",
                    "market_name": "stock",
                    "price_aud": "230.73",
                    "price_brl": "769.59",
                    "price_cad": "205.26",
                    "price_eur": "140.34",
                    "price_gbp": "120.60",
                    "price_usd": "150",
                    "price_php": "8638.80",
                    "price_zar": "2874.92",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/estee-lauder--big.svg"
                },
                {
                    "id": 209,
                    "market_id": 3,
                    "tradingview": "ELV",
                    "name": "Elevance Health",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ELV",
                    "market_name": "stock",
                    "price_aud": "819.75",
                    "price_brl": "2734.20",
                    "price_cad": "729.25",
                    "price_eur": "498.59",
                    "price_gbp": "428.45",
                    "price_usd": "532.92",
                    "price_php": "30691.93",
                    "price_zar": "10214.00",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/anthem--big.svg"
                },
                {
                    "id": 252,
                    "market_id": 3,
                    "tradingview": "EOG",
                    "name": "EOG Resources",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "EOG",
                    "market_name": "stock",
                    "price_aud": "207.35",
                    "price_brl": "691.60",
                    "price_cad": "184.46",
                    "price_eur": "126.12",
                    "price_gbp": "108.37",
                    "price_usd": "134.8",
                    "price_php": "7763.40",
                    "price_zar": "2583.59",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/eog--big.svg"
                },
                {
                    "id": 270,
                    "market_id": 3,
                    "tradingview": "EPD",
                    "name": "Enterprise Products Partners",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "EPD",
                    "market_name": "stock",
                    "price_aud": "44.55",
                    "price_brl": "148.58",
                    "price_cad": "39.63",
                    "price_eur": "27.09",
                    "price_gbp": "23.28",
                    "price_usd": "28.96",
                    "price_php": "1667.86",
                    "price_zar": "555.05",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/enterprise-prods-partners--big.svg"
                },
                {
                    "id": 241,
                    "market_id": 3,
                    "tradingview": "EQIX",
                    "name": "Equinix",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "EQIX",
                    "market_name": "stock",
                    "price_aud": "1171.97",
                    "price_brl": "3909.00",
                    "price_cad": "1042.58",
                    "price_eur": "712.82",
                    "price_gbp": "612.54",
                    "price_usd": "761.9",
                    "price_php": "43879.34",
                    "price_zar": "14602.65",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/equinix--big.svg"
                },
                {
                    "id": 273,
                    "market_id": 3,
                    "tradingview": "EW",
                    "name": "Edwards Lifesciences",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "EW",
                    "market_name": "stock",
                    "price_aud": "134.98",
                    "price_brl": "450.21",
                    "price_cad": "120.08",
                    "price_eur": "82.10",
                    "price_gbp": "70.55",
                    "price_usd": "87.75",
                    "price_php": "5053.70",
                    "price_zar": "1681.83",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/edwards--big.svg"
                },
                {
                    "id": 266,
                    "market_id": 3,
                    "tradingview": "F",
                    "name": "Ford",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "F",
                    "market_name": "stock",
                    "price_aud": "20.00",
                    "price_brl": "66.70",
                    "price_cad": "17.79",
                    "price_eur": "12.16",
                    "price_gbp": "10.45",
                    "price_usd": "13",
                    "price_php": "748.70",
                    "price_zar": "249.16",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ford--big.svg"
                },
                {
                    "id": 265,
                    "market_id": 3,
                    "tradingview": "FCX",
                    "name": "Freeport-McmoRan",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "FCX",
                    "market_name": "stock",
                    "price_aud": "74.45",
                    "price_brl": "248.32",
                    "price_cad": "66.23",
                    "price_eur": "45.28",
                    "price_gbp": "38.91",
                    "price_usd": "48.4",
                    "price_php": "2787.45",
                    "price_zar": "927.64",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/freeport-mcmoran--big.svg"
                },
                {
                    "id": 259,
                    "market_id": 3,
                    "tradingview": "FDX",
                    "name": "Fedex",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "FDX",
                    "market_name": "stock",
                    "price_aud": "419.03",
                    "price_brl": "1397.63",
                    "price_cad": "372.77",
                    "price_eur": "254.86",
                    "price_gbp": "219.01",
                    "price_usd": "272.41",
                    "price_php": "15688.64",
                    "price_zar": "5221.04",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/fedex--big.svg"
                },
                {
                    "id": 236,
                    "market_id": 3,
                    "tradingview": "FI",
                    "name": "Fiserv",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "FI",
                    "market_name": "stock",
                    "price_aud": "239.02",
                    "price_brl": "797.24",
                    "price_cad": "212.64",
                    "price_eur": "145.38",
                    "price_gbp": "124.93",
                    "price_usd": "155.39",
                    "price_php": "8949.22",
                    "price_zar": "2978.22",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/fiserv--big.svg"
                },
                {
                    "id": 267,
                    "market_id": 3,
                    "tradingview": "FTNT",
                    "name": "Fortinet",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "FTNT",
                    "market_name": "stock",
                    "price_aud": "100.43",
                    "price_brl": "334.98",
                    "price_cad": "89.34",
                    "price_eur": "61.08",
                    "price_gbp": "52.49",
                    "price_usd": "65.29",
                    "price_php": "3760.18",
                    "price_zar": "1251.35",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/fortinet--big.svg"
                },
                {
                    "id": 263,
                    "market_id": 3,
                    "tradingview": "GD",
                    "name": "General Dynamics",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "GD",
                    "market_name": "stock",
                    "price_aud": "452.24",
                    "price_brl": "1508.40",
                    "price_cad": "402.31",
                    "price_eur": "275.06",
                    "price_gbp": "236.37",
                    "price_usd": "294",
                    "price_php": "16932.05",
                    "price_zar": "5634.83",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/general-dynamics--big.svg"
                },
                {
                    "id": 203,
                    "market_id": 3,
                    "tradingview": "GE",
                    "name": "General Electric",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "GE",
                    "market_name": "stock",
                    "price_aud": "250.30",
                    "price_brl": "834.85",
                    "price_cad": "222.67",
                    "price_eur": "152.24",
                    "price_gbp": "130.82",
                    "price_usd": "162.72",
                    "price_php": "9371.37",
                    "price_zar": "3118.71",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/general-electric--big.svg"
                },
                {
                    "id": 216,
                    "market_id": 3,
                    "tradingview": "GILD",
                    "name": "Gilead Sciences",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "GILD",
                    "market_name": "stock",
                    "price_aud": "103.20",
                    "price_brl": "344.21",
                    "price_cad": "91.81",
                    "price_eur": "62.77",
                    "price_gbp": "53.94",
                    "price_usd": "67.09",
                    "price_php": "3863.85",
                    "price_zar": "1285.85",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/gilead--big.svg"
                },
                {
                    "id": 139,
                    "market_id": 3,
                    "tradingview": "GOOG",
                    "name": "Alphabet",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "GOOG",
                    "market_name": "stock",
                    "price_aud": "245.44",
                    "price_brl": "818.64",
                    "price_cad": "218.34",
                    "price_eur": "149.28",
                    "price_gbp": "128.28",
                    "price_usd": "159.56",
                    "price_php": "9189.38",
                    "price_zar": "3058.14",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/alphabet--big.svg"
                },
                {
                    "id": 210,
                    "market_id": 3,
                    "tradingview": "GS",
                    "name": "Goldman Sachs",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "GS",
                    "market_name": "stock",
                    "price_aud": "653.73",
                    "price_brl": "2180.45",
                    "price_cad": "581.56",
                    "price_eur": "397.61",
                    "price_gbp": "341.68",
                    "price_usd": "424.99",
                    "price_php": "24476.02",
                    "price_zar": "8145.40",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/goldman-sachs--big.svg"
                },
                {
                    "id": 227,
                    "market_id": 3,
                    "tradingview": "HCA",
                    "name": "HCA Healthcare",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "HCA",
                    "market_name": "stock",
                    "price_aud": "491.36",
                    "price_brl": "1638.87",
                    "price_cad": "437.11",
                    "price_eur": "298.85",
                    "price_gbp": "256.81",
                    "price_usd": "319.43",
                    "price_php": "18396.61",
                    "price_zar": "6122.23",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/hca-healthcare--big.svg"
                },
                {
                    "id": 155,
                    "market_id": 3,
                    "tradingview": "HD",
                    "name": "Home Depot",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "HD",
                    "market_name": "stock",
                    "price_aud": "521.46",
                    "price_brl": "1739.27",
                    "price_cad": "463.89",
                    "price_eur": "317.16",
                    "price_gbp": "272.55",
                    "price_usd": "339",
                    "price_php": "19523.69",
                    "price_zar": "6497.31",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/home-depot--big.svg"
                },
                {
                    "id": 187,
                    "market_id": 3,
                    "tradingview": "HON",
                    "name": "Honeywell",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "HON",
                    "market_name": "stock",
                    "price_aud": "301.49",
                    "price_brl": "1005.60",
                    "price_cad": "268.21",
                    "price_eur": "183.37",
                    "price_gbp": "157.58",
                    "price_usd": "196",
                    "price_php": "11288.03",
                    "price_zar": "3756.56",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/honeywell--big.svg"
                },
                {
                    "id": 274,
                    "market_id": 3,
                    "tradingview": "HUM",
                    "name": "Humana",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "HUM",
                    "market_name": "stock",
                    "price_aud": "523.00",
                    "price_brl": "1744.40",
                    "price_cad": "465.26",
                    "price_eur": "318.10",
                    "price_gbp": "273.35",
                    "price_usd": "340",
                    "price_php": "19581.28",
                    "price_zar": "6516.47",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/humana--big.svg"
                },
                {
                    "id": 198,
                    "market_id": 3,
                    "tradingview": "IBM",
                    "name": "International Business Machines",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "IBM",
                    "market_name": "stock",
                    "price_aud": "282.65",
                    "price_brl": "942.75",
                    "price_cad": "251.44",
                    "price_eur": "171.91",
                    "price_gbp": "147.73",
                    "price_usd": "183.75",
                    "price_php": "10582.53",
                    "price_zar": "3521.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/international-bus-mach--big.svg"
                },
                {
                    "id": 258,
                    "market_id": 3,
                    "tradingview": "ICE",
                    "name": "Intercontinental Exchange",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ICE",
                    "market_name": "stock",
                    "price_aud": "203.55",
                    "price_brl": "678.93",
                    "price_cad": "181.08",
                    "price_eur": "123.80",
                    "price_gbp": "106.39",
                    "price_usd": "132.33",
                    "price_php": "7621.15",
                    "price_zar": "2536.25",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/intercontinental-exchange--big.svg"
                },
                {
                    "id": 185,
                    "market_id": 3,
                    "tradingview": "INTC",
                    "name": "Intel",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "INTC",
                    "market_name": "stock",
                    "price_aud": "53.16",
                    "price_brl": "177.31",
                    "price_cad": "47.29",
                    "price_eur": "32.33",
                    "price_gbp": "27.79",
                    "price_usd": "34.56",
                    "price_php": "1990.38",
                    "price_zar": "662.38",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/intel--big.svg"
                },
                {
                    "id": 190,
                    "market_id": 3,
                    "tradingview": "INTU",
                    "name": "Intuit",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "INTU",
                    "market_name": "stock",
                    "price_aud": "970.44",
                    "price_brl": "3236.79",
                    "price_cad": "863.30",
                    "price_eur": "590.24",
                    "price_gbp": "507.21",
                    "price_usd": "630.88",
                    "price_php": "36333.64",
                    "price_zar": "12091.51",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/intuit--big.svg"
                },
                {
                    "id": 202,
                    "market_id": 3,
                    "tradingview": "ISRG",
                    "name": "Intuitive Surgical",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ISRG",
                    "market_name": "stock",
                    "price_aud": "580.03",
                    "price_brl": "1934.65",
                    "price_cad": "516.00",
                    "price_eur": "352.79",
                    "price_gbp": "303.16",
                    "price_usd": "377.08",
                    "price_php": "21716.79",
                    "price_zar": "7227.15",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/intuitive-surgical--big.svg"
                },
                {
                    "id": 237,
                    "market_id": 3,
                    "tradingview": "ITW",
                    "name": "Illinois Tool Works",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ITW",
                    "market_name": "stock",
                    "price_aud": "385.54",
                    "price_brl": "1285.93",
                    "price_cad": "342.98",
                    "price_eur": "234.49",
                    "price_gbp": "201.51",
                    "price_usd": "250.64",
                    "price_php": "14434.86",
                    "price_zar": "4803.79",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/illinois-tool-works--big.svg"
                },
                {
                    "id": 147,
                    "market_id": 3,
                    "tradingview": "JNJ",
                    "name": "Johnson & Johnson",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "JNJ",
                    "market_name": "stock",
                    "price_aud": "230.10",
                    "price_brl": "767.49",
                    "price_cad": "204.70",
                    "price_eur": "139.95",
                    "price_gbp": "120.27",
                    "price_usd": "149.59",
                    "price_php": "8615.19",
                    "price_zar": "2867.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/johnson-and-johnson--big.svg"
                },
                {
                    "id": 150,
                    "market_id": 3,
                    "tradingview": "JPM",
                    "name": "JP Morgan",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "JPM",
                    "market_name": "stock",
                    "price_aud": "296.15",
                    "price_brl": "987.79",
                    "price_cad": "263.46",
                    "price_eur": "180.13",
                    "price_gbp": "154.79",
                    "price_usd": "192.53",
                    "price_php": "11088.19",
                    "price_zar": "3690.05",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/jpmorgan-chase--big.svg"
                },
                {
                    "id": 260,
                    "market_id": 3,
                    "tradingview": "KKR",
                    "name": "KKR & Co.",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "KKR",
                    "market_name": "stock",
                    "price_aud": "151.52",
                    "price_brl": "505.36",
                    "price_cad": "134.79",
                    "price_eur": "92.15",
                    "price_gbp": "79.19",
                    "price_usd": "98.5",
                    "price_php": "5672.81",
                    "price_zar": "1887.86",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/kkr--big.svg"
                },
                {
                    "id": 253,
                    "market_id": 3,
                    "tradingview": "KLAC",
                    "name": "KLA Corporation",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "KLAC",
                    "market_name": "stock",
                    "price_aud": "1004.49",
                    "price_brl": "3350.38",
                    "price_cad": "893.59",
                    "price_eur": "610.95",
                    "price_gbp": "525.01",
                    "price_usd": "653.02",
                    "price_php": "37608.73",
                    "price_zar": "12515.85",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/kla-tencor--big.svg"
                },
                {
                    "id": 158,
                    "market_id": 3,
                    "tradingview": "KO",
                    "name": "Coca Cola",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "KO",
                    "market_name": "stock",
                    "price_aud": "93.15",
                    "price_brl": "310.71",
                    "price_cad": "82.87",
                    "price_eur": "56.66",
                    "price_gbp": "48.69",
                    "price_usd": "60.56",
                    "price_php": "3487.77",
                    "price_zar": "1160.70",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/coca-cola--big.svg"
                },
                {
                    "id": 146,
                    "market_id": 3,
                    "tradingview": "LLY",
                    "name": "Eli Lilly",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "LLY",
                    "market_name": "stock",
                    "price_aud": "1149.04",
                    "price_brl": "3832.51",
                    "price_cad": "1022.18",
                    "price_eur": "698.87",
                    "price_gbp": "600.56",
                    "price_usd": "746.99",
                    "price_php": "43020.65",
                    "price_zar": "14316.89",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/eli-lilly--big.svg"
                },
                {
                    "id": 201,
                    "market_id": 3,
                    "tradingview": "LMT",
                    "name": "Lockheed Martin",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "LMT",
                    "market_name": "stock",
                    "price_aud": "707.72",
                    "price_brl": "2360.54",
                    "price_cad": "629.59",
                    "price_eur": "430.45",
                    "price_gbp": "369.90",
                    "price_usd": "460.09",
                    "price_php": "26497.50",
                    "price_zar": "8818.13",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/lockheed-martin--big.svg"
                },
                {
                    "id": 189,
                    "market_id": 3,
                    "tradingview": "LOW",
                    "name": "Lowe's Companies",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "LOW",
                    "market_name": "stock",
                    "price_aud": "359.93",
                    "price_brl": "1200.51",
                    "price_cad": "320.19",
                    "price_eur": "218.92",
                    "price_gbp": "188.12",
                    "price_usd": "233.99",
                    "price_php": "13475.95",
                    "price_zar": "4484.68",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/lowe-s--big.svg"
                },
                {
                    "id": 226,
                    "market_id": 3,
                    "tradingview": "LRCX",
                    "name": "Lam Research",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "LRCX",
                    "market_name": "stock",
                    "price_aud": "1373.63",
                    "price_brl": "4581.63",
                    "price_cad": "1221.98",
                    "price_eur": "835.47",
                    "price_gbp": "717.94",
                    "price_usd": "893",
                    "price_php": "51429.66",
                    "price_zar": "17115.33",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/lam-research--big.svg"
                },
                {
                    "id": 151,
                    "market_id": 3,
                    "tradingview": "MA",
                    "name": "Mastercard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MA",
                    "market_name": "stock",
                    "price_aud": "716.84",
                    "price_brl": "2390.96",
                    "price_cad": "637.70",
                    "price_eur": "436.00",
                    "price_gbp": "374.67",
                    "price_usd": "466.02",
                    "price_php": "26839.02",
                    "price_zar": "8931.79",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/mastercard--big.svg"
                },
                {
                    "id": 275,
                    "market_id": 3,
                    "tradingview": "MAR",
                    "name": "Marriott International",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MAR",
                    "market_name": "stock",
                    "price_aud": "370.51",
                    "price_brl": "1235.81",
                    "price_cad": "329.61",
                    "price_eur": "225.35",
                    "price_gbp": "193.65",
                    "price_usd": "240.87",
                    "price_php": "13872.19",
                    "price_zar": "4616.54",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/marriott--big.svg"
                },
                {
                    "id": 165,
                    "market_id": 3,
                    "tradingview": "MCD",
                    "name": "McDonald's",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MCD",
                    "market_name": "stock",
                    "price_aud": "425.90",
                    "price_brl": "1420.56",
                    "price_cad": "378.88",
                    "price_eur": "259.04",
                    "price_gbp": "222.60",
                    "price_usd": "276.88",
                    "price_php": "15946.07",
                    "price_zar": "5306.71",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/mcdonalds--big.svg"
                },
                {
                    "id": 272,
                    "market_id": 3,
                    "tradingview": "MCK",
                    "name": "Mckesson",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MCK",
                    "market_name": "stock",
                    "price_aud": "818.34",
                    "price_brl": "2729.48",
                    "price_cad": "727.99",
                    "price_eur": "497.73",
                    "price_gbp": "427.71",
                    "price_usd": "532",
                    "price_php": "30638.94",
                    "price_zar": "10196.37",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/mckesson--big.svg"
                },
                {
                    "id": 257,
                    "market_id": 3,
                    "tradingview": "MCO",
                    "name": "Moody's",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MCO",
                    "market_name": "stock",
                    "price_aud": "587.78",
                    "price_brl": "1960.48",
                    "price_cad": "522.89",
                    "price_eur": "357.50",
                    "price_gbp": "307.21",
                    "price_usd": "382.115",
                    "price_php": "22006.77",
                    "price_zar": "7323.65",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/moodys--big.svg"
                },
                {
                    "id": 212,
                    "market_id": 3,
                    "tradingview": "MDLZ",
                    "name": "Mondelez",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MDLZ",
                    "market_name": "stock",
                    "price_aud": "107.88",
                    "price_brl": "359.81",
                    "price_cad": "95.97",
                    "price_eur": "65.61",
                    "price_gbp": "56.38",
                    "price_usd": "70.13",
                    "price_php": "4038.93",
                    "price_zar": "1344.12",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/mondelez--big.svg"
                },
                {
                    "id": 143,
                    "market_id": 3,
                    "tradingview": "META",
                    "name": "Meta Platforms",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "META",
                    "market_name": "stock",
                    "price_aud": "780.59",
                    "price_brl": "2603.57",
                    "price_cad": "694.41",
                    "price_eur": "474.77",
                    "price_gbp": "407.98",
                    "price_usd": "507.46",
                    "price_php": "29225.64",
                    "price_zar": "9726.03",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/meta-platforms--big.svg"
                },
                {
                    "id": 218,
                    "market_id": 3,
                    "tradingview": "MMC",
                    "name": "Marsh & McLennan Companies",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MMC",
                    "market_name": "stock",
                    "price_aud": "310.28",
                    "price_brl": "1034.89",
                    "price_cad": "276.02",
                    "price_eur": "188.72",
                    "price_gbp": "162.17",
                    "price_usd": "201.71",
                    "price_php": "11616.88",
                    "price_zar": "3865.99",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/marsh-and-mclennan--big.svg"
                },
                {
                    "id": 277,
                    "market_id": 3,
                    "tradingview": "MMM",
                    "name": "3m",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MMM",
                    "market_name": "stock",
                    "price_aud": "143.33",
                    "price_brl": "478.07",
                    "price_cad": "127.51",
                    "price_eur": "87.18",
                    "price_gbp": "74.91",
                    "price_usd": "93.18",
                    "price_php": "5366.42",
                    "price_zar": "1785.90",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/3m--big.svg"
                },
                {
                    "id": 261,
                    "market_id": 3,
                    "tradingview": "MNST",
                    "name": "Monster Beverage",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MNST",
                    "market_name": "stock",
                    "price_aud": "82.42",
                    "price_brl": "274.90",
                    "price_cad": "73.32",
                    "price_eur": "50.13",
                    "price_gbp": "43.08",
                    "price_usd": "53.58",
                    "price_php": "3085.78",
                    "price_zar": "1026.92",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/monster-beverage--big.svg"
                },
                {
                    "id": 229,
                    "market_id": 3,
                    "tradingview": "MO",
                    "name": "Altria",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MO",
                    "market_name": "stock",
                    "price_aud": "66.01",
                    "price_brl": "220.15",
                    "price_cad": "58.72",
                    "price_eur": "40.15",
                    "price_gbp": "34.50",
                    "price_usd": "42.91",
                    "price_php": "2471.27",
                    "price_zar": "822.42",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/altria--big.svg"
                },
                {
                    "id": 157,
                    "market_id": 3,
                    "tradingview": "MRK",
                    "name": "Merck",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MRK",
                    "market_name": "stock",
                    "price_aud": "195.43",
                    "price_brl": "651.84",
                    "price_cad": "173.86",
                    "price_eur": "118.86",
                    "price_gbp": "102.14",
                    "price_usd": "127.05",
                    "price_php": "7317.06",
                    "price_zar": "2435.05",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/merck--big.svg"
                },
                {
                    "id": 184,
                    "market_id": 3,
                    "tradingview": "MS",
                    "name": "Morgan Stanley",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MS",
                    "market_name": "stock",
                    "price_aud": "143.92",
                    "price_brl": "480.02",
                    "price_cad": "128.03",
                    "price_eur": "87.53",
                    "price_gbp": "75.22",
                    "price_usd": "93.56",
                    "price_php": "5388.31",
                    "price_zar": "1793.18",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/morgan-stanley--big.svg"
                },
                {
                    "id": 138,
                    "market_id": 3,
                    "tradingview": "MSFT",
                    "name": "Microsoft",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MSFT",
                    "market_name": "stock",
                    "price_aud": "630.27",
                    "price_brl": "2102.21",
                    "price_cad": "560.69",
                    "price_eur": "383.34",
                    "price_gbp": "329.42",
                    "price_usd": "409.74",
                    "price_php": "23597.75",
                    "price_zar": "7853.12",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/microsoft--big.svg"
                },
                {
                    "id": 240,
                    "market_id": 3,
                    "tradingview": "MU",
                    "name": "Micron Technology",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MU",
                    "market_name": "stock",
                    "price_aud": "176.28",
                    "price_brl": "587.97",
                    "price_cad": "156.82",
                    "price_eur": "107.22",
                    "price_gbp": "92.13",
                    "price_usd": "114.6",
                    "price_php": "6600.04",
                    "price_zar": "2196.44",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/micron-technology--big.svg"
                },
                {
                    "id": 279,
                    "market_id": 3,
                    "tradingview": "MULN",
                    "name": "Mullen Automotive",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MULN",
                    "market_name": "stock",
                    "price_aud": "4.12",
                    "price_brl": "13.75",
                    "price_cad": "3.67",
                    "price_eur": "2.51",
                    "price_gbp": "2.15",
                    "price_usd": "2.68",
                    "price_php": "154.35",
                    "price_zar": "51.37",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/mullen-automotive--big.svg"
                },
                {
                    "id": 180,
                    "market_id": 3,
                    "tradingview": "NEE",
                    "name": "Nextera Energy",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "NEE",
                    "market_name": "stock",
                    "price_aud": "102.29",
                    "price_brl": "341.18",
                    "price_cad": "91.00",
                    "price_eur": "62.22",
                    "price_gbp": "53.46",
                    "price_usd": "66.5",
                    "price_php": "3829.87",
                    "price_zar": "1274.55",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/nextera-energy--big.svg"
                },
                {
                    "id": 169,
                    "market_id": 3,
                    "tradingview": "NFLX",
                    "name": "Netflix",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "NFLX",
                    "market_name": "stock",
                    "price_aud": "885.28",
                    "price_brl": "2952.76",
                    "price_cad": "787.54",
                    "price_eur": "538.44",
                    "price_gbp": "462.70",
                    "price_usd": "575.52",
                    "price_php": "33145.35",
                    "price_zar": "11030.47",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/netflix--big.svg"
                },
                {
                    "id": 172,
                    "market_id": 3,
                    "tradingview": "NKE",
                    "name": "Nike",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "NKE",
                    "market_name": "stock",
                    "price_aud": "145.04",
                    "price_brl": "483.76",
                    "price_cad": "129.03",
                    "price_eur": "88.22",
                    "price_gbp": "75.81",
                    "price_usd": "94.29",
                    "price_php": "5430.35",
                    "price_zar": "1807.17",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/nike--big.svg"
                },
                {
                    "id": 278,
                    "market_id": 3,
                    "tradingview": "NLST",
                    "name": "Netlist Inc",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "NLST",
                    "market_name": "stock",
                    "price_aud": "2.18",
                    "price_brl": "7.29",
                    "price_cad": "1.94",
                    "price_eur": "1.33",
                    "price_gbp": "1.14",
                    "price_usd": "1.42",
                    "price_php": "81.78",
                    "price_zar": "27.22",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/netlist--big.svg"
                },
                {
                    "id": 247,
                    "market_id": 3,
                    "tradingview": "NOC",
                    "name": "Northrop Grumman",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "NOC",
                    "market_name": "stock",
                    "price_aud": "730.16",
                    "price_brl": "2435.39",
                    "price_cad": "649.55",
                    "price_eur": "444.10",
                    "price_gbp": "381.63",
                    "price_usd": "474.68",
                    "price_php": "27337.77",
                    "price_zar": "9097.76",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/northrop-grumman--big.svg"
                },
                {
                    "id": 207,
                    "market_id": 3,
                    "tradingview": "NOW",
                    "name": "ServiceNow",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "NOW",
                    "market_name": "stock",
                    "price_aud": "1146.29",
                    "price_brl": "3823.32",
                    "price_cad": "1019.73",
                    "price_eur": "697.19",
                    "price_gbp": "599.12",
                    "price_usd": "745.2",
                    "price_php": "42917.56",
                    "price_zar": "14282.58",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/servicenow--big.svg"
                },
                {
                    "id": 280,
                    "market_id": 3,
                    "tradingview": "NVAX",
                    "name": "Novavax, Inc.",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "NVAX",
                    "market_name": "stock",
                    "price_aud": "6.45",
                    "price_brl": "21.50",
                    "price_cad": "5.73",
                    "price_eur": "3.92",
                    "price_gbp": "3.37",
                    "price_usd": "4.19",
                    "price_php": "241.31",
                    "price_zar": "80.31",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/novavax--big.svg"
                },
                {
                    "id": 141,
                    "market_id": 3,
                    "tradingview": "NVDA",
                    "name": "Nvidia",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "NVDA",
                    "market_name": "stock",
                    "price_aud": "1294.42",
                    "price_brl": "4317.40",
                    "price_cad": "1151.51",
                    "price_eur": "787.29",
                    "price_gbp": "676.54",
                    "price_usd": "841.5",
                    "price_php": "48463.67",
                    "price_zar": "16128.27",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/nvidia--big.svg"
                },
                {
                    "id": 154,
                    "market_id": 3,
                    "tradingview": "ORCL",
                    "name": "Oracle",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ORCL",
                    "market_name": "stock",
                    "price_aud": "177.51",
                    "price_brl": "592.07",
                    "price_cad": "157.91",
                    "price_eur": "107.97",
                    "price_gbp": "92.78",
                    "price_usd": "115.4",
                    "price_php": "6646.12",
                    "price_zar": "2211.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/oracle--big.svg"
                },
                {
                    "id": 268,
                    "market_id": 3,
                    "tradingview": "ORLY",
                    "name": "O'Reilly Automotive",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ORLY",
                    "market_name": "stock",
                    "price_aud": "1680.28",
                    "price_brl": "5604.41",
                    "price_cad": "1494.77",
                    "price_eur": "1021.98",
                    "price_gbp": "878.21",
                    "price_usd": "1092.35",
                    "price_php": "62910.62",
                    "price_zar": "20936.09",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/o-reilly-auto--big.svg"
                },
                {
                    "id": 235,
                    "market_id": 3,
                    "tradingview": "PANW",
                    "name": "Palo Alto Networks",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "PANW",
                    "market_name": "stock",
                    "price_aud": "452.62",
                    "price_brl": "1509.68",
                    "price_cad": "402.65",
                    "price_eur": "275.29",
                    "price_gbp": "236.57",
                    "price_usd": "294.25",
                    "price_php": "16946.45",
                    "price_zar": "5639.62",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/palo-alto-networks--big.svg"
                },
                {
                    "id": 159,
                    "market_id": 3,
                    "tradingview": "PEP",
                    "name": "Pepsico",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "PEP",
                    "market_name": "stock",
                    "price_aud": "262.42",
                    "price_brl": "875.28",
                    "price_cad": "233.45",
                    "price_eur": "159.61",
                    "price_gbp": "137.16",
                    "price_usd": "170.6",
                    "price_php": "9825.20",
                    "price_zar": "3269.74",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/pepsico--big.svg"
                },
                {
                    "id": 164,
                    "market_id": 3,
                    "tradingview": "PFE",
                    "name": "Pfizer",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "PFE",
                    "market_name": "stock",
                    "price_aud": "40.55",
                    "price_brl": "135.24",
                    "price_cad": "36.07",
                    "price_eur": "24.66",
                    "price_gbp": "21.19",
                    "price_usd": "26.36",
                    "price_php": "1518.13",
                    "price_zar": "505.22",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/pfizer--big.svg"
                },
                {
                    "id": 152,
                    "market_id": 3,
                    "tradingview": "PG",
                    "name": "Procter and Gamble",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "PG",
                    "market_name": "stock",
                    "price_aud": "248.41",
                    "price_brl": "828.54",
                    "price_cad": "220.98",
                    "price_eur": "151.09",
                    "price_gbp": "129.83",
                    "price_usd": "161.49",
                    "price_php": "9300.53",
                    "price_zar": "3095.13",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/procter-and-gamble--big.svg"
                },
                {
                    "id": 232,
                    "market_id": 3,
                    "tradingview": "PGR",
                    "name": "Progressive",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "PGR",
                    "market_name": "stock",
                    "price_aud": "329.21",
                    "price_brl": "1098.05",
                    "price_cad": "292.87",
                    "price_eur": "200.23",
                    "price_gbp": "172.07",
                    "price_usd": "214.02",
                    "price_php": "12325.84",
                    "price_zar": "4101.93",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/progressive-ohio--big.svg"
                },
                {
                    "id": 208,
                    "market_id": 3,
                    "tradingview": "PLD",
                    "name": "Prologis",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "PLD",
                    "market_name": "stock",
                    "price_aud": "161.51",
                    "price_brl": "538.71",
                    "price_cad": "143.68",
                    "price_eur": "98.24",
                    "price_gbp": "84.42",
                    "price_usd": "105",
                    "price_php": "6047.16",
                    "price_zar": "2012.44",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/prologis--big.svg"
                },
                {
                    "id": 181,
                    "market_id": 3,
                    "tradingview": "PM",
                    "name": "Philip Morris",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "PM",
                    "market_name": "stock",
                    "price_aud": "150.21",
                    "price_brl": "501.00",
                    "price_cad": "133.62",
                    "price_eur": "91.36",
                    "price_gbp": "78.51",
                    "price_usd": "97.65",
                    "price_php": "5623.86",
                    "price_zar": "1871.57",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/philip-morris--big.svg"
                },
                {
                    "id": 238,
                    "market_id": 3,
                    "tradingview": "PYPL",
                    "name": "PayPal",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "PYPL",
                    "market_name": "stock",
                    "price_aud": "99.22",
                    "price_brl": "330.92",
                    "price_cad": "88.26",
                    "price_eur": "60.34",
                    "price_gbp": "51.86",
                    "price_usd": "64.5",
                    "price_php": "3714.68",
                    "price_zar": "1236.21",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/paypal--big.svg"
                },
                {
                    "id": 188,
                    "market_id": 3,
                    "tradingview": "QCOM",
                    "name": "Qualcomm",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "QCOM",
                    "market_name": "stock",
                    "price_aud": "250.32",
                    "price_brl": "834.90",
                    "price_cad": "222.68",
                    "price_eur": "152.25",
                    "price_gbp": "130.83",
                    "price_usd": "162.73",
                    "price_php": "9371.95",
                    "price_zar": "3118.90",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/qualcomm--big.svg"
                },
                {
                    "id": 281,
                    "market_id": 3,
                    "tradingview": "RBLX",
                    "name": "Robolox Corporation",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "RBLX",
                    "market_name": "stock",
                    "price_aud": "56.16",
                    "price_brl": "187.32",
                    "price_cad": "49.96",
                    "price_eur": "34.16",
                    "price_gbp": "29.35",
                    "price_usd": "36.51",
                    "price_php": "2102.68",
                    "price_zar": "699.75",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/roblox--big.svg"
                },
                {
                    "id": 225,
                    "market_id": 3,
                    "tradingview": "REGN",
                    "name": "Regeneron Pharmaceuticals",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "REGN",
                    "market_name": "stock",
                    "price_aud": "1395.66",
                    "price_brl": "4655.10",
                    "price_cad": "1241.58",
                    "price_eur": "848.87",
                    "price_gbp": "729.46",
                    "price_usd": "907.32",
                    "price_php": "52254.37",
                    "price_zar": "17389.79",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/regeneron-pharmaceuticals--big.svg"
                },
                {
                    "id": 183,
                    "market_id": 3,
                    "tradingview": "RTX",
                    "name": "Raytheon",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "RTX",
                    "market_name": "stock",
                    "price_aud": "157.36",
                    "price_brl": "524.86",
                    "price_cad": "139.99",
                    "price_eur": "95.71",
                    "price_gbp": "82.25",
                    "price_usd": "102.3",
                    "price_php": "5891.66",
                    "price_zar": "1960.69",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/raytheon--big.svg"
                },
                {
                    "id": 204,
                    "market_id": 3,
                    "tradingview": "SBUX",
                    "name": "Starbucks",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SBUX",
                    "market_name": "stock",
                    "price_aud": "135.35",
                    "price_brl": "451.44",
                    "price_cad": "120.41",
                    "price_eur": "82.32",
                    "price_gbp": "70.74",
                    "price_usd": "87.99",
                    "price_php": "5067.52",
                    "price_zar": "1686.43",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/starbucks--big.svg"
                },
                {
                    "id": 269,
                    "market_id": 3,
                    "tradingview": "SCCO",
                    "name": "Southern Copper",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SCCO",
                    "market_name": "stock",
                    "price_aud": "168.45",
                    "price_brl": "561.85",
                    "price_cad": "149.85",
                    "price_eur": "102.45",
                    "price_gbp": "88.04",
                    "price_usd": "109.51",
                    "price_php": "6306.90",
                    "price_zar": "2098.88",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/southern-copper--big.svg"
                },
                {
                    "id": 214,
                    "market_id": 3,
                    "tradingview": "SCHW",
                    "name": "Charles Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SCHW",
                    "market_name": "stock",
                    "price_aud": "115.71",
                    "price_brl": "385.92",
                    "price_cad": "102.93",
                    "price_eur": "70.37",
                    "price_gbp": "60.47",
                    "price_usd": "75.22",
                    "price_php": "4332.07",
                    "price_zar": "1441.67",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                {
                    "id": 248,
                    "market_id": 3,
                    "tradingview": "SHW",
                    "name": "Sherwin Williams",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SHW",
                    "market_name": "stock",
                    "price_aud": "465.10",
                    "price_brl": "1551.29",
                    "price_cad": "413.75",
                    "price_eur": "282.88",
                    "price_gbp": "243.09",
                    "price_usd": "302.36",
                    "price_php": "17413.52",
                    "price_zar": "5795.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/sherwin-williams--big.svg"
                },
                {
                    "id": 244,
                    "market_id": 3,
                    "tradingview": "SLB",
                    "name": "Schlumberger",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SLB",
                    "market_name": "stock",
                    "price_aud": "75.99",
                    "price_brl": "253.45",
                    "price_cad": "67.60",
                    "price_eur": "46.22",
                    "price_gbp": "39.72",
                    "price_usd": "49.4",
                    "price_php": "2845.04",
                    "price_zar": "946.81",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schlumberger--big.svg"
                },
                {
                    "id": 262,
                    "market_id": 3,
                    "tradingview": "SNOW",
                    "name": "Snowflake",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SNOW",
                    "market_name": "stock",
                    "price_aud": "233.98",
                    "price_brl": "780.42",
                    "price_cad": "208.15",
                    "price_eur": "142.31",
                    "price_gbp": "122.29",
                    "price_usd": "152.11",
                    "price_php": "8760.32",
                    "price_zar": "2915.36",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/snowflake--big.svg"
                },
                {
                    "id": 254,
                    "market_id": 3,
                    "tradingview": "SNPS",
                    "name": "Synopsys",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SNPS",
                    "market_name": "stock",
                    "price_aud": "811.46",
                    "price_brl": "2706.55",
                    "price_cad": "721.87",
                    "price_eur": "493.54",
                    "price_gbp": "424.12",
                    "price_usd": "527.53",
                    "price_php": "30381.51",
                    "price_zar": "10110.69",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/synopsys--big.svg"
                },
                {
                    "id": 231,
                    "market_id": 3,
                    "tradingview": "SO",
                    "name": "Southern Company",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SO",
                    "market_name": "stock",
                    "price_aud": "113.04",
                    "price_brl": "377.05",
                    "price_cad": "100.56",
                    "price_eur": "68.76",
                    "price_gbp": "59.08",
                    "price_usd": "73.49",
                    "price_php": "4232.44",
                    "price_zar": "1408.52",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/southern--big.svg"
                },
                {
                    "id": 192,
                    "market_id": 3,
                    "tradingview": "SPGI",
                    "name": "S&P Globale",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SPGI",
                    "market_name": "stock",
                    "price_aud": "630.69",
                    "price_brl": "2103.60",
                    "price_cad": "561.06",
                    "price_eur": "383.60",
                    "price_gbp": "329.64",
                    "price_usd": "410.01",
                    "price_php": "23613.30",
                    "price_zar": "7858.29",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/s-and-p-global--big.svg"
                },
                {
                    "id": 205,
                    "market_id": 3,
                    "tradingview": "SYK",
                    "name": "Stryker Corporation",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SYK",
                    "market_name": "stock",
                    "price_aud": "515.17",
                    "price_brl": "1718.29",
                    "price_cad": "458.29",
                    "price_eur": "313.33",
                    "price_gbp": "269.26",
                    "price_usd": "334.91",
                    "price_php": "19288.14",
                    "price_zar": "6418.92",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/stryker--big.svg"
                },
                {
                    "id": 206,
                    "market_id": 3,
                    "tradingview": "T",
                    "name": "At&t",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "T",
                    "market_name": "stock",
                    "price_aud": "25.93",
                    "price_brl": "86.50",
                    "price_cad": "23.07",
                    "price_eur": "15.77",
                    "price_gbp": "13.55",
                    "price_usd": "16.86",
                    "price_php": "971.00",
                    "price_zar": "323.14",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/at-and-t--big.svg"
                },
                {
                    "id": 256,
                    "market_id": 3,
                    "tradingview": "TGT",
                    "name": "Target",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "TGT",
                    "market_name": "stock",
                    "price_aud": "256.11",
                    "price_brl": "854.24",
                    "price_cad": "227.84",
                    "price_eur": "155.77",
                    "price_gbp": "133.86",
                    "price_usd": "166.5",
                    "price_php": "9589.07",
                    "price_zar": "3191.16",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/target--big.svg"
                },
                {
                    "id": 215,
                    "market_id": 3,
                    "tradingview": "TJX",
                    "name": "TJX Companies",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "TJX",
                    "market_name": "stock",
                    "price_aud": "145.36",
                    "price_brl": "484.84",
                    "price_cad": "129.31",
                    "price_eur": "88.41",
                    "price_gbp": "75.98",
                    "price_usd": "94.5",
                    "price_php": "5442.44",
                    "price_zar": "1811.20",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/tjx-cos--big.svg"
                },
                {
                    "id": 168,
                    "market_id": 3,
                    "tradingview": "TMO",
                    "name": "Thermo Fisher Scientific",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "TMO",
                    "market_name": "stock",
                    "price_aud": "902.94",
                    "price_brl": "3011.66",
                    "price_cad": "803.25",
                    "price_eur": "549.18",
                    "price_gbp": "471.93",
                    "price_usd": "587",
                    "price_php": "33806.50",
                    "price_zar": "11250.50",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/thermo-fisher-scientific--big.svg"
                },
                {
                    "id": 175,
                    "market_id": 3,
                    "tradingview": "TMUS",
                    "name": "T-Mobile",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "TMUS",
                    "market_name": "stock",
                    "price_aud": "251.13",
                    "price_brl": "837.62",
                    "price_cad": "223.41",
                    "price_eur": "152.74",
                    "price_gbp": "131.26",
                    "price_usd": "163.26",
                    "price_php": "9402.47",
                    "price_zar": "3129.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/t-mobile--big.svg"
                },
                {
                    "id": 142,
                    "market_id": 3,
                    "tradingview": "TSLA",
                    "name": "Tesla",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "TSLA",
                    "market_name": "stock",
                    "price_aud": "249.21",
                    "price_brl": "831.21",
                    "price_cad": "221.69",
                    "price_eur": "151.57",
                    "price_gbp": "130.25",
                    "price_usd": "162.01",
                    "price_php": "9330.48",
                    "price_zar": "3105.10",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/tesla--big.svg"
                },
                {
                    "id": 177,
                    "market_id": 3,
                    "tradingview": "TXN",
                    "name": "Texas Instruments",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "TXN",
                    "market_name": "stock",
                    "price_aud": "273.80",
                    "price_brl": "913.25",
                    "price_cad": "243.58",
                    "price_eur": "166.53",
                    "price_gbp": "143.11",
                    "price_usd": "178",
                    "price_php": "10251.38",
                    "price_zar": "3411.57",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/texas-instruments--big.svg"
                },
                {
                    "id": 220,
                    "market_id": 3,
                    "tradingview": "UBER",
                    "name": "Uber",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "UBER",
                    "market_name": "stock",
                    "price_aud": "107.43",
                    "price_brl": "358.32",
                    "price_cad": "95.57",
                    "price_eur": "65.34",
                    "price_gbp": "56.15",
                    "price_usd": "69.84",
                    "price_php": "4022.23",
                    "price_zar": "1338.56",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/uber--big.svg"
                },
                {
                    "id": 145,
                    "market_id": 3,
                    "tradingview": "UNH",
                    "name": "United Health Group",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "UNH",
                    "market_name": "stock",
                    "price_aud": "755.07",
                    "price_brl": "2518.46",
                    "price_cad": "671.71",
                    "price_eur": "459.25",
                    "price_gbp": "394.64",
                    "price_usd": "490.87",
                    "price_php": "28270.19",
                    "price_zar": "9408.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/unitedhealth--big.svg"
                },
                {
                    "id": 194,
                    "market_id": 3,
                    "tradingview": "UNP",
                    "name": "Union Pacific",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "UNP",
                    "market_name": "stock",
                    "price_aud": "363.47",
                    "price_brl": "1212.31",
                    "price_cad": "323.34",
                    "price_eur": "221.07",
                    "price_gbp": "189.97",
                    "price_usd": "236.29",
                    "price_php": "13608.41",
                    "price_zar": "4528.76",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/union-pacific--big.svg"
                },
                {
                    "id": 182,
                    "market_id": 3,
                    "tradingview": "UPS",
                    "name": "United Parcel Service",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "UPS",
                    "market_name": "stock",
                    "price_aud": "228.20",
                    "price_brl": "761.12",
                    "price_cad": "203.00",
                    "price_eur": "138.79",
                    "price_gbp": "119.27",
                    "price_usd": "148.35",
                    "price_php": "8543.77",
                    "price_zar": "2843.29",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/united-parcel--big.svg"
                },
                {
                    "id": 144,
                    "market_id": 3,
                    "tradingview": "V",
                    "name": "Visa",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "V",
                    "market_name": "stock",
                    "price_aud": "433.79",
                    "price_brl": "1446.88",
                    "price_cad": "385.90",
                    "price_eur": "263.84",
                    "price_gbp": "226.73",
                    "price_usd": "282.01",
                    "price_php": "16241.52",
                    "price_zar": "5405.03",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/visa--big.svg"
                },
                {
                    "id": 222,
                    "market_id": 3,
                    "tradingview": "VRTX",
                    "name": "Vertex Pharmaceutical",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "VRTX",
                    "market_name": "stock",
                    "price_aud": "622.84",
                    "price_brl": "2077.43",
                    "price_cad": "554.08",
                    "price_eur": "378.82",
                    "price_gbp": "325.53",
                    "price_usd": "404.91",
                    "price_php": "23319.58",
                    "price_zar": "7760.55",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vertex-pharmaceutical--big.svg"
                },
                {
                    "id": 178,
                    "market_id": 3,
                    "tradingview": "VZ",
                    "name": "Verizon",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "VZ",
                    "market_name": "stock",
                    "price_aud": "61.24",
                    "price_brl": "204.25",
                    "price_cad": "54.48",
                    "price_eur": "37.25",
                    "price_gbp": "32.01",
                    "price_usd": "39.81",
                    "price_php": "2292.74",
                    "price_zar": "763.00",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/verizon--big.svg"
                },
                {
                    "id": 264,
                    "market_id": 3,
                    "tradingview": "WDAY",
                    "name": "Workday",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "WDAY",
                    "market_name": "stock",
                    "price_aud": "395.46",
                    "price_brl": "1319.03",
                    "price_cad": "351.80",
                    "price_eur": "240.53",
                    "price_gbp": "206.69",
                    "price_usd": "257.09",
                    "price_php": "14806.33",
                    "price_zar": "4927.41",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/workday--big.svg"
                },
                {
                    "id": 179,
                    "market_id": 3,
                    "tradingview": "WFC",
                    "name": "Wells Fargo",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "WFC",
                    "market_name": "stock",
                    "price_aud": "93.37",
                    "price_brl": "311.43",
                    "price_cad": "83.06",
                    "price_eur": "56.79",
                    "price_gbp": "48.80",
                    "price_usd": "60.7",
                    "price_php": "3495.83",
                    "price_zar": "1163.38",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/wells-fargo--big.svg"
                },
                {
                    "id": 245,
                    "market_id": 3,
                    "tradingview": "WM",
                    "name": "Waste Management",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "WM",
                    "market_name": "stock",
                    "price_aud": "321.15",
                    "price_brl": "1071.17",
                    "price_cad": "285.69",
                    "price_eur": "195.33",
                    "price_gbp": "167.85",
                    "price_usd": "208.78",
                    "price_php": "12024.06",
                    "price_zar": "4001.50",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/waste-management--big.svg"
                },
                {
                    "id": 149,
                    "market_id": 3,
                    "tradingview": "WMT",
                    "name": "Walmart",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "WMT",
                    "market_name": "stock",
                    "price_aud": "90.85",
                    "price_brl": "303.01",
                    "price_cad": "80.82",
                    "price_eur": "55.26",
                    "price_gbp": "47.48",
                    "price_usd": "59.06",
                    "price_php": "3401.38",
                    "price_zar": "1131.95",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/walmart--big.svg"
                },
                {
                    "id": 148,
                    "market_id": 3,
                    "tradingview": "XOM",
                    "name": "Exxon",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "XOM",
                    "market_name": "stock",
                    "price_aud": "186.51",
                    "price_brl": "622.09",
                    "price_cad": "165.92",
                    "price_eur": "113.44",
                    "price_gbp": "97.48",
                    "price_usd": "121.25",
                    "price_php": "6983.03",
                    "price_zar": "2323.89",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/exxon--big.svg"
                },
                {
                    "id": 233,
                    "market_id": 3,
                    "tradingview": "ZTS",
                    "name": "Zoetis",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ZTS",
                    "market_name": "stock",
                    "price_aud": "229.58",
                    "price_brl": "765.74",
                    "price_cad": "204.23",
                    "price_eur": "139.63",
                    "price_gbp": "119.99",
                    "price_usd": "149.25",
                    "price_php": "8595.61",
                    "price_zar": "2860.54",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/zoetis--big.svg"
                }
            ],
            "total": 145,
            "object": {
                "AAPL": {
                    "id": 137,
                    "market_id": 3,
                    "tradingview": "AAPL",
                    "name": "Apple",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "AAPL",
                    "market_name": "stock",
                    "price_aud": "256.82",
                    "price_brl": "856.60",
                    "price_cad": "228.47",
                    "price_eur": "156.20",
                    "price_gbp": "134.23",
                    "price_usd": "166.96",
                    "price_php": "9615.56",
                    "price_zar": "3199.97",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/apple--big.svg"
                },
                "ABBV": {
                    "id": 160,
                    "market_id": 3,
                    "tradingview": "ABBV",
                    "name": "AbbVie",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ABBV",
                    "market_name": "stock",
                    "price_aud": "260.81",
                    "price_brl": "869.89",
                    "price_cad": "232.01",
                    "price_eur": "158.63",
                    "price_gbp": "136.31",
                    "price_usd": "169.55",
                    "price_php": "9764.72",
                    "price_zar": "3249.61",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/abbvie--big.svg"
                },
                "ABNB": {
                    "id": 230,
                    "market_id": 3,
                    "tradingview": "ABNB",
                    "name": "Airbnb",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ABNB",
                    "market_name": "stock",
                    "price_aud": "251.92",
                    "price_brl": "840.24",
                    "price_cad": "224.10",
                    "price_eur": "153.22",
                    "price_gbp": "131.67",
                    "price_usd": "163.77",
                    "price_php": "9431.84",
                    "price_zar": "3138.83",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/airbnb--big.svg"
                },
                "ABT": {
                    "id": 170,
                    "market_id": 3,
                    "tradingview": "ABT",
                    "name": "Abbott Labs",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ABT",
                    "market_name": "stock",
                    "price_aud": "165.81",
                    "price_brl": "553.03",
                    "price_cad": "147.50",
                    "price_eur": "100.85",
                    "price_gbp": "86.66",
                    "price_usd": "107.79",
                    "price_php": "6207.84",
                    "price_zar": "2065.91",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/abbott--big.svg"
                },
                "ADBE": {
                    "id": 163,
                    "market_id": 3,
                    "tradingview": "ADBE",
                    "name": "Adobe",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ADBE",
                    "market_name": "stock",
                    "price_aud": "729.89",
                    "price_brl": "2434.47",
                    "price_cad": "649.31",
                    "price_eur": "443.93",
                    "price_gbp": "381.48",
                    "price_usd": "474.5",
                    "price_php": "27327.40",
                    "price_zar": "9094.31",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/adobe--big.svg"
                },
                "ADI": {
                    "id": 217,
                    "market_id": 3,
                    "tradingview": "ADI",
                    "name": "Analog Devices",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ADI",
                    "market_name": "stock",
                    "price_aud": "301.63",
                    "price_brl": "1006.06",
                    "price_cad": "268.33",
                    "price_eur": "183.46",
                    "price_gbp": "157.65",
                    "price_usd": "196.09",
                    "price_php": "11293.22",
                    "price_zar": "3758.28",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/analog-devices--big.svg"
                },
                "ADP": {
                    "id": 221,
                    "market_id": 3,
                    "tradingview": "ADP",
                    "name": "Automatic Data Processing",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ADP",
                    "market_name": "stock",
                    "price_aud": "379.70",
                    "price_brl": "1266.44",
                    "price_cad": "337.78",
                    "price_eur": "230.94",
                    "price_gbp": "198.45",
                    "price_usd": "246.84",
                    "price_php": "14216.01",
                    "price_zar": "4730.96",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/automatic-data-processing--big.svg"
                },
                "AMAT": {
                    "id": 200,
                    "market_id": 3,
                    "tradingview": "AMAT",
                    "name": "Applied Material",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "AMAT",
                    "market_name": "stock",
                    "price_aud": "301.34",
                    "price_brl": "1005.08",
                    "price_cad": "268.07",
                    "price_eur": "183.28",
                    "price_gbp": "157.50",
                    "price_usd": "195.9",
                    "price_php": "11282.27",
                    "price_zar": "3754.64",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/applied-materials--big.svg"
                },
                "AMD": {
                    "id": 171,
                    "market_id": 3,
                    "tradingview": "AMD",
                    "name": "Advanced Mirco Devices",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "AMD",
                    "market_name": "stock",
                    "price_aud": "238.19",
                    "price_brl": "794.47",
                    "price_cad": "211.90",
                    "price_eur": "144.87",
                    "price_gbp": "124.49",
                    "price_usd": "154.85",
                    "price_php": "8918.12",
                    "price_zar": "2967.87",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/advanced-micro-devices--big.svg"
                },
                "AMGN": {
                    "id": 197,
                    "market_id": 3,
                    "tradingview": "AMGN",
                    "name": "Amgen",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "AMGN",
                    "market_name": "stock",
                    "price_aud": "420.77",
                    "price_brl": "1403.42",
                    "price_cad": "374.31",
                    "price_eur": "255.92",
                    "price_gbp": "219.92",
                    "price_usd": "273.54",
                    "price_php": "15753.72",
                    "price_zar": "5242.69",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/amgen--big.svg"
                },
                "AMT": {
                    "id": 223,
                    "market_id": 3,
                    "tradingview": "AMT",
                    "name": "American Tower",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "AMT",
                    "market_name": "stock",
                    "price_aud": "269.19",
                    "price_brl": "897.86",
                    "price_cad": "239.47",
                    "price_eur": "163.73",
                    "price_gbp": "140.69",
                    "price_usd": "175",
                    "price_php": "10078.60",
                    "price_zar": "3354.07",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/american-tower--big.svg"
                },
                "AMZN": {
                    "id": 140,
                    "market_id": 3,
                    "tradingview": "AMZN",
                    "name": "Amazon",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "AMZN",
                    "market_name": "stock",
                    "price_aud": "277.54",
                    "price_brl": "925.71",
                    "price_cad": "246.90",
                    "price_eur": "168.81",
                    "price_gbp": "145.06",
                    "price_usd": "180.43",
                    "price_php": "10391.32",
                    "price_zar": "3458.14",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/amazon--big.svg"
                },
                "APD": {
                    "id": 250,
                    "market_id": 3,
                    "tradingview": "APD",
                    "name": "Air Products and Chemicals",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "APD",
                    "market_name": "stock",
                    "price_aud": "359.50",
                    "price_brl": "1199.07",
                    "price_cad": "319.81",
                    "price_eur": "218.65",
                    "price_gbp": "187.90",
                    "price_usd": "233.71",
                    "price_php": "13459.83",
                    "price_zar": "4479.31",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/air-products-and-chemicals--big.svg"
                },
                "AVGO": {
                    "id": 153,
                    "market_id": 3,
                    "tradingview": "AVGO",
                    "name": "Broadcom",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "AVGO",
                    "market_name": "stock",
                    "price_aud": "1935.30",
                    "price_brl": "6455.01",
                    "price_cad": "1721.64",
                    "price_eur": "1177.09",
                    "price_gbp": "1011.50",
                    "price_usd": "1258.14",
                    "price_php": "72458.80",
                    "price_zar": "24113.64",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/broadcom--big.svg"
                },
                "AXP": {
                    "id": 191,
                    "market_id": 3,
                    "tradingview": "AXP",
                    "name": "American Express",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "AXP",
                    "market_name": "stock",
                    "price_aud": "367.01",
                    "price_brl": "1224.11",
                    "price_cad": "326.49",
                    "price_eur": "223.22",
                    "price_gbp": "191.82",
                    "price_usd": "238.59",
                    "price_php": "13740.88",
                    "price_zar": "4572.84",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/american-express--big.svg"
                },
                "BA": {
                    "id": 193,
                    "market_id": 3,
                    "tradingview": "BA",
                    "name": "Boeing",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "BA",
                    "market_name": "stock",
                    "price_aud": "263.81",
                    "price_brl": "879.90",
                    "price_cad": "234.68",
                    "price_eur": "160.45",
                    "price_gbp": "137.88",
                    "price_usd": "171.5",
                    "price_php": "9877.03",
                    "price_zar": "3286.99",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/boeing--big.svg"
                },
                "BAC": {
                    "id": 162,
                    "market_id": 3,
                    "tradingview": "BAC",
                    "name": "Bank of America",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "BAC",
                    "market_name": "stock",
                    "price_aud": "58.91",
                    "price_brl": "196.50",
                    "price_cad": "52.41",
                    "price_eur": "35.83",
                    "price_gbp": "30.79",
                    "price_usd": "38.3",
                    "price_php": "2205.77",
                    "price_zar": "734.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/bank-of-america--big.svg"
                },
                "BDX": {
                    "id": 239,
                    "market_id": 3,
                    "tradingview": "BDX",
                    "name": "Becton Dickinson",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "BDX",
                    "market_name": "stock",
                    "price_aud": "360.50",
                    "price_brl": "1202.41",
                    "price_cad": "320.70",
                    "price_eur": "219.26",
                    "price_gbp": "188.42",
                    "price_usd": "234.36",
                    "price_php": "13497.26",
                    "price_zar": "4491.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/becton-dickinson--big.svg"
                },
                "BKNG": {
                    "id": 213,
                    "market_id": 3,
                    "tradingview": "BKNG",
                    "name": "Booking Holdings",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "BKNG",
                    "market_name": "stock",
                    "price_aud": "5436.07",
                    "price_brl": "18131.49",
                    "price_cad": "4835.92",
                    "price_eur": "3306.32",
                    "price_gbp": "2841.22",
                    "price_usd": "3533.99",
                    "price_php": "203529.55",
                    "price_zar": "67732.81",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/booking--big.svg"
                },
                "BLK": {
                    "id": 211,
                    "market_id": 3,
                    "tradingview": "BLK",
                    "name": "BlackRock",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "BLK",
                    "market_name": "stock",
                    "price_aud": "1179.93",
                    "price_brl": "3935.53",
                    "price_cad": "1049.66",
                    "price_eur": "717.65",
                    "price_gbp": "616.70",
                    "price_usd": "767.07",
                    "price_php": "44177.10",
                    "price_zar": "14701.74",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/blackrock--big.svg"
                },
                "BMY": {
                    "id": 186,
                    "market_id": 3,
                    "tradingview": "BMY",
                    "name": "Bristol-Myers Squibb",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "BMY",
                    "market_name": "stock",
                    "price_aud": "75.67",
                    "price_brl": "252.37",
                    "price_cad": "67.31",
                    "price_eur": "46.02",
                    "price_gbp": "39.55",
                    "price_usd": "49.19",
                    "price_php": "2832.95",
                    "price_zar": "942.78",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/bristol-myers-squibb--big.svg"
                },
                "BSX": {
                    "id": 234,
                    "market_id": 3,
                    "tradingview": "BSX",
                    "name": "Boston Scientific",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "BSX",
                    "market_name": "stock",
                    "price_aud": "110.94",
                    "price_brl": "370.02",
                    "price_cad": "98.69",
                    "price_eur": "67.47",
                    "price_gbp": "57.98",
                    "price_usd": "72.12",
                    "price_php": "4153.54",
                    "price_zar": "1382.26",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/boston-scientific--big.svg"
                },
                "BX": {
                    "id": 249,
                    "market_id": 3,
                    "tradingview": "BX",
                    "name": "Blackstone",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "BX",
                    "market_name": "stock",
                    "price_aud": "191.69",
                    "price_brl": "639.38",
                    "price_cad": "170.53",
                    "price_eur": "116.59",
                    "price_gbp": "100.19",
                    "price_usd": "124.62",
                    "price_php": "7177.12",
                    "price_zar": "2388.48",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/blackstone--big.svg"
                },
                "C": {
                    "id": 219,
                    "market_id": 3,
                    "tradingview": "C",
                    "name": "Citigroup",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "C",
                    "market_name": "stock",
                    "price_aud": "96.52",
                    "price_brl": "321.95",
                    "price_cad": "85.87",
                    "price_eur": "58.71",
                    "price_gbp": "50.45",
                    "price_usd": "62.75",
                    "price_php": "3613.90",
                    "price_zar": "1202.67",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/citigroup--big.svg"
                },
                "CAT": {
                    "id": 195,
                    "market_id": 3,
                    "tradingview": "CAT",
                    "name": "Caterpillar",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CAT",
                    "market_name": "stock",
                    "price_aud": "558.79",
                    "price_brl": "1863.79",
                    "price_cad": "497.10",
                    "price_eur": "339.87",
                    "price_gbp": "292.06",
                    "price_usd": "363.27",
                    "price_php": "20921.45",
                    "price_zar": "6962.47",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/caterpillar--big.svg"
                },
                "CHTR": {
                    "id": 276,
                    "market_id": 3,
                    "tradingview": "CHTR",
                    "name": "Charter Communications",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CHTR",
                    "market_name": "stock",
                    "price_aud": "408.11",
                    "price_brl": "1361.20",
                    "price_cad": "363.05",
                    "price_eur": "248.22",
                    "price_gbp": "213.30",
                    "price_usd": "265.31",
                    "price_php": "15279.73",
                    "price_zar": "5084.96",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/charter--big.svg"
                },
                "CI": {
                    "id": 228,
                    "market_id": 3,
                    "tradingview": "CI",
                    "name": "Cigna",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CI",
                    "market_name": "stock",
                    "price_aud": "542.46",
                    "price_brl": "1809.31",
                    "price_cad": "482.57",
                    "price_eur": "329.93",
                    "price_gbp": "283.52",
                    "price_usd": "352.65",
                    "price_php": "20309.82",
                    "price_zar": "6758.93",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/cigna--big.svg"
                },
                "CL": {
                    "id": 255,
                    "market_id": 3,
                    "tradingview": "CL",
                    "name": "Colgate-Palmolive",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CL",
                    "market_name": "stock",
                    "price_aud": "136.26",
                    "price_brl": "454.47",
                    "price_cad": "121.21",
                    "price_eur": "82.87",
                    "price_gbp": "71.22",
                    "price_usd": "88.58",
                    "price_php": "5101.50",
                    "price_zar": "1697.73",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/colgate-palmolive--big.svg"
                },
                "CMCSA": {
                    "id": 174,
                    "market_id": 3,
                    "tradingview": "CMCSA",
                    "name": "Comcast",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CMCSA",
                    "market_name": "stock",
                    "price_aud": "61.98",
                    "price_brl": "206.71",
                    "price_cad": "55.13",
                    "price_eur": "37.69",
                    "price_gbp": "32.39",
                    "price_usd": "40.29",
                    "price_php": "2320.38",
                    "price_zar": "772.20",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/comcast--big.svg"
                },
                "CME": {
                    "id": 251,
                    "market_id": 3,
                    "tradingview": "CME",
                    "name": "CME Group",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CME",
                    "market_name": "stock",
                    "price_aud": "333.32",
                    "price_brl": "1111.75",
                    "price_cad": "296.52",
                    "price_eur": "202.73",
                    "price_gbp": "174.21",
                    "price_usd": "216.69",
                    "price_php": "12479.61",
                    "price_zar": "4153.10",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/cme--big.svg"
                },
                "CMG": {
                    "id": 271,
                    "market_id": 3,
                    "tradingview": "CMG",
                    "name": "Chipotle Mexican Grill",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CMG",
                    "market_name": "stock",
                    "price_aud": "4518.54",
                    "price_brl": "15071.14",
                    "price_cad": "4019.68",
                    "price_eur": "2748.26",
                    "price_gbp": "2361.66",
                    "price_usd": "2937.5",
                    "price_php": "169176.50",
                    "price_zar": "56300.42",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/chipotle-mexican-grill--big.svg"
                },
                "COP": {
                    "id": 196,
                    "market_id": 3,
                    "tradingview": "COP",
                    "name": "ConocoPhillips",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "COP",
                    "market_name": "stock",
                    "price_aud": "199.72",
                    "price_brl": "666.16",
                    "price_cad": "177.67",
                    "price_eur": "121.48",
                    "price_gbp": "104.39",
                    "price_usd": "129.84",
                    "price_php": "7477.75",
                    "price_zar": "2488.53",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/conocophillips--big.svg"
                },
                "COST": {
                    "id": 161,
                    "market_id": 3,
                    "tradingview": "COST",
                    "name": "COSTCO",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "COST",
                    "market_name": "stock",
                    "price_aud": "1111.17",
                    "price_brl": "3706.19",
                    "price_cad": "988.49",
                    "price_eur": "675.83",
                    "price_gbp": "580.76",
                    "price_usd": "722.37",
                    "price_php": "41602.73",
                    "price_zar": "13845.02",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/costco-wholesale--big.svg"
                },
                "CRM": {
                    "id": 167,
                    "market_id": 3,
                    "tradingview": "CRM",
                    "name": "Salesforce Inc",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CRM",
                    "market_name": "stock",
                    "price_aud": "426.80",
                    "price_brl": "1423.54",
                    "price_cad": "379.68",
                    "price_eur": "259.58",
                    "price_gbp": "223.07",
                    "price_usd": "277.46",
                    "price_php": "15979.48",
                    "price_zar": "5317.83",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/salesforce--big.svg"
                },
                "CSCO": {
                    "id": 166,
                    "market_id": 3,
                    "tradingview": "CSCO",
                    "name": "Cisco",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CSCO",
                    "market_name": "stock",
                    "price_aud": "74.10",
                    "price_brl": "247.14",
                    "price_cad": "65.92",
                    "price_eur": "45.07",
                    "price_gbp": "38.73",
                    "price_usd": "48.17",
                    "price_php": "2774.21",
                    "price_zar": "923.23",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/cisco--big.svg"
                },
                "CSX": {
                    "id": 246,
                    "market_id": 3,
                    "tradingview": "CSX",
                    "name": "CSX Corporation",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CSX",
                    "market_name": "stock",
                    "price_aud": "53.38",
                    "price_brl": "178.03",
                    "price_cad": "47.48",
                    "price_eur": "32.46",
                    "price_gbp": "27.90",
                    "price_usd": "34.7",
                    "price_php": "1998.44",
                    "price_zar": "665.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/csx--big.svg"
                },
                "CVS": {
                    "id": 224,
                    "market_id": 3,
                    "tradingview": "CVS",
                    "name": "CVS Health",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CVS",
                    "market_name": "stock",
                    "price_aud": "105.85",
                    "price_brl": "353.04",
                    "price_cad": "94.16",
                    "price_eur": "64.38",
                    "price_gbp": "55.32",
                    "price_usd": "68.81",
                    "price_php": "3962.91",
                    "price_zar": "1318.82",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/cvs-health--big.svg"
                },
                "CVX": {
                    "id": 156,
                    "market_id": 3,
                    "tradingview": "CVX",
                    "name": "Chevron",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "CVX",
                    "market_name": "stock",
                    "price_aud": "250.39",
                    "price_brl": "835.16",
                    "price_cad": "222.75",
                    "price_eur": "152.29",
                    "price_gbp": "130.87",
                    "price_usd": "162.78",
                    "price_php": "9374.83",
                    "price_zar": "3119.86",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/chevron--big.svg"
                },
                "DE": {
                    "id": 199,
                    "market_id": 3,
                    "tradingview": "DE",
                    "name": "Deere & Company",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "DE",
                    "market_name": "stock",
                    "price_aud": "611.00",
                    "price_brl": "2037.93",
                    "price_cad": "543.54",
                    "price_eur": "371.62",
                    "price_gbp": "319.34",
                    "price_usd": "397.21",
                    "price_php": "22876.12",
                    "price_zar": "7612.97",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/deere--big.svg"
                },
                "DHR": {
                    "id": 173,
                    "market_id": 3,
                    "tradingview": "DHR",
                    "name": "Danaher",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "DHR",
                    "market_name": "stock",
                    "price_aud": "390.00",
                    "price_brl": "1300.81",
                    "price_cad": "346.94",
                    "price_eur": "237.21",
                    "price_gbp": "203.84",
                    "price_usd": "253.54",
                    "price_php": "14601.88",
                    "price_zar": "4859.37",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/danaher--big.svg"
                },
                "DIS": {
                    "id": 176,
                    "market_id": 3,
                    "tradingview": "DIS",
                    "name": "Walt Disney",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "DIS",
                    "market_name": "stock",
                    "price_aud": "175.34",
                    "price_brl": "584.84",
                    "price_cad": "155.98",
                    "price_eur": "106.65",
                    "price_gbp": "91.64",
                    "price_usd": "113.99",
                    "price_php": "6564.91",
                    "price_zar": "2184.74",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/walt-disney--big.svg"
                },
                "DUK": {
                    "id": 242,
                    "market_id": 3,
                    "tradingview": "DUK",
                    "name": "Duke Energy",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "DUK",
                    "market_name": "stock",
                    "price_aud": "151.05",
                    "price_brl": "503.82",
                    "price_cad": "134.38",
                    "price_eur": "91.87",
                    "price_gbp": "78.95",
                    "price_usd": "98.2",
                    "price_php": "5655.53",
                    "price_zar": "1882.11",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/duke-energy--big.svg"
                },
                "EL": {
                    "id": 243,
                    "market_id": 3,
                    "tradingview": "EL",
                    "name": "Estee Lauder",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "EL",
                    "market_name": "stock",
                    "price_aud": "230.73",
                    "price_brl": "769.59",
                    "price_cad": "205.26",
                    "price_eur": "140.34",
                    "price_gbp": "120.60",
                    "price_usd": "150",
                    "price_php": "8638.80",
                    "price_zar": "2874.92",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/estee-lauder--big.svg"
                },
                "ELV": {
                    "id": 209,
                    "market_id": 3,
                    "tradingview": "ELV",
                    "name": "Elevance Health",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ELV",
                    "market_name": "stock",
                    "price_aud": "819.75",
                    "price_brl": "2734.20",
                    "price_cad": "729.25",
                    "price_eur": "498.59",
                    "price_gbp": "428.45",
                    "price_usd": "532.92",
                    "price_php": "30691.93",
                    "price_zar": "10214.00",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/anthem--big.svg"
                },
                "EOG": {
                    "id": 252,
                    "market_id": 3,
                    "tradingview": "EOG",
                    "name": "EOG Resources",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "EOG",
                    "market_name": "stock",
                    "price_aud": "207.35",
                    "price_brl": "691.60",
                    "price_cad": "184.46",
                    "price_eur": "126.12",
                    "price_gbp": "108.37",
                    "price_usd": "134.8",
                    "price_php": "7763.40",
                    "price_zar": "2583.59",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/eog--big.svg"
                },
                "EPD": {
                    "id": 270,
                    "market_id": 3,
                    "tradingview": "EPD",
                    "name": "Enterprise Products Partners",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "EPD",
                    "market_name": "stock",
                    "price_aud": "44.55",
                    "price_brl": "148.58",
                    "price_cad": "39.63",
                    "price_eur": "27.09",
                    "price_gbp": "23.28",
                    "price_usd": "28.96",
                    "price_php": "1667.86",
                    "price_zar": "555.05",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/enterprise-prods-partners--big.svg"
                },
                "EQIX": {
                    "id": 241,
                    "market_id": 3,
                    "tradingview": "EQIX",
                    "name": "Equinix",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "EQIX",
                    "market_name": "stock",
                    "price_aud": "1171.97",
                    "price_brl": "3909.00",
                    "price_cad": "1042.58",
                    "price_eur": "712.82",
                    "price_gbp": "612.54",
                    "price_usd": "761.9",
                    "price_php": "43879.34",
                    "price_zar": "14602.65",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/equinix--big.svg"
                },
                "EW": {
                    "id": 273,
                    "market_id": 3,
                    "tradingview": "EW",
                    "name": "Edwards Lifesciences",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "EW",
                    "market_name": "stock",
                    "price_aud": "134.98",
                    "price_brl": "450.21",
                    "price_cad": "120.08",
                    "price_eur": "82.10",
                    "price_gbp": "70.55",
                    "price_usd": "87.75",
                    "price_php": "5053.70",
                    "price_zar": "1681.83",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/edwards--big.svg"
                },
                "F": {
                    "id": 266,
                    "market_id": 3,
                    "tradingview": "F",
                    "name": "Ford",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "F",
                    "market_name": "stock",
                    "price_aud": "20.00",
                    "price_brl": "66.70",
                    "price_cad": "17.79",
                    "price_eur": "12.16",
                    "price_gbp": "10.45",
                    "price_usd": "13",
                    "price_php": "748.70",
                    "price_zar": "249.16",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/ford--big.svg"
                },
                "FCX": {
                    "id": 265,
                    "market_id": 3,
                    "tradingview": "FCX",
                    "name": "Freeport-McmoRan",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "FCX",
                    "market_name": "stock",
                    "price_aud": "74.45",
                    "price_brl": "248.32",
                    "price_cad": "66.23",
                    "price_eur": "45.28",
                    "price_gbp": "38.91",
                    "price_usd": "48.4",
                    "price_php": "2787.45",
                    "price_zar": "927.64",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/freeport-mcmoran--big.svg"
                },
                "FDX": {
                    "id": 259,
                    "market_id": 3,
                    "tradingview": "FDX",
                    "name": "Fedex",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "FDX",
                    "market_name": "stock",
                    "price_aud": "419.03",
                    "price_brl": "1397.63",
                    "price_cad": "372.77",
                    "price_eur": "254.86",
                    "price_gbp": "219.01",
                    "price_usd": "272.41",
                    "price_php": "15688.64",
                    "price_zar": "5221.04",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/fedex--big.svg"
                },
                "FI": {
                    "id": 236,
                    "market_id": 3,
                    "tradingview": "FI",
                    "name": "Fiserv",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "FI",
                    "market_name": "stock",
                    "price_aud": "239.02",
                    "price_brl": "797.24",
                    "price_cad": "212.64",
                    "price_eur": "145.38",
                    "price_gbp": "124.93",
                    "price_usd": "155.39",
                    "price_php": "8949.22",
                    "price_zar": "2978.22",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/fiserv--big.svg"
                },
                "FTNT": {
                    "id": 267,
                    "market_id": 3,
                    "tradingview": "FTNT",
                    "name": "Fortinet",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "FTNT",
                    "market_name": "stock",
                    "price_aud": "100.43",
                    "price_brl": "334.98",
                    "price_cad": "89.34",
                    "price_eur": "61.08",
                    "price_gbp": "52.49",
                    "price_usd": "65.29",
                    "price_php": "3760.18",
                    "price_zar": "1251.35",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/fortinet--big.svg"
                },
                "GD": {
                    "id": 263,
                    "market_id": 3,
                    "tradingview": "GD",
                    "name": "General Dynamics",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "GD",
                    "market_name": "stock",
                    "price_aud": "452.24",
                    "price_brl": "1508.40",
                    "price_cad": "402.31",
                    "price_eur": "275.06",
                    "price_gbp": "236.37",
                    "price_usd": "294",
                    "price_php": "16932.05",
                    "price_zar": "5634.83",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/general-dynamics--big.svg"
                },
                "GE": {
                    "id": 203,
                    "market_id": 3,
                    "tradingview": "GE",
                    "name": "General Electric",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "GE",
                    "market_name": "stock",
                    "price_aud": "250.30",
                    "price_brl": "834.85",
                    "price_cad": "222.67",
                    "price_eur": "152.24",
                    "price_gbp": "130.82",
                    "price_usd": "162.72",
                    "price_php": "9371.37",
                    "price_zar": "3118.71",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/general-electric--big.svg"
                },
                "GILD": {
                    "id": 216,
                    "market_id": 3,
                    "tradingview": "GILD",
                    "name": "Gilead Sciences",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "GILD",
                    "market_name": "stock",
                    "price_aud": "103.20",
                    "price_brl": "344.21",
                    "price_cad": "91.81",
                    "price_eur": "62.77",
                    "price_gbp": "53.94",
                    "price_usd": "67.09",
                    "price_php": "3863.85",
                    "price_zar": "1285.85",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/gilead--big.svg"
                },
                "GOOG": {
                    "id": 139,
                    "market_id": 3,
                    "tradingview": "GOOG",
                    "name": "Alphabet",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "GOOG",
                    "market_name": "stock",
                    "price_aud": "245.44",
                    "price_brl": "818.64",
                    "price_cad": "218.34",
                    "price_eur": "149.28",
                    "price_gbp": "128.28",
                    "price_usd": "159.56",
                    "price_php": "9189.38",
                    "price_zar": "3058.14",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/alphabet--big.svg"
                },
                "GS": {
                    "id": 210,
                    "market_id": 3,
                    "tradingview": "GS",
                    "name": "Goldman Sachs",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "GS",
                    "market_name": "stock",
                    "price_aud": "653.73",
                    "price_brl": "2180.45",
                    "price_cad": "581.56",
                    "price_eur": "397.61",
                    "price_gbp": "341.68",
                    "price_usd": "424.99",
                    "price_php": "24476.02",
                    "price_zar": "8145.40",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/goldman-sachs--big.svg"
                },
                "HCA": {
                    "id": 227,
                    "market_id": 3,
                    "tradingview": "HCA",
                    "name": "HCA Healthcare",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "HCA",
                    "market_name": "stock",
                    "price_aud": "491.36",
                    "price_brl": "1638.87",
                    "price_cad": "437.11",
                    "price_eur": "298.85",
                    "price_gbp": "256.81",
                    "price_usd": "319.43",
                    "price_php": "18396.61",
                    "price_zar": "6122.23",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/hca-healthcare--big.svg"
                },
                "HD": {
                    "id": 155,
                    "market_id": 3,
                    "tradingview": "HD",
                    "name": "Home Depot",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "HD",
                    "market_name": "stock",
                    "price_aud": "521.46",
                    "price_brl": "1739.27",
                    "price_cad": "463.89",
                    "price_eur": "317.16",
                    "price_gbp": "272.55",
                    "price_usd": "339",
                    "price_php": "19523.69",
                    "price_zar": "6497.31",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/home-depot--big.svg"
                },
                "HON": {
                    "id": 187,
                    "market_id": 3,
                    "tradingview": "HON",
                    "name": "Honeywell",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "HON",
                    "market_name": "stock",
                    "price_aud": "301.49",
                    "price_brl": "1005.60",
                    "price_cad": "268.21",
                    "price_eur": "183.37",
                    "price_gbp": "157.58",
                    "price_usd": "196",
                    "price_php": "11288.03",
                    "price_zar": "3756.56",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/honeywell--big.svg"
                },
                "HUM": {
                    "id": 274,
                    "market_id": 3,
                    "tradingview": "HUM",
                    "name": "Humana",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "HUM",
                    "market_name": "stock",
                    "price_aud": "523.00",
                    "price_brl": "1744.40",
                    "price_cad": "465.26",
                    "price_eur": "318.10",
                    "price_gbp": "273.35",
                    "price_usd": "340",
                    "price_php": "19581.28",
                    "price_zar": "6516.47",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/humana--big.svg"
                },
                "IBM": {
                    "id": 198,
                    "market_id": 3,
                    "tradingview": "IBM",
                    "name": "International Business Machines",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "IBM",
                    "market_name": "stock",
                    "price_aud": "282.65",
                    "price_brl": "942.75",
                    "price_cad": "251.44",
                    "price_eur": "171.91",
                    "price_gbp": "147.73",
                    "price_usd": "183.75",
                    "price_php": "10582.53",
                    "price_zar": "3521.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/international-bus-mach--big.svg"
                },
                "ICE": {
                    "id": 258,
                    "market_id": 3,
                    "tradingview": "ICE",
                    "name": "Intercontinental Exchange",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ICE",
                    "market_name": "stock",
                    "price_aud": "203.55",
                    "price_brl": "678.93",
                    "price_cad": "181.08",
                    "price_eur": "123.80",
                    "price_gbp": "106.39",
                    "price_usd": "132.33",
                    "price_php": "7621.15",
                    "price_zar": "2536.25",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/intercontinental-exchange--big.svg"
                },
                "INTC": {
                    "id": 185,
                    "market_id": 3,
                    "tradingview": "INTC",
                    "name": "Intel",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "INTC",
                    "market_name": "stock",
                    "price_aud": "53.16",
                    "price_brl": "177.31",
                    "price_cad": "47.29",
                    "price_eur": "32.33",
                    "price_gbp": "27.79",
                    "price_usd": "34.56",
                    "price_php": "1990.38",
                    "price_zar": "662.38",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/intel--big.svg"
                },
                "INTU": {
                    "id": 190,
                    "market_id": 3,
                    "tradingview": "INTU",
                    "name": "Intuit",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "INTU",
                    "market_name": "stock",
                    "price_aud": "970.44",
                    "price_brl": "3236.79",
                    "price_cad": "863.30",
                    "price_eur": "590.24",
                    "price_gbp": "507.21",
                    "price_usd": "630.88",
                    "price_php": "36333.64",
                    "price_zar": "12091.51",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/intuit--big.svg"
                },
                "ISRG": {
                    "id": 202,
                    "market_id": 3,
                    "tradingview": "ISRG",
                    "name": "Intuitive Surgical",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ISRG",
                    "market_name": "stock",
                    "price_aud": "580.03",
                    "price_brl": "1934.65",
                    "price_cad": "516.00",
                    "price_eur": "352.79",
                    "price_gbp": "303.16",
                    "price_usd": "377.08",
                    "price_php": "21716.79",
                    "price_zar": "7227.15",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/intuitive-surgical--big.svg"
                },
                "ITW": {
                    "id": 237,
                    "market_id": 3,
                    "tradingview": "ITW",
                    "name": "Illinois Tool Works",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ITW",
                    "market_name": "stock",
                    "price_aud": "385.54",
                    "price_brl": "1285.93",
                    "price_cad": "342.98",
                    "price_eur": "234.49",
                    "price_gbp": "201.51",
                    "price_usd": "250.64",
                    "price_php": "14434.86",
                    "price_zar": "4803.79",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/illinois-tool-works--big.svg"
                },
                "JNJ": {
                    "id": 147,
                    "market_id": 3,
                    "tradingview": "JNJ",
                    "name": "Johnson & Johnson",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "JNJ",
                    "market_name": "stock",
                    "price_aud": "230.10",
                    "price_brl": "767.49",
                    "price_cad": "204.70",
                    "price_eur": "139.95",
                    "price_gbp": "120.27",
                    "price_usd": "149.59",
                    "price_php": "8615.19",
                    "price_zar": "2867.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/johnson-and-johnson--big.svg"
                },
                "JPM": {
                    "id": 150,
                    "market_id": 3,
                    "tradingview": "JPM",
                    "name": "JP Morgan",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "JPM",
                    "market_name": "stock",
                    "price_aud": "296.15",
                    "price_brl": "987.79",
                    "price_cad": "263.46",
                    "price_eur": "180.13",
                    "price_gbp": "154.79",
                    "price_usd": "192.53",
                    "price_php": "11088.19",
                    "price_zar": "3690.05",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/jpmorgan-chase--big.svg"
                },
                "KKR": {
                    "id": 260,
                    "market_id": 3,
                    "tradingview": "KKR",
                    "name": "KKR & Co.",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "KKR",
                    "market_name": "stock",
                    "price_aud": "151.52",
                    "price_brl": "505.36",
                    "price_cad": "134.79",
                    "price_eur": "92.15",
                    "price_gbp": "79.19",
                    "price_usd": "98.5",
                    "price_php": "5672.81",
                    "price_zar": "1887.86",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/kkr--big.svg"
                },
                "KLAC": {
                    "id": 253,
                    "market_id": 3,
                    "tradingview": "KLAC",
                    "name": "KLA Corporation",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "KLAC",
                    "market_name": "stock",
                    "price_aud": "1004.49",
                    "price_brl": "3350.38",
                    "price_cad": "893.59",
                    "price_eur": "610.95",
                    "price_gbp": "525.01",
                    "price_usd": "653.02",
                    "price_php": "37608.73",
                    "price_zar": "12515.85",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/kla-tencor--big.svg"
                },
                "KO": {
                    "id": 158,
                    "market_id": 3,
                    "tradingview": "KO",
                    "name": "Coca Cola",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "KO",
                    "market_name": "stock",
                    "price_aud": "93.15",
                    "price_brl": "310.71",
                    "price_cad": "82.87",
                    "price_eur": "56.66",
                    "price_gbp": "48.69",
                    "price_usd": "60.56",
                    "price_php": "3487.77",
                    "price_zar": "1160.70",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/coca-cola--big.svg"
                },
                "LLY": {
                    "id": 146,
                    "market_id": 3,
                    "tradingview": "LLY",
                    "name": "Eli Lilly",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "LLY",
                    "market_name": "stock",
                    "price_aud": "1149.04",
                    "price_brl": "3832.51",
                    "price_cad": "1022.18",
                    "price_eur": "698.87",
                    "price_gbp": "600.56",
                    "price_usd": "746.99",
                    "price_php": "43020.65",
                    "price_zar": "14316.89",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/eli-lilly--big.svg"
                },
                "LMT": {
                    "id": 201,
                    "market_id": 3,
                    "tradingview": "LMT",
                    "name": "Lockheed Martin",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "LMT",
                    "market_name": "stock",
                    "price_aud": "707.72",
                    "price_brl": "2360.54",
                    "price_cad": "629.59",
                    "price_eur": "430.45",
                    "price_gbp": "369.90",
                    "price_usd": "460.09",
                    "price_php": "26497.50",
                    "price_zar": "8818.13",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/lockheed-martin--big.svg"
                },
                "LOW": {
                    "id": 189,
                    "market_id": 3,
                    "tradingview": "LOW",
                    "name": "Lowe's Companies",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "LOW",
                    "market_name": "stock",
                    "price_aud": "359.93",
                    "price_brl": "1200.51",
                    "price_cad": "320.19",
                    "price_eur": "218.92",
                    "price_gbp": "188.12",
                    "price_usd": "233.99",
                    "price_php": "13475.95",
                    "price_zar": "4484.68",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/lowe-s--big.svg"
                },
                "LRCX": {
                    "id": 226,
                    "market_id": 3,
                    "tradingview": "LRCX",
                    "name": "Lam Research",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "LRCX",
                    "market_name": "stock",
                    "price_aud": "1373.63",
                    "price_brl": "4581.63",
                    "price_cad": "1221.98",
                    "price_eur": "835.47",
                    "price_gbp": "717.94",
                    "price_usd": "893",
                    "price_php": "51429.66",
                    "price_zar": "17115.33",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/lam-research--big.svg"
                },
                "MA": {
                    "id": 151,
                    "market_id": 3,
                    "tradingview": "MA",
                    "name": "Mastercard",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MA",
                    "market_name": "stock",
                    "price_aud": "716.84",
                    "price_brl": "2390.96",
                    "price_cad": "637.70",
                    "price_eur": "436.00",
                    "price_gbp": "374.67",
                    "price_usd": "466.02",
                    "price_php": "26839.02",
                    "price_zar": "8931.79",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/mastercard--big.svg"
                },
                "MAR": {
                    "id": 275,
                    "market_id": 3,
                    "tradingview": "MAR",
                    "name": "Marriott International",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MAR",
                    "market_name": "stock",
                    "price_aud": "370.51",
                    "price_brl": "1235.81",
                    "price_cad": "329.61",
                    "price_eur": "225.35",
                    "price_gbp": "193.65",
                    "price_usd": "240.87",
                    "price_php": "13872.19",
                    "price_zar": "4616.54",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/marriott--big.svg"
                },
                "MCD": {
                    "id": 165,
                    "market_id": 3,
                    "tradingview": "MCD",
                    "name": "McDonald's",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MCD",
                    "market_name": "stock",
                    "price_aud": "425.90",
                    "price_brl": "1420.56",
                    "price_cad": "378.88",
                    "price_eur": "259.04",
                    "price_gbp": "222.60",
                    "price_usd": "276.88",
                    "price_php": "15946.07",
                    "price_zar": "5306.71",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/mcdonalds--big.svg"
                },
                "MCK": {
                    "id": 272,
                    "market_id": 3,
                    "tradingview": "MCK",
                    "name": "Mckesson",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MCK",
                    "market_name": "stock",
                    "price_aud": "818.34",
                    "price_brl": "2729.48",
                    "price_cad": "727.99",
                    "price_eur": "497.73",
                    "price_gbp": "427.71",
                    "price_usd": "532",
                    "price_php": "30638.94",
                    "price_zar": "10196.37",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/mckesson--big.svg"
                },
                "MCO": {
                    "id": 257,
                    "market_id": 3,
                    "tradingview": "MCO",
                    "name": "Moody's",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MCO",
                    "market_name": "stock",
                    "price_aud": "587.78",
                    "price_brl": "1960.48",
                    "price_cad": "522.89",
                    "price_eur": "357.50",
                    "price_gbp": "307.21",
                    "price_usd": "382.115",
                    "price_php": "22006.77",
                    "price_zar": "7323.65",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/moodys--big.svg"
                },
                "MDLZ": {
                    "id": 212,
                    "market_id": 3,
                    "tradingview": "MDLZ",
                    "name": "Mondelez",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MDLZ",
                    "market_name": "stock",
                    "price_aud": "107.88",
                    "price_brl": "359.81",
                    "price_cad": "95.97",
                    "price_eur": "65.61",
                    "price_gbp": "56.38",
                    "price_usd": "70.13",
                    "price_php": "4038.93",
                    "price_zar": "1344.12",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/mondelez--big.svg"
                },
                "META": {
                    "id": 143,
                    "market_id": 3,
                    "tradingview": "META",
                    "name": "Meta Platforms",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "META",
                    "market_name": "stock",
                    "price_aud": "780.59",
                    "price_brl": "2603.57",
                    "price_cad": "694.41",
                    "price_eur": "474.77",
                    "price_gbp": "407.98",
                    "price_usd": "507.46",
                    "price_php": "29225.64",
                    "price_zar": "9726.03",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/meta-platforms--big.svg"
                },
                "MMC": {
                    "id": 218,
                    "market_id": 3,
                    "tradingview": "MMC",
                    "name": "Marsh & McLennan Companies",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MMC",
                    "market_name": "stock",
                    "price_aud": "310.28",
                    "price_brl": "1034.89",
                    "price_cad": "276.02",
                    "price_eur": "188.72",
                    "price_gbp": "162.17",
                    "price_usd": "201.71",
                    "price_php": "11616.88",
                    "price_zar": "3865.99",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/marsh-and-mclennan--big.svg"
                },
                "MMM": {
                    "id": 277,
                    "market_id": 3,
                    "tradingview": "MMM",
                    "name": "3m",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MMM",
                    "market_name": "stock",
                    "price_aud": "143.33",
                    "price_brl": "478.07",
                    "price_cad": "127.51",
                    "price_eur": "87.18",
                    "price_gbp": "74.91",
                    "price_usd": "93.18",
                    "price_php": "5366.42",
                    "price_zar": "1785.90",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/3m--big.svg"
                },
                "MNST": {
                    "id": 261,
                    "market_id": 3,
                    "tradingview": "MNST",
                    "name": "Monster Beverage",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MNST",
                    "market_name": "stock",
                    "price_aud": "82.42",
                    "price_brl": "274.90",
                    "price_cad": "73.32",
                    "price_eur": "50.13",
                    "price_gbp": "43.08",
                    "price_usd": "53.58",
                    "price_php": "3085.78",
                    "price_zar": "1026.92",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/monster-beverage--big.svg"
                },
                "MO": {
                    "id": 229,
                    "market_id": 3,
                    "tradingview": "MO",
                    "name": "Altria",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MO",
                    "market_name": "stock",
                    "price_aud": "66.01",
                    "price_brl": "220.15",
                    "price_cad": "58.72",
                    "price_eur": "40.15",
                    "price_gbp": "34.50",
                    "price_usd": "42.91",
                    "price_php": "2471.27",
                    "price_zar": "822.42",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/altria--big.svg"
                },
                "MRK": {
                    "id": 157,
                    "market_id": 3,
                    "tradingview": "MRK",
                    "name": "Merck",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MRK",
                    "market_name": "stock",
                    "price_aud": "195.43",
                    "price_brl": "651.84",
                    "price_cad": "173.86",
                    "price_eur": "118.86",
                    "price_gbp": "102.14",
                    "price_usd": "127.05",
                    "price_php": "7317.06",
                    "price_zar": "2435.05",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/merck--big.svg"
                },
                "MS": {
                    "id": 184,
                    "market_id": 3,
                    "tradingview": "MS",
                    "name": "Morgan Stanley",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MS",
                    "market_name": "stock",
                    "price_aud": "143.92",
                    "price_brl": "480.02",
                    "price_cad": "128.03",
                    "price_eur": "87.53",
                    "price_gbp": "75.22",
                    "price_usd": "93.56",
                    "price_php": "5388.31",
                    "price_zar": "1793.18",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/morgan-stanley--big.svg"
                },
                "MSFT": {
                    "id": 138,
                    "market_id": 3,
                    "tradingview": "MSFT",
                    "name": "Microsoft",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MSFT",
                    "market_name": "stock",
                    "price_aud": "630.27",
                    "price_brl": "2102.21",
                    "price_cad": "560.69",
                    "price_eur": "383.34",
                    "price_gbp": "329.42",
                    "price_usd": "409.74",
                    "price_php": "23597.75",
                    "price_zar": "7853.12",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/microsoft--big.svg"
                },
                "MU": {
                    "id": 240,
                    "market_id": 3,
                    "tradingview": "MU",
                    "name": "Micron Technology",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MU",
                    "market_name": "stock",
                    "price_aud": "176.28",
                    "price_brl": "587.97",
                    "price_cad": "156.82",
                    "price_eur": "107.22",
                    "price_gbp": "92.13",
                    "price_usd": "114.6",
                    "price_php": "6600.04",
                    "price_zar": "2196.44",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/micron-technology--big.svg"
                },
                "MULN": {
                    "id": 279,
                    "market_id": 3,
                    "tradingview": "MULN",
                    "name": "Mullen Automotive",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "MULN",
                    "market_name": "stock",
                    "price_aud": "4.12",
                    "price_brl": "13.75",
                    "price_cad": "3.67",
                    "price_eur": "2.51",
                    "price_gbp": "2.15",
                    "price_usd": "2.68",
                    "price_php": "154.35",
                    "price_zar": "51.37",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/mullen-automotive--big.svg"
                },
                "NEE": {
                    "id": 180,
                    "market_id": 3,
                    "tradingview": "NEE",
                    "name": "Nextera Energy",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "NEE",
                    "market_name": "stock",
                    "price_aud": "102.29",
                    "price_brl": "341.18",
                    "price_cad": "91.00",
                    "price_eur": "62.22",
                    "price_gbp": "53.46",
                    "price_usd": "66.5",
                    "price_php": "3829.87",
                    "price_zar": "1274.55",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/nextera-energy--big.svg"
                },
                "NFLX": {
                    "id": 169,
                    "market_id": 3,
                    "tradingview": "NFLX",
                    "name": "Netflix",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "NFLX",
                    "market_name": "stock",
                    "price_aud": "885.28",
                    "price_brl": "2952.76",
                    "price_cad": "787.54",
                    "price_eur": "538.44",
                    "price_gbp": "462.70",
                    "price_usd": "575.52",
                    "price_php": "33145.35",
                    "price_zar": "11030.47",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/netflix--big.svg"
                },
                "NKE": {
                    "id": 172,
                    "market_id": 3,
                    "tradingview": "NKE",
                    "name": "Nike",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "NKE",
                    "market_name": "stock",
                    "price_aud": "145.04",
                    "price_brl": "483.76",
                    "price_cad": "129.03",
                    "price_eur": "88.22",
                    "price_gbp": "75.81",
                    "price_usd": "94.29",
                    "price_php": "5430.35",
                    "price_zar": "1807.17",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/nike--big.svg"
                },
                "NLST": {
                    "id": 278,
                    "market_id": 3,
                    "tradingview": "NLST",
                    "name": "Netlist Inc",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "NLST",
                    "market_name": "stock",
                    "price_aud": "2.18",
                    "price_brl": "7.29",
                    "price_cad": "1.94",
                    "price_eur": "1.33",
                    "price_gbp": "1.14",
                    "price_usd": "1.42",
                    "price_php": "81.78",
                    "price_zar": "27.22",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/netlist--big.svg"
                },
                "NOC": {
                    "id": 247,
                    "market_id": 3,
                    "tradingview": "NOC",
                    "name": "Northrop Grumman",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "NOC",
                    "market_name": "stock",
                    "price_aud": "730.16",
                    "price_brl": "2435.39",
                    "price_cad": "649.55",
                    "price_eur": "444.10",
                    "price_gbp": "381.63",
                    "price_usd": "474.68",
                    "price_php": "27337.77",
                    "price_zar": "9097.76",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/northrop-grumman--big.svg"
                },
                "NOW": {
                    "id": 207,
                    "market_id": 3,
                    "tradingview": "NOW",
                    "name": "ServiceNow",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "NOW",
                    "market_name": "stock",
                    "price_aud": "1146.29",
                    "price_brl": "3823.32",
                    "price_cad": "1019.73",
                    "price_eur": "697.19",
                    "price_gbp": "599.12",
                    "price_usd": "745.2",
                    "price_php": "42917.56",
                    "price_zar": "14282.58",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/servicenow--big.svg"
                },
                "NVAX": {
                    "id": 280,
                    "market_id": 3,
                    "tradingview": "NVAX",
                    "name": "Novavax, Inc.",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "NVAX",
                    "market_name": "stock",
                    "price_aud": "6.45",
                    "price_brl": "21.50",
                    "price_cad": "5.73",
                    "price_eur": "3.92",
                    "price_gbp": "3.37",
                    "price_usd": "4.19",
                    "price_php": "241.31",
                    "price_zar": "80.31",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/novavax--big.svg"
                },
                "NVDA": {
                    "id": 141,
                    "market_id": 3,
                    "tradingview": "NVDA",
                    "name": "Nvidia",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "NVDA",
                    "market_name": "stock",
                    "price_aud": "1294.42",
                    "price_brl": "4317.40",
                    "price_cad": "1151.51",
                    "price_eur": "787.29",
                    "price_gbp": "676.54",
                    "price_usd": "841.5",
                    "price_php": "48463.67",
                    "price_zar": "16128.27",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/nvidia--big.svg"
                },
                "ORCL": {
                    "id": 154,
                    "market_id": 3,
                    "tradingview": "ORCL",
                    "name": "Oracle",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ORCL",
                    "market_name": "stock",
                    "price_aud": "177.51",
                    "price_brl": "592.07",
                    "price_cad": "157.91",
                    "price_eur": "107.97",
                    "price_gbp": "92.78",
                    "price_usd": "115.4",
                    "price_php": "6646.12",
                    "price_zar": "2211.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/oracle--big.svg"
                },
                "ORLY": {
                    "id": 268,
                    "market_id": 3,
                    "tradingview": "ORLY",
                    "name": "O'Reilly Automotive",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ORLY",
                    "market_name": "stock",
                    "price_aud": "1680.28",
                    "price_brl": "5604.41",
                    "price_cad": "1494.77",
                    "price_eur": "1021.98",
                    "price_gbp": "878.21",
                    "price_usd": "1092.35",
                    "price_php": "62910.62",
                    "price_zar": "20936.09",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/o-reilly-auto--big.svg"
                },
                "PANW": {
                    "id": 235,
                    "market_id": 3,
                    "tradingview": "PANW",
                    "name": "Palo Alto Networks",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "PANW",
                    "market_name": "stock",
                    "price_aud": "452.62",
                    "price_brl": "1509.68",
                    "price_cad": "402.65",
                    "price_eur": "275.29",
                    "price_gbp": "236.57",
                    "price_usd": "294.25",
                    "price_php": "16946.45",
                    "price_zar": "5639.62",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/palo-alto-networks--big.svg"
                },
                "PEP": {
                    "id": 159,
                    "market_id": 3,
                    "tradingview": "PEP",
                    "name": "Pepsico",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "PEP",
                    "market_name": "stock",
                    "price_aud": "262.42",
                    "price_brl": "875.28",
                    "price_cad": "233.45",
                    "price_eur": "159.61",
                    "price_gbp": "137.16",
                    "price_usd": "170.6",
                    "price_php": "9825.20",
                    "price_zar": "3269.74",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/pepsico--big.svg"
                },
                "PFE": {
                    "id": 164,
                    "market_id": 3,
                    "tradingview": "PFE",
                    "name": "Pfizer",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "PFE",
                    "market_name": "stock",
                    "price_aud": "40.55",
                    "price_brl": "135.24",
                    "price_cad": "36.07",
                    "price_eur": "24.66",
                    "price_gbp": "21.19",
                    "price_usd": "26.36",
                    "price_php": "1518.13",
                    "price_zar": "505.22",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/pfizer--big.svg"
                },
                "PG": {
                    "id": 152,
                    "market_id": 3,
                    "tradingview": "PG",
                    "name": "Procter and Gamble",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "PG",
                    "market_name": "stock",
                    "price_aud": "248.41",
                    "price_brl": "828.54",
                    "price_cad": "220.98",
                    "price_eur": "151.09",
                    "price_gbp": "129.83",
                    "price_usd": "161.49",
                    "price_php": "9300.53",
                    "price_zar": "3095.13",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/procter-and-gamble--big.svg"
                },
                "PGR": {
                    "id": 232,
                    "market_id": 3,
                    "tradingview": "PGR",
                    "name": "Progressive",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "PGR",
                    "market_name": "stock",
                    "price_aud": "329.21",
                    "price_brl": "1098.05",
                    "price_cad": "292.87",
                    "price_eur": "200.23",
                    "price_gbp": "172.07",
                    "price_usd": "214.02",
                    "price_php": "12325.84",
                    "price_zar": "4101.93",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/progressive-ohio--big.svg"
                },
                "PLD": {
                    "id": 208,
                    "market_id": 3,
                    "tradingview": "PLD",
                    "name": "Prologis",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "PLD",
                    "market_name": "stock",
                    "price_aud": "161.51",
                    "price_brl": "538.71",
                    "price_cad": "143.68",
                    "price_eur": "98.24",
                    "price_gbp": "84.42",
                    "price_usd": "105",
                    "price_php": "6047.16",
                    "price_zar": "2012.44",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/prologis--big.svg"
                },
                "PM": {
                    "id": 181,
                    "market_id": 3,
                    "tradingview": "PM",
                    "name": "Philip Morris",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "PM",
                    "market_name": "stock",
                    "price_aud": "150.21",
                    "price_brl": "501.00",
                    "price_cad": "133.62",
                    "price_eur": "91.36",
                    "price_gbp": "78.51",
                    "price_usd": "97.65",
                    "price_php": "5623.86",
                    "price_zar": "1871.57",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/philip-morris--big.svg"
                },
                "PYPL": {
                    "id": 238,
                    "market_id": 3,
                    "tradingview": "PYPL",
                    "name": "PayPal",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "PYPL",
                    "market_name": "stock",
                    "price_aud": "99.22",
                    "price_brl": "330.92",
                    "price_cad": "88.26",
                    "price_eur": "60.34",
                    "price_gbp": "51.86",
                    "price_usd": "64.5",
                    "price_php": "3714.68",
                    "price_zar": "1236.21",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/paypal--big.svg"
                },
                "QCOM": {
                    "id": 188,
                    "market_id": 3,
                    "tradingview": "QCOM",
                    "name": "Qualcomm",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "QCOM",
                    "market_name": "stock",
                    "price_aud": "250.32",
                    "price_brl": "834.90",
                    "price_cad": "222.68",
                    "price_eur": "152.25",
                    "price_gbp": "130.83",
                    "price_usd": "162.73",
                    "price_php": "9371.95",
                    "price_zar": "3118.90",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/qualcomm--big.svg"
                },
                "RBLX": {
                    "id": 281,
                    "market_id": 3,
                    "tradingview": "RBLX",
                    "name": "Robolox Corporation",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "RBLX",
                    "market_name": "stock",
                    "price_aud": "56.16",
                    "price_brl": "187.32",
                    "price_cad": "49.96",
                    "price_eur": "34.16",
                    "price_gbp": "29.35",
                    "price_usd": "36.51",
                    "price_php": "2102.68",
                    "price_zar": "699.75",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/roblox--big.svg"
                },
                "REGN": {
                    "id": 225,
                    "market_id": 3,
                    "tradingview": "REGN",
                    "name": "Regeneron Pharmaceuticals",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "REGN",
                    "market_name": "stock",
                    "price_aud": "1395.66",
                    "price_brl": "4655.10",
                    "price_cad": "1241.58",
                    "price_eur": "848.87",
                    "price_gbp": "729.46",
                    "price_usd": "907.32",
                    "price_php": "52254.37",
                    "price_zar": "17389.79",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/regeneron-pharmaceuticals--big.svg"
                },
                "RTX": {
                    "id": 183,
                    "market_id": 3,
                    "tradingview": "RTX",
                    "name": "Raytheon",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "RTX",
                    "market_name": "stock",
                    "price_aud": "157.36",
                    "price_brl": "524.86",
                    "price_cad": "139.99",
                    "price_eur": "95.71",
                    "price_gbp": "82.25",
                    "price_usd": "102.3",
                    "price_php": "5891.66",
                    "price_zar": "1960.69",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/raytheon--big.svg"
                },
                "SBUX": {
                    "id": 204,
                    "market_id": 3,
                    "tradingview": "SBUX",
                    "name": "Starbucks",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SBUX",
                    "market_name": "stock",
                    "price_aud": "135.35",
                    "price_brl": "451.44",
                    "price_cad": "120.41",
                    "price_eur": "82.32",
                    "price_gbp": "70.74",
                    "price_usd": "87.99",
                    "price_php": "5067.52",
                    "price_zar": "1686.43",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/starbucks--big.svg"
                },
                "SCCO": {
                    "id": 269,
                    "market_id": 3,
                    "tradingview": "SCCO",
                    "name": "Southern Copper",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SCCO",
                    "market_name": "stock",
                    "price_aud": "168.45",
                    "price_brl": "561.85",
                    "price_cad": "149.85",
                    "price_eur": "102.45",
                    "price_gbp": "88.04",
                    "price_usd": "109.51",
                    "price_php": "6306.90",
                    "price_zar": "2098.88",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/southern-copper--big.svg"
                },
                "SCHW": {
                    "id": 214,
                    "market_id": 3,
                    "tradingview": "SCHW",
                    "name": "Charles Schwab",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SCHW",
                    "market_name": "stock",
                    "price_aud": "115.71",
                    "price_brl": "385.92",
                    "price_cad": "102.93",
                    "price_eur": "70.37",
                    "price_gbp": "60.47",
                    "price_usd": "75.22",
                    "price_php": "4332.07",
                    "price_zar": "1441.67",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schwab--big.svg"
                },
                "SHW": {
                    "id": 248,
                    "market_id": 3,
                    "tradingview": "SHW",
                    "name": "Sherwin Williams",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SHW",
                    "market_name": "stock",
                    "price_aud": "465.10",
                    "price_brl": "1551.29",
                    "price_cad": "413.75",
                    "price_eur": "282.88",
                    "price_gbp": "243.09",
                    "price_usd": "302.36",
                    "price_php": "17413.52",
                    "price_zar": "5795.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/sherwin-williams--big.svg"
                },
                "SLB": {
                    "id": 244,
                    "market_id": 3,
                    "tradingview": "SLB",
                    "name": "Schlumberger",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SLB",
                    "market_name": "stock",
                    "price_aud": "75.99",
                    "price_brl": "253.45",
                    "price_cad": "67.60",
                    "price_eur": "46.22",
                    "price_gbp": "39.72",
                    "price_usd": "49.4",
                    "price_php": "2845.04",
                    "price_zar": "946.81",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/schlumberger--big.svg"
                },
                "SNOW": {
                    "id": 262,
                    "market_id": 3,
                    "tradingview": "SNOW",
                    "name": "Snowflake",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SNOW",
                    "market_name": "stock",
                    "price_aud": "233.98",
                    "price_brl": "780.42",
                    "price_cad": "208.15",
                    "price_eur": "142.31",
                    "price_gbp": "122.29",
                    "price_usd": "152.11",
                    "price_php": "8760.32",
                    "price_zar": "2915.36",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/snowflake--big.svg"
                },
                "SNPS": {
                    "id": 254,
                    "market_id": 3,
                    "tradingview": "SNPS",
                    "name": "Synopsys",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SNPS",
                    "market_name": "stock",
                    "price_aud": "811.46",
                    "price_brl": "2706.55",
                    "price_cad": "721.87",
                    "price_eur": "493.54",
                    "price_gbp": "424.12",
                    "price_usd": "527.53",
                    "price_php": "30381.51",
                    "price_zar": "10110.69",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/synopsys--big.svg"
                },
                "SO": {
                    "id": 231,
                    "market_id": 3,
                    "tradingview": "SO",
                    "name": "Southern Company",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SO",
                    "market_name": "stock",
                    "price_aud": "113.04",
                    "price_brl": "377.05",
                    "price_cad": "100.56",
                    "price_eur": "68.76",
                    "price_gbp": "59.08",
                    "price_usd": "73.49",
                    "price_php": "4232.44",
                    "price_zar": "1408.52",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/southern--big.svg"
                },
                "SPGI": {
                    "id": 192,
                    "market_id": 3,
                    "tradingview": "SPGI",
                    "name": "S&P Globale",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SPGI",
                    "market_name": "stock",
                    "price_aud": "630.69",
                    "price_brl": "2103.60",
                    "price_cad": "561.06",
                    "price_eur": "383.60",
                    "price_gbp": "329.64",
                    "price_usd": "410.01",
                    "price_php": "23613.30",
                    "price_zar": "7858.29",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/s-and-p-global--big.svg"
                },
                "SYK": {
                    "id": 205,
                    "market_id": 3,
                    "tradingview": "SYK",
                    "name": "Stryker Corporation",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "SYK",
                    "market_name": "stock",
                    "price_aud": "515.17",
                    "price_brl": "1718.29",
                    "price_cad": "458.29",
                    "price_eur": "313.33",
                    "price_gbp": "269.26",
                    "price_usd": "334.91",
                    "price_php": "19288.14",
                    "price_zar": "6418.92",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/stryker--big.svg"
                },
                "T": {
                    "id": 206,
                    "market_id": 3,
                    "tradingview": "T",
                    "name": "At&t",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "T",
                    "market_name": "stock",
                    "price_aud": "25.93",
                    "price_brl": "86.50",
                    "price_cad": "23.07",
                    "price_eur": "15.77",
                    "price_gbp": "13.55",
                    "price_usd": "16.86",
                    "price_php": "971.00",
                    "price_zar": "323.14",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/at-and-t--big.svg"
                },
                "TGT": {
                    "id": 256,
                    "market_id": 3,
                    "tradingview": "TGT",
                    "name": "Target",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "TGT",
                    "market_name": "stock",
                    "price_aud": "256.11",
                    "price_brl": "854.24",
                    "price_cad": "227.84",
                    "price_eur": "155.77",
                    "price_gbp": "133.86",
                    "price_usd": "166.5",
                    "price_php": "9589.07",
                    "price_zar": "3191.16",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/target--big.svg"
                },
                "TJX": {
                    "id": 215,
                    "market_id": 3,
                    "tradingview": "TJX",
                    "name": "TJX Companies",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "TJX",
                    "market_name": "stock",
                    "price_aud": "145.36",
                    "price_brl": "484.84",
                    "price_cad": "129.31",
                    "price_eur": "88.41",
                    "price_gbp": "75.98",
                    "price_usd": "94.5",
                    "price_php": "5442.44",
                    "price_zar": "1811.20",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/tjx-cos--big.svg"
                },
                "TMO": {
                    "id": 168,
                    "market_id": 3,
                    "tradingview": "TMO",
                    "name": "Thermo Fisher Scientific",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "TMO",
                    "market_name": "stock",
                    "price_aud": "902.94",
                    "price_brl": "3011.66",
                    "price_cad": "803.25",
                    "price_eur": "549.18",
                    "price_gbp": "471.93",
                    "price_usd": "587",
                    "price_php": "33806.50",
                    "price_zar": "11250.50",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/thermo-fisher-scientific--big.svg"
                },
                "TMUS": {
                    "id": 175,
                    "market_id": 3,
                    "tradingview": "TMUS",
                    "name": "T-Mobile",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "TMUS",
                    "market_name": "stock",
                    "price_aud": "251.13",
                    "price_brl": "837.62",
                    "price_cad": "223.41",
                    "price_eur": "152.74",
                    "price_gbp": "131.26",
                    "price_usd": "163.26",
                    "price_php": "9402.47",
                    "price_zar": "3129.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/t-mobile--big.svg"
                },
                "TSLA": {
                    "id": 142,
                    "market_id": 3,
                    "tradingview": "TSLA",
                    "name": "Tesla",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "TSLA",
                    "market_name": "stock",
                    "price_aud": "249.21",
                    "price_brl": "831.21",
                    "price_cad": "221.69",
                    "price_eur": "151.57",
                    "price_gbp": "130.25",
                    "price_usd": "162.01",
                    "price_php": "9330.48",
                    "price_zar": "3105.10",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/tesla--big.svg"
                },
                "TXN": {
                    "id": 177,
                    "market_id": 3,
                    "tradingview": "TXN",
                    "name": "Texas Instruments",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "TXN",
                    "market_name": "stock",
                    "price_aud": "273.80",
                    "price_brl": "913.25",
                    "price_cad": "243.58",
                    "price_eur": "166.53",
                    "price_gbp": "143.11",
                    "price_usd": "178",
                    "price_php": "10251.38",
                    "price_zar": "3411.57",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/texas-instruments--big.svg"
                },
                "UBER": {
                    "id": 220,
                    "market_id": 3,
                    "tradingview": "UBER",
                    "name": "Uber",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "UBER",
                    "market_name": "stock",
                    "price_aud": "107.43",
                    "price_brl": "358.32",
                    "price_cad": "95.57",
                    "price_eur": "65.34",
                    "price_gbp": "56.15",
                    "price_usd": "69.84",
                    "price_php": "4022.23",
                    "price_zar": "1338.56",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/uber--big.svg"
                },
                "UNH": {
                    "id": 145,
                    "market_id": 3,
                    "tradingview": "UNH",
                    "name": "United Health Group",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "UNH",
                    "market_name": "stock",
                    "price_aud": "755.07",
                    "price_brl": "2518.46",
                    "price_cad": "671.71",
                    "price_eur": "459.25",
                    "price_gbp": "394.64",
                    "price_usd": "490.87",
                    "price_php": "28270.19",
                    "price_zar": "9408.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/unitedhealth--big.svg"
                },
                "UNP": {
                    "id": 194,
                    "market_id": 3,
                    "tradingview": "UNP",
                    "name": "Union Pacific",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "UNP",
                    "market_name": "stock",
                    "price_aud": "363.47",
                    "price_brl": "1212.31",
                    "price_cad": "323.34",
                    "price_eur": "221.07",
                    "price_gbp": "189.97",
                    "price_usd": "236.29",
                    "price_php": "13608.41",
                    "price_zar": "4528.76",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/union-pacific--big.svg"
                },
                "UPS": {
                    "id": 182,
                    "market_id": 3,
                    "tradingview": "UPS",
                    "name": "United Parcel Service",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "UPS",
                    "market_name": "stock",
                    "price_aud": "228.20",
                    "price_brl": "761.12",
                    "price_cad": "203.00",
                    "price_eur": "138.79",
                    "price_gbp": "119.27",
                    "price_usd": "148.35",
                    "price_php": "8543.77",
                    "price_zar": "2843.29",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/united-parcel--big.svg"
                },
                "V": {
                    "id": 144,
                    "market_id": 3,
                    "tradingview": "V",
                    "name": "Visa",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "V",
                    "market_name": "stock",
                    "price_aud": "433.79",
                    "price_brl": "1446.88",
                    "price_cad": "385.90",
                    "price_eur": "263.84",
                    "price_gbp": "226.73",
                    "price_usd": "282.01",
                    "price_php": "16241.52",
                    "price_zar": "5405.03",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/visa--big.svg"
                },
                "VRTX": {
                    "id": 222,
                    "market_id": 3,
                    "tradingview": "VRTX",
                    "name": "Vertex Pharmaceutical",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "VRTX",
                    "market_name": "stock",
                    "price_aud": "622.84",
                    "price_brl": "2077.43",
                    "price_cad": "554.08",
                    "price_eur": "378.82",
                    "price_gbp": "325.53",
                    "price_usd": "404.91",
                    "price_php": "23319.58",
                    "price_zar": "7760.55",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/vertex-pharmaceutical--big.svg"
                },
                "VZ": {
                    "id": 178,
                    "market_id": 3,
                    "tradingview": "VZ",
                    "name": "Verizon",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "VZ",
                    "market_name": "stock",
                    "price_aud": "61.24",
                    "price_brl": "204.25",
                    "price_cad": "54.48",
                    "price_eur": "37.25",
                    "price_gbp": "32.01",
                    "price_usd": "39.81",
                    "price_php": "2292.74",
                    "price_zar": "763.00",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/verizon--big.svg"
                },
                "WDAY": {
                    "id": 264,
                    "market_id": 3,
                    "tradingview": "WDAY",
                    "name": "Workday",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "WDAY",
                    "market_name": "stock",
                    "price_aud": "395.46",
                    "price_brl": "1319.03",
                    "price_cad": "351.80",
                    "price_eur": "240.53",
                    "price_gbp": "206.69",
                    "price_usd": "257.09",
                    "price_php": "14806.33",
                    "price_zar": "4927.41",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/workday--big.svg"
                },
                "WFC": {
                    "id": 179,
                    "market_id": 3,
                    "tradingview": "WFC",
                    "name": "Wells Fargo",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "WFC",
                    "market_name": "stock",
                    "price_aud": "93.37",
                    "price_brl": "311.43",
                    "price_cad": "83.06",
                    "price_eur": "56.79",
                    "price_gbp": "48.80",
                    "price_usd": "60.7",
                    "price_php": "3495.83",
                    "price_zar": "1163.38",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/wells-fargo--big.svg"
                },
                "WM": {
                    "id": 245,
                    "market_id": 3,
                    "tradingview": "WM",
                    "name": "Waste Management",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "WM",
                    "market_name": "stock",
                    "price_aud": "321.15",
                    "price_brl": "1071.17",
                    "price_cad": "285.69",
                    "price_eur": "195.33",
                    "price_gbp": "167.85",
                    "price_usd": "208.78",
                    "price_php": "12024.06",
                    "price_zar": "4001.50",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/waste-management--big.svg"
                },
                "WMT": {
                    "id": 149,
                    "market_id": 3,
                    "tradingview": "WMT",
                    "name": "Walmart",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "WMT",
                    "market_name": "stock",
                    "price_aud": "90.85",
                    "price_brl": "303.01",
                    "price_cad": "80.82",
                    "price_eur": "55.26",
                    "price_gbp": "47.48",
                    "price_usd": "59.06",
                    "price_php": "3401.38",
                    "price_zar": "1131.95",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/walmart--big.svg"
                },
                "XOM": {
                    "id": 148,
                    "market_id": 3,
                    "tradingview": "XOM",
                    "name": "Exxon",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "XOM",
                    "market_name": "stock",
                    "price_aud": "186.51",
                    "price_brl": "622.09",
                    "price_cad": "165.92",
                    "price_eur": "113.44",
                    "price_gbp": "97.48",
                    "price_usd": "121.25",
                    "price_php": "6983.03",
                    "price_zar": "2323.89",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/exxon--big.svg"
                },
                "ZTS": {
                    "id": 233,
                    "market_id": 3,
                    "tradingview": "ZTS",
                    "name": "Zoetis",
                    "polygon": "",
                    "listed": "yes",
                    "type": "stock",
                    "firebase": "ZTS",
                    "market_name": "stock",
                    "price_aud": "229.58",
                    "price_brl": "765.74",
                    "price_cad": "204.23",
                    "price_eur": "139.63",
                    "price_gbp": "119.99",
                    "price_usd": "149.25",
                    "price_php": "8595.61",
                    "price_zar": "2860.54",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/zoetis--big.svg"
                }
            }
        },
        "crypto": {
            "current_page": 1,
            "data": [
                {
                    "id": 34,
                    "market_id": 4,
                    "tradingview": "AAVEUSD",
                    "name": "Aave",
                    "polygon": "AAVE-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "AAVEUSD",
                    "market_name": "crypto",
                    "price_aud": "148.61",
                    "price_brl": "495.67",
                    "price_cad": "132.20",
                    "price_eur": "90.39",
                    "price_gbp": "77.67",
                    "price_usd": "96.61",
                    "price_php": "5563.96",
                    "price_zar": "1851.64",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCAAVE--big.svg"
                },
                {
                    "id": 35,
                    "market_id": 4,
                    "tradingview": "ACHUSD",
                    "name": "Alchemy Pay",
                    "polygon": "ACH-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ACHUSD",
                    "market_name": "crypto",
                    "price_aud": "0.05",
                    "price_brl": "0.18",
                    "price_cad": "0.05",
                    "price_eur": "0.03",
                    "price_gbp": "0.03",
                    "price_usd": "0.03553",
                    "price_php": "2.05",
                    "price_zar": "0.68",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCACH--big.svg"
                },
                {
                    "id": 36,
                    "market_id": 4,
                    "tradingview": "ADAUSD",
                    "name": "Cardano",
                    "polygon": "ADA-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ADAUSD",
                    "market_name": "crypto",
                    "price_aud": "0.76",
                    "price_brl": "2.54",
                    "price_cad": "0.68",
                    "price_eur": "0.46",
                    "price_gbp": "0.40",
                    "price_usd": "0.49604",
                    "price_php": "28.57",
                    "price_zar": "9.51",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCADA--big.svg"
                },
                {
                    "id": 37,
                    "market_id": 4,
                    "tradingview": "ALGOUSD",
                    "name": "Algorand",
                    "polygon": "ALGO-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ALGOUSD",
                    "market_name": "crypto",
                    "price_aud": "0.35",
                    "price_brl": "1.17",
                    "price_cad": "0.31",
                    "price_eur": "0.21",
                    "price_gbp": "0.18",
                    "price_usd": "0.22841",
                    "price_php": "13.15",
                    "price_zar": "4.38",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCALGO--big.svg"
                },
                {
                    "id": 38,
                    "market_id": 4,
                    "tradingview": "AMPUSD",
                    "name": "Amp",
                    "polygon": "AMP-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "AMPUSD",
                    "market_name": "crypto",
                    "price_aud": "0.01",
                    "price_brl": "0.04",
                    "price_cad": "0.01",
                    "price_eur": "0.01",
                    "price_gbp": "0.01",
                    "price_usd": "0.00788",
                    "price_php": "0.45",
                    "price_zar": "0.15",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCAMP--big.svg"
                },
                {
                    "id": 39,
                    "market_id": 4,
                    "tradingview": "ANKRUSD",
                    "name": "ANKR",
                    "polygon": "ANKR-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ANKRUSD",
                    "market_name": "crypto",
                    "price_aud": "0.08",
                    "price_brl": "0.28",
                    "price_cad": "0.07",
                    "price_eur": "0.05",
                    "price_gbp": "0.04",
                    "price_usd": "0.0541",
                    "price_php": "3.12",
                    "price_zar": "1.04",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCANKR--big.svg"
                },
                {
                    "id": 129,
                    "market_id": 4,
                    "tradingview": "ANTUSD",
                    "name": "Aragon",
                    "polygon": "ANT-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ANTUSD",
                    "market_name": "crypto",
                    "price_aud": "12.73",
                    "price_brl": "42.45",
                    "price_cad": "11.32",
                    "price_eur": "7.74",
                    "price_gbp": "6.65",
                    "price_usd": "8.2732",
                    "price_php": "476.47",
                    "price_zar": "158.56",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCANT--big.svg"
                },
                {
                    "id": 40,
                    "market_id": 4,
                    "tradingview": "APEUSD",
                    "name": "ApeCoin",
                    "polygon": "APE-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "APEUSD",
                    "market_name": "crypto",
                    "price_aud": "2.08",
                    "price_brl": "6.93",
                    "price_cad": "1.85",
                    "price_eur": "1.26",
                    "price_gbp": "1.09",
                    "price_usd": "1.3505",
                    "price_php": "77.78",
                    "price_zar": "25.88",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCAPE--big.svg"
                },
                {
                    "id": 41,
                    "market_id": 4,
                    "tradingview": "API3USD",
                    "name": "API3",
                    "polygon": "API3-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "API3USD",
                    "market_name": "crypto",
                    "price_aud": "4.08",
                    "price_brl": "13.60",
                    "price_cad": "3.63",
                    "price_eur": "2.48",
                    "price_gbp": "2.13",
                    "price_usd": "2.651",
                    "price_php": "152.68",
                    "price_zar": "50.81",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCAPI3--big.svg"
                },
                {
                    "id": 42,
                    "market_id": 4,
                    "tradingview": "ATOMUSD",
                    "name": "Cosmos",
                    "polygon": "ATOM-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ATOMUSD",
                    "market_name": "crypto",
                    "price_aud": "13.57",
                    "price_brl": "45.28",
                    "price_cad": "12.08",
                    "price_eur": "8.26",
                    "price_gbp": "7.09",
                    "price_usd": "8.8249",
                    "price_php": "508.24",
                    "price_zar": "169.14",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCATOM--big.svg"
                },
                {
                    "id": 43,
                    "market_id": 4,
                    "tradingview": "AVAXUSD",
                    "name": "Avalanche",
                    "polygon": "AVAX-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "AVAXUSD",
                    "market_name": "crypto",
                    "price_aud": "60.16",
                    "price_brl": "200.65",
                    "price_cad": "53.52",
                    "price_eur": "36.59",
                    "price_gbp": "31.44",
                    "price_usd": "39.109",
                    "price_php": "2252.37",
                    "price_zar": "749.57",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCAVAX--big.svg"
                },
                {
                    "id": 44,
                    "market_id": 4,
                    "tradingview": "AXSUSD",
                    "name": "Axie Infinity",
                    "polygon": "AXS-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "AXSUSD",
                    "market_name": "crypto",
                    "price_aud": "12.53",
                    "price_brl": "41.78",
                    "price_cad": "11.14",
                    "price_eur": "7.62",
                    "price_gbp": "6.55",
                    "price_usd": "8.143",
                    "price_php": "468.97",
                    "price_zar": "156.07",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCAXS--big.svg"
                },
                {
                    "id": 120,
                    "market_id": 4,
                    "tradingview": "BALUSD",
                    "name": "Balancer",
                    "polygon": "BAL-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BALUSD",
                    "market_name": "crypto",
                    "price_aud": "6.32",
                    "price_brl": "21.09",
                    "price_cad": "5.62",
                    "price_eur": "3.85",
                    "price_gbp": "3.30",
                    "price_usd": "4.11",
                    "price_php": "236.70",
                    "price_zar": "78.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBAL--big.svg"
                },
                {
                    "id": 128,
                    "market_id": 4,
                    "tradingview": "BANDUSD",
                    "name": "BAND",
                    "polygon": "BAND-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BANDUSD",
                    "market_name": "crypto",
                    "price_aud": "2.60",
                    "price_brl": "8.68",
                    "price_cad": "2.31",
                    "price_eur": "1.58",
                    "price_gbp": "1.36",
                    "price_usd": "1.691",
                    "price_php": "97.39",
                    "price_zar": "32.41",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBAND--big.svg"
                },
                {
                    "id": 45,
                    "market_id": 4,
                    "tradingview": "BATUSD",
                    "name": "Basic Attention Token",
                    "polygon": "BAT-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BATUSD",
                    "market_name": "crypto",
                    "price_aud": "0.43",
                    "price_brl": "1.44",
                    "price_cad": "0.38",
                    "price_eur": "0.26",
                    "price_gbp": "0.23",
                    "price_usd": "0.28122",
                    "price_php": "16.20",
                    "price_zar": "5.39",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBAT--big.svg"
                },
                {
                    "id": 136,
                    "market_id": 4,
                    "tradingview": "BCHUSD",
                    "name": "Bitcoin Cash",
                    "polygon": "BCH-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BCHUSD",
                    "market_name": "crypto",
                    "price_aud": "776.37",
                    "price_brl": "2589.52",
                    "price_cad": "690.66",
                    "price_eur": "472.20",
                    "price_gbp": "405.78",
                    "price_usd": "504.72",
                    "price_php": "29067.83",
                    "price_zar": "9673.51",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBCH--big.svg"
                },
                {
                    "id": 32,
                    "market_id": 4,
                    "tradingview": "BNBUSD",
                    "name": "Binance Coin",
                    "polygon": "BNB-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BNBUSD",
                    "market_name": "crypto",
                    "price_aud": "529.67",
                    "price_brl": "1766.67",
                    "price_cad": "471.20",
                    "price_eur": "322.16",
                    "price_gbp": "276.84",
                    "price_usd": "344.34",
                    "price_php": "19831.23",
                    "price_zar": "6599.65",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBNB--big.svg"
                },
                {
                    "id": 46,
                    "market_id": 4,
                    "tradingview": "BNTUSD",
                    "name": "Bancor",
                    "polygon": "BNT-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BNTUSD",
                    "market_name": "crypto",
                    "price_aud": "1.17",
                    "price_brl": "3.90",
                    "price_cad": "1.04",
                    "price_eur": "0.71",
                    "price_gbp": "0.61",
                    "price_usd": "0.761",
                    "price_php": "43.83",
                    "price_zar": "14.59",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBNT--big.svg"
                },
                {
                    "id": 47,
                    "market_id": 4,
                    "tradingview": "BOBAUSD",
                    "name": "BOBA",
                    "polygon": "BOBA-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BOBAUSD",
                    "market_name": "crypto",
                    "price_aud": "0.65",
                    "price_brl": "2.16",
                    "price_cad": "0.58",
                    "price_eur": "0.39",
                    "price_gbp": "0.34",
                    "price_usd": "0.42122",
                    "price_php": "24.26",
                    "price_zar": "8.07",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBOBA--big.svg"
                },
                {
                    "id": 48,
                    "market_id": 4,
                    "tradingview": "BONDUSD",
                    "name": "BarnBridge",
                    "polygon": "BOND-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BONDUSD",
                    "market_name": "crypto",
                    "price_aud": "4.91",
                    "price_brl": "16.39",
                    "price_cad": "4.37",
                    "price_eur": "2.99",
                    "price_gbp": "2.57",
                    "price_usd": "3.195",
                    "price_php": "184.01",
                    "price_zar": "61.24",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBOND--big.svg"
                },
                {
                    "id": 49,
                    "market_id": 4,
                    "tradingview": "BOSONUSD",
                    "name": "Boson Protocol",
                    "polygon": "BOSON-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BOSONUSD",
                    "market_name": "crypto",
                    "price_aud": "0.97",
                    "price_brl": "3.22",
                    "price_cad": "0.86",
                    "price_eur": "0.59",
                    "price_gbp": "0.51",
                    "price_usd": "0.62844",
                    "price_php": "36.19",
                    "price_zar": "12.04",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBOSON--big.svg"
                },
                {
                    "id": 30,
                    "market_id": 4,
                    "tradingview": "BTCUSD",
                    "name": "Bitcoin",
                    "polygon": "BTC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BTCUSD",
                    "market_name": "crypto",
                    "price_aud": "102135.06",
                    "price_brl": "340661.58",
                    "price_cad": "90859.08",
                    "price_eur": "62120.39",
                    "price_gbp": "53381.89",
                    "price_usd": "66398",
                    "price_php": "3823993.62",
                    "price_zar": "1272590.71",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBTC--big.svg"
                },
                {
                    "id": 50,
                    "market_id": 4,
                    "tradingview": "CHZUSD",
                    "name": "Chiliz",
                    "polygon": "CHZ-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "CHZUSD",
                    "market_name": "crypto",
                    "price_aud": "0.19",
                    "price_brl": "0.64",
                    "price_cad": "0.17",
                    "price_eur": "0.12",
                    "price_gbp": "0.10",
                    "price_usd": "0.1243",
                    "price_php": "7.16",
                    "price_zar": "2.38",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCCHZ--big.svg"
                },
                {
                    "id": 51,
                    "market_id": 4,
                    "tradingview": "COMPUSD",
                    "name": "Compound",
                    "polygon": "COMP-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "COMPUSD",
                    "market_name": "crypto",
                    "price_aud": "92.89",
                    "price_brl": "309.84",
                    "price_cad": "82.64",
                    "price_eur": "56.50",
                    "price_gbp": "48.55",
                    "price_usd": "60.39",
                    "price_php": "3477.98",
                    "price_zar": "1157.44",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCCOMP--big.svg"
                },
                {
                    "id": 52,
                    "market_id": 4,
                    "tradingview": "COTIUSD",
                    "name": "COTI",
                    "polygon": "COTI-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "COTIUSD",
                    "market_name": "crypto",
                    "price_aud": "0.21",
                    "price_brl": "0.69",
                    "price_cad": "0.18",
                    "price_eur": "0.13",
                    "price_gbp": "0.11",
                    "price_usd": "0.1342",
                    "price_php": "7.73",
                    "price_zar": "2.57",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCCOTI--big.svg"
                },
                {
                    "id": 53,
                    "market_id": 4,
                    "tradingview": "CRVUSD",
                    "name": "Curve DAO Token",
                    "polygon": "CRV-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "CRVUSD",
                    "market_name": "crypto",
                    "price_aud": "0.73",
                    "price_brl": "2.44",
                    "price_cad": "0.65",
                    "price_eur": "0.44",
                    "price_gbp": "0.38",
                    "price_usd": "0.475",
                    "price_php": "27.36",
                    "price_zar": "9.10",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCCRV--big.svg"
                },
                {
                    "id": 54,
                    "market_id": 4,
                    "tradingview": "CTSIUSD",
                    "name": "Cartesi",
                    "polygon": "CTSI-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "CTSIUSD",
                    "market_name": "crypto",
                    "price_aud": "0.34",
                    "price_brl": "1.14",
                    "price_cad": "0.30",
                    "price_eur": "0.21",
                    "price_gbp": "0.18",
                    "price_usd": "0.2223",
                    "price_php": "12.80",
                    "price_zar": "4.26",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCCTSI--big.svg"
                },
                {
                    "id": 55,
                    "market_id": 4,
                    "tradingview": "CVCUSD",
                    "name": "Civic",
                    "polygon": "CVC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "CVCUSD",
                    "market_name": "crypto",
                    "price_aud": "0.26",
                    "price_brl": "0.88",
                    "price_cad": "0.23",
                    "price_eur": "0.16",
                    "price_gbp": "0.14",
                    "price_usd": "0.1708",
                    "price_php": "9.84",
                    "price_zar": "3.27",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCCVC--big.svg"
                },
                {
                    "id": 56,
                    "market_id": 4,
                    "tradingview": "DASHUSD",
                    "name": "Dash",
                    "polygon": "DASH-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "DASHUSD",
                    "market_name": "crypto",
                    "price_aud": "49.16",
                    "price_brl": "163.98",
                    "price_cad": "43.74",
                    "price_eur": "29.90",
                    "price_gbp": "25.70",
                    "price_usd": "31.961",
                    "price_php": "1840.70",
                    "price_zar": "612.57",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCDASH--big.svg"
                },
                {
                    "id": 57,
                    "market_id": 4,
                    "tradingview": "DOGEUSD",
                    "name": "Dogecoin",
                    "polygon": "DOGE-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "DOGEUSD",
                    "market_name": "crypto",
                    "price_aud": "0.25",
                    "price_brl": "0.83",
                    "price_cad": "0.22",
                    "price_eur": "0.15",
                    "price_gbp": "0.13",
                    "price_usd": "0.16167",
                    "price_php": "9.31",
                    "price_zar": "3.10",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCDOGE--big.svg"
                },
                {
                    "id": 58,
                    "market_id": 4,
                    "tradingview": "DOTUSD",
                    "name": "Polkadot",
                    "polygon": "DOT-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "DOTUSD",
                    "market_name": "crypto",
                    "price_aud": "11.46",
                    "price_brl": "38.23",
                    "price_cad": "10.20",
                    "price_eur": "6.97",
                    "price_gbp": "5.99",
                    "price_usd": "7.4504",
                    "price_php": "429.08",
                    "price_zar": "142.80",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCDOT--big.svg"
                },
                {
                    "id": 59,
                    "market_id": 4,
                    "tradingview": "DUSKUSD",
                    "name": "DUSK",
                    "polygon": "DUSK-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "DUSKUSD",
                    "market_name": "crypto",
                    "price_aud": "0.59",
                    "price_brl": "1.96",
                    "price_cad": "0.52",
                    "price_eur": "0.36",
                    "price_gbp": "0.31",
                    "price_usd": "0.38228",
                    "price_php": "22.02",
                    "price_zar": "7.33",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCDUSK--big.svg"
                },
                {
                    "id": 60,
                    "market_id": 4,
                    "tradingview": "EGLDUSD",
                    "name": "MultiversX (Elrond)",
                    "polygon": "EGLD-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "EGLDUSD",
                    "market_name": "crypto",
                    "price_aud": "69.05",
                    "price_brl": "230.31",
                    "price_cad": "61.43",
                    "price_eur": "42.00",
                    "price_gbp": "36.09",
                    "price_usd": "44.89",
                    "price_php": "2585.30",
                    "price_zar": "860.37",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCEGLD--big.svg"
                },
                {
                    "id": 61,
                    "market_id": 4,
                    "tradingview": "ENJUSD",
                    "name": "Enjin Coin",
                    "polygon": "ENJ-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ENJUSD",
                    "market_name": "crypto",
                    "price_aud": "0.55",
                    "price_brl": "1.82",
                    "price_cad": "0.48",
                    "price_eur": "0.33",
                    "price_gbp": "0.28",
                    "price_usd": "0.35432",
                    "price_php": "20.41",
                    "price_zar": "6.79",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCENJ--big.svg"
                },
                {
                    "id": 62,
                    "market_id": 4,
                    "tradingview": "EOSUSD",
                    "name": "EOS",
                    "polygon": "EOS-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "EOSUSD",
                    "market_name": "crypto",
                    "price_aud": "1.34",
                    "price_brl": "4.46",
                    "price_cad": "1.19",
                    "price_eur": "0.81",
                    "price_gbp": "0.70",
                    "price_usd": "0.86858",
                    "price_php": "50.02",
                    "price_zar": "16.65",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCEOS--big.svg"
                },
                {
                    "id": 63,
                    "market_id": 4,
                    "tradingview": "ETCUSD",
                    "name": "Ethereum Classic",
                    "polygon": "ETC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ETCUSD",
                    "market_name": "crypto",
                    "price_aud": "43.11",
                    "price_brl": "143.78",
                    "price_cad": "38.35",
                    "price_eur": "26.22",
                    "price_gbp": "22.53",
                    "price_usd": "28.024",
                    "price_php": "1613.96",
                    "price_zar": "537.11",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCETC--big.svg"
                },
                {
                    "id": 31,
                    "market_id": 4,
                    "tradingview": "ETHUSD",
                    "name": "Ethereum",
                    "polygon": "ETH-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ETHUSD",
                    "market_name": "crypto",
                    "price_aud": "5034.61",
                    "price_brl": "16792.45",
                    "price_cad": "4478.78",
                    "price_eur": "3062.14",
                    "price_gbp": "2631.39",
                    "price_usd": "3273",
                    "price_php": "188498.62",
                    "price_zar": "62730.65",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCETH--big.svg"
                },
                {
                    "id": 64,
                    "market_id": 4,
                    "tradingview": "FARMUSD",
                    "name": "Harvest Finance",
                    "polygon": "FARM-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "FARMUSD",
                    "market_name": "crypto",
                    "price_aud": "125.24",
                    "price_brl": "417.73",
                    "price_cad": "111.42",
                    "price_eur": "76.17",
                    "price_gbp": "65.46",
                    "price_usd": "81.42",
                    "price_php": "4689.14",
                    "price_zar": "1560.50",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCFARM--big.svg"
                },
                {
                    "id": 65,
                    "market_id": 4,
                    "tradingview": "FETUSD",
                    "name": "Fetch.ai",
                    "polygon": "FET-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "FETUSD",
                    "market_name": "crypto",
                    "price_aud": "3.90",
                    "price_brl": "13.00",
                    "price_cad": "3.47",
                    "price_eur": "2.37",
                    "price_gbp": "2.04",
                    "price_usd": "2.5333",
                    "price_php": "145.90",
                    "price_zar": "48.55",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCFET--big.svg"
                },
                {
                    "id": 66,
                    "market_id": 4,
                    "tradingview": "FILUSD",
                    "name": "Filecoin",
                    "polygon": "FIL-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "FILUSD",
                    "market_name": "crypto",
                    "price_aud": "9.96",
                    "price_brl": "33.24",
                    "price_cad": "8.86",
                    "price_eur": "6.06",
                    "price_gbp": "5.21",
                    "price_usd": "6.478",
                    "price_php": "373.08",
                    "price_zar": "124.16",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCFIL--big.svg"
                },
                {
                    "id": 67,
                    "market_id": 4,
                    "tradingview": "FLOWUSD",
                    "name": "Flow",
                    "polygon": "FLOW-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "FLOWUSD",
                    "market_name": "crypto",
                    "price_aud": "1.55",
                    "price_brl": "5.16",
                    "price_cad": "1.38",
                    "price_eur": "0.94",
                    "price_gbp": "0.81",
                    "price_usd": "1.006",
                    "price_php": "57.94",
                    "price_zar": "19.28",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCFLOW--big.svg"
                },
                {
                    "id": 68,
                    "market_id": 4,
                    "tradingview": "FORTHUSD",
                    "name": "FORTH",
                    "polygon": "FORTH-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "FORTHUSD",
                    "market_name": "crypto",
                    "price_aud": "7.50",
                    "price_brl": "25.03",
                    "price_cad": "6.68",
                    "price_eur": "4.56",
                    "price_gbp": "3.92",
                    "price_usd": "4.879",
                    "price_php": "280.99",
                    "price_zar": "93.51",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCFORTH--big.svg"
                },
                {
                    "id": 69,
                    "market_id": 4,
                    "tradingview": "FTMUSD",
                    "name": "Fantom",
                    "polygon": "FTM-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "FTMUSD",
                    "market_name": "crypto",
                    "price_aud": "1.16",
                    "price_brl": "3.87",
                    "price_cad": "1.03",
                    "price_eur": "0.71",
                    "price_gbp": "0.61",
                    "price_usd": "0.7545",
                    "price_php": "43.45",
                    "price_zar": "14.46",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCFTM--big.svg"
                },
                {
                    "id": 70,
                    "market_id": 4,
                    "tradingview": "GALAUSD",
                    "name": "Gala",
                    "polygon": "GALA-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "GALAUSD",
                    "market_name": "crypto",
                    "price_aud": "0.08",
                    "price_brl": "0.26",
                    "price_cad": "0.07",
                    "price_eur": "0.05",
                    "price_gbp": "0.04",
                    "price_usd": "0.0508",
                    "price_php": "2.93",
                    "price_zar": "0.97",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCGALA--big.svg"
                },
                {
                    "id": 71,
                    "market_id": 4,
                    "tradingview": "GNOUSD",
                    "name": "Gnosis",
                    "polygon": "GNO-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "GNOUSD",
                    "market_name": "crypto",
                    "price_aud": "560.74",
                    "price_brl": "1870.31",
                    "price_cad": "498.84",
                    "price_eur": "341.05",
                    "price_gbp": "293.08",
                    "price_usd": "364.54",
                    "price_php": "20994.59",
                    "price_zar": "6986.81",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCGNO--big.svg"
                },
                {
                    "id": 72,
                    "market_id": 4,
                    "tradingview": "GRTUSD",
                    "name": "The Graph",
                    "polygon": "GRT-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "GRTUSD",
                    "market_name": "crypto",
                    "price_aud": "0.45",
                    "price_brl": "1.50",
                    "price_cad": "0.40",
                    "price_eur": "0.27",
                    "price_gbp": "0.24",
                    "price_usd": "0.29305",
                    "price_php": "16.88",
                    "price_zar": "5.62",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCGRAPH--big.svg"
                },
                {
                    "id": 73,
                    "market_id": 4,
                    "tradingview": "GTCUSD",
                    "name": "Gitcoin",
                    "polygon": "GTC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "GTCUSD",
                    "market_name": "crypto",
                    "price_aud": "2.02",
                    "price_brl": "6.74",
                    "price_cad": "1.80",
                    "price_eur": "1.23",
                    "price_gbp": "1.06",
                    "price_usd": "1.313",
                    "price_php": "75.62",
                    "price_zar": "25.17",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCGTC--big.svg"
                },
                {
                    "id": 74,
                    "market_id": 4,
                    "tradingview": "HBARUSD",
                    "name": "Hedera",
                    "polygon": "HBAR-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "HBARUSD",
                    "market_name": "crypto",
                    "price_aud": "0.20",
                    "price_brl": "0.65",
                    "price_cad": "0.17",
                    "price_eur": "0.12",
                    "price_gbp": "0.10",
                    "price_usd": "0.127",
                    "price_php": "7.31",
                    "price_zar": "2.43",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCHBAR--big.svg"
                },
                {
                    "id": 75,
                    "market_id": 4,
                    "tradingview": "HMTUSD",
                    "name": "HMT",
                    "polygon": "HMT-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "HMTUSD",
                    "market_name": "crypto",
                    "price_aud": "0.05",
                    "price_brl": "0.17",
                    "price_cad": "0.05",
                    "price_eur": "0.03",
                    "price_gbp": "0.03",
                    "price_usd": "0.03393",
                    "price_php": "1.95",
                    "price_zar": "0.65",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCHMT--big.svg"
                },
                {
                    "id": 107,
                    "market_id": 4,
                    "tradingview": "ICPUSD",
                    "name": "Internet Computer",
                    "polygon": "ICP-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ICPUSD",
                    "market_name": "crypto",
                    "price_aud": "22.53",
                    "price_brl": "75.16",
                    "price_cad": "20.05",
                    "price_eur": "13.71",
                    "price_gbp": "11.78",
                    "price_usd": "14.65",
                    "price_php": "843.72",
                    "price_zar": "280.78",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCICP--big.svg"
                },
                {
                    "id": 125,
                    "market_id": 4,
                    "tradingview": "ICXUSD",
                    "name": "ICON",
                    "polygon": "ICX-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ICXUSD",
                    "market_name": "crypto",
                    "price_aud": "0.40",
                    "price_brl": "1.33",
                    "price_cad": "0.36",
                    "price_eur": "0.24",
                    "price_gbp": "0.21",
                    "price_usd": "0.2602",
                    "price_php": "14.99",
                    "price_zar": "4.99",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCICX--big.svg"
                },
                {
                    "id": 76,
                    "market_id": 4,
                    "tradingview": "IMXUSD",
                    "name": "Immutable X",
                    "polygon": "IMX-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "IMXUSD",
                    "market_name": "crypto",
                    "price_aud": "3.69",
                    "price_brl": "12.31",
                    "price_cad": "3.28",
                    "price_eur": "2.24",
                    "price_gbp": "1.93",
                    "price_usd": "2.399",
                    "price_php": "138.16",
                    "price_zar": "45.98",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCIMXIMMUTABLEX--big.svg"
                },
                {
                    "id": 77,
                    "market_id": 4,
                    "tradingview": "INJUSD",
                    "name": "Injective",
                    "polygon": "INJ-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "INJUSD",
                    "market_name": "crypto",
                    "price_aud": "44.55",
                    "price_brl": "148.58",
                    "price_cad": "39.63",
                    "price_eur": "27.09",
                    "price_gbp": "23.28",
                    "price_usd": "28.96",
                    "price_php": "1667.86",
                    "price_zar": "555.05",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCINJ--big.svg"
                },
                {
                    "id": 78,
                    "market_id": 4,
                    "tradingview": "JASMYUSD",
                    "name": "JasmyCoin",
                    "polygon": "JASMY-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "JASMYUSD",
                    "market_name": "crypto",
                    "price_aud": "0.03",
                    "price_brl": "0.11",
                    "price_cad": "0.03",
                    "price_eur": "0.02",
                    "price_gbp": "0.02",
                    "price_usd": "0.02068",
                    "price_php": "1.19",
                    "price_zar": "0.40",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCJASMY--big.svg"
                },
                {
                    "id": 79,
                    "market_id": 4,
                    "tradingview": "JSTUSD",
                    "name": "JST",
                    "polygon": "JST-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "JSTUSD",
                    "market_name": "crypto",
                    "price_aud": "0.05",
                    "price_brl": "0.18",
                    "price_cad": "0.05",
                    "price_eur": "0.03",
                    "price_gbp": "0.03",
                    "price_usd": "0.034367",
                    "price_php": "1.98",
                    "price_zar": "0.66",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCJST--big.svg"
                },
                {
                    "id": 80,
                    "market_id": 4,
                    "tradingview": "KAVAUSD",
                    "name": "Kava",
                    "polygon": "KAVA-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "KAVAUSD",
                    "market_name": "crypto",
                    "price_aud": "1.18",
                    "price_brl": "3.93",
                    "price_cad": "1.05",
                    "price_eur": "0.72",
                    "price_gbp": "0.62",
                    "price_usd": "0.7669",
                    "price_php": "44.17",
                    "price_zar": "14.70",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCKAVA--big.svg"
                },
                {
                    "id": 81,
                    "market_id": 4,
                    "tradingview": "KEEPUSD",
                    "name": "Keep Network",
                    "polygon": "KEEP-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "KEEPUSD",
                    "market_name": "crypto",
                    "price_aud": "0.27",
                    "price_brl": "0.92",
                    "price_cad": "0.24",
                    "price_eur": "0.17",
                    "price_gbp": "0.14",
                    "price_usd": "0.17842",
                    "price_php": "10.28",
                    "price_zar": "3.42",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCKEEP--big.svg"
                },
                {
                    "id": 82,
                    "market_id": 4,
                    "tradingview": "KNCUSD",
                    "name": "Kyber Network",
                    "polygon": "KNC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "KNCUSD",
                    "market_name": "crypto",
                    "price_aud": "0.99",
                    "price_brl": "3.31",
                    "price_cad": "0.88",
                    "price_eur": "0.60",
                    "price_gbp": "0.52",
                    "price_usd": "0.64516",
                    "price_php": "37.16",
                    "price_zar": "12.37",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCKNC--big.svg"
                },
                {
                    "id": 83,
                    "market_id": 4,
                    "tradingview": "KSMUSD",
                    "name": "Kusama",
                    "polygon": "KSM-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "KSMUSD",
                    "market_name": "crypto",
                    "price_aud": "48.17",
                    "price_brl": "160.65",
                    "price_cad": "42.85",
                    "price_eur": "29.30",
                    "price_gbp": "25.17",
                    "price_usd": "31.313",
                    "price_php": "1803.38",
                    "price_zar": "600.15",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCKSM--big.svg"
                },
                {
                    "id": 84,
                    "market_id": 4,
                    "tradingview": "LCXUSD",
                    "name": "LCX Token",
                    "polygon": "LCX-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "LCXUSD",
                    "market_name": "crypto",
                    "price_aud": "0.43",
                    "price_brl": "1.45",
                    "price_cad": "0.39",
                    "price_eur": "0.26",
                    "price_gbp": "0.23",
                    "price_usd": "0.28219",
                    "price_php": "16.25",
                    "price_zar": "5.41",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCLCX--big.svg"
                },
                {
                    "id": 85,
                    "market_id": 4,
                    "tradingview": "LINKUSD",
                    "name": "Chainlink",
                    "polygon": "LINK-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "LINKUSD",
                    "market_name": "crypto",
                    "price_aud": "23.80",
                    "price_brl": "79.37",
                    "price_cad": "21.17",
                    "price_eur": "14.47",
                    "price_gbp": "12.44",
                    "price_usd": "15.47",
                    "price_php": "890.95",
                    "price_zar": "296.50",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCLINK--big.svg"
                },
                {
                    "id": 86,
                    "market_id": 4,
                    "tradingview": "LPTUSD",
                    "name": "Livepeer",
                    "polygon": "LPT-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "LPTUSD",
                    "market_name": "crypto",
                    "price_aud": "23.41",
                    "price_brl": "78.09",
                    "price_cad": "20.83",
                    "price_eur": "14.24",
                    "price_gbp": "12.24",
                    "price_usd": "15.22",
                    "price_php": "876.55",
                    "price_zar": "291.71",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCLPT--big.svg"
                },
                {
                    "id": 87,
                    "market_id": 4,
                    "tradingview": "LRCUSD",
                    "name": "Loopring",
                    "polygon": "LRC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "LRCUSD",
                    "market_name": "crypto",
                    "price_aud": "0.44",
                    "price_brl": "1.45",
                    "price_cad": "0.39",
                    "price_eur": "0.26",
                    "price_gbp": "0.23",
                    "price_usd": "0.2831",
                    "price_php": "16.30",
                    "price_zar": "5.43",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCLRC--big.svg"
                },
                {
                    "id": 135,
                    "market_id": 4,
                    "tradingview": "LSKUSD",
                    "name": "LSK",
                    "polygon": "LSK-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "LSKUSD",
                    "market_name": "crypto",
                    "price_aud": "2.68",
                    "price_brl": "8.95",
                    "price_cad": "2.39",
                    "price_eur": "1.63",
                    "price_gbp": "1.40",
                    "price_usd": "1.743592",
                    "price_php": "100.42",
                    "price_zar": "33.42",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCLSK--big.svg"
                },
                {
                    "id": 88,
                    "market_id": 4,
                    "tradingview": "LTCUSD",
                    "name": "Litecoin",
                    "polygon": "LTC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "LTCUSD",
                    "market_name": "crypto",
                    "price_aud": "133.98",
                    "price_brl": "446.88",
                    "price_cad": "119.19",
                    "price_eur": "81.49",
                    "price_gbp": "70.03",
                    "price_usd": "87.101",
                    "price_php": "5016.32",
                    "price_zar": "1669.39",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCLTC--big.svg"
                },
                {
                    "id": 89,
                    "market_id": 4,
                    "tradingview": "LUNAUSD",
                    "name": "LUNA",
                    "polygon": "LUNA-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "LUNAUSD",
                    "market_name": "crypto",
                    "price_aud": "0.00",
                    "price_brl": "0.00",
                    "price_cad": "0.00",
                    "price_eur": "0.00",
                    "price_gbp": "0.00",
                    "price_usd": "0.00011318",
                    "price_php": "0.01",
                    "price_zar": "0.00",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCLUNA--big.svg"
                },
                {
                    "id": 90,
                    "market_id": 4,
                    "tradingview": "LYMUSD",
                    "name": "LYM",
                    "polygon": "LYM-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "LYMUSD",
                    "market_name": "crypto",
                    "price_aud": "0.00",
                    "price_brl": "0.02",
                    "price_cad": "0.00",
                    "price_eur": "0.00",
                    "price_gbp": "0.00",
                    "price_usd": "0.0031192",
                    "price_php": "0.18",
                    "price_zar": "0.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCLYM--big.svg"
                },
                {
                    "id": 91,
                    "market_id": 4,
                    "tradingview": "MANAUSD",
                    "name": "Decentraland",
                    "polygon": "MANA-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "MANAUSD",
                    "market_name": "crypto",
                    "price_aud": "0.76",
                    "price_brl": "2.54",
                    "price_cad": "0.68",
                    "price_eur": "0.46",
                    "price_gbp": "0.40",
                    "price_usd": "0.49509",
                    "price_php": "28.51",
                    "price_zar": "9.49",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCMANA--big.svg"
                },
                {
                    "id": 92,
                    "market_id": 4,
                    "tradingview": "MASKUSD",
                    "name": "Mask Network",
                    "polygon": "MASK-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "MASKUSD",
                    "market_name": "crypto",
                    "price_aud": "5.73",
                    "price_brl": "19.11",
                    "price_cad": "5.10",
                    "price_eur": "3.49",
                    "price_gbp": "2.99",
                    "price_usd": "3.725",
                    "price_php": "214.53",
                    "price_zar": "71.39",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCMASK--big.svg"
                },
                {
                    "id": 93,
                    "market_id": 4,
                    "tradingview": "MATICUSD",
                    "name": "Polygon",
                    "polygon": "MATIC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "MATICUSD",
                    "market_name": "crypto",
                    "price_aud": "1.16",
                    "price_brl": "3.88",
                    "price_cad": "1.04",
                    "price_eur": "0.71",
                    "price_gbp": "0.61",
                    "price_usd": "0.7565",
                    "price_php": "43.57",
                    "price_zar": "14.50",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCMATIC--big.svg"
                },
                {
                    "id": 94,
                    "market_id": 4,
                    "tradingview": "MIRUSD",
                    "name": "Mirror Protocol",
                    "polygon": "MIR-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "MIRUSD",
                    "market_name": "crypto",
                    "price_aud": "0.09",
                    "price_brl": "0.29",
                    "price_cad": "0.08",
                    "price_eur": "0.05",
                    "price_gbp": "0.05",
                    "price_usd": "0.057",
                    "price_php": "3.28",
                    "price_zar": "1.09",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCMIR--big.svg"
                },
                {
                    "id": 95,
                    "market_id": 4,
                    "tradingview": "MKRUSD",
                    "name": "Maker",
                    "polygon": "MKR-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "MKRUSD",
                    "market_name": "crypto",
                    "price_aud": "4648.67",
                    "price_brl": "15505.19",
                    "price_cad": "4135.44",
                    "price_eur": "2827.40",
                    "price_gbp": "2429.67",
                    "price_usd": "3022.1",
                    "price_php": "174048.78",
                    "price_zar": "57921.87",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCMKR--big.svg"
                },
                {
                    "id": 96,
                    "market_id": 4,
                    "tradingview": "MLNUSD",
                    "name": "Enzyme",
                    "polygon": "MLN-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "MLNUSD",
                    "market_name": "crypto",
                    "price_aud": "35.19",
                    "price_brl": "117.36",
                    "price_cad": "31.30",
                    "price_eur": "21.40",
                    "price_gbp": "18.39",
                    "price_usd": "22.874",
                    "price_php": "1317.36",
                    "price_zar": "438.41",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCMLN--big.svg"
                },
                {
                    "id": 97,
                    "market_id": 4,
                    "tradingview": "NANOUSD",
                    "name": "Nano",
                    "polygon": "NANO-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "NANOUSD",
                    "market_name": "crypto",
                    "price_aud": "1.98",
                    "price_brl": "6.60",
                    "price_cad": "1.76",
                    "price_eur": "1.20",
                    "price_gbp": "1.03",
                    "price_usd": "1.285752",
                    "price_php": "74.05",
                    "price_zar": "24.64",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCNANO--big.svg"
                },
                {
                    "id": 98,
                    "market_id": 4,
                    "tradingview": "NEARUSD",
                    "name": "NEAR Protocol",
                    "polygon": "NEAR-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "NEARUSD",
                    "market_name": "crypto",
                    "price_aud": "10.78",
                    "price_brl": "35.97",
                    "price_cad": "9.59",
                    "price_eur": "6.56",
                    "price_gbp": "5.64",
                    "price_usd": "7.01",
                    "price_php": "403.72",
                    "price_zar": "134.35",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCNEAR--big.svg"
                },
                {
                    "id": 99,
                    "market_id": 4,
                    "tradingview": "NEOUSD",
                    "name": "NEO",
                    "polygon": "NEO-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "NEOUSD",
                    "market_name": "crypto",
                    "price_aud": "28.51",
                    "price_brl": "95.11",
                    "price_cad": "25.37",
                    "price_eur": "17.34",
                    "price_gbp": "14.90",
                    "price_usd": "18.537",
                    "price_php": "1067.58",
                    "price_zar": "355.28",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCNEO--big.svg"
                },
                {
                    "id": 100,
                    "market_id": 4,
                    "tradingview": "NEXOUSD",
                    "name": "NEXO",
                    "polygon": "NEXO-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "NEXOUSD",
                    "market_name": "crypto",
                    "price_aud": "1.99",
                    "price_brl": "6.64",
                    "price_cad": "1.77",
                    "price_eur": "1.21",
                    "price_gbp": "1.04",
                    "price_usd": "1.2946",
                    "price_php": "74.56",
                    "price_zar": "24.81",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCNEXO--big.svg"
                },
                {
                    "id": 126,
                    "market_id": 4,
                    "tradingview": "OCEANUSD",
                    "name": "Ocean Protocol",
                    "polygon": "OCEAN-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "OCEANUSD",
                    "market_name": "crypto",
                    "price_aud": "1.62",
                    "price_brl": "5.41",
                    "price_cad": "1.44",
                    "price_eur": "0.99",
                    "price_gbp": "0.85",
                    "price_usd": "1.055",
                    "price_php": "60.76",
                    "price_zar": "20.22",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCOCEAN--big.svg"
                },
                {
                    "id": 115,
                    "market_id": 4,
                    "tradingview": "PAXGUSD",
                    "name": "PAX Gold",
                    "polygon": "PAXG-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "PAXGUSD",
                    "market_name": "crypto",
                    "price_aud": "3542.53",
                    "price_brl": "11815.77",
                    "price_cad": "3151.43",
                    "price_eur": "2154.63",
                    "price_gbp": "1851.54",
                    "price_usd": "2303",
                    "price_php": "132634.38",
                    "price_zar": "44139.53",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCPAXG--big.svg"
                },
                {
                    "id": 109,
                    "market_id": 4,
                    "tradingview": "QNTUSD",
                    "name": "Quant",
                    "polygon": "QNT-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "QNTUSD",
                    "market_name": "crypto",
                    "price_aud": "173.22",
                    "price_brl": "577.76",
                    "price_cad": "154.10",
                    "price_eur": "105.36",
                    "price_gbp": "90.53",
                    "price_usd": "112.61",
                    "price_php": "6485.44",
                    "price_zar": "2158.29",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCQNT--big.svg"
                },
                {
                    "id": 118,
                    "market_id": 4,
                    "tradingview": "QTUMUSD",
                    "name": "Qtum",
                    "polygon": "QTUM-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "QTUMUSD",
                    "market_name": "crypto",
                    "price_aud": "6.52",
                    "price_brl": "21.75",
                    "price_cad": "5.80",
                    "price_eur": "3.97",
                    "price_gbp": "3.41",
                    "price_usd": "4.2393",
                    "price_php": "244.15",
                    "price_zar": "81.25",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCQTUM--big.svg"
                },
                {
                    "id": 112,
                    "market_id": 4,
                    "tradingview": "RNDRUSD",
                    "name": "Render Token",
                    "polygon": "RNDR-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "RNDRUSD",
                    "market_name": "crypto",
                    "price_aud": "14.17",
                    "price_brl": "47.28",
                    "price_cad": "12.61",
                    "price_eur": "8.62",
                    "price_gbp": "7.41",
                    "price_usd": "9.2151",
                    "price_php": "530.72",
                    "price_zar": "176.62",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCRNDR--big.svg"
                },
                {
                    "id": 119,
                    "market_id": 4,
                    "tradingview": "ROSEUSD",
                    "name": "Oasis Network",
                    "polygon": "ROSE-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ROSEUSD",
                    "market_name": "crypto",
                    "price_aud": "0.19",
                    "price_brl": "0.64",
                    "price_cad": "0.17",
                    "price_eur": "0.12",
                    "price_gbp": "0.10",
                    "price_usd": "0.1246",
                    "price_php": "7.18",
                    "price_zar": "2.39",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCROSE--big.svg"
                },
                {
                    "id": 110,
                    "market_id": 4,
                    "tradingview": "SANDUSD",
                    "name": "The Sandbox",
                    "polygon": "SAND-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "SANDUSD",
                    "market_name": "crypto",
                    "price_aud": "0.77",
                    "price_brl": "2.56",
                    "price_cad": "0.68",
                    "price_eur": "0.47",
                    "price_gbp": "0.40",
                    "price_usd": "0.4992",
                    "price_php": "28.75",
                    "price_zar": "9.57",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCSAND--big.svg"
                },
                {
                    "id": 131,
                    "market_id": 4,
                    "tradingview": "SCUSD",
                    "name": "Siacom",
                    "polygon": "SC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "SCUSD",
                    "market_name": "crypto",
                    "price_aud": "0.01",
                    "price_brl": "0.04",
                    "price_cad": "0.01",
                    "price_eur": "0.01",
                    "price_gbp": "0.01",
                    "price_usd": "0.00804",
                    "price_php": "0.46",
                    "price_zar": "0.15",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCSC--big.svg"
                },
                {
                    "id": 103,
                    "market_id": 4,
                    "tradingview": "SHIBUSD",
                    "name": "Shiba Inu",
                    "polygon": "SHIB-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "SHIBUSD",
                    "market_name": "crypto",
                    "price_aud": "0.00",
                    "price_brl": "0.00",
                    "price_cad": "0.00",
                    "price_eur": "0.00",
                    "price_gbp": "0.00",
                    "price_usd": "2.709E-5",
                    "price_php": "0.00",
                    "price_zar": "0.00",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCSHIB--big.svg"
                },
                {
                    "id": 132,
                    "market_id": 4,
                    "tradingview": "SKLUSD",
                    "name": "SKL",
                    "polygon": "SKL-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "SKLUSD",
                    "market_name": "crypto",
                    "price_aud": "0.16",
                    "price_brl": "0.53",
                    "price_cad": "0.14",
                    "price_eur": "0.10",
                    "price_gbp": "0.08",
                    "price_usd": "0.10298",
                    "price_php": "5.93",
                    "price_zar": "1.97",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCSKL--big.svg"
                },
                {
                    "id": 113,
                    "market_id": 4,
                    "tradingview": "SNXUSD",
                    "name": "Synthetix",
                    "polygon": "SNX-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "SNXUSD",
                    "market_name": "crypto",
                    "price_aud": "4.75",
                    "price_brl": "15.86",
                    "price_cad": "4.23",
                    "price_eur": "2.89",
                    "price_gbp": "2.49",
                    "price_usd": "3.091",
                    "price_php": "178.02",
                    "price_zar": "59.24",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCSNX--big.svg"
                },
                {
                    "id": 102,
                    "market_id": 4,
                    "tradingview": "SOLUSD",
                    "name": "Solana",
                    "polygon": "SOL-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "SOLUSD",
                    "market_name": "crypto",
                    "price_aud": "242.76",
                    "price_brl": "809.71",
                    "price_cad": "215.96",
                    "price_eur": "147.65",
                    "price_gbp": "126.88",
                    "price_usd": "157.819",
                    "price_php": "9089.11",
                    "price_zar": "3024.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCSOL--big.svg"
                },
                {
                    "id": 133,
                    "market_id": 4,
                    "tradingview": "STORJUSD",
                    "name": "STORJ",
                    "polygon": "STORJ-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "STORJUSD",
                    "market_name": "crypto",
                    "price_aud": "0.91",
                    "price_brl": "3.03",
                    "price_cad": "0.81",
                    "price_eur": "0.55",
                    "price_gbp": "0.47",
                    "price_usd": "0.59024",
                    "price_php": "33.99",
                    "price_zar": "11.31",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCSTORJ--big.svg"
                },
                {
                    "id": 130,
                    "market_id": 4,
                    "tradingview": "SUSHIUSD",
                    "name": "SushiSwap",
                    "polygon": "SUSHI-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "SUSHIUSD",
                    "market_name": "crypto",
                    "price_aud": "1.64",
                    "price_brl": "5.47",
                    "price_cad": "1.46",
                    "price_eur": "1.00",
                    "price_gbp": "0.86",
                    "price_usd": "1.067",
                    "price_php": "61.45",
                    "price_zar": "20.45",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCSUSHI--big.svg"
                },
                {
                    "id": 122,
                    "market_id": 4,
                    "tradingview": "TRACUSD",
                    "name": "TRAC",
                    "polygon": "TRAC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "TRACUSD",
                    "market_name": "crypto",
                    "price_aud": "1.52",
                    "price_brl": "5.08",
                    "price_cad": "1.35",
                    "price_eur": "0.93",
                    "price_gbp": "0.80",
                    "price_usd": "0.9893",
                    "price_php": "56.98",
                    "price_zar": "18.96",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCTRAC--big.svg"
                },
                {
                    "id": 33,
                    "market_id": 4,
                    "tradingview": "TRXUSD",
                    "name": "TRON",
                    "polygon": "TRX-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "TRXUSD",
                    "market_name": "crypto",
                    "price_aud": "0.18",
                    "price_brl": "0.58",
                    "price_cad": "0.16",
                    "price_eur": "0.11",
                    "price_gbp": "0.09",
                    "price_usd": "0.11377",
                    "price_php": "6.55",
                    "price_zar": "2.18",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCTRX--big.svg"
                },
                {
                    "id": 134,
                    "market_id": 4,
                    "tradingview": "UMAUSD",
                    "name": "UMA",
                    "polygon": "UMA-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "UMAUSD",
                    "market_name": "crypto",
                    "price_aud": "4.62",
                    "price_brl": "15.41",
                    "price_cad": "4.11",
                    "price_eur": "2.81",
                    "price_gbp": "2.42",
                    "price_usd": "3.004",
                    "price_php": "173.01",
                    "price_zar": "57.57",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCUMA--big.svg"
                },
                {
                    "id": 106,
                    "market_id": 4,
                    "tradingview": "UNIUSD",
                    "name": "Uniswap",
                    "polygon": "UNI-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "UNIUSD",
                    "market_name": "crypto",
                    "price_aud": "12.51",
                    "price_brl": "41.73",
                    "price_cad": "11.13",
                    "price_eur": "7.61",
                    "price_gbp": "6.54",
                    "price_usd": "8.134",
                    "price_php": "468.45",
                    "price_zar": "155.90",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCUNI--big.svg"
                },
                {
                    "id": 108,
                    "market_id": 4,
                    "tradingview": "VETUSD",
                    "name": "Vechain",
                    "polygon": "VET-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "VETUSD",
                    "market_name": "crypto",
                    "price_aud": "0.07",
                    "price_brl": "0.22",
                    "price_cad": "0.06",
                    "price_eur": "0.04",
                    "price_gbp": "0.03",
                    "price_usd": "0.042477",
                    "price_php": "2.45",
                    "price_zar": "0.81",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCVET--big.svg"
                },
                {
                    "id": 124,
                    "market_id": 4,
                    "tradingview": "WAVESUSD",
                    "name": "WAVES",
                    "polygon": "WAVES-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "WAVESUSD",
                    "market_name": "crypto",
                    "price_aud": "4.07",
                    "price_brl": "13.57",
                    "price_cad": "3.62",
                    "price_eur": "2.48",
                    "price_gbp": "2.13",
                    "price_usd": "2.6456",
                    "price_php": "152.37",
                    "price_zar": "50.71",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCWAVES--big.svg"
                },
                {
                    "id": 116,
                    "market_id": 4,
                    "tradingview": "XDCUSD",
                    "name": "XDC Network",
                    "polygon": "XDC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "XDCUSD",
                    "market_name": "crypto",
                    "price_aud": "0.07",
                    "price_brl": "0.22",
                    "price_cad": "0.06",
                    "price_eur": "0.04",
                    "price_gbp": "0.03",
                    "price_usd": "0.042857",
                    "price_php": "2.47",
                    "price_zar": "0.82",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCXDC--big.svg"
                },
                {
                    "id": 105,
                    "market_id": 4,
                    "tradingview": "XLMUSD",
                    "name": "Stellar",
                    "polygon": "XLM-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "XLMUSD",
                    "market_name": "crypto",
                    "price_aud": "0.18",
                    "price_brl": "0.61",
                    "price_cad": "0.16",
                    "price_eur": "0.11",
                    "price_gbp": "0.10",
                    "price_usd": "0.11936",
                    "price_php": "6.87",
                    "price_zar": "2.29",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCXLM--big.svg"
                },
                {
                    "id": 104,
                    "market_id": 4,
                    "tradingview": "XMRUSD",
                    "name": "Monero",
                    "polygon": "XMR-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "XMRUSD",
                    "market_name": "crypto",
                    "price_aud": "186.02",
                    "price_brl": "620.44",
                    "price_cad": "165.48",
                    "price_eur": "113.14",
                    "price_gbp": "97.22",
                    "price_usd": "120.93",
                    "price_php": "6964.60",
                    "price_zar": "2317.76",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCXMR--big.svg"
                },
                {
                    "id": 121,
                    "market_id": 4,
                    "tradingview": "XRDUSD",
                    "name": "Radix",
                    "polygon": "XRD-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "XRDUSD",
                    "market_name": "crypto",
                    "price_aud": "0.08",
                    "price_brl": "0.28",
                    "price_cad": "0.07",
                    "price_eur": "0.05",
                    "price_gbp": "0.04",
                    "price_usd": "0.054334",
                    "price_php": "3.13",
                    "price_zar": "1.04",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCXRD--big.svg"
                },
                {
                    "id": 101,
                    "market_id": 4,
                    "tradingview": "XRPUSD",
                    "name": "Ripple",
                    "polygon": "XRP-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "XRPUSD",
                    "market_name": "crypto",
                    "price_aud": "0.84",
                    "price_brl": "2.80",
                    "price_cad": "0.75",
                    "price_eur": "0.51",
                    "price_gbp": "0.44",
                    "price_usd": "0.5464",
                    "price_php": "31.47",
                    "price_zar": "10.47",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCXRP--big.svg"
                },
                {
                    "id": 111,
                    "market_id": 4,
                    "tradingview": "XTZUSD",
                    "name": "Tezos",
                    "polygon": "XTZ-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "XTZUSD",
                    "market_name": "crypto",
                    "price_aud": "1.63",
                    "price_brl": "5.44",
                    "price_cad": "1.45",
                    "price_eur": "0.99",
                    "price_gbp": "0.85",
                    "price_usd": "1.0607",
                    "price_php": "61.09",
                    "price_zar": "20.33",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCXTZ--big.svg"
                },
                {
                    "id": 123,
                    "market_id": 4,
                    "tradingview": "YFIUSD",
                    "name": "yearn.finance",
                    "polygon": "YFI-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "YFIUSD",
                    "market_name": "crypto",
                    "price_aud": "11509.89",
                    "price_brl": "38390.12",
                    "price_cad": "10239.17",
                    "price_eur": "7000.52",
                    "price_gbp": "6015.76",
                    "price_usd": "7482.58",
                    "price_php": "430936.75",
                    "price_zar": "143411.88",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCYFI--big.svg"
                },
                {
                    "id": 114,
                    "market_id": 4,
                    "tradingview": "ZECUSD",
                    "name": "ZCash",
                    "polygon": "ZEC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ZECUSD",
                    "market_name": "crypto",
                    "price_aud": "37.36",
                    "price_brl": "124.60",
                    "price_cad": "33.23",
                    "price_eur": "22.72",
                    "price_gbp": "19.52",
                    "price_usd": "24.285",
                    "price_php": "1398.62",
                    "price_zar": "465.45",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCZEC--big.svg"
                },
                {
                    "id": 117,
                    "market_id": 4,
                    "tradingview": "ZILUSD",
                    "name": "Ziliqa",
                    "polygon": "ZIL-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ZILUSD",
                    "market_name": "crypto",
                    "price_aud": "0.04",
                    "price_brl": "0.14",
                    "price_cad": "0.04",
                    "price_eur": "0.03",
                    "price_gbp": "0.02",
                    "price_usd": "0.02779",
                    "price_php": "1.60",
                    "price_zar": "0.53",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCZIL--big.svg"
                },
                {
                    "id": 127,
                    "market_id": 4,
                    "tradingview": "ZRXUSD",
                    "name": "Ox",
                    "polygon": "ZRX-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ZRXUSD",
                    "market_name": "crypto",
                    "price_aud": "0.86",
                    "price_brl": "2.88",
                    "price_cad": "0.77",
                    "price_eur": "0.53",
                    "price_gbp": "0.45",
                    "price_usd": "0.56182",
                    "price_php": "32.36",
                    "price_zar": "10.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCZRX--big.svg"
                }
            ],
            "total": 107,
            "object": {
                "AAVEUSD": {
                    "id": 34,
                    "market_id": 4,
                    "tradingview": "AAVEUSD",
                    "name": "Aave",
                    "polygon": "AAVE-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "AAVEUSD",
                    "market_name": "crypto",
                    "price_aud": "148.61",
                    "price_brl": "495.67",
                    "price_cad": "132.20",
                    "price_eur": "90.39",
                    "price_gbp": "77.67",
                    "price_usd": "96.61",
                    "price_php": "5563.96",
                    "price_zar": "1851.64",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCAAVE--big.svg"
                },
                "ACHUSD": {
                    "id": 35,
                    "market_id": 4,
                    "tradingview": "ACHUSD",
                    "name": "Alchemy Pay",
                    "polygon": "ACH-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ACHUSD",
                    "market_name": "crypto",
                    "price_aud": "0.05",
                    "price_brl": "0.18",
                    "price_cad": "0.05",
                    "price_eur": "0.03",
                    "price_gbp": "0.03",
                    "price_usd": "0.03553",
                    "price_php": "2.05",
                    "price_zar": "0.68",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCACH--big.svg"
                },
                "ADAUSD": {
                    "id": 36,
                    "market_id": 4,
                    "tradingview": "ADAUSD",
                    "name": "Cardano",
                    "polygon": "ADA-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ADAUSD",
                    "market_name": "crypto",
                    "price_aud": "0.76",
                    "price_brl": "2.54",
                    "price_cad": "0.68",
                    "price_eur": "0.46",
                    "price_gbp": "0.40",
                    "price_usd": "0.49604",
                    "price_php": "28.57",
                    "price_zar": "9.51",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCADA--big.svg"
                },
                "ALGOUSD": {
                    "id": 37,
                    "market_id": 4,
                    "tradingview": "ALGOUSD",
                    "name": "Algorand",
                    "polygon": "ALGO-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ALGOUSD",
                    "market_name": "crypto",
                    "price_aud": "0.35",
                    "price_brl": "1.17",
                    "price_cad": "0.31",
                    "price_eur": "0.21",
                    "price_gbp": "0.18",
                    "price_usd": "0.22841",
                    "price_php": "13.15",
                    "price_zar": "4.38",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCALGO--big.svg"
                },
                "AMPUSD": {
                    "id": 38,
                    "market_id": 4,
                    "tradingview": "AMPUSD",
                    "name": "Amp",
                    "polygon": "AMP-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "AMPUSD",
                    "market_name": "crypto",
                    "price_aud": "0.01",
                    "price_brl": "0.04",
                    "price_cad": "0.01",
                    "price_eur": "0.01",
                    "price_gbp": "0.01",
                    "price_usd": "0.00788",
                    "price_php": "0.45",
                    "price_zar": "0.15",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCAMP--big.svg"
                },
                "ANKRUSD": {
                    "id": 39,
                    "market_id": 4,
                    "tradingview": "ANKRUSD",
                    "name": "ANKR",
                    "polygon": "ANKR-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ANKRUSD",
                    "market_name": "crypto",
                    "price_aud": "0.08",
                    "price_brl": "0.28",
                    "price_cad": "0.07",
                    "price_eur": "0.05",
                    "price_gbp": "0.04",
                    "price_usd": "0.0541",
                    "price_php": "3.12",
                    "price_zar": "1.04",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCANKR--big.svg"
                },
                "ANTUSD": {
                    "id": 129,
                    "market_id": 4,
                    "tradingview": "ANTUSD",
                    "name": "Aragon",
                    "polygon": "ANT-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ANTUSD",
                    "market_name": "crypto",
                    "price_aud": "12.73",
                    "price_brl": "42.45",
                    "price_cad": "11.32",
                    "price_eur": "7.74",
                    "price_gbp": "6.65",
                    "price_usd": "8.2732",
                    "price_php": "476.47",
                    "price_zar": "158.56",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCANT--big.svg"
                },
                "APEUSD": {
                    "id": 40,
                    "market_id": 4,
                    "tradingview": "APEUSD",
                    "name": "ApeCoin",
                    "polygon": "APE-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "APEUSD",
                    "market_name": "crypto",
                    "price_aud": "2.08",
                    "price_brl": "6.93",
                    "price_cad": "1.85",
                    "price_eur": "1.26",
                    "price_gbp": "1.09",
                    "price_usd": "1.3505",
                    "price_php": "77.78",
                    "price_zar": "25.88",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCAPE--big.svg"
                },
                "API3USD": {
                    "id": 41,
                    "market_id": 4,
                    "tradingview": "API3USD",
                    "name": "API3",
                    "polygon": "API3-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "API3USD",
                    "market_name": "crypto",
                    "price_aud": "4.08",
                    "price_brl": "13.60",
                    "price_cad": "3.63",
                    "price_eur": "2.48",
                    "price_gbp": "2.13",
                    "price_usd": "2.651",
                    "price_php": "152.68",
                    "price_zar": "50.81",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCAPI3--big.svg"
                },
                "ATOMUSD": {
                    "id": 42,
                    "market_id": 4,
                    "tradingview": "ATOMUSD",
                    "name": "Cosmos",
                    "polygon": "ATOM-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ATOMUSD",
                    "market_name": "crypto",
                    "price_aud": "13.57",
                    "price_brl": "45.28",
                    "price_cad": "12.08",
                    "price_eur": "8.26",
                    "price_gbp": "7.09",
                    "price_usd": "8.8249",
                    "price_php": "508.24",
                    "price_zar": "169.14",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCATOM--big.svg"
                },
                "AVAXUSD": {
                    "id": 43,
                    "market_id": 4,
                    "tradingview": "AVAXUSD",
                    "name": "Avalanche",
                    "polygon": "AVAX-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "AVAXUSD",
                    "market_name": "crypto",
                    "price_aud": "60.16",
                    "price_brl": "200.65",
                    "price_cad": "53.52",
                    "price_eur": "36.59",
                    "price_gbp": "31.44",
                    "price_usd": "39.109",
                    "price_php": "2252.37",
                    "price_zar": "749.57",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCAVAX--big.svg"
                },
                "AXSUSD": {
                    "id": 44,
                    "market_id": 4,
                    "tradingview": "AXSUSD",
                    "name": "Axie Infinity",
                    "polygon": "AXS-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "AXSUSD",
                    "market_name": "crypto",
                    "price_aud": "12.53",
                    "price_brl": "41.78",
                    "price_cad": "11.14",
                    "price_eur": "7.62",
                    "price_gbp": "6.55",
                    "price_usd": "8.143",
                    "price_php": "468.97",
                    "price_zar": "156.07",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCAXS--big.svg"
                },
                "BALUSD": {
                    "id": 120,
                    "market_id": 4,
                    "tradingview": "BALUSD",
                    "name": "Balancer",
                    "polygon": "BAL-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BALUSD",
                    "market_name": "crypto",
                    "price_aud": "6.32",
                    "price_brl": "21.09",
                    "price_cad": "5.62",
                    "price_eur": "3.85",
                    "price_gbp": "3.30",
                    "price_usd": "4.11",
                    "price_php": "236.70",
                    "price_zar": "78.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBAL--big.svg"
                },
                "BANDUSD": {
                    "id": 128,
                    "market_id": 4,
                    "tradingview": "BANDUSD",
                    "name": "BAND",
                    "polygon": "BAND-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BANDUSD",
                    "market_name": "crypto",
                    "price_aud": "2.60",
                    "price_brl": "8.68",
                    "price_cad": "2.31",
                    "price_eur": "1.58",
                    "price_gbp": "1.36",
                    "price_usd": "1.691",
                    "price_php": "97.39",
                    "price_zar": "32.41",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBAND--big.svg"
                },
                "BATUSD": {
                    "id": 45,
                    "market_id": 4,
                    "tradingview": "BATUSD",
                    "name": "Basic Attention Token",
                    "polygon": "BAT-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BATUSD",
                    "market_name": "crypto",
                    "price_aud": "0.43",
                    "price_brl": "1.44",
                    "price_cad": "0.38",
                    "price_eur": "0.26",
                    "price_gbp": "0.23",
                    "price_usd": "0.28122",
                    "price_php": "16.20",
                    "price_zar": "5.39",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBAT--big.svg"
                },
                "BCHUSD": {
                    "id": 136,
                    "market_id": 4,
                    "tradingview": "BCHUSD",
                    "name": "Bitcoin Cash",
                    "polygon": "BCH-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BCHUSD",
                    "market_name": "crypto",
                    "price_aud": "776.37",
                    "price_brl": "2589.52",
                    "price_cad": "690.66",
                    "price_eur": "472.20",
                    "price_gbp": "405.78",
                    "price_usd": "504.72",
                    "price_php": "29067.83",
                    "price_zar": "9673.51",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBCH--big.svg"
                },
                "BNBUSD": {
                    "id": 32,
                    "market_id": 4,
                    "tradingview": "BNBUSD",
                    "name": "Binance Coin",
                    "polygon": "BNB-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BNBUSD",
                    "market_name": "crypto",
                    "price_aud": "529.67",
                    "price_brl": "1766.67",
                    "price_cad": "471.20",
                    "price_eur": "322.16",
                    "price_gbp": "276.84",
                    "price_usd": "344.34",
                    "price_php": "19831.23",
                    "price_zar": "6599.65",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBNB--big.svg"
                },
                "BNTUSD": {
                    "id": 46,
                    "market_id": 4,
                    "tradingview": "BNTUSD",
                    "name": "Bancor",
                    "polygon": "BNT-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BNTUSD",
                    "market_name": "crypto",
                    "price_aud": "1.17",
                    "price_brl": "3.90",
                    "price_cad": "1.04",
                    "price_eur": "0.71",
                    "price_gbp": "0.61",
                    "price_usd": "0.761",
                    "price_php": "43.83",
                    "price_zar": "14.59",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBNT--big.svg"
                },
                "BOBAUSD": {
                    "id": 47,
                    "market_id": 4,
                    "tradingview": "BOBAUSD",
                    "name": "BOBA",
                    "polygon": "BOBA-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BOBAUSD",
                    "market_name": "crypto",
                    "price_aud": "0.65",
                    "price_brl": "2.16",
                    "price_cad": "0.58",
                    "price_eur": "0.39",
                    "price_gbp": "0.34",
                    "price_usd": "0.42122",
                    "price_php": "24.26",
                    "price_zar": "8.07",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBOBA--big.svg"
                },
                "BONDUSD": {
                    "id": 48,
                    "market_id": 4,
                    "tradingview": "BONDUSD",
                    "name": "BarnBridge",
                    "polygon": "BOND-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BONDUSD",
                    "market_name": "crypto",
                    "price_aud": "4.91",
                    "price_brl": "16.39",
                    "price_cad": "4.37",
                    "price_eur": "2.99",
                    "price_gbp": "2.57",
                    "price_usd": "3.195",
                    "price_php": "184.01",
                    "price_zar": "61.24",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBOND--big.svg"
                },
                "BOSONUSD": {
                    "id": 49,
                    "market_id": 4,
                    "tradingview": "BOSONUSD",
                    "name": "Boson Protocol",
                    "polygon": "BOSON-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BOSONUSD",
                    "market_name": "crypto",
                    "price_aud": "0.97",
                    "price_brl": "3.22",
                    "price_cad": "0.86",
                    "price_eur": "0.59",
                    "price_gbp": "0.51",
                    "price_usd": "0.62844",
                    "price_php": "36.19",
                    "price_zar": "12.04",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBOSON--big.svg"
                },
                "BTCUSD": {
                    "id": 30,
                    "market_id": 4,
                    "tradingview": "BTCUSD",
                    "name": "Bitcoin",
                    "polygon": "BTC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "BTCUSD",
                    "market_name": "crypto",
                    "price_aud": "102135.06",
                    "price_brl": "340661.58",
                    "price_cad": "90859.08",
                    "price_eur": "62120.39",
                    "price_gbp": "53381.89",
                    "price_usd": "66398",
                    "price_php": "3823993.62",
                    "price_zar": "1272590.71",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCBTC--big.svg"
                },
                "CHZUSD": {
                    "id": 50,
                    "market_id": 4,
                    "tradingview": "CHZUSD",
                    "name": "Chiliz",
                    "polygon": "CHZ-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "CHZUSD",
                    "market_name": "crypto",
                    "price_aud": "0.19",
                    "price_brl": "0.64",
                    "price_cad": "0.17",
                    "price_eur": "0.12",
                    "price_gbp": "0.10",
                    "price_usd": "0.1243",
                    "price_php": "7.16",
                    "price_zar": "2.38",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCCHZ--big.svg"
                },
                "COMPUSD": {
                    "id": 51,
                    "market_id": 4,
                    "tradingview": "COMPUSD",
                    "name": "Compound",
                    "polygon": "COMP-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "COMPUSD",
                    "market_name": "crypto",
                    "price_aud": "92.89",
                    "price_brl": "309.84",
                    "price_cad": "82.64",
                    "price_eur": "56.50",
                    "price_gbp": "48.55",
                    "price_usd": "60.39",
                    "price_php": "3477.98",
                    "price_zar": "1157.44",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCCOMP--big.svg"
                },
                "COTIUSD": {
                    "id": 52,
                    "market_id": 4,
                    "tradingview": "COTIUSD",
                    "name": "COTI",
                    "polygon": "COTI-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "COTIUSD",
                    "market_name": "crypto",
                    "price_aud": "0.21",
                    "price_brl": "0.69",
                    "price_cad": "0.18",
                    "price_eur": "0.13",
                    "price_gbp": "0.11",
                    "price_usd": "0.1342",
                    "price_php": "7.73",
                    "price_zar": "2.57",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCCOTI--big.svg"
                },
                "CRVUSD": {
                    "id": 53,
                    "market_id": 4,
                    "tradingview": "CRVUSD",
                    "name": "Curve DAO Token",
                    "polygon": "CRV-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "CRVUSD",
                    "market_name": "crypto",
                    "price_aud": "0.73",
                    "price_brl": "2.44",
                    "price_cad": "0.65",
                    "price_eur": "0.44",
                    "price_gbp": "0.38",
                    "price_usd": "0.475",
                    "price_php": "27.36",
                    "price_zar": "9.10",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCCRV--big.svg"
                },
                "CTSIUSD": {
                    "id": 54,
                    "market_id": 4,
                    "tradingview": "CTSIUSD",
                    "name": "Cartesi",
                    "polygon": "CTSI-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "CTSIUSD",
                    "market_name": "crypto",
                    "price_aud": "0.34",
                    "price_brl": "1.14",
                    "price_cad": "0.30",
                    "price_eur": "0.21",
                    "price_gbp": "0.18",
                    "price_usd": "0.2223",
                    "price_php": "12.80",
                    "price_zar": "4.26",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCCTSI--big.svg"
                },
                "CVCUSD": {
                    "id": 55,
                    "market_id": 4,
                    "tradingview": "CVCUSD",
                    "name": "Civic",
                    "polygon": "CVC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "CVCUSD",
                    "market_name": "crypto",
                    "price_aud": "0.26",
                    "price_brl": "0.88",
                    "price_cad": "0.23",
                    "price_eur": "0.16",
                    "price_gbp": "0.14",
                    "price_usd": "0.1708",
                    "price_php": "9.84",
                    "price_zar": "3.27",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCCVC--big.svg"
                },
                "DASHUSD": {
                    "id": 56,
                    "market_id": 4,
                    "tradingview": "DASHUSD",
                    "name": "Dash",
                    "polygon": "DASH-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "DASHUSD",
                    "market_name": "crypto",
                    "price_aud": "49.16",
                    "price_brl": "163.98",
                    "price_cad": "43.74",
                    "price_eur": "29.90",
                    "price_gbp": "25.70",
                    "price_usd": "31.961",
                    "price_php": "1840.70",
                    "price_zar": "612.57",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCDASH--big.svg"
                },
                "DOGEUSD": {
                    "id": 57,
                    "market_id": 4,
                    "tradingview": "DOGEUSD",
                    "name": "Dogecoin",
                    "polygon": "DOGE-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "DOGEUSD",
                    "market_name": "crypto",
                    "price_aud": "0.25",
                    "price_brl": "0.83",
                    "price_cad": "0.22",
                    "price_eur": "0.15",
                    "price_gbp": "0.13",
                    "price_usd": "0.16167",
                    "price_php": "9.31",
                    "price_zar": "3.10",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCDOGE--big.svg"
                },
                "DOTUSD": {
                    "id": 58,
                    "market_id": 4,
                    "tradingview": "DOTUSD",
                    "name": "Polkadot",
                    "polygon": "DOT-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "DOTUSD",
                    "market_name": "crypto",
                    "price_aud": "11.46",
                    "price_brl": "38.23",
                    "price_cad": "10.20",
                    "price_eur": "6.97",
                    "price_gbp": "5.99",
                    "price_usd": "7.4504",
                    "price_php": "429.08",
                    "price_zar": "142.80",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCDOT--big.svg"
                },
                "DUSKUSD": {
                    "id": 59,
                    "market_id": 4,
                    "tradingview": "DUSKUSD",
                    "name": "DUSK",
                    "polygon": "DUSK-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "DUSKUSD",
                    "market_name": "crypto",
                    "price_aud": "0.59",
                    "price_brl": "1.96",
                    "price_cad": "0.52",
                    "price_eur": "0.36",
                    "price_gbp": "0.31",
                    "price_usd": "0.38228",
                    "price_php": "22.02",
                    "price_zar": "7.33",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCDUSK--big.svg"
                },
                "EGLDUSD": {
                    "id": 60,
                    "market_id": 4,
                    "tradingview": "EGLDUSD",
                    "name": "MultiversX (Elrond)",
                    "polygon": "EGLD-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "EGLDUSD",
                    "market_name": "crypto",
                    "price_aud": "69.05",
                    "price_brl": "230.31",
                    "price_cad": "61.43",
                    "price_eur": "42.00",
                    "price_gbp": "36.09",
                    "price_usd": "44.89",
                    "price_php": "2585.30",
                    "price_zar": "860.37",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCEGLD--big.svg"
                },
                "ENJUSD": {
                    "id": 61,
                    "market_id": 4,
                    "tradingview": "ENJUSD",
                    "name": "Enjin Coin",
                    "polygon": "ENJ-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ENJUSD",
                    "market_name": "crypto",
                    "price_aud": "0.55",
                    "price_brl": "1.82",
                    "price_cad": "0.48",
                    "price_eur": "0.33",
                    "price_gbp": "0.28",
                    "price_usd": "0.35432",
                    "price_php": "20.41",
                    "price_zar": "6.79",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCENJ--big.svg"
                },
                "EOSUSD": {
                    "id": 62,
                    "market_id": 4,
                    "tradingview": "EOSUSD",
                    "name": "EOS",
                    "polygon": "EOS-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "EOSUSD",
                    "market_name": "crypto",
                    "price_aud": "1.34",
                    "price_brl": "4.46",
                    "price_cad": "1.19",
                    "price_eur": "0.81",
                    "price_gbp": "0.70",
                    "price_usd": "0.86858",
                    "price_php": "50.02",
                    "price_zar": "16.65",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCEOS--big.svg"
                },
                "ETCUSD": {
                    "id": 63,
                    "market_id": 4,
                    "tradingview": "ETCUSD",
                    "name": "Ethereum Classic",
                    "polygon": "ETC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ETCUSD",
                    "market_name": "crypto",
                    "price_aud": "43.11",
                    "price_brl": "143.78",
                    "price_cad": "38.35",
                    "price_eur": "26.22",
                    "price_gbp": "22.53",
                    "price_usd": "28.024",
                    "price_php": "1613.96",
                    "price_zar": "537.11",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCETC--big.svg"
                },
                "ETHUSD": {
                    "id": 31,
                    "market_id": 4,
                    "tradingview": "ETHUSD",
                    "name": "Ethereum",
                    "polygon": "ETH-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ETHUSD",
                    "market_name": "crypto",
                    "price_aud": "5034.61",
                    "price_brl": "16792.45",
                    "price_cad": "4478.78",
                    "price_eur": "3062.14",
                    "price_gbp": "2631.39",
                    "price_usd": "3273",
                    "price_php": "188498.62",
                    "price_zar": "62730.65",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCETH--big.svg"
                },
                "FARMUSD": {
                    "id": 64,
                    "market_id": 4,
                    "tradingview": "FARMUSD",
                    "name": "Harvest Finance",
                    "polygon": "FARM-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "FARMUSD",
                    "market_name": "crypto",
                    "price_aud": "125.24",
                    "price_brl": "417.73",
                    "price_cad": "111.42",
                    "price_eur": "76.17",
                    "price_gbp": "65.46",
                    "price_usd": "81.42",
                    "price_php": "4689.14",
                    "price_zar": "1560.50",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCFARM--big.svg"
                },
                "FETUSD": {
                    "id": 65,
                    "market_id": 4,
                    "tradingview": "FETUSD",
                    "name": "Fetch.ai",
                    "polygon": "FET-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "FETUSD",
                    "market_name": "crypto",
                    "price_aud": "3.90",
                    "price_brl": "13.00",
                    "price_cad": "3.47",
                    "price_eur": "2.37",
                    "price_gbp": "2.04",
                    "price_usd": "2.5333",
                    "price_php": "145.90",
                    "price_zar": "48.55",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCFET--big.svg"
                },
                "FILUSD": {
                    "id": 66,
                    "market_id": 4,
                    "tradingview": "FILUSD",
                    "name": "Filecoin",
                    "polygon": "FIL-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "FILUSD",
                    "market_name": "crypto",
                    "price_aud": "9.96",
                    "price_brl": "33.24",
                    "price_cad": "8.86",
                    "price_eur": "6.06",
                    "price_gbp": "5.21",
                    "price_usd": "6.478",
                    "price_php": "373.08",
                    "price_zar": "124.16",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCFIL--big.svg"
                },
                "FLOWUSD": {
                    "id": 67,
                    "market_id": 4,
                    "tradingview": "FLOWUSD",
                    "name": "Flow",
                    "polygon": "FLOW-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "FLOWUSD",
                    "market_name": "crypto",
                    "price_aud": "1.55",
                    "price_brl": "5.16",
                    "price_cad": "1.38",
                    "price_eur": "0.94",
                    "price_gbp": "0.81",
                    "price_usd": "1.006",
                    "price_php": "57.94",
                    "price_zar": "19.28",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCFLOW--big.svg"
                },
                "FORTHUSD": {
                    "id": 68,
                    "market_id": 4,
                    "tradingview": "FORTHUSD",
                    "name": "FORTH",
                    "polygon": "FORTH-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "FORTHUSD",
                    "market_name": "crypto",
                    "price_aud": "7.50",
                    "price_brl": "25.03",
                    "price_cad": "6.68",
                    "price_eur": "4.56",
                    "price_gbp": "3.92",
                    "price_usd": "4.879",
                    "price_php": "280.99",
                    "price_zar": "93.51",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCFORTH--big.svg"
                },
                "FTMUSD": {
                    "id": 69,
                    "market_id": 4,
                    "tradingview": "FTMUSD",
                    "name": "Fantom",
                    "polygon": "FTM-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "FTMUSD",
                    "market_name": "crypto",
                    "price_aud": "1.16",
                    "price_brl": "3.87",
                    "price_cad": "1.03",
                    "price_eur": "0.71",
                    "price_gbp": "0.61",
                    "price_usd": "0.7545",
                    "price_php": "43.45",
                    "price_zar": "14.46",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCFTM--big.svg"
                },
                "GALAUSD": {
                    "id": 70,
                    "market_id": 4,
                    "tradingview": "GALAUSD",
                    "name": "Gala",
                    "polygon": "GALA-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "GALAUSD",
                    "market_name": "crypto",
                    "price_aud": "0.08",
                    "price_brl": "0.26",
                    "price_cad": "0.07",
                    "price_eur": "0.05",
                    "price_gbp": "0.04",
                    "price_usd": "0.0508",
                    "price_php": "2.93",
                    "price_zar": "0.97",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCGALA--big.svg"
                },
                "GNOUSD": {
                    "id": 71,
                    "market_id": 4,
                    "tradingview": "GNOUSD",
                    "name": "Gnosis",
                    "polygon": "GNO-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "GNOUSD",
                    "market_name": "crypto",
                    "price_aud": "560.74",
                    "price_brl": "1870.31",
                    "price_cad": "498.84",
                    "price_eur": "341.05",
                    "price_gbp": "293.08",
                    "price_usd": "364.54",
                    "price_php": "20994.59",
                    "price_zar": "6986.81",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCGNO--big.svg"
                },
                "GRTUSD": {
                    "id": 72,
                    "market_id": 4,
                    "tradingview": "GRTUSD",
                    "name": "The Graph",
                    "polygon": "GRT-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "GRTUSD",
                    "market_name": "crypto",
                    "price_aud": "0.45",
                    "price_brl": "1.50",
                    "price_cad": "0.40",
                    "price_eur": "0.27",
                    "price_gbp": "0.24",
                    "price_usd": "0.29305",
                    "price_php": "16.88",
                    "price_zar": "5.62",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCGRAPH--big.svg"
                },
                "GTCUSD": {
                    "id": 73,
                    "market_id": 4,
                    "tradingview": "GTCUSD",
                    "name": "Gitcoin",
                    "polygon": "GTC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "GTCUSD",
                    "market_name": "crypto",
                    "price_aud": "2.02",
                    "price_brl": "6.74",
                    "price_cad": "1.80",
                    "price_eur": "1.23",
                    "price_gbp": "1.06",
                    "price_usd": "1.313",
                    "price_php": "75.62",
                    "price_zar": "25.17",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCGTC--big.svg"
                },
                "HBARUSD": {
                    "id": 74,
                    "market_id": 4,
                    "tradingview": "HBARUSD",
                    "name": "Hedera",
                    "polygon": "HBAR-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "HBARUSD",
                    "market_name": "crypto",
                    "price_aud": "0.20",
                    "price_brl": "0.65",
                    "price_cad": "0.17",
                    "price_eur": "0.12",
                    "price_gbp": "0.10",
                    "price_usd": "0.127",
                    "price_php": "7.31",
                    "price_zar": "2.43",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCHBAR--big.svg"
                },
                "HMTUSD": {
                    "id": 75,
                    "market_id": 4,
                    "tradingview": "HMTUSD",
                    "name": "HMT",
                    "polygon": "HMT-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "HMTUSD",
                    "market_name": "crypto",
                    "price_aud": "0.05",
                    "price_brl": "0.17",
                    "price_cad": "0.05",
                    "price_eur": "0.03",
                    "price_gbp": "0.03",
                    "price_usd": "0.03393",
                    "price_php": "1.95",
                    "price_zar": "0.65",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCHMT--big.svg"
                },
                "ICPUSD": {
                    "id": 107,
                    "market_id": 4,
                    "tradingview": "ICPUSD",
                    "name": "Internet Computer",
                    "polygon": "ICP-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ICPUSD",
                    "market_name": "crypto",
                    "price_aud": "22.53",
                    "price_brl": "75.16",
                    "price_cad": "20.05",
                    "price_eur": "13.71",
                    "price_gbp": "11.78",
                    "price_usd": "14.65",
                    "price_php": "843.72",
                    "price_zar": "280.78",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCICP--big.svg"
                },
                "ICXUSD": {
                    "id": 125,
                    "market_id": 4,
                    "tradingview": "ICXUSD",
                    "name": "ICON",
                    "polygon": "ICX-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ICXUSD",
                    "market_name": "crypto",
                    "price_aud": "0.40",
                    "price_brl": "1.33",
                    "price_cad": "0.36",
                    "price_eur": "0.24",
                    "price_gbp": "0.21",
                    "price_usd": "0.2602",
                    "price_php": "14.99",
                    "price_zar": "4.99",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCICX--big.svg"
                },
                "IMXUSD": {
                    "id": 76,
                    "market_id": 4,
                    "tradingview": "IMXUSD",
                    "name": "Immutable X",
                    "polygon": "IMX-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "IMXUSD",
                    "market_name": "crypto",
                    "price_aud": "3.69",
                    "price_brl": "12.31",
                    "price_cad": "3.28",
                    "price_eur": "2.24",
                    "price_gbp": "1.93",
                    "price_usd": "2.399",
                    "price_php": "138.16",
                    "price_zar": "45.98",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCIMXIMMUTABLEX--big.svg"
                },
                "INJUSD": {
                    "id": 77,
                    "market_id": 4,
                    "tradingview": "INJUSD",
                    "name": "Injective",
                    "polygon": "INJ-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "INJUSD",
                    "market_name": "crypto",
                    "price_aud": "44.55",
                    "price_brl": "148.58",
                    "price_cad": "39.63",
                    "price_eur": "27.09",
                    "price_gbp": "23.28",
                    "price_usd": "28.96",
                    "price_php": "1667.86",
                    "price_zar": "555.05",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCINJ--big.svg"
                },
                "JASMYUSD": {
                    "id": 78,
                    "market_id": 4,
                    "tradingview": "JASMYUSD",
                    "name": "JasmyCoin",
                    "polygon": "JASMY-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "JASMYUSD",
                    "market_name": "crypto",
                    "price_aud": "0.03",
                    "price_brl": "0.11",
                    "price_cad": "0.03",
                    "price_eur": "0.02",
                    "price_gbp": "0.02",
                    "price_usd": "0.02068",
                    "price_php": "1.19",
                    "price_zar": "0.40",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCJASMY--big.svg"
                },
                "JSTUSD": {
                    "id": 79,
                    "market_id": 4,
                    "tradingview": "JSTUSD",
                    "name": "JST",
                    "polygon": "JST-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "JSTUSD",
                    "market_name": "crypto",
                    "price_aud": "0.05",
                    "price_brl": "0.18",
                    "price_cad": "0.05",
                    "price_eur": "0.03",
                    "price_gbp": "0.03",
                    "price_usd": "0.034367",
                    "price_php": "1.98",
                    "price_zar": "0.66",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCJST--big.svg"
                },
                "KAVAUSD": {
                    "id": 80,
                    "market_id": 4,
                    "tradingview": "KAVAUSD",
                    "name": "Kava",
                    "polygon": "KAVA-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "KAVAUSD",
                    "market_name": "crypto",
                    "price_aud": "1.18",
                    "price_brl": "3.93",
                    "price_cad": "1.05",
                    "price_eur": "0.72",
                    "price_gbp": "0.62",
                    "price_usd": "0.7669",
                    "price_php": "44.17",
                    "price_zar": "14.70",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCKAVA--big.svg"
                },
                "KEEPUSD": {
                    "id": 81,
                    "market_id": 4,
                    "tradingview": "KEEPUSD",
                    "name": "Keep Network",
                    "polygon": "KEEP-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "KEEPUSD",
                    "market_name": "crypto",
                    "price_aud": "0.27",
                    "price_brl": "0.92",
                    "price_cad": "0.24",
                    "price_eur": "0.17",
                    "price_gbp": "0.14",
                    "price_usd": "0.17842",
                    "price_php": "10.28",
                    "price_zar": "3.42",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCKEEP--big.svg"
                },
                "KNCUSD": {
                    "id": 82,
                    "market_id": 4,
                    "tradingview": "KNCUSD",
                    "name": "Kyber Network",
                    "polygon": "KNC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "KNCUSD",
                    "market_name": "crypto",
                    "price_aud": "0.99",
                    "price_brl": "3.31",
                    "price_cad": "0.88",
                    "price_eur": "0.60",
                    "price_gbp": "0.52",
                    "price_usd": "0.64516",
                    "price_php": "37.16",
                    "price_zar": "12.37",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCKNC--big.svg"
                },
                "KSMUSD": {
                    "id": 83,
                    "market_id": 4,
                    "tradingview": "KSMUSD",
                    "name": "Kusama",
                    "polygon": "KSM-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "KSMUSD",
                    "market_name": "crypto",
                    "price_aud": "48.17",
                    "price_brl": "160.65",
                    "price_cad": "42.85",
                    "price_eur": "29.30",
                    "price_gbp": "25.17",
                    "price_usd": "31.313",
                    "price_php": "1803.38",
                    "price_zar": "600.15",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCKSM--big.svg"
                },
                "LCXUSD": {
                    "id": 84,
                    "market_id": 4,
                    "tradingview": "LCXUSD",
                    "name": "LCX Token",
                    "polygon": "LCX-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "LCXUSD",
                    "market_name": "crypto",
                    "price_aud": "0.43",
                    "price_brl": "1.45",
                    "price_cad": "0.39",
                    "price_eur": "0.26",
                    "price_gbp": "0.23",
                    "price_usd": "0.28219",
                    "price_php": "16.25",
                    "price_zar": "5.41",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCLCX--big.svg"
                },
                "LINKUSD": {
                    "id": 85,
                    "market_id": 4,
                    "tradingview": "LINKUSD",
                    "name": "Chainlink",
                    "polygon": "LINK-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "LINKUSD",
                    "market_name": "crypto",
                    "price_aud": "23.80",
                    "price_brl": "79.37",
                    "price_cad": "21.17",
                    "price_eur": "14.47",
                    "price_gbp": "12.44",
                    "price_usd": "15.47",
                    "price_php": "890.95",
                    "price_zar": "296.50",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCLINK--big.svg"
                },
                "LPTUSD": {
                    "id": 86,
                    "market_id": 4,
                    "tradingview": "LPTUSD",
                    "name": "Livepeer",
                    "polygon": "LPT-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "LPTUSD",
                    "market_name": "crypto",
                    "price_aud": "23.41",
                    "price_brl": "78.09",
                    "price_cad": "20.83",
                    "price_eur": "14.24",
                    "price_gbp": "12.24",
                    "price_usd": "15.22",
                    "price_php": "876.55",
                    "price_zar": "291.71",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCLPT--big.svg"
                },
                "LRCUSD": {
                    "id": 87,
                    "market_id": 4,
                    "tradingview": "LRCUSD",
                    "name": "Loopring",
                    "polygon": "LRC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "LRCUSD",
                    "market_name": "crypto",
                    "price_aud": "0.44",
                    "price_brl": "1.45",
                    "price_cad": "0.39",
                    "price_eur": "0.26",
                    "price_gbp": "0.23",
                    "price_usd": "0.2831",
                    "price_php": "16.30",
                    "price_zar": "5.43",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCLRC--big.svg"
                },
                "LSKUSD": {
                    "id": 135,
                    "market_id": 4,
                    "tradingview": "LSKUSD",
                    "name": "LSK",
                    "polygon": "LSK-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "LSKUSD",
                    "market_name": "crypto",
                    "price_aud": "2.68",
                    "price_brl": "8.95",
                    "price_cad": "2.39",
                    "price_eur": "1.63",
                    "price_gbp": "1.40",
                    "price_usd": "1.743592",
                    "price_php": "100.42",
                    "price_zar": "33.42",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCLSK--big.svg"
                },
                "LTCUSD": {
                    "id": 88,
                    "market_id": 4,
                    "tradingview": "LTCUSD",
                    "name": "Litecoin",
                    "polygon": "LTC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "LTCUSD",
                    "market_name": "crypto",
                    "price_aud": "133.98",
                    "price_brl": "446.88",
                    "price_cad": "119.19",
                    "price_eur": "81.49",
                    "price_gbp": "70.02",
                    "price_usd": "87.101",
                    "price_php": "5016.35",
                    "price_zar": "1669.38",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCLTC--big.svg"
                },
                "LUNAUSD": {
                    "id": 89,
                    "market_id": 4,
                    "tradingview": "LUNAUSD",
                    "name": "LUNA",
                    "polygon": "LUNA-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "LUNAUSD",
                    "market_name": "crypto",
                    "price_aud": "0.00",
                    "price_brl": "0.00",
                    "price_cad": "0.00",
                    "price_eur": "0.00",
                    "price_gbp": "0.00",
                    "price_usd": "0.00011316",
                    "price_php": "0.01",
                    "price_zar": "0.00",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCLUNA--big.svg"
                },
                "LYMUSD": {
                    "id": 90,
                    "market_id": 4,
                    "tradingview": "LYMUSD",
                    "name": "LYM",
                    "polygon": "LYM-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "LYMUSD",
                    "market_name": "crypto",
                    "price_aud": "0.00",
                    "price_brl": "0.02",
                    "price_cad": "0.00",
                    "price_eur": "0.00",
                    "price_gbp": "0.00",
                    "price_usd": "0.0031192",
                    "price_php": "0.18",
                    "price_zar": "0.06",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCLYM--big.svg"
                },
                "MANAUSD": {
                    "id": 91,
                    "market_id": 4,
                    "tradingview": "MANAUSD",
                    "name": "Decentraland",
                    "polygon": "MANA-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "MANAUSD",
                    "market_name": "crypto",
                    "price_aud": "0.76",
                    "price_brl": "2.54",
                    "price_cad": "0.68",
                    "price_eur": "0.46",
                    "price_gbp": "0.40",
                    "price_usd": "0.49509",
                    "price_php": "28.51",
                    "price_zar": "9.49",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCMANA--big.svg"
                },
                "MASKUSD": {
                    "id": 92,
                    "market_id": 4,
                    "tradingview": "MASKUSD",
                    "name": "Mask Network",
                    "polygon": "MASK-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "MASKUSD",
                    "market_name": "crypto",
                    "price_aud": "5.73",
                    "price_brl": "19.12",
                    "price_cad": "5.10",
                    "price_eur": "3.49",
                    "price_gbp": "3.00",
                    "price_usd": "3.726",
                    "price_php": "214.59",
                    "price_zar": "71.41",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCMASK--big.svg"
                },
                "MATICUSD": {
                    "id": 93,
                    "market_id": 4,
                    "tradingview": "MATICUSD",
                    "name": "Polygon",
                    "polygon": "MATIC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "MATICUSD",
                    "market_name": "crypto",
                    "price_aud": "1.16",
                    "price_brl": "3.88",
                    "price_cad": "1.03",
                    "price_eur": "0.71",
                    "price_gbp": "0.61",
                    "price_usd": "0.7558",
                    "price_php": "43.53",
                    "price_zar": "14.49",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCMATIC--big.svg"
                },
                "MIRUSD": {
                    "id": 94,
                    "market_id": 4,
                    "tradingview": "MIRUSD",
                    "name": "Mirror Protocol",
                    "polygon": "MIR-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "MIRUSD",
                    "market_name": "crypto",
                    "price_aud": "0.09",
                    "price_brl": "0.29",
                    "price_cad": "0.08",
                    "price_eur": "0.05",
                    "price_gbp": "0.05",
                    "price_usd": "0.057",
                    "price_php": "3.28",
                    "price_zar": "1.09",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCMIR--big.svg"
                },
                "MKRUSD": {
                    "id": 95,
                    "market_id": 4,
                    "tradingview": "MKRUSD",
                    "name": "Maker",
                    "polygon": "MKR-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "MKRUSD",
                    "market_name": "crypto",
                    "price_aud": "4648.60",
                    "price_brl": "15505.19",
                    "price_cad": "4135.39",
                    "price_eur": "2827.30",
                    "price_gbp": "2429.59",
                    "price_usd": "3022.1",
                    "price_php": "174051.81",
                    "price_zar": "57922.48",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCMKR--big.svg"
                },
                "MLNUSD": {
                    "id": 96,
                    "market_id": 4,
                    "tradingview": "MLNUSD",
                    "name": "Enzyme",
                    "polygon": "MLN-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "MLNUSD",
                    "market_name": "crypto",
                    "price_aud": "35.18",
                    "price_brl": "117.36",
                    "price_cad": "31.30",
                    "price_eur": "21.40",
                    "price_gbp": "18.39",
                    "price_usd": "22.874",
                    "price_php": "1317.38",
                    "price_zar": "438.41",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCMLN--big.svg"
                },
                "NANOUSD": {
                    "id": 97,
                    "market_id": 4,
                    "tradingview": "NANOUSD",
                    "name": "Nano",
                    "polygon": "NANO-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "NANOUSD",
                    "market_name": "crypto",
                    "price_aud": "1.98",
                    "price_brl": "6.60",
                    "price_cad": "1.76",
                    "price_eur": "1.20",
                    "price_gbp": "1.03",
                    "price_usd": "1.285752",
                    "price_php": "74.05",
                    "price_zar": "24.64",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCNANO--big.svg"
                },
                "NEARUSD": {
                    "id": 98,
                    "market_id": 4,
                    "tradingview": "NEARUSD",
                    "name": "NEAR Protocol",
                    "polygon": "NEAR-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "NEARUSD",
                    "market_name": "crypto",
                    "price_aud": "10.78",
                    "price_brl": "35.97",
                    "price_cad": "9.59",
                    "price_eur": "6.56",
                    "price_gbp": "5.64",
                    "price_usd": "7.01",
                    "price_php": "403.73",
                    "price_zar": "134.36",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCNEAR--big.svg"
                },
                "NEOUSD": {
                    "id": 99,
                    "market_id": 4,
                    "tradingview": "NEOUSD",
                    "name": "NEO",
                    "polygon": "NEO-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "NEOUSD",
                    "market_name": "crypto",
                    "price_aud": "28.50",
                    "price_brl": "95.06",
                    "price_cad": "25.35",
                    "price_eur": "17.33",
                    "price_gbp": "14.90",
                    "price_usd": "18.528",
                    "price_php": "1067.08",
                    "price_zar": "355.11",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCNEO--big.svg"
                },
                "NEXOUSD": {
                    "id": 100,
                    "market_id": 4,
                    "tradingview": "NEXOUSD",
                    "name": "NEXO",
                    "polygon": "NEXO-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "NEXOUSD",
                    "market_name": "crypto",
                    "price_aud": "1.99",
                    "price_brl": "6.64",
                    "price_cad": "1.77",
                    "price_eur": "1.21",
                    "price_gbp": "1.04",
                    "price_usd": "1.2948",
                    "price_php": "74.57",
                    "price_zar": "24.82",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCNEXO--big.svg"
                },
                "OCEANUSD": {
                    "id": 126,
                    "market_id": 4,
                    "tradingview": "OCEANUSD",
                    "name": "Ocean Protocol",
                    "polygon": "OCEAN-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "OCEANUSD",
                    "market_name": "crypto",
                    "price_aud": "1.62",
                    "price_brl": "5.41",
                    "price_cad": "1.44",
                    "price_eur": "0.99",
                    "price_gbp": "0.85",
                    "price_usd": "1.055",
                    "price_php": "60.76",
                    "price_zar": "20.22",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCOCEAN--big.svg"
                },
                "PAXGUSD": {
                    "id": 115,
                    "market_id": 4,
                    "tradingview": "PAXGUSD",
                    "name": "PAX Gold",
                    "polygon": "PAXG-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "PAXGUSD",
                    "market_name": "crypto",
                    "price_aud": "3542.48",
                    "price_brl": "11815.77",
                    "price_cad": "3151.38",
                    "price_eur": "2154.55",
                    "price_gbp": "1851.48",
                    "price_usd": "2303",
                    "price_php": "132636.68",
                    "price_zar": "44139.99",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCPAXG--big.svg"
                },
                "QNTUSD": {
                    "id": 109,
                    "market_id": 4,
                    "tradingview": "QNTUSD",
                    "name": "Quant",
                    "polygon": "QNT-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "QNTUSD",
                    "market_name": "crypto",
                    "price_aud": "173.22",
                    "price_brl": "577.76",
                    "price_cad": "154.09",
                    "price_eur": "105.35",
                    "price_gbp": "90.53",
                    "price_usd": "112.61",
                    "price_php": "6485.55",
                    "price_zar": "2158.32",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCQNT--big.svg"
                },
                "QTUMUSD": {
                    "id": 118,
                    "market_id": 4,
                    "tradingview": "QTUMUSD",
                    "name": "Qtum",
                    "polygon": "QTUM-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "QTUMUSD",
                    "market_name": "crypto",
                    "price_aud": "6.52",
                    "price_brl": "21.75",
                    "price_cad": "5.80",
                    "price_eur": "3.97",
                    "price_gbp": "3.41",
                    "price_usd": "4.23929",
                    "price_php": "244.15",
                    "price_zar": "81.25",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCQTUM--big.svg"
                },
                "RNDRUSD": {
                    "id": 112,
                    "market_id": 4,
                    "tradingview": "RNDRUSD",
                    "name": "Render Token",
                    "polygon": "RNDR-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "RNDRUSD",
                    "market_name": "crypto",
                    "price_aud": "14.17",
                    "price_brl": "47.27",
                    "price_cad": "12.61",
                    "price_eur": "8.62",
                    "price_gbp": "7.41",
                    "price_usd": "9.214",
                    "price_php": "530.66",
                    "price_zar": "176.60",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCRNDR--big.svg"
                },
                "ROSEUSD": {
                    "id": 119,
                    "market_id": 4,
                    "tradingview": "ROSEUSD",
                    "name": "Oasis Network",
                    "polygon": "ROSE-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ROSEUSD",
                    "market_name": "crypto",
                    "price_aud": "0.19",
                    "price_brl": "0.64",
                    "price_cad": "0.17",
                    "price_eur": "0.12",
                    "price_gbp": "0.10",
                    "price_usd": "0.1246",
                    "price_php": "7.18",
                    "price_zar": "2.39",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCROSE--big.svg"
                },
                "SANDUSD": {
                    "id": 110,
                    "market_id": 4,
                    "tradingview": "SANDUSD",
                    "name": "The Sandbox",
                    "polygon": "SAND-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "SANDUSD",
                    "market_name": "crypto",
                    "price_aud": "0.77",
                    "price_brl": "2.56",
                    "price_cad": "0.68",
                    "price_eur": "0.47",
                    "price_gbp": "0.40",
                    "price_usd": "0.49862",
                    "price_php": "28.72",
                    "price_zar": "9.56",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCSAND--big.svg"
                },
                "SCUSD": {
                    "id": 131,
                    "market_id": 4,
                    "tradingview": "SCUSD",
                    "name": "Siacom",
                    "polygon": "SC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "SCUSD",
                    "market_name": "crypto",
                    "price_aud": "0.01",
                    "price_brl": "0.04",
                    "price_cad": "0.01",
                    "price_eur": "0.01",
                    "price_gbp": "0.01",
                    "price_usd": "0.00804",
                    "price_php": "0.46",
                    "price_zar": "0.15",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCSC--big.svg"
                },
                "SHIBUSD": {
                    "id": 103,
                    "market_id": 4,
                    "tradingview": "SHIBUSD",
                    "name": "Shiba Inu",
                    "polygon": "SHIB-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "SHIBUSD",
                    "market_name": "crypto",
                    "price_aud": "0.00",
                    "price_brl": "0.00",
                    "price_cad": "0.00",
                    "price_eur": "0.00",
                    "price_gbp": "0.00",
                    "price_usd": "2.708E-5",
                    "price_php": "0.00",
                    "price_zar": "0.00",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCSHIB--big.svg"
                },
                "SKLUSD": {
                    "id": 132,
                    "market_id": 4,
                    "tradingview": "SKLUSD",
                    "name": "SKL",
                    "polygon": "SKL-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "SKLUSD",
                    "market_name": "crypto",
                    "price_aud": "0.16",
                    "price_brl": "0.53",
                    "price_cad": "0.14",
                    "price_eur": "0.10",
                    "price_gbp": "0.08",
                    "price_usd": "0.10298",
                    "price_php": "5.93",
                    "price_zar": "1.97",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCSKL--big.svg"
                },
                "SNXUSD": {
                    "id": 113,
                    "market_id": 4,
                    "tradingview": "SNXUSD",
                    "name": "Synthetix",
                    "polygon": "SNX-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "SNXUSD",
                    "market_name": "crypto",
                    "price_aud": "4.75",
                    "price_brl": "15.85",
                    "price_cad": "4.23",
                    "price_eur": "2.89",
                    "price_gbp": "2.48",
                    "price_usd": "3.089",
                    "price_php": "177.90",
                    "price_zar": "59.20",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCSNX--big.svg"
                },
                "SOLUSD": {
                    "id": 102,
                    "market_id": 4,
                    "tradingview": "SOLUSD",
                    "name": "Solana",
                    "polygon": "SOL-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "SOLUSD",
                    "market_name": "crypto",
                    "price_aud": "242.66",
                    "price_brl": "809.38",
                    "price_cad": "215.87",
                    "price_eur": "147.59",
                    "price_gbp": "126.83",
                    "price_usd": "157.7553",
                    "price_php": "9085.60",
                    "price_zar": "3023.59",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCSOL--big.svg"
                },
                "STORJUSD": {
                    "id": 133,
                    "market_id": 4,
                    "tradingview": "STORJUSD",
                    "name": "STORJ",
                    "polygon": "STORJ-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "STORJUSD",
                    "market_name": "crypto",
                    "price_aud": "0.91",
                    "price_brl": "3.03",
                    "price_cad": "0.81",
                    "price_eur": "0.55",
                    "price_gbp": "0.47",
                    "price_usd": "0.5902",
                    "price_php": "33.99",
                    "price_zar": "11.31",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCSTORJ--big.svg"
                },
                "SUSHIUSD": {
                    "id": 130,
                    "market_id": 4,
                    "tradingview": "SUSHIUSD",
                    "name": "SushiSwap",
                    "polygon": "SUSHI-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "SUSHIUSD",
                    "market_name": "crypto",
                    "price_aud": "1.64",
                    "price_brl": "5.47",
                    "price_cad": "1.46",
                    "price_eur": "1.00",
                    "price_gbp": "0.86",
                    "price_usd": "1.067",
                    "price_php": "61.45",
                    "price_zar": "20.45",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCSUSHI--big.svg"
                },
                "TRACUSD": {
                    "id": 122,
                    "market_id": 4,
                    "tradingview": "TRACUSD",
                    "name": "TRAC",
                    "polygon": "TRAC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "TRACUSD",
                    "market_name": "crypto",
                    "price_aud": "1.52",
                    "price_brl": "5.08",
                    "price_cad": "1.35",
                    "price_eur": "0.93",
                    "price_gbp": "0.80",
                    "price_usd": "0.9893",
                    "price_php": "56.98",
                    "price_zar": "18.96",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCTRAC--big.svg"
                },
                "TRXUSD": {
                    "id": 33,
                    "market_id": 4,
                    "tradingview": "TRXUSD",
                    "name": "TRON",
                    "polygon": "TRX-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "TRXUSD",
                    "market_name": "crypto",
                    "price_aud": "0.18",
                    "price_brl": "0.58",
                    "price_cad": "0.16",
                    "price_eur": "0.11",
                    "price_gbp": "0.09",
                    "price_usd": "0.11378",
                    "price_php": "6.55",
                    "price_zar": "2.18",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCTRX--big.svg"
                },
                "UMAUSD": {
                    "id": 134,
                    "market_id": 4,
                    "tradingview": "UMAUSD",
                    "name": "UMA",
                    "polygon": "UMA-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "UMAUSD",
                    "market_name": "crypto",
                    "price_aud": "4.62",
                    "price_brl": "15.42",
                    "price_cad": "4.11",
                    "price_eur": "2.81",
                    "price_gbp": "2.42",
                    "price_usd": "3.006",
                    "price_php": "173.12",
                    "price_zar": "57.61",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCUMA--big.svg"
                },
                "UNIUSD": {
                    "id": 106,
                    "market_id": 4,
                    "tradingview": "UNIUSD",
                    "name": "Uniswap",
                    "polygon": "UNI-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "UNIUSD",
                    "market_name": "crypto",
                    "price_aud": "12.50",
                    "price_brl": "41.70",
                    "price_cad": "11.12",
                    "price_eur": "7.60",
                    "price_gbp": "6.53",
                    "price_usd": "8.1284",
                    "price_php": "468.14",
                    "price_zar": "155.79",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCUNI--big.svg"
                },
                "VETUSD": {
                    "id": 108,
                    "market_id": 4,
                    "tradingview": "VETUSD",
                    "name": "Vechain",
                    "polygon": "VET-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "VETUSD",
                    "market_name": "crypto",
                    "price_aud": "0.07",
                    "price_brl": "0.22",
                    "price_cad": "0.06",
                    "price_eur": "0.04",
                    "price_gbp": "0.03",
                    "price_usd": "0.042473",
                    "price_php": "2.45",
                    "price_zar": "0.81",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCVET--big.svg"
                },
                "WAVESUSD": {
                    "id": 124,
                    "market_id": 4,
                    "tradingview": "WAVESUSD",
                    "name": "WAVES",
                    "polygon": "WAVES-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "WAVESUSD",
                    "market_name": "crypto",
                    "price_aud": "4.07",
                    "price_brl": "13.56",
                    "price_cad": "3.62",
                    "price_eur": "2.47",
                    "price_gbp": "2.13",
                    "price_usd": "2.6436",
                    "price_php": "152.25",
                    "price_zar": "50.67",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCWAVES--big.svg"
                },
                "XDCUSD": {
                    "id": 116,
                    "market_id": 4,
                    "tradingview": "XDCUSD",
                    "name": "XDC Network",
                    "polygon": "XDC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "XDCUSD",
                    "market_name": "crypto",
                    "price_aud": "0.07",
                    "price_brl": "0.22",
                    "price_cad": "0.06",
                    "price_eur": "0.04",
                    "price_gbp": "0.03",
                    "price_usd": "0.042863",
                    "price_php": "2.47",
                    "price_zar": "0.82",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCXDC--big.svg"
                },
                "XLMUSD": {
                    "id": 105,
                    "market_id": 4,
                    "tradingview": "XLMUSD",
                    "name": "Stellar",
                    "polygon": "XLM-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "XLMUSD",
                    "market_name": "crypto",
                    "price_aud": "0.18",
                    "price_brl": "0.61",
                    "price_cad": "0.16",
                    "price_eur": "0.11",
                    "price_gbp": "0.10",
                    "price_usd": "0.119264",
                    "price_php": "6.87",
                    "price_zar": "2.29",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCXLM--big.svg"
                },
                "XMRUSD": {
                    "id": 104,
                    "market_id": 4,
                    "tradingview": "XMRUSD",
                    "name": "Monero",
                    "polygon": "XMR-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "XMRUSD",
                    "market_name": "crypto",
                    "price_aud": "186.01",
                    "price_brl": "620.44",
                    "price_cad": "165.48",
                    "price_eur": "113.13",
                    "price_gbp": "97.22",
                    "price_usd": "120.93",
                    "price_php": "6964.72",
                    "price_zar": "2317.78",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCXMR--big.svg"
                },
                "XRDUSD": {
                    "id": 121,
                    "market_id": 4,
                    "tradingview": "XRDUSD",
                    "name": "Radix",
                    "polygon": "XRD-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "XRDUSD",
                    "market_name": "crypto",
                    "price_aud": "0.08",
                    "price_brl": "0.28",
                    "price_cad": "0.07",
                    "price_eur": "0.05",
                    "price_gbp": "0.04",
                    "price_usd": "0.054334",
                    "price_php": "3.13",
                    "price_zar": "1.04",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCXRD--big.svg"
                },
                "XRPUSD": {
                    "id": 101,
                    "market_id": 4,
                    "tradingview": "XRPUSD",
                    "name": "Ripple",
                    "polygon": "XRP-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "XRPUSD",
                    "market_name": "crypto",
                    "price_aud": "0.84",
                    "price_brl": "2.80",
                    "price_cad": "0.75",
                    "price_eur": "0.51",
                    "price_gbp": "0.44",
                    "price_usd": "0.54554",
                    "price_php": "31.42",
                    "price_zar": "10.46",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCXRP--big.svg"
                },
                "XTZUSD": {
                    "id": 111,
                    "market_id": 4,
                    "tradingview": "XTZUSD",
                    "name": "Tezos",
                    "polygon": "XTZ-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "XTZUSD",
                    "market_name": "crypto",
                    "price_aud": "1.63",
                    "price_brl": "5.44",
                    "price_cad": "1.45",
                    "price_eur": "0.99",
                    "price_gbp": "0.85",
                    "price_usd": "1.0608",
                    "price_php": "61.09",
                    "price_zar": "20.33",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCXTZ--big.svg"
                },
                "YFIUSD": {
                    "id": 123,
                    "market_id": 4,
                    "tradingview": "YFIUSD",
                    "name": "yearn.finance",
                    "polygon": "YFI-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "YFIUSD",
                    "market_name": "crypto",
                    "price_aud": "11527.28",
                    "price_brl": "38448.72",
                    "price_cad": "10254.66",
                    "price_eur": "7010.95",
                    "price_gbp": "6024.75",
                    "price_usd": "7494",
                    "price_php": "431601.94",
                    "price_zar": "143632.25",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCYFI--big.svg"
                },
                "ZECUSD": {
                    "id": 114,
                    "market_id": 4,
                    "tradingview": "ZECUSD",
                    "name": "ZCash",
                    "polygon": "ZEC-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ZECUSD",
                    "market_name": "crypto",
                    "price_aud": "37.35",
                    "price_brl": "124.58",
                    "price_cad": "33.23",
                    "price_eur": "22.72",
                    "price_gbp": "19.52",
                    "price_usd": "24.281",
                    "price_php": "1398.42",
                    "price_zar": "465.38",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCZEC--big.svg"
                },
                "ZILUSD": {
                    "id": 117,
                    "market_id": 4,
                    "tradingview": "ZILUSD",
                    "name": "Ziliqa",
                    "polygon": "ZIL-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ZILUSD",
                    "market_name": "crypto",
                    "price_aud": "0.04",
                    "price_brl": "0.14",
                    "price_cad": "0.04",
                    "price_eur": "0.03",
                    "price_gbp": "0.02",
                    "price_usd": "0.02779",
                    "price_php": "1.60",
                    "price_zar": "0.53",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCZIL--big.svg"
                },
                "ZRXUSD": {
                    "id": 127,
                    "market_id": 4,
                    "tradingview": "ZRXUSD",
                    "name": "Ox",
                    "polygon": "ZRX-USD",
                    "listed": "yes",
                    "type": "crypto",
                    "firebase": "ZRXUSD",
                    "market_name": "crypto",
                    "price_aud": "0.86",
                    "price_brl": "2.88",
                    "price_cad": "0.77",
                    "price_eur": "0.53",
                    "price_gbp": "0.45",
                    "price_usd": "0.56182",
                    "price_php": "32.36",
                    "price_zar": "10.77",
                    "icon": "https:\/\/s3-symbol-logo.tradingview.com\/crypto\/XTVCZRX--big.svg"
                }
            }
        }
    }
}
export default data;