import React, { useEffect, useState } from 'react'
import { InfinitySpin } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from '../../authentication/userAuth';
import { useNavigate } from 'react-router-dom';

const IdentityVerification = () => { 
    const { user, refreshUserData } = useAuth();
    const [filename1, setFilename1] = useState('');
    const [idType, setIdType] = useState('Passport');
    const [filename2, setFilename2] = useState('');
    const [isLoading, setIsloading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const getUser = async () => {
            const resp = await fetch('https://blazesignal.com/api/user/profile', {
                method: "POST",
                headers: {
                "content-type": "application/json"
                },
                body:JSON.stringify({id:user.owner_id})
            });
            const response = await resp.json();
            refreshUserData(response)
        }
        getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const uploadFile1 = (e) => {
        setFilename1(e.target.files[0].name)
    }

    const uploadFile2 = (e) => {
        setFilename2(e.target.files[0].name)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true);
        try {
            const formData = new FormData();
            formData.append("userId", user.owner_id);
            formData.append("idType", idType);
            formData.append(`front`, e.target.front.files[0]);
            formData.append(`back`, e.target.back.files[0]);
            const resp = await fetch("https://blazesignal.com/api/user/verification/identity-upload", {
                method: 'POST',
                body: formData
            });
            const response = await resp.json();
            if (response.status === 200) {
                toast(response.message);
                setIsloading(false)
                setTimeout(() => {
                    navigate('/user/account');
                }, 2000);
            } else {
                setIsloading(false);
                toast.error(response.message);
            }

        } catch (err) {
            setIsloading(false);
            toast.error(err.message);
        }
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div className="roww">
                <div className="coll l4 s12 offset-l4">
                    <div className="cardd-panel">
                        <h3 className="btnn-color center">Verify Your Identity</h3>
                        <p style={{ "textAlign": "justify" }}>
                            Please verify your identity by uploading a valid government issued identification card.
                            You may experience difficulties when uploading from an ios device.
                            Make sure your browser has camera access in your ios settings.
                        </p>
                        <div className="container">
                            <center><br />
                                <form encType="multipart/form-data" onSubmit={handleSubmit}>
                                    <div>
                                        <select
                                            name="idType"
                                            className="form-control form-select"
                                            value={idType}
                                            onChange={(e) => {
                                                setIdType(e.target.value)
                                            }}
                                        >
                                            <option selected value="Passport">
                                                Passport
                                            </option>
                                            <option value="Drivers License">
                                                Drivers License
                                            </option>
                                            <option value="National Id">
                                                National ID
                                            </option>
                                        </select>
                                    </div>
                                    <div>
                                        <div className="file-field input-field">
                                            <div className="btnn btnn-secondary">
                                                <span>select front</span>
                                                <input
                                                    type="file"
                                                    accept=".jpg,.png,.jpeg"
                                                    id="front"
                                                    name="front"
                                                    required
                                                    onChange={uploadFile1}
                                                    multiple={true}
                                                />
                                            </div>
                                            <div className="file-path-wrapper">
                                                <input className="file-path validate" type="text" value={filename1} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="file-field input-field">
                                            <div className="btnn btnn-secondary">
                                                <span>Select back</span>
                                                <input
                                                    type="file"
                                                    accept=".jpg,.png,.jpeg"
                                                    id="back"
                                                    name="back"
                                                    required
                                                    onChange={uploadFile2}
                                                />
                                            </div>
                                            <div className="file-path-wrapper">
                                                <input className="file-path validate" type="text" value={filename2} />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        isLoading ? (
                                            <InfinitySpin
                                                visible={true}
                                                width="200"
                                                color="#4fa94d"
                                                ariaLabel="infinity-spin-loading"
                                            />
                                        ) : (
                                            <>
                                                <div><button type="submit" className="btnn btnn-full">Upload</button></div>
                                            </>
                                        )
                                    }

                                    <ToastContainer />
                                </form>
                            </center>
                        </div>
                    </div><br /><br />
                </div>
            </div>
        </div>
    )
}

export default IdentityVerification
