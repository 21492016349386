import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useAuth } from '../authentication/userAuth';

const MiningContracts = () => {
    const [contracts, setContracts] = useState([]);
    const { user } = useAuth();
    const month = { '01': 'Jan', '02': 'Feb', '03': 'Mar', '04': 'Apr', '05': 'May', '06': 'June', '07': 'July', '08': 'Aug', '09': 'Sep', '10': 'Oct', '11': 'Nov', '12': 'Dec' };
    useEffect(() => {
        const fetchData = async () => {
            const data = await fetch(`https://blazesignal.com/api/user/trading/contracts/all`, {
                method: "POST",
                headers: { 
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    id: user.owner_id
                })
            })
            const response = await data.json();
            if (response.status === 200) {
                setContracts(response.contracts)
            }
        }
        fetchData(); 
    }, [user.owner_id])

    return (
        <div className="container">
            <div>
                <div className="center">
                    <b>MY MINING CONTRACTS</b>
                </div><br />
                <div className="container">
                    <ul className="collection">
                        {
                            (contracts?.length > 0) ? (
                                contracts.map((item, index) => (
                                    <>
                                        <li className="collection-item app-py-1 " key={index}>
                                            <div className="roww app-list-link">
                                                <Link to={`#`}>
                                                    <div className="coll l1 s2" style={{ "padding": "0px" }}>
                                                        <center>
                                                            <p className="app-trading-month">{month[item.createdAt.split(' ').split('-')[1]]}</p>
                                                            <span className="app-trading-day">
                                                            {item.createdAt.split(' ').split('-')[2].split('')}{item.createdAt.split(' ').split('-')[2].split('')[1]}
                                                            </span>
                                                        </center>
                                                    </div>
                                                    <div className="coll l9 s7" style={{ "fontSize": "14px" }} >
                                                        FUND $ {item.amount}<br />TRADING BALANCE DEPOSIT
                                                    </div>
                                                </Link>
                                                <div className="coll l2 s3" style={{ "textAlign": "right", "fontSize": "14px" }}>{item.status}</div>
                                            </div>
                                        </li >
                                    </>
                                ))
                            ) : (
                                <>
                                    <li className="collection-item app-py-2">
                                        <p id="no-data" style={{ "textAlign": "center" }}>
                                            <span className="app-font-normal">YOU HAVE NO MINING CONTRACT AT THE MOMENT</span>
                                        </p>
                                    </li>
                                </>
                            )
                        }

                    </ul>
                </div>
            </div>
        </div>
    )
}

export default MiningContracts
