import { useOutlet } from "react-router-dom";


export const Verify = () => {
  const outlet = useOutlet();

  return (
    <>
      {outlet}
    </>
  );
};