import React, { useContext, useEffect } from 'react';
import Header from './header'
import FooterLanding from './footer';
import { AuthContext } from '../context';
import GoogleTranslate from './component/googleTranslate';

const LayoutLanding = ({ children }) => {
  const { setSitesettings } = useContext(AuthContext);
  useEffect(() => {
    const fetchData = async () => {
      const resp = await fetch('https://blazesignal.com/api/user/settings', { method: "POST" });
      const repsonse = await resp.json();
      setSitesettings(repsonse.settings);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  return (
    <>
      <Header />
      <GoogleTranslate />
      {children}
      <FooterLanding />
    </>
  )
}

export default LayoutLanding