import React from 'react';
import BottomNav from './components/bottomnav'; 

const MyPosts = () => {
    return (
        <>
            <section className="container">
                <div className="roww">
                    <div className="coll l8 s12">
                        <ul className="collection roww">
                            <li className="collection-item app-mb-2">
                                <div className="roww">
                                    <div className="coll s2 l1">
                                        <a href="/user/traders/single/4">
                                            <img className="circle" src={`https://quantumcrypta.net/uploads/trader-81-1706125158.png`} style={{ "height": "40px" }} alt='' />
                                        </a>
                                    </div>
                                    <div className="coll s6">
                                        <a href="/user/traders/single/4">
                                            <h6>Mark Minervini</h6>
                                        </a>2023-08-31 15:32:06
                                    </div>
                                </div>
                                <div style={{ "paddingTop": "10px" }}>
                                    <div>2 best Metaverse 100X Crypto Coins For Bull Run<br /></div><br />
                                    <div>Today I'm sharing you 2 best Metaverse Crypto Coins<br /></div><br />
                                    <div>Like Share Comment<br /></div><br />
                                    <div>This is not a financial advice always do your own research before any investment<br /></div><br />
                                    <div>Decentraland (MANA)<br /></div><br />
                                    <div>Decentraland operates an entire metaverse of its own. It is a VR platform powered by the Ethereum (ETH) blockchain, with a native token MANA used to transact business within its worlds.<br /></div><br />
                                    <div>ApeCoin (APE)<br /></div><br />
                                    <div>ApeCoin is not only one of the biggest metaverse coins on the market but also one of the newest. It was designed to power the growing ecosystem around the popular Bored Ape Yacht Club.<br /></div>
                                </div>
                            </li>
                            <li className="collection-item app-mb-2">
                                <div className="roww">
                                    <div className="coll s2 l1">
                                        <a href="/user/traders/single/82">
                                            <img className="circle" src={`https://quantumcrypta.net/uploads/human.png`} style={{ "height": "40px" }} alt='' />
                                        </a>
                                    </div>
                                    <div className="coll s6">
                                        <a href="/user/traders/single/82">
                                            <h6>Eleanora Feeney</h6>
                                        </a>2023-08-31 15:32:06
                                    </div>
                                </div>
                                <div style={{ "paddingTop": "10px" }}>
                                    <div>#Litecoin 's halving hype has faded, on-chain activity has slowed, and traders are dealing with uncertainty. Yet, LTC inspires optimism as it gathers traction amid conflicting emotions.<br /></div><br />
                                    <div>The number of active and new Litecoin addresses has decreased in recent weeks.<br /></div><br />
                                    <div>Since traders were indecisive, the LTC financing rate offered conflicting signals.<br /></div><br />
                                    <div>Litecoin [LTC] has been buzzing with on-chain activity as it prepares for its halving event. Yet as the weeks went, it seemed that the energy was dwindling. The post-halving impact, which was originally expected to bring favorable trends to LTC, is now in doubt.<br /></div><br />
                                    <div>The euphoria over Litecoin's halving has subsided.<br /></div><br />
                                    <div>Prior to Litecoin's #halving event, two measures - active addresses and new addresses - demonstrated the network's thriving on-chain activity. These measures were unambiguous markers of user interest and indicated to a possible increase after the halving.<br /></div><br />
                                    <div>Yet, in recent days, these measures have declined.<br /></div><br />
                                    <div>According to Glassnode's statistics on new addresses, there has been a decrease in their formation since the end of June. Daily new addresses declined from 300,000 to 200,000 during the 17th and 18th of June, finally falling to 100,000 in July.<br /></div><br />
                                    <div>Despite a little uptick at the time of writing, the figure only reached slightly more than 190,000.<br /></div><br />
                                    <div>Similarly, the number of active addresses has decreased in recent weeks. It dropped from over 400,000 in June to roughly 200,000 now. Yet, at the time of writing, a minor spike happened, sending the figure beyond 300,000 for the first time in July.<br /></div><br />
                                    <div>Although the initial increase between these two indicators suggested a rebound of interest, the enthusiasm seems to have waned in recent weeks. When the network's activity fluctuates, the future remains unknown.<br /></div><br />
                                    <div>The financing rate for Litecoin is mixed.<br /></div><br />
                                    <div>According to Coinglass statistics, market sentiment toward Litecoin was very divided. The financing rate oscillated between negative and positive values during the period of observation.<br /></div><br />
                                    <div>While the positive financing rate had acquired notoriety by then, it indicated that traders were dealing with uncertainties about the future of Litecoin.<br /></div><br />
                                    <div>This lack of consensus among traders suggested that the LTC price trend would see some volatility. With traders unable to agree on whether to finance a long or short position, the market was expected to see oscillations and unpredictability in Litecoin's value.<br /></div><br />
                                    <div>LTC is gaining traction.<br /></div><br />
                                    <div>Litecoin managed to achieve advances on a daily timeframe chart despite the conflicting emotion exhibited by traders through the funding rate. As of this writing, it was trading at about $91, representing an almost 1% rise in value.<br /></div><br />
                                    <div>This price increase had successfully flipped its short Moving Average back into support, which was encouraging. The action demonstrated the possibility of further benefits.<br /></div>
                                </div>
                            </li>
                            <li className="collection-item app-mb-2">
                                <div className="roww">
                                    <div className="coll s2 l1">
                                        <a href="/user/traders/single/14">
                                            <img className="circle" src={`https://quantumcrypta.net/uploads/trader-71-1703763044.png`} style={{ "height": "40px" }} alt='' />
                                        </a>
                                    </div>
                                    <div className="coll s6">
                                        <a href="/user/traders/single/14">
                                            <h6>Tony Edward</h6>
                                        </a>2023-08-31 15:32:06
                                    </div>
                                </div>
                                <div style={{ "paddingTop": "10px" }}>
                                    <div>🚀Rep. Ritchie Torres is pushing for crypto regulatory reforms with the Financial Innovation and Technology (FIT) for the 21st Century Act!👏 The act aims to bring clarity and fairness to the crypto market by defining which cryptocurrencies fall under the SEC's jurisdiction and providing a pathway for assets to transition to the CFTC if they can demonstrate sufficient decentralization.<br /></div><br />
                                    <div>Despite recent complications in bipartisan discussions on stablecoin regulations, Torres remains committed to fostering innovation while ensuring investor protection. The outcome of these legislative efforts will shape the future of the crypto market in the US. Stay tuned! 🇺🇸💡<br /></div>
                                </div>
                            </li>
                            <li className="collection-item app-mb-2">
                                <div className="roww">
                                    <div className="coll s2 l1">
                                        <a href="/user/traders/single/1">
                                            <img className="circle" src={`https://quantumcrypta.net/uploads/trader-84-1706294087.png`} style={{ "height": "40px" }} alt='' />
                                        </a>
                                    </div>
                                    <div className="coll s6">
                                        <a href="/user/traders/single/1">
                                            <h6>Alex</h6>
                                        </a>2023-08-31 15:32:06
                                    </div>
                                </div>
                                <div style={{ "paddingTop": "10px" }}>
                                    <div>TOP 10 COINS THAT WILL MAKE YOU RICH IN 2024.<br /></div><br />
                                    <div>With the right research, you can make dicisions on the coins that can lead to explosives profits in the expected 2024 bullrun. Bitcoin could do more than double, to about $1 00,000 per coin during the next Bull Run. Also there are many alt coins expected to rise exponentially by 2024. These crypto-currencies that make them attractive investments.<br /></div><br />
                                    <div>BELOW ARE THE TOP 10 COINS I think this coin make you rich 2024<br /></div><br />
                                    <div>1. #BTC<br /></div><br />
                                    <div>2. #Ethereum<br /></div><br />
                                    <div>3. #BNB<br /></div><br />
                                    <div>4. #XRP<br /></div><br />
                                    <div>5. #ltc<br /></div><br />
                                    <div>6. Sol<br /></div><br />
                                    <div>7. Matic<br /></div><br />
                                    <div>8. Pepe<br /></div><br />
                                    <div>9. Doge<br /></div><br />
                                    <div>10. Shib<br /></div><br />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div><br /><br /><br /><br />
            </section>
            <BottomNav posts='active' />
        </>
    )
}

export default MyPosts
