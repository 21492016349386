import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthContext } from '../../context';
import { ToastContainer, toast } from 'react-toastify';
import { InfinitySpin, LineWave } from 'react-loader-spinner';
import { useAuth } from '../../authentication/userAuth';

const DepositStep3 = () => {
  const { setDepositDetails } = useContext(AuthContext);
  const { user } = useAuth();
  const [isLoading, setIsloading] = useState(false);
  const [isLoading2, setIsloading2] = useState(false);
 
  const navigate = useNavigate();
  const { amount, account, type } = useParams();
  const [paytype, setPaytype] = useState('btc');
  const [walletAddress, setwalletAddress] = useState('');

  const [wallets, setWallets] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.target.wallet.value === "ddd") {
      toast.error("Please select a crypto payment method");
      return;
    }
    setIsloading(true);

    let data = {
      paytype,
      amount,
      account,
      payerid: user.owner_id,
      wallet_address: walletAddress,
      type
    }
    setDepositDetails(data);
    localStorage.setItem("setDepositDetails", JSON.stringify(data));

    const dd = await fetch('https://blazesignal.com/api/user/deposits/fund/add', {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify(data)
    })

    const resp = await dd.json();
    if (resp.status === 200) {
      setIsloading(false);
      setDepositDetails(data)
      navigate(`/user/deposits/fund/step4/${walletAddress}/${resp.depositID}`)
    } else {
      setIsloading(false);
      toast.error(resp.message)
    }

  }

  useEffect(() => {
    const fetchData = async () => {
      const dd = await fetch('https://blazesignal.com/api/user/wallets/', { method: "POST" });
      const resp = await dd.json();
      setWallets(resp);
    }
    fetchData();
  }, []);

  const getWallet = async (e) => {
    e.preventDefault();

    if (e.target.value === "ddd") {
      toast.error("Please select a crypto payment method");
      return;
    }
    setIsloading2(true);
    const dd = await fetch('https://blazesignal.com/api/user/wallet/address', {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        wallet: e.target.value
      })
    })

    const resp = await dd.json();
    setwalletAddress(resp);
    setIsloading2(false);
    setPaytype(e.target.value);
  }
  return (
    <>
      {
        isLoading2 && (
          <div style={{ position: "fixed", height: "100vh", width: "100%", zIndex: 999, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <LineWave
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="line-wave-loading"
              wrapperStyle={{}}
              wrapperClass=""
              firstLineColor=""
              middleLineColor=""
              lastLineColor=""
            />
          </div>
        )
      }
      <div className="roww center"><br /><br />
        <h1>PAY ${amount}</h1>
        <p>Send Crypto</p><br />
        <div className="roww">
          <div className="coll l4 offset-l4 s12">
            <div className="cardd-panel">
              <div>
                <center>{amount / 6500} BTC</center><br />
              </div>
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="roww">
                  <div className="input-field undefined">
                    <label className="active">Select Payment Method</label>
                    <select id="wallet_id" name='wallet' className="browser-default app-my-2" defaultValue={paytype}
                      onChange={getWallet}>
                      <option value="ddd" selected>Select wallet</option>
                      {
                        wallets.map((wallet) => (
                          <option value={wallet.short_name}>{wallet.coin_name}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                {
                  isLoading ? (
                    <InfinitySpin
                      visible={true}
                      width="200"
                      color="#4fa94d"
                      ariaLabel="infinity-spin-loading"
                    />
                  ) : (
                    <>
                      <div><button type="submit" className="btnn btnn-full">Proceed</button></div>
                    </>
                  )
                }
                <ToastContainer />
              </form>
            </div>
          </div>
        </div>
      </div></>
  )
}

export default DepositStep3
