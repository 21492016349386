import React from 'react'

const CopyTradingAgreement = () => {
    return (
        <>
            <div><br />
                <h1 className="center">Copy Trading Agreement</h1>
            </div>
            <section className="container app-py-3">
                <div className="cardd-panel"> 
                    <div className="container">
                        <div>The users including copiers and traders of the copy trading service must read the Copy Trading Service Agreement (hereinafter referred to as the Agreement) carefully before accepting the Agreement. By continuing to access or use the copy trading service (hereinafter referred to as the Service), you consent to bear all the risks and the loss incurred as provided in the Agreement. Please do not continue to access or use the Service if you do not accept the Agreement.</div>
                        <div> </div>
                        <div>Copy Trading Copier Agreement</div>
                        <div> </div>
                        <div>1. Copiers need to be aware of the following risks:</div><br />
                        <div>a. Copy trading users may not be able to copy trades during maintenance necessitated by unstable services provided by the network operator or third-party partners, excessive system load, and other reasons.</div><br />
                        <div>b. Copy trading activities may not be executed simultaneously or even at all, due to time-delayed execution of system instructions, delays in the trading signal from external platforms or other network issues.</div><br />
                        <div>c. The opening price, closing price and PnL ratios of copiers may differ as a result of objective factors such as changes in the market and insufficient market depth. Differences will be more significant under more extreme market conditions.</div><br />
                        <div>d. The opening price, closing price and PnL ratios of traders may differ from copiers as a result of changes in the market and insufficient market depth restrictions. Differences will be more significant under extreme market conditions.</div><br />
                        <div>e. When you use CopyTrade Pro (with Binance traders), you may have a different position ratio and order amount from the trader due to unstable service provided by Binance such as a delay or loss in the trading signal. When traders are using pair trading, grid trading, the Martingale strategy or other strategies that require positions to be strictly aligned, copiers may be unable to copy successfully causing losses.</div><br />
                        <div>f. When using CopyTrade Pro (with Binance traders), due to the differences in liquidation rules of different Exchanges, forced liquidation of your orders may be triggered earlier than the trader when there is a significant loss of the position.</div><br />
                        <div>g. When the TP/SL is set and triggered, due to factors such as unstable network, insufficient market depth, and unstable external service signal, the actual trigger price might be different from the preset value and there is no guarantee that the closing price or PnL ratio of your order is as same as the preset value.</div><br />
                        <div>h. There may be a risk of loss when copying the orders of traders. In the case of improper risk control, you may lose all of your balance.</div><br />
                        <div>i. Before using the Service to copy orders, you should be aware that BingX only provides the copying service of the trading signal, and sends the trading instruction as synchronized with the trader to the market under your authorization to deal with the market counterparty. Limited by factors such as changes in market conditions, changes in market depth, and time-delayed execution of system instructions, there is no guarantee that the copied orders will have the same opening (closing) price, opening (closing) time, and profit/loss (profit/loss ratio). The loss incurred thereby is solely at your risk.</div><br />
                        <div>j. Trading strategies belong to the trader's intellectual property rights. In order to protect traders' rights and interests and to maintain a fair community environment for all traders, the platform may hide some or all of the key information and features of your copy trades.</div><br />
                        <div>k. Traders may be removed due to violations, delisting and other reasons which will end the copy trading relationship. To protect the assets of copiers, the system will automatically cancel all copy trades after the relationship ends.</div>
                        <div> </div><br /><br /><br />
                        <div>2. Copiers agree that the platform has the right to adjust the Copy Trading community rules in accordance with the development direction of the community, including but not limited to:</div>
                        <div>Adjusting the traders’ profit share from sharing trades or profits from recommending traders.</div>
                        <div> </div>
                        <div>Copy Trading Trader Agreement</div>
                        <div> </div>
                        <div>1. Traders agree to comply with the platform's restrictions on accessing the Service after starting sharing trades, including but not limited to:</div>
                        <div>a. You will not be able to copy the orders of other traders</div>
                        <div>b. Your existing copy orders may be closed</div>
                        <div> </div>
                        <div>2. Traders agree to comply with the platform's restrictions on the copy trading activities, including but not limited to:</div>
                        <div>a. Limits on leverage</div>
                        <div>b. Limits on the number of copiers</div>
                        <div>c. Limits on the trading volume of your copiers</div>
                        <div>d. Limits on the trading pair varieties</div>
                        <div> </div><br /><br />
                        <div>3. Traders agree that the platform has the right to adjust the Copy Trading community rules in accordance with the development direction of the community, including but not limited to:</div>
                        <div>a. Adjusting the traders‘ public trading information</div>
                        <div>b. Adjusting the traders’ profit share from the share trading or from recommending traders</div>
                        <div>c. Adjusting the settlement cycle of the traders’ profit share</div>
                        <div>d. Adjusting the traders' ranking rules</div>
                        <div>e. Adjusting the trading fees of shared orders/copy orders</div>
                        <div>f. Hiding some or all key info. of the copy orders on the copier's position page</div>
                        <div> </div><br /><br />
                        <div>4. Traders need to be aware of the following risks:</div><br />
                        <div>a. You will not be able to share trades during service maintenance necessitated by unstable services provided by the network operator or third parties, excessive system load, and other reasons.</div><br />
                        <div>b. Your trading activities of shared accounts may not be copied simultaneously or even cannot be copied by copiers due to time-delayed execution of system instructions, a delay of the trading signal from external platforms, and other network issues.</div><br />
                        <div>c. Due to market limitations such as market changes and insufficient market depth, you may have different opening prices, closing prices, and PnL ratios from your copiers. And these differences will widen under extreme market conditions.</div><br />
                        <div>d. When you share orders from Binance via API, you may have a different position ratio and order amount from your copiers due to unstable service of Binance such as a delay or loss in the trading signal. You may encounter unsuccessful copy trading and a loss ensued thereby if the trader uses strategies such as hedging, grid trading, martingale trading, etc., that require strict alignment of positions.</div><br />
                        <div>e. If you share orders from external platforms via API, due to the differences in liquidation rules of different Exchanges, the forced liquidation of copiers' positions with a significant loss may be triggered earlier than you.</div><br />
                        <div>f. Your copiers can set TP/SL for their copy orders. However, due to factors such as unstable network, insufficient market depth, and unstable external service signal, the actual trigger price might be different from their preset value and there is no guarantee that the closing price or PnL ratio of their orders is the same as the preset value.</div>
                        <div> </div>
                        <div>5. Prohibitions and Penalties:</div><br />
                        <div>Share Trading Prohibitions</div><br />
                        <div>Traders are prohibited from using multiple accounts for share trading.</div>
                        <div>Traders are prohibited from plagiarizing the orders of other traders in any way such as copying other traders' trading strategies.</div>
                        <div>Traders are not allowed to explicitly or implicitly display their social accounts in the platform's personal profile, avatar, and other places.</div>
                        <div>Traders are not allowed to express or imply pornographic or reactionary information in the platform's personal profile, avatar, and other places.</div>
                        <div>Traders are not allowed to increase their copiers or funds by wash trades.</div>
                        <div>Traders are not allowed to conduct ultra-short-term transactions.</div>
                        <div>Otherwise, BingX shall impose the following penalties depending on the severity of the cases:</div><br />
                        <div>Issue a warning</div>
                        <div>Reduce the weight of the community rankings</div>
                        <div>Reduce the max. trading leverage or the max. trading volume of all the copiers</div>
                        <div>Remove the trader from the trader list</div>
                        <div>Confiscate the profit share earned from share trading violations</div>
                        <div>Special Note: The system will monitor accounts and detect violations based on an automatic recognition algorithm. Therefore, there may be misjudgments. If you do not agree with the decision of the platform, you can contact BingX customer support to appeal.</div>
                        <div> </div>
                        <div>Basic Copy Trading Agreement</div>
                        <div> </div>
                        <div>Users' Rights and Obligations</div>
                        <div> </div>
                        <div>a. You (copiers and traders) must be natural or legal persons who can fully assume legal responsibility.</div><br />
                        <div>b. You shall be responsible for the personal data (including documents and other relevant information) provided to the platform and ensure the data's authenticity and reliability, which will be strictly verified and evaluated by BingX. You will be restricted from trading on the platform if the personal information (such as trading experience, trading strategy, etc.) provided is insufficient or fails to pass the review.</div><br />
                        <div>c. You shall not submit others' information (such as name, ID number, phone number, or email address) and you will be restricted by the platform from accessing or using its products and services if the information you provide is inaccurate, illegal or invalid.</div><br />
                        <div>d. If you want to change your personal information or the account type, you can apply to the platform's online support by submitting a work order, and the platform will process it after reviewing. You are advised to inform the platform of such change in a timely manner, otherwise, you shall solely bear any loss incurred thereby. In order to keep your personal information updated and accurate, you can request the platform to correct or delete the information you consider incorrect.</div><br />
                        <div>e. You shall be responsible for the custody of your account ID and passwords, and assume legal responsibility for any trading activities in your account.</div><br />
                        <div>f. You need to read the relevant terms carefully on the platform's official website, understand the applicable laws and regulations, and bear all the risks incurred by your trading or copy trading activities. The platform does not provide any specific investment advice and does not express any relevant opinions.</div><br />
                        <div>g. You must comply with the relevant laws and regulations when posting information on the platform and using the Service. You must not maliciously publish or spread harmful information against laws and social ethics, including text, pictures, audio, video, etc, nor can you publish any defamatory and harmful comments that damage the reputation of the platform.</div><br />
                        <div>h. You should check your account information regularly and report to the platform in time if there is an error. If such error is not reported to the platform within 72 hours after the trading instruction is executed, you shall bear any losses and damages incurred thereby.</div><br />
                        <div>i. Traders need to keep their trading status updated and accurate so that their copiers can catch up with their trading activities and strategies.</div><br />
                        <div>j. Traders must ensure an accurate description of their trading strategies on the platform so that the copiers can make investment decisions based on the description. Traders need to ensure that their trading activities are consistent with the strategy description. Otherwise, the platform will not approve their strategies. In the case of serious loss caused by traders' trading activities, the platform will take actions to interfere with their trading, such as suspending the traders' account or cutting off the share trading with copiers.</div>
                        <div> </div>
                        <div>The Platform's Rights and Obligations</div>
                        <div> </div>
                        <div>a. The platform has the right to use the information you publish on the platform (such as text, pictures, etc., excluding ID cards, bank cards, and personal and private information) for other legal purposes, such as copying, modifying, adapting, translating, promoting, and analysis.</div><br />
                        <div>b. All graphic information on the platform is for the purpose of disseminating useful information to the masses and does not mean that the platform agrees with its message.</div><br />
                        <div>c. The platform will not provide or express any advice or opinions on investment. You need to exercise independent thinking and trading.</div><br />
                        <div>d. To safeguard the interests and rights of both parties, the platform has the right to evaluate your expertise, experience and adaptability based on the information you provide. The platform will reject information that is unreliable or inaccurate.</div><br />
                        <div>e. The platform will not bear any loss incurred by any unauthorized disclosure of the trading data or related information to the platform by any natural or legal persons.</div><br />
                        <div>f. On a regular, pre-determined basis (at the sole discretion of the platform), the platform will calculate the Total Amount owing by the platform to the trader. This Total Amount will be derived after netting off all losses against profits for all copy trading transactions carried out by his/her copiers during the relevant period for settling the outstanding Total Amount to the trader (the Settlement Period). In the event of any dispute as to the Total Amount owing by the platform to the trader for any given Settlement Period, both parties hereby agree that the calculation methodology employed by the platform is proprietary, final and non-negotiable. The trader is entitled to request a list of the transactions used in deriving the Total Amount and may produce evidence that the platform has not taken into account a transaction or transactions in calculating the Total Amount for the Settlement Period (which the platform (in its sole discretion) may decide to incorporate and revise the Total Amount accordingly). However, the trader is not entitled to question or demand the production of any information relating to the calculation of the Total Amount, including but not limited to computer code, applications, spreadsheets, formulas, equations, or any other database or program used by the platform in this regard.</div>
                        <div> </div>
                        <div> </div>
                        <div>Effectiveness, Amendment and Termination of the Agreement</div>
                        <div> </div>
                        <div>a. The Agreement shall take effect upon users becoming a copier or a trader on the platform until the termination of this Agreement or breach of contract. Either party can terminate this Agreement at any time. Users need to issue a notice in writing or by mail in advance to terminate the Agreement. The platform will notify you in writing or by email within 7 working days after the receipt of the notice to confirm the termination of the Agreement.</div><br />
                        <div>b. The platform reserves the right to modify the relevant terms and the final interpretation of the Agreement in order to safeguard its legal rights and interests and improve the Agreement. The platform will issue a notice on the website (or via email and other messaging channels) before modifying the terms of this Agreement. Users are advised to keep updated with these amendments while using or accessing the Service, and abide by the Agreement and related service agreements. The Agreement shall still be effective regardless of any modification made by the platform of any trading conditions and execution rules.</div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CopyTradingAgreement
