import React from 'react'
import LayoutLanding from './layout'

const NopageLanding = () => {
  return (
    <LayoutLanding>
      <h1>404</h1>
    </LayoutLanding>
  )
}

export default NopageLanding
 