import React from 'react'

const Fft = ({ myWatch, id, Wishlist, removeWishlist }) => {
    const isFound = myWatch.includes(id.toString());

    return (
        isFound ? (
            <div className="coll l1" style={{ "paddingTop": "10px", textAlign: 'right' }}  onClick={removeWishlist}>
                <span className="material-icons notranslate" style={{ color: "#6648fe" }}>star</span>
            </div>
        ) : (
            <div className="coll l1 watchlist" style={{ "paddingTop": "10px", textAlign: 'right' }} onClick={Wishlist}>
                <span className="material-symbols-outlined notranslate">
                    star
                </span>
            </div>
        )
    )
}

export default Fft
