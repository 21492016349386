import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../context';

const FooterLanding = () => {
  const { sitesettings } = useContext(AuthContext);
  return (
    <>

      <footer className="py-5 in-cirro-footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row gx-0 mb-3">
                <div className="col-md-12 col-lg-3 footer-logo">
                  <img src="img/logog.png" alt="footer-logo"  className="mb-1 d-block" />
                </div>

                <div className="col-md-12 col-lg-9">
                  <div align="center">
                    <div id="google_translate_element"></div>
                  </div>

                  <div className="d-flex flex-column flex-xl-row justify-content-xl-end">
                    <ul className="list-inline in-footer-link order-last order-xl-first">
                      <li className="list-inline-item"><Link to="/contact">Contact</Link></li>
                      <li className="list-inline-item"><Link to="/about">FAQ</Link></li>
                      <li className="list-inline-item"><Link to="/education">Careers</Link></li>
                      <li className="list-inline-item"><Link to="/about">Partners</Link></li>
                    </ul>
                    <div className="social-media-list small hstack gap-3 ms-lg-5 order-first order-xl-last">
                      <div><Link to="https://www.facebook.com/" className="color-facebook text-decoration-none"><i className="fab fa-facebook-square"></i></Link></div>
                      <div><Link to="https://twitter.com/" className="color-twitter text-decoration-none"><i className="fab fa-twitter"></i></Link></div>
                      <div><Link to="#" className="color-linkedin text-decoration-none"><i className="fab fa-linkedin"></i></Link></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3 mt-5 d-flex">
                  <div className="align-self-end">
                    <p className="mb-0 copyright-text">© 2024 BlazeSignal Limited</p>
                    <p className="mb-0 copyright-text">All Rights Reserved.</p>
                  </div>
                </div>
                <div className="col-md-12 col-lg-9 d-lg-flex justify-content-lg-end d-none d-lg-block">
                  <div className="align-self-end">
                    <nav className="nav in-footer-link-2">
                      <Link className="nav-link border-end-md" to="/terms">Risk Disclosure</Link>
                      <Link className="nav-link border-end-md" to="/terms">Privacy policy</Link>
                      <Link className="nav-link border-end-md" to="/terms">Customer Agreement</Link>
                      <Link className="nav-link pe-0" to="/terms">AML policy</Link>

                    </nav>
                  </div>
                </div>
              </div>
              <p className="mb-0 copyright-text"><i>You can start your online trading today with BlazeSignal. Please feel free to browse our economic calendar. It contains important information on EURUSD, BTCUSD, GBPUSD, USDCHF, BTCETH, AUDUSD, USDCAD, NZDUSD and other currency pairs and trading instruments and provides up-to-date market news and market research. BlazeSignal offers a deposit forex bonus of up to 50%*. You can trade micro-lots (0.01 lots) with BlazeSignal.</i></p>
            </div>
          </div>
        </div>
      </footer>
      <div className="d-none d-md-block">
        <Link to="#" className="to-top fas fa-arrow-up text-decoration-none text-white"></Link>
      </div>

      <link rel="stylesheet" to="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css" />
      
      <Link to={`https://wa.me/${sitesettings.phone}?text=Hello`} className="floating" target="_blank">
        <i className="fab fa-whatsapp fab-icon"></i>
      </Link>


    </>
  )
}

export default FooterLanding
