import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LayoutLanding from './layout'
import { ToastContainer, toast } from 'react-toastify';
import { InfinitySpin } from 'react-loader-spinner';

const ForgotPasswordHome = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const [newPassword, setnewPassword] = useState('');
    const [ConfirmnewPassword, setConfirmnewPassword] = useState('');
    const [token, setToken] = useState('');

    const [error, setError] = useState('');
    const [isLoading, setIsloading] = useState(false);

    const [pinSent, setPinSent] = useState(false);

    const handleSubmitSendPin = async (e) => {
        e.preventDefault();
        setIsloading(true);
        try {
            let data = {
                email
            }

            const resp = await fetch('https://blazesignal.com/api/user/reset/send-token', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(data)
            });
            const response = await resp.json();

            if (response.status === 200) {
                toast.success(response.message);
                setIsloading(false);
                setTimeout(() => {
                    setPinSent(true);
                }, 2000);

            } else {
                setError(response.message);
                toast.error(response.message);
            }

        } catch (err) {
            setIsloading(false);
            setError(err.message);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true);

        if (ConfirmnewPassword === newPassword) {
            try {
                let data = {
                    newPassword,
                    email,
                    token
                }

                const resp = await fetch('https://blazesignal.com/api/user/reset/password', {
                    method: "POST",
                    headers: {
                        "content-type": "application/json"
                    },
                    body: JSON.stringify(data)
                });
                const response = await resp.json();

                if (response.status === 200) {
                    toast(response.message);
                    setIsloading(false);
                    setTimeout(() => {
                        navigate('/login');
                    }, 2000);
                } else {
                    setError(response.message);
                    toast(response.message);
                    setIsloading(false);
                }

            } catch (err) {
                setIsloading(false);
                setError(err.message);
            }
        } else {
            setIsloading(false)
            toast("Passsword does not match");
            setError("Passsword does not match");
        }

    }
    return (
        <LayoutLanding>
            <section className="py-5">
                <div className="container">
                    <div className="roww justify-content-center">
                        {
                            pinSent ? (
                                <div class="col s12 m12 l4 offset-l4"><br /><br />
                                    <div class="cardd-panel">
                                        <center><br />
                                            <div>
                                                <p>please enter your new password and the token sent to your email <b>{email}</b></p><br />
                                                <form autocomplete="off" onSubmit={handleSubmit}>
                                                    <div class="row">
                                                        <div class="input-field undefined">
                                                            <input type="password" id="new_password" name="new_password" autocomplete="new-password" required="" value={newPassword} aria-autocomplete="list" onChange={(e) => setnewPassword(e.target.value)} />
                                                            <label class="active" for="new_password">new password</label>
                                                        </div>
                                                        <div class="input-field undefined">
                                                            <input type="password" id="confirm_new_password" name="confirm_new_password" autocomplete="new-password" required="" value={ConfirmnewPassword} onChange={(e) => setConfirmnewPassword(e.target.value)} />
                                                            <label class="active" for="confirm_new_password">confirm new password</label>
                                                        </div>
                                                        <div>
                                                            <div class="input-field undefined">
                                                                <input type="text" id="password_token" name="password_token" maxlength="300" inputmode="text" required="" value={token} onChange={(e) => setToken(e.target.value)} />
                                                                <label class="active" for="password_token">token</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        isLoading ? (
                                                            <InfinitySpin
                                                                visible={true}
                                                                width="200"
                                                                color="#4fa94d"
                                                                ariaLabel="infinity-spin-loading"
                                                            />
                                                        ) : (
                                                            <>
                                                                <div><button type="submit" class="btn btn-full">Update</button></div><br /><br />
                                                                {
                                                                    error && (
                                                                        <div className="red black-text lighten-4 cardd-panel text-center" style={{ "padding": "30px" }}><span>{error}</span><br /></div>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }

                                                </form>
                                            </div><br />
                                        </center>
                                    </div>
                                </div>
                            ) : (
                                <div className="col s12 m12 l4 offset-l4">
                                    <div className="cardd-panel">
                                        <center>
                                            <div>
                                                <h4 className="mb-2">Account Recovery</h4>
                                                <p>To recover your account please provide your email or username to find your account.</p>
                                                <form onSubmit={handleSubmitSendPin}>
                                                    <div className="roww gy-2 gap-2">
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label>Email or Username</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form--control h-45"
                                                                    name="email"
                                                                    value={email}
                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                    required
                                                                    autoFocus="off"
                                                                />
                                                            </div>
                                                        </div>
                                                        <br />
                                                    </div>
                                                    {
                                                        isLoading ? (
                                                            <InfinitySpin
                                                                visible={true}
                                                                width="200"
                                                                color="#4fa94d"
                                                                ariaLabel="infinity-spin-loading"
                                                            />
                                                        ) : (
                                                            <>
                                                                <div className="col-12">
                                                                    <button type="submit" className="btn btn-primary w-100">Send</button>
                                                                </div><br /><br />
                                                                {
                                                                    error && (
                                                                        <div className="red black-text lighten-4 cardd-panel text-center" style={{ "padding": "30px" }}><span>{error}</span><br /></div>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </form>
                                            </div>
                                        </center>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <ToastContainer />
            </section>
        </LayoutLanding>
    )
}

export default ForgotPasswordHome
