import QRCode from 'easyqrcodejs';
import React, { useEffect, useRef } from 'react'

const Qrcode = ({wallet}) => {
    const code = useRef();
    useEffect(() => {
      new QRCode(code.current, { text: wallet });
    }, [wallet]);
  
    return (
        <div ref={code}></div>
    );
  }

export default Qrcode
 