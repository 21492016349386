import React from 'react'

const DepositFunds = () => {
  return (
    <div>
      DepositFunds
    </div>
  )
} 

export default DepositFunds 
