import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

const DepositSingle = () => {
  const navigate = useNavigate();
  const [depost, setDeposit] = useState({})
  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`https://blazesignal.com/api/user/deposits/fund/single`, {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({ id })
      })
      const response = await data.json();
      if (response.status === 200) {
        setDeposit(response.deposit);
      } else {
        navigate('/user/deposits/list');
      }
    }
    fetchData();
  }, [navigate, id])
  return (
    <>
      <section className="container">
        <div className="cardd-panel">
          <div className="roww">
            <div className="coll l3 s12 center">
              <p>
                {
                  (depost.status === "Pending") &&(
                    <Link to={`/user/deposits/upload-proof/${depost.id}`}>Upload Payment Proof</Link>
                  )
                }
              </p>
              <br />
            </div>
            <div className="coll l9 s12">
              <table className="striped">
                <tbody>
                  <tr className="roww">
                    <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>Date</td>
                    <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>{depost.createdAt}</td>
                  </tr>
                  <tr className="roww">
                    <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>Deposit Type</td>
                    <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>{depost.fundtype}</td>
                  </tr>
                  <tr className="roww">
                    <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>Reason</td>
                    <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>{depost.reason}</td>
                  </tr>
                  <tr className="roww">
                    <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>Status</td>
                    <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>{depost.status}</td>
                  </tr>
                  <tr className="roww">
                    <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>Amount</td>
                    <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>${depost.amount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default DepositSingle
 