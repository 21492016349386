import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import BottomNav from '../components/bottomnav'
import { useAuth } from '../../authentication/userAuth';

const UserTrades = () => {
  const { user } = useAuth(); 
  const [openTrades, setOpenTrades] = useState([]);
  const [closedTrades, setClosedTrades] = useState([]);
  const [tray, setTray] = useState(true);
  const month = { '01': 'Jan', '02': 'Feb', '03': 'Mar', '04': 'Apr', '05': 'May', '06': 'June', '07': 'July', '08': 'Aug', '09': 'Sep', '10': 'Oct', '11': 'Nov', '12': 'Dec' };

  useEffect(() => {
    const getUser = async () => {
      const resp = await fetch('https://blazesignal.com/api/user/trading/trades/all', {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({ id: user.owner_id })
      });
      const response = await resp.json();
      setOpenTrades(response.open);
      setClosedTrades(response.closed);
    }
    getUser();
  }, [user.owner_id]);
  return (
    <>
      <section className="">
        <center>
          <ul className="bg tabs container">
            <li className="tab coll s6" style={tray ? ({}) : ({ "borderBottom": "3px solid black" })}>
              <Link to="#open" className={tray ? '' : 'active'} onClick={() => setTray(false)}>
                <span className="material-icons notranslate">hourglass_top</span>
                <span>Open</span>
              </Link>
            </li>
            <li className="tab  coll s6" style={tray ? ({ "borderBottom": "3px solid black" }) : ({})}>
              <Link to="#closed" className={tray ? 'active' : ''} onClick={() => setTray(true)}>
                <span className="material-icons notranslate">hourglass_full</span>
                <span>Closed</span>
              </Link>
            </li>

          </ul>
        </center><br /><br />
        <div className="container pc-scroll">
          <div id="closed" className={tray ? 'active' : ''} style={tray ? ({ "display": "block" }) : ({ "display": "none" })}>
            <ul className="collection">
              {
                (closedTrades.length > 0) ? (
                  closedTrades.map((item, index) => (
                      <li className="collection-item app-py-1 " key={index}>
                        <div className="roww app-list-link">
                          <Link to={`#`}>
                            <div className="coll l1 s2" style={{ "padding": "0px" }}>
                              <center>
                                <p className="app-trading-month">{month[item.createdAt.split(' ')[0].split('-')[1]]}</p>
                                <span className="app-trading-day">
                                  {item.createdAt.split(' ')[0].split('-')[2].split('')[0]}{item.createdAt.split(' ')[0].split('-')[2].split('')[1]}
                                </span>
                              </center>
                            </div>
                            <div className="coll l9 s7" style={{ "fontSize": "14px" }} >
                              {item.coin}<br />
                              <br />
                              Amount: {item.amount}
                              <br />
                              Leverage: {item.leverage}
                              <br />
                            </div>
                          </Link>
                          {
                            (item.type === 'buy') ? (<div className="coll l2 s3" style={{ color: "green" }}>Buy</div>) : (<div className="coll l2 s3" style={{ color: "red" }}>Sell</div>)
                          }
                        </div>
                      </li >
                  ))
                ) : (
                    <li className="collection-item app-py-2">
                      <p id="no-data" style={{ "textAlign": "center" }}>
                        <span className="app-font-normal">NO CLOSED TRADES</span>
                      </p>
                    </li>
                )
              }
            </ul>
          </div>

          <div id="open" className={tray ? '' : 'active'} style={tray ? ({ "display": "none" }) : ({ "display": "block" })}>
            <ul className="collection">

              {
                (openTrades.length > 0) ? (
                  openTrades.map((item, index) => (
                      <li className="collection-item app-py-1 " key={index}>
                        <div className="roww app-list-link">
                          <Link to={`#`}>
                            <div className="coll l1 s2" style={{ "padding": "0px" }}>
                              <center>
                                <p className="app-trading-month">{month[item.createdAt.split(' ')[0].split('-')[1]]}</p>
                                <span className="app-trading-day">
                                  {item.createdAt.split(' ')[0].split('-')[2].split('')[0]}{item.createdAt.split(' ')[0].split('-')[2].split('')[1]}
                                </span>
                              </center>
                            </div>
                            <div className="coll l9 s7" style={{ "fontSize": "14px" }} >
                              {item.coin}
                              <br />
                              Amount: {item.amount}
                              <br />
                              Leverage: {item.leverage}
                              <br />
                            </div>
                          </Link>
                          {
                              (item.type === 'buy') ? (<div  className="coll l2 s3" style={{ color: "green" }}>Buy</div>) : (<div className="coll l2 s3" style={{ color: "red" }}>Sell</div>)
                            }
                        </div>
                      </li >
                  ))
                ) : (
                    <li className="collection-item app-py-2">
                      <p id="no-data" style={{ "textAlign": "center" }}>
                        <span className="app-font-normal">NO OPEN TRADES</span>
                      </p>
                    </li>
                )
              }
            </ul>
          </div>
        </div><br /><br />
      </section>
      <BottomNav trades='active' />
    </>
  )
}

export default UserTrades
