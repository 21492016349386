import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { InfinitySpin } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../authentication/userAuth';

const AddressVerification = () => { 
    const navigate = useNavigate();
    const { user } = useAuth();
    const [isLoading, setIsloading] = useState(false);
    const [filename, setFilename] = useState('');


    useEffect(() => { 
        if (user.addressVerificationStatus) {
            navigate('/user');
        }
    }, [navigate, user.addressVerificationStatus])


    const uploadFile = (e) => {
        setFilename(e.target.files[0].name)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true);

        try {
            const formData = new FormData();
            formData.append("uploader_id", user.owner_id);
            formData.append(`files`, e.target.bill.files[0]);

            const resp = await fetch("https://blazesignal.com/api/user/verification/address-upload", {
                method: 'POST',
                body: formData,
            });

            const response = await resp.json();

            if (response.status === 200) {
                toast(response.message);
                setIsloading(false)
                setTimeout(() => {
                    navigate('/user/account');
                }, 2000);
            } else {
                toast(response.message);
                setIsloading(false);
            }

        } catch (err) {
            setIsloading(false);
            toast(err.message);
        }
    }
    return (
        <>
            <div className="roww">
                <div className="coll l6 s12 offset-l3"><br /><br />
                    <div className="cardd-panel">
                        <center>
                            <h2>Address Verification</h2>
                            <p></p>
                        </center><br />
                        <b>City</b> {user.city} <br />
                        <b>State</b> {user.state} <br />
                        <b>Phone</b> {user.phone}<br />
                        <b>Country</b> {user.country}<br />
                        <b>Email address</b> {user.email}<br />
                        <b>Currency</b> {user.currency}
                        <br /><br />
                        <form encType="multipart/form-data" onSubmit={handleSubmit}>
                            <div>
                                <div className="file-field input-field">
                                    <div className="btnn btnn-secondary">
                                        <span>select bill</span>
                                        <input type="file" accept=".jpg,.png,.jpeg" id="bill" name="bill" onChange={uploadFile} required />
                                    </div>
                                    <div className="file-path-wrapper">
                                        <input className="file-path validate" type="text" defaultValue={filename} />
                                    </div>
                                </div>
                            </div>
                            {
                                isLoading ? (
                                    <InfinitySpin
                                        visible={true}
                                        width="200"
                                        color="#4fa94d"
                                        ariaLabel="infinity-spin-loading"
                                    />
                                ) : (
                                    <>
                                        <div><button type="submit" className="btnn btnn-full">Submit</button></div>
                                    </>
                                )
                            }
                        </form><br />

                        <ToastContainer />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddressVerification
