import React, { useEffect } from 'react';
import BottomNav from './components/bottomnav';
import UserWatchList from './trading/watchlist';
import { Link } from 'react-router-dom';
import { useAuth } from '../authentication/userAuth';
const HomeDashboard = () => { 
    const { user, refreshUserData } = useAuth();
    useEffect(() => {
        const getUser = async () => {
            const resp = await fetch('https://blazesignal.com/api/user/profile', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ id: user.owner_id })
            });
            const response = await resp.json();
            refreshUserData(response)
        }
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <section className="container">
                <div className="roww">
                    <div className="coll l4 s12 ">
                        <div className="bg bg-secondary white-text dashboard-trading notranslate app-px-1 app-py-1" style={{ "background": "url('https://blazesignal.com/assets/images/site/DashboardTrading.png')", backgroundSize: "cover", backgroundPosition: "bottom", backgroundRepeat: "no-repeat" }}>
                            <div className="roww center white-text">
                                <div className="coll l6 s6 app-py-1">
                                    <h3 className="f-20 white-text">${user.trading_deposit}</h3>
                                    <span style={{ "fontSize": "11px" }}>DEPOSIT BALANCE </span>
                                </div>
                                <div className="coll l6 s6 app-py-1">
                                    <h3 className="f-20 white-text">${user.trading_profit}</h3><span style={{ "fontSize": "11px" }}>PROFIT BALANCE</span>
                                </div>
                            </div><br />
                            <center>
                                <progress value={user.signal_} max="100" style={{ "width": "100%", "display": "block", "marginTop": "4px" }}></progress>
                                <small>SIGNAL STRENGTH</small>
                            </center>
                        </div>
                        <br />
                        <div className="roww center">
                            <div className="coll s6" style={{ "cursor": "pointer" }}>
                                <Link to="/user/plans/categories/trading" style={{ color: "#060642" }}>
                                    <h6 className='sg'>TRADING PLAN</h6>{user.tradingplan}
                                </Link>
                            </div>
                            <div className="coll s6" style={{ "cursor": "pointer" }}>
                                <Link to="/user/account/verification/home" style={{ color: "#060642" }}>
                                    <h6 className='sg'>ID VERIFICATION</h6>{
                                        <span>{
                                            (user.idVerifyStatus === "1") ? (
                                                <Link to="#" style={{ color: "#060642" }}>
                                                    Done
                                                </Link>
                                            ) : (
                                                (user.idVerifyStatus === "3") ? (
                                                    <Link to="/user/account/verification/home" style={{ color: "#060642" }}>
                                                        Under review
                                                    </Link>
                                                ) : (
                                                    <Link to="/user/account/verification/home" style={{ color: "#060642" }}>
                                                        Not verified
                                                    </Link>
                                                )
                                            )
                                        }</span>
                                    }
                                </Link>
                            </div>
                        </div><br /><br />
                        <div className="roww">
                            <div className="coll l4 s4 center">
                                <Link to="/user/deposits/list" style={{ color: "#060642" }}>
                                    <center>
                                        <span className="material-icons notranslate" style={{ "fontSize": "30px" }}>system_update_alt</span>
                                        <br /><span>Deposit</span>
                                    </center>
                                </Link>
                            </div>
                            <div className="coll l4 s4 center">
                                <Link to="/user/traders/" style={{ color: "#060642" }}>
                                    <center>
                                        <span className="material-icons notranslate" style={{ "fontSize": "30px" }}>group</span><br />
                                        <span>Copy Traders</span>
                                    </center>
                                </Link>
                            </div>
                            <div className="coll l4 s4 center">
                                <Link to="/user/referals" style={{ color: "#060642" }}>
                                    <center>
                                        <span className="material-icons notranslate" style={{ "fontSize": "30px" }}>savings</span><br />
                                        <span>Refer &amp; Earn</span>
                                    </center>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="coll l8 s12"><br />
                        <UserWatchList />
                    </div>
                </div>
            </section>
            <BottomNav home='active' />
        </>
    )
}
export default HomeDashboard