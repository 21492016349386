import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { InfinitySpin } from 'react-loader-spinner';
import { useAuth } from '../../authentication/userAuth';
import { useNavigate } from 'react-router-dom';
 
const PhotoUpdate = () => {
    const [filename, setFilename] = useState('');
    const { user, refreshUserData } = useAuth();
    const [isLoading, setIsloading] = useState(false);
    const navigate = useNavigate();

    const getUser = async () => {
        const resp = await fetch('https://blazesignal.com/api/user/profile', {
            method: "POST", 
            headers: {
            "content-type": "application/json"
            },
            body:JSON.stringify({id:user.owner_id})
        });
        const response = await resp.json();
        refreshUserData(response)
    }

    const uploadFile = (e) => {
        setFilename(e.target.files[0].name);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true);

        try {
            const formData = new FormData();
            formData.append("id", user.owner_id);
            formData.append(`photo`, e.target.photo.files[0]);

            const resp = await fetch("https://blazesignal.com/api/user/account/update/photo", {
                method: 'POST',
                body: formData,
            });

            const response = await resp.json();

            if (response.status === 200) {
                toast(response.message);
                setIsloading(false);
                getUser();
                setTimeout(() => {
                    navigate('/user/account');
                }, 2000);
            } else {
                toast.error(response.message);
            }

        } catch (err) {
            setIsloading(false);
            toast.error(err.message);
        }
    }

    return (
        <>
            <div className="roww app-py-1"><br />
                <div className="coll l6 offset-l3 s12">
                    <div className="cardd-panel app-py-1">
                        <center>
                            <h3>Update Photo</h3><br />
                            <img src={`https://blazesignal.com/uploads/${user.profilephoto}`} className="circle" style={{width:"150px", height:"150px"}} alt=""/>
                            <form encType="multipart/form-data" onSubmit={handleSubmit}>
                                <div>
                                    <div className="file-field input-field">
                                        <div className="btnn btnn-secondary">
                                            <span>select photo</span>
                                            <input type="file" accept=".jpg,.png,.jpeg" id="photo" name="photo" onChange={uploadFile} required />
                                        </div>
                                        <div className="file-path-wrapper">
                                            <input className="file-path validate" type="text" defaultValue={filename} />
                                        </div>
                                    </div>
                                </div>
                                {
                                    isLoading ? (
                                        <InfinitySpin
                                            visible={true}
                                            width="200"
                                            color="#4fa94d"
                                            ariaLabel="infinity-spin-loading"
                                        />
                                    ) : (
                                            <div><button type="submit" className="btnn btnn-full">Update</button></div>
                                       
                                    )
                                }
                            </form>
                            <ToastContainer />
                        </center>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PhotoUpdate