import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const CopyTradingSingle = () => {
    const {id} = useParams();
    const [trader, setTrader] = useState({})
    useEffect(() => {
        async function fetchData() {
            const response = await fetch(`https://blazesignal.com/api/user/traders/single/`, {
                method: "POST",
                headers: { 
                    "content-type": "application/json"
                },
                body: JSON.stringify({ id })
            });
            const resp = await response.json();
            setTrader(resp.trader);
        }
        fetchData();
    }, [id])
    return (
        <>
            <section className="roww">
                <div className="coll l4 offset-l4 s12 center">
                    <div className="cardd-panel">
                        <center>
                            <img src={`https://blazesignal.com/uploads/images/${trader?.image}`} className="circle responsive-img" style={{ "maxHeight": "150px" }} alt="" />
                            <h2>{trader?.name}</h2>
                            <p></p><br />
                            <table className="striped"> 
                                <tbody>
                                    <tr className="roww">
                                        <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>trades</td>
                                        <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>{trader?.trades}</td>
                                    </tr>
                                    <tr className="roww">
                                        <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>total wins</td>
                                        <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>{trader?.total_wins}</td>
                                    </tr>
                                    <tr className="roww">
                                        <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>followers</td>
                                        <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>{trader?.followers}</td>
                                    </tr>
                                    <tr className="roww">
                                        <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>total losses</td>
                                        <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>{trader?.total_losses}</td>
                                    </tr>
                                    <tr className="roww">
                                        <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>win percent</td>
                                        <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>{trader?.win_percent}%</td>
                                    </tr>
                                    <tr className="roww">
                                        <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>profit share</td>
                                        <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>{trader?.profit_share}%</td>
                                    </tr>
                                    <tr className="roww">
                                        <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>Minimum</td>
                                        <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>${trader?.minimum}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </center>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CopyTradingSingle
