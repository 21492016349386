import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

const WithdrawSingle = () => {
    const navigate = useNavigate();
    const [withdraw, setWithdraw] = useState({})
    const {id} = useParams();
    useEffect(() => {
      const fetchData = async () => {
        const data = await fetch(`https://blazesignal.com/api/user/withdrawals/single`, {
          method: "POST",
          headers: {
            "content-type": "application/json"
          }, 
          body: JSON.stringify({id})
        })
        const response = await data.json();
        if(response.status === 200) {
            setWithdraw(response.withdraw);
        }else{
          navigate('/user/withdrawals/list');
        }  
      }
      fetchData();
    }, [navigate, id])
    return (
      <>
        <section className="container">
          <div className="cardd-panel">
            <div className="roww">
              <div className="coll l9 s12">
                <table className="striped">
                  <tbody>
                    <tr className="roww">
                      <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>Date</td>
                      <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>{withdraw.createdAt}</td>
                    </tr>
                    <tr className="roww">
                      <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>Wallet to be funded </td>
                      <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>{withdraw.currency}</td>
                    </tr>
                    <tr className="roww">
                      <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>Wallet address</td>
                      <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>{withdraw.to_wallet}</td>
                    </tr>
                    <tr className="roww">
                      <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>Status</td>
                      <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>{withdraw.status}</td>
                    </tr>
                    <tr className="roww">
                      <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>Amount</td>
                      <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>${withdraw.amount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
export default WithdrawSingle
