import React, {useState } from 'react'
import { InfinitySpin } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../authentication/userAuth';


const EmailVerificationLanding = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsloading] = useState(false);
    const [isLoading2, setIsloading2] = useState(false);

    const [pin, setPin] = useState('');

    const [error, setError] = useState('');
    const [error2, setError2] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true)

        try {
            let data = {
                code: pin,
                email: user.email
            }

            const resp = await fetch('https://blazesignal.com/api/user/verification/email', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(data)
            });
            const response = await resp.json();
            setTimeout(() => {
                setIsloading(false)
            }, 2000)

            if (response.status === 200) {
                toast.success(response.message);

                setTimeout(() => {
                    navigate('/verify/identity');
                }, 2200)
            } else {
                setTimeout(() => {
                    setError(response.message)
                    toast.error(response.message)
                }, 2100)

            }

        } catch (err) {
            setIsloading(false)
            setError(err.message)
            toast.error(err.message)
        }
    }

    const handleResend = async (e) => {
        e.preventDefault();

        setIsloading2(true);
        try {
            let data = {
                email: user.email
            }

            const resp = await fetch('https://blazesignal.com/api/user/verification/resend-email', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(data)
            });
            const response = await resp.json();
            setTimeout(() => {
                setIsloading2(false)
            }, 2000)

            if (response.status === 200) {
                toast.success(response.message)
            } else {
                setTimeout(() => {
                    setError2(response.message)
                    toast.error(response.message)
                }, 2100)
            }

        } catch (err) {
            setIsloading2(false)
            setError2(err.message)
            toast.error(err.message)
        }
    }

    const goBack = () => {
        logout();
        navigate("/login", { replace: true });
    }

    return (
        <>
            <div className="roww">
                <div className="col l4 s12 offset-l4"><br />
                    <div className="cardd-panel" style={{ "borderRadius": "10px" }}>
                        <div className="container">
                            {
                                user&&(
                                    <span className="material-symbols-outlined" onClick={goBack}>arrow_back</span>
                                )
                            }
                            <center>
                                <h3 className="btnn-color">Email Verification</h3><br />
                                <p>An email containing your 5-digit PIN has been sent to your email If you have not received it in a minute or two, click ‘Resend’.</p><br />
                                <form autoComplete="off" onSubmit={handleSubmit}>
                                    <div className="roww">
                                        <div>
                                            <div className="input-field">
                                                <label className="active" htmlFor="pin">pin</label>
                                                <input
                                                    type="number"
                                                    id="pin"
                                                    name="pin"
                                                    className=""
                                                    onChange={(e) => setPin(e.target.value)}
                                                    value={pin}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        isLoading ? (
                                            <InfinitySpin
                                                visible={true}
                                                width="200"
                                                color="#4fa94d"
                                                ariaLabel="infinity-spin-loading"
                                            />
                                        ) : (
                                            <>
                                                <div>
                                                    <button type="submit" className="btnn btnn-full">VERIFY EMAIL</button>
                                                </div><br /><br />
                                                {
                                                    error && (
                                                        <div className="red black-text lighten-4 card-panel text-center" style={{ "padding": "30px" }}><span>{error}</span><br /></div>
                                                    )
                                                }
                                            </>
                                        )
                                    }

                                </form><br /><br />
                                <form autoComplete="off" onSubmit={handleResend}>
                                    <div className="roww">
                                        <div className="input-field undefined">
                                            <input type="email" id="email" name="email" autoComplete="new-email" required readOnly defaultValue={user.email} />
                                            <label className="active" htmlFor="email">email</label>
                                        </div>
                                    </div>

                                    {
                                        isLoading2 ? (
                                            <InfinitySpin
                                                visible={true}
                                                width="200"
                                                color="#4fa94d"
                                                ariaLabel="infinity-spin-loading"
                                            />
                                        ) : (
                                            <>
                                                <div><button type="submit" className="btnn btnn-full">RESEND PIN</button></div>
                                                <br /><br />
                                                {
                                                    error2 && (
                                                        <div className="red black-text lighten-4 card-panel text-center" style={{ "padding": "30px" }}><span>{error2}</span><br /></div>
                                                    )
                                                }
                                            </>
                                        )
                                    }


                                </form><br /><br />
                                
                            </center>
                            <ToastContainer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailVerificationLanding
